import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocalStorage } from '@rehooks/local-storage'
import Box from '@mui/material/Box'
import LoadingPageCircular from '../../../modules/ELearning/components/LoadingPageCircular'
import { StyledFormWrapper } from './Form/Styled'
import Header from './components/header'
import TabMenu from './components/tabMenu'
import { EContentListTab } from './Listing'
import { useParams } from 'react-router-dom'
import { initialConfig } from '../../../components/CRUD/handler/initialConfig'
import { crudConfig as initialCrudConfig } from './crudConfig'
import _ from 'lodash'
import { setFieldValue } from '../../../redux/slices/crud'
import { getBodyMutation } from './Form/mutation/get'
import { fetchDetail } from '../../../components/CRUD/handler/fetchDetail'
import { setupLevel } from './Form/handler/setupLevel'
import { initialForm } from './Form/handler/initialForm'
import { LIST_TAB } from './components/tabMenu/model'
import { KnowledgeBaseListTab } from './KnowledgeBase'

export const EContentList = () => {
  const { uuid } = useParams()
  const [user] = useLocalStorage('user')
  const [listTab, setListTab] = useState(LIST_TAB.eContent)
  const { crudConfig, isLoading } = useSelector(
    (state) => ({
      crudConfig: state.crud.crudConfig,
      isLoading: state.crud.isLoading,
      listTab: state.crud.listTab,
    }),
    shallowEqual,
  )
  const dispatch = useDispatch()

  const initializeCrud = async () => {
    if (uuid && !_.isUndefined(crudConfig)) {
      await fetchDetail(uuid, getBodyMutation, user)
      setupLevel()
    } else if (!uuid && !_.isUndefined(crudConfig)) {
      dispatch(
        setFieldValue({ key: 'formData', value: getBodyMutation({}, user) }),
      )
    } else {
      await initialForm(user)
      initialConfig(initialCrudConfig)
    }
  }

  useEffect(initializeCrud, [crudConfig])

  return (
    <>
      <LoadingPageCircular isLoading={isLoading} />
      {!_.isUndefined(crudConfig) && (
        <Box sx={{ mx: 3 }}>
          <div>
            <Header />
            <TabMenu tab={listTab} setListTab={setListTab} />
            <ListContent tab={listTab} />
          </div>
        </Box>
      )}
    </>
  )
}

export const ListContent = ({ tab }) => {
  return (
    <StyledFormWrapper sx={{ pt: 2 }}>
      {tab === LIST_TAB.eContent && <EContentListTab tab={tab} />}
      {tab === LIST_TAB.knowledgeBase && <KnowledgeBaseListTab tab={tab} />}
    </StyledFormWrapper>
  )
}
