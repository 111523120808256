export const arrayStatus = [
  {
    label: 'ฉบับร่าง',
    name: 'draftChecked',
  },
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const defaultFilter = {
  nameChecked: false,
  name: '',
  pointChecked: false,
  point: '',
  badgeNameChecked: false,
  badgeName: '',
  statusChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
}

export const arrayCheckbox = [
  {
    label: 'ชื่อคะแนน',
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    placeholder: 'ค้นหาชื่อคะแนน',
    type: 'text',
  },
  {
    label: 'จำนวนคะแนน',
    nameCheckBox: 'pointChecked',
    nameInput: 'point',
    placeholder: 'ค้นหาจำนวนคะแนน',
    type: 'text',
  },
  {
    label: 'ชื่อเหรียญรางวัล',
    nameCheckBox: 'badgeNameChecked',
    nameInput: 'badgeName',
    placeholder: 'ค้นหาชื่อเหรียญรางวัล',
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: arrayStatus,
  },
]
