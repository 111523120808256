import { COLUMN_NAME } from '../../../../constants/reportCourseSetting/reportCourseSetting'
import { defaultColumns } from '../../../../constants/report/courseSetting'
import axios from 'axios'
import _ from 'lodash'
import { setReduxValue } from '.'
import { store } from '../../../../App'
import { getOptionsKey } from '../../../../utils/lib'
import {
  approvalOptions,
  courseLevelControlOptions,
  isAttendanceOptions,
  isAvailableOptions,
  listAPIFetchOptions,
  requireOptions,
  statusOptions,
} from './model'
import { getOptionsReportCourseSettingByKey } from '../../../../services/reportCourseSetting'

export const fetchOptions = () => async (dispatch) => {
  const options = await Promise.all(
    _.map(listAPIFetchOptions, (item) => {
      return axios({ method: item.method, url: item?.url, data: item?.body })
    }),
  ).then((response) => {
    return _.map(response, (item) => {
      return _.get(item, 'data.result', [])
    })
  })

  dispatch(setReduxValue({ key: 'fetchMasterDataOptions', value: options }))
}

export const setMasterDataOptions = (column) => async (dispatch) => {
  const { fetchMasterDataOptions } = store.getState().reportCourseSetting
  let options = []
  switch (column) {
    case COLUMN_NAME.ROADMAP:
    case COLUMN_NAME.ABBREVIATION_ROADMAP:
      options = _.get(fetchMasterDataOptions, '[0]', [])
      break
    case COLUMN_NAME.TRAINING_PLATFORM:
      options = _.get(fetchMasterDataOptions, '[1]', [])
      break
    case COLUMN_NAME.PRODUCT_TYPE:
      options = _.get(fetchMasterDataOptions, '[2]', [])
      break
    case COLUMN_NAME.DISTRIBUTION:
    case COLUMN_NAME.ABBREVIATION_DISTRIBUTION:
      options = _.get(fetchMasterDataOptions, '[3]', [])
      break
    case COLUMN_NAME.ACQUIRED_SKILL:
      options = _.get(fetchMasterDataOptions, '[4]', [])
      break
    case COLUMN_NAME.LEVEL_OF_LEARNER:
    case COLUMN_NAME.OTHER_LEVEL_OF_LEARNER:
      options = _.get(fetchMasterDataOptions, '[5]', [])
      break
    case COLUMN_NAME.COURSE_LEVEL_CONTROL:
      options = courseLevelControlOptions
      break
    default:
      break
  }

  dispatch(
    setReduxValue({
      key: 'masterDataOptions',
      value: getOptionsKey({
        data: options,
        label:
          column === COLUMN_NAME.ABBREVIATION_ROADMAP ||
          column === COLUMN_NAME.ABBREVIATION_DISTRIBUTION
            ? 'abbreviation'
            : column,
        key: column === COLUMN_NAME.COURSE_LEVEL_CONTROL ? 'value' : 'uuid',
      }),
    }),
  )
}

export const setOptions = (column) => async (dispatch) => {
  let options = []
  switch (column) {
    case COLUMN_NAME.IS_APPROVED:
      options = approvalOptions
      break
    case COLUMN_NAME.HAS_BREAK_MEAL:
    case COLUMN_NAME.HAS_DOCUMENT:
    case COLUMN_NAME.HAS_PREREQUISITE:
    case COLUMN_NAME.AGENT_CONFIRMATION:
      options = isAvailableOptions
      break
    case COLUMN_NAME.REQUIRE_OIC_EXAM:
    case COLUMN_NAME.REQUIRE_DOCUMENT:
      options = requireOptions
      break
    case COLUMN_NAME.IS_ATTENDANCE:
      options = isAttendanceOptions
      break
    case COLUMN_NAME.STATUS:
      options = statusOptions
      break

    default:
      break
  }

  dispatch(
    setReduxValue({
      key: 'options',
      value: options,
    }),
  )
}

export const handleDisabledColumnOptions = () => (dispatch) => {
  const { displayColumns, columnOptions } = store.getState().reportCourseSetting
  const idColumnArr = _.map(displayColumns, 'name')

  const newColumnOptions = _.map(columnOptions, (column) => {
    let disabled = _.includes(idColumnArr, column?.name)
    if (column?.name === COLUMN_NAME.STAFF_ID_LIST) {
      const staffIdList = [
        'staffId_1',
        'staffId_2',
        'staffId_3',
        'staffId_4',
        'staffId_5',
        'staffIdList',
      ]
      disabled = _.some(idColumnArr, (key) => _.includes(staffIdList, key))
    }
    if (column?.name === COLUMN_NAME.STAFF_NAME_LIST) {
      const staffNameList = [
        'staffName_1',
        'staffName_2',
        'staffName_3',
        'staffName_4',
        'staffName_5',
        'staffNameList',
      ]
      disabled = _.some(idColumnArr, (key) => _.includes(staffNameList, key))
    }

    return { ...column, disabled: disabled }
  })
  const sortedColumns = handleSortColumns(newColumnOptions)
  dispatch(setReduxValue({ key: 'columnOptions', value: sortedColumns }))
}

export const fetchOptionsReportCourseSettingByKey = () => async (dispatch) => {
  const { columnSelected } = store.getState().reportCourseSetting
  const { payload: response } = await dispatch(
    getOptionsReportCourseSettingByKey({
      columnSelected: columnSelected,
    }),
  )

  const options = _.get(response, 'results', [])
  dispatch(
    setReduxValue({
      key: 'options',
      value: options,
    }),
  )
}

export const handleColumnOptions = () => (dispatch) => {
  const { isOpenFilterDrawer } = store.getState().reportCourseSetting
  const staffColNameList = [
    COLUMN_NAME.STAFF_ID_1,
    COLUMN_NAME.STAFF_ID_2,
    COLUMN_NAME.STAFF_ID_3,
    COLUMN_NAME.STAFF_ID_4,
    COLUMN_NAME.STAFF_ID_5,
    COLUMN_NAME.STAFF_NAME_1,
    COLUMN_NAME.STAFF_NAME_2,
    COLUMN_NAME.STAFF_NAME_3,
    COLUMN_NAME.STAFF_NAME_4,
    COLUMN_NAME.STAFF_NAME_5,
  ]
  let columnList = defaultColumns
  if (isOpenFilterDrawer) {
    const staffColumns = _.filter(defaultColumns, (col) =>
      _.includes(staffColNameList, col.value),
    )
    const differenceColumns = _.difference(defaultColumns, staffColumns)
    const staffListColName = [
      {
        id: 43,
        name: COLUMN_NAME.STAFF_ID_LIST,
        value: COLUMN_NAME.STAFF_ID_LIST,
        label: 'Staff ID ผู้จัดทำ',
        minWidth: 160,
      },
      {
        id: 44,
        name: COLUMN_NAME.STAFF_NAME_LIST,
        value: COLUMN_NAME.STAFF_NAME_LIST,
        label: 'ชื่อผู้จัดทำ',
        minWidth: 160,
      },
    ]
    columnList = [...differenceColumns, ...staffListColName]
  }

  const filteredColumns = _.filter(
    columnList,
    (col) =>
      col.value === COLUMN_NAME.CREATED_AT ||
      col.value === COLUMN_NAME.CREATED_BY ||
      col.value === COLUMN_NAME.UPDATED_AT ||
      col.value === COLUMN_NAME.UPDATED_BY,
  )

  const diffColumns = _.difference(columnList, filteredColumns)
  const columnOptions = [...diffColumns, ...filteredColumns]
  const sortedColumns = handleSortColumns(columnOptions)

  dispatch(setReduxValue({ key: 'columnOptions', value: sortedColumns }))
}

export const handleSortColumns = (columns) => {
  return columns.sort((a, b) => a.label.localeCompare(b.label))
}
