import _ from 'lodash'
import { store } from '../../../App'
import {
  resetTablePage,
  setDefaultSort,
  setFilterProp,
  setFilterTotal,
  setInitialTable,
  setOrder,
  setSearchText,
  startLoading,
  stopLoading,
} from '../../../redux/slices/table'
import { headCells } from './model'
import callAxios from '../../../utils/baseService'
import { skuHolderFilter } from '../../../utils/apiPath'
import { PRODUCT_TYPE_VIEW_TEXT } from '../../../constants/stock'
import { convertFormatDateTime } from '../../../utils/lib'

export const getTableState = () => {
  const state = store.getState()
  return _.get(state, 'table', {})
}

export const handleEquipmentTable = (props) => async (dispatch) => {
  const tableState = getTableState()
  const { table, search } = tableState
  const page = _.get(props, 'page', _.get(table, 'page', -1))
  const realPage = page <= 0 ? 1 : +page
  const sort = _.get(table, 'sort', 'updatedAt')
  const uuid = location.pathname.split('/').pop()
  const body = {
    uuid,
    limit: _.get(table, 'limit', 20),
    page: realPage,
    order: _.get(table, 'order', 'desc').toString().toUpperCase(),
    sort,
    status: [],
    search: search.searchText || '',
  }

  dispatch(startLoading())
  let response = await callAxios.post(skuHolderFilter, body)
  dispatch(stopLoading())
  const initTable = dispatch(handleInitTable(response))
  dispatch(setInitialTable(initTable))
}

export const handleInitTable = (response) => (dispatch) => {
  const payload = _.get(response, 'data', {})
  const rows = _.map(_.get(payload, 'result', []), (item) => {
    return {
      ...item,
      productName: _.get(item, 'productWarehouse.product.productName', '-'),
      productType:
        PRODUCT_TYPE_VIEW_TEXT[
          _.get(item, 'productWarehouse.product.productType', '-')
        ],
      warehouseName: _.get(
        item,
        'productWarehouse.warehouse.warehouseName',
        '-',
      ),
      updatedAt: convertFormatDateTime({
        value: _.get(item, 'ticket.updatedAt'),
        type: 'dateTime',
      }),
    }
  })
  return {
    isCheckBox: false,
    rows,
    allCount: _.get(payload, 'totalCount', 0),
    headCells,
    placeholder: 'ค้นหาด้วยคีย์เวิร์ด',
    searchKey: 'keyword',
    tableHeadText: 'อุปกรณ์ที่ถือครอง',
    tableHeadVariant: 'h6',
    handleSearch: (text) => dispatch(handleQuickSearch(text)),
  }
}

export const handleQuickSearch = (keyword) => (dispatch) => {
  const tableState = getTableState()
  const sort = _.get(tableState.table, 'sort', 'updatedAt')
  dispatch(setSearchText(keyword))
  dispatch(resetTabelState())
  if (sort === 'updatedAt') dispatch(handleEquipmentTable({ page: 1 }))
}

export const resetTabelState = () => (dispatch) => {
  dispatch(setFilterProp(null))
  dispatch(setFilterTotal(0))
  dispatch(setDefaultSort({ state: 0, id: '', active: false }))
  dispatch(resetTablePage())
  dispatch(setOrder({ order: 'desc', sort: 'updatedAt' }))
}
