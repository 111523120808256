import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Close from '@mui/icons-material/Close'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Typography from '@mui/material/Typography'

import { setToggleFilterDrawer } from '../../../redux/slices/table'
import DateRangeInput from '../../../components/DateRangeInput'
import SelectIcon from '../../../components/redux/SelectIcon'
import SelectGroupChip from '../../../components/SelectGroupChip'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
  StyledList,
} from '../../../components/FilterDrawer/Styled'
import { arrayCheckbox, arrayStatus, arrayType, defaultFilter } from './model'
import * as events from './events'
import {
  handleNumberInput,
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../utils/lib'

export const ListCheckbox = (props) => {
  const { arrayCheckbox, stateFilter, setFilter, isFeedback } = props
  const { id: uuid } = useParams()
  const [courseDepartmentOption, setCourseDepartmentOption] = useState([])
  const [staffOption, setStaffOption] = useState([])
  const dispatch = useDispatch()
  const staffOptionList = stateFilter.course === '' ? [] : staffOption

  useEffect(() => {
    if (isFeedback)
      dispatch(events.fetchCourseOrDepartment(uuid, setCourseDepartmentOption))
  }, [])

  return arrayCheckbox.map((item, index) => {
    const next3Month = new Date()
    next3Month.setMonth(next3Month.getMonth() + 3)

    return (
      <StyledGroupBox key={index}>
        <FormGroup sx={{ position: 'relative' }}>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  'data-testid': `check-${item.nameCheckBox}`,
                }}
                name={item.nameCheckBox}
                disabled={
                  item.nameCheckBox === 'staffChecked' &&
                  _.isEmpty(stateFilter.course)
                }
                checked={stateFilter[item.nameCheckBox]}
                onChange={(e) => {
                  events.handleChange({
                    value: e.target.checked,
                    key: e.target.name,
                    filter: stateFilter,
                    setFilter,
                  })
                }}
              />
            }
            label={item.label}
            sx={!stateFilter[item.nameCheckBox] ? { marginBottom: -1 } : {}}
          />

          {stateFilter[item.nameCheckBox] && (
            <>
              {item.type === 'date' && (
                <DateRangeInput
                  maxDate={item.maxDate ? next3Month : undefined}
                  dateState={{
                    startDate: stateFilter.startDate
                      ? stateFilter.startDate
                      : new Date(),
                    endDate: stateFilter.toDate
                      ? stateFilter.toDate
                      : new Date(),
                    display: stateFilter.displayDate,
                    key: 'selection',
                  }}
                  onChange={(selectedDates) => {
                    events.handleSelectDate(
                      selectedDates,
                      stateFilter,
                      setFilter
                    )
                  }}
                />
              )}
              {item.type === 'select' && (
                <SelectIcon
                  dataTestId="select-positionOne"
                  listOption={courseDepartmentOption} // fetch data from api
                  placeholder={item.placeholder}
                  value={stateFilter[item.nameInput]}
                  handleChange={(e) => {
                    events.handleChange({
                      key: item.nameInput,
                      value: e.target.value,
                      filter: stateFilter,
                      setFilter,
                    })
                    dispatch(
                      events.fetchStaffs(uuid, e.target.value, setStaffOption)
                    )
                  }}
                />
              )}
              {item.type === 'selectMultiple' && (
                <SelectGroupChip
                  boxSx={{ maxWidth: 278 }}
                  menuSx={{ maxWidth: 278, width: 278, top: 80 }}
                  id={item.nameInput}
                  name={item.nameInput}
                  placeholder={item.placeholder}
                  options={
                    item.nameInput === 'staff'
                      ? staffOptionList
                      : courseDepartmentOption
                  }
                  showTextError={false}
                  value={stateFilter[item.nameInput]}
                  onChange={(list) => {
                    events.handleChange({
                      key: item.nameInput,
                      value: list,
                      filter: stateFilter,
                      setFilter,
                    })
                  }}
                />
              )}
              {(!item.type || item.type === 'text') && (
                <InputBase
                  sx={{ borderRadius: 0 }}
                  type="basic"
                  inputProps={
                    item.nameInput === 'version'
                      ? {
                          'data-testid': `input-${item.nameInput}`,
                          type: 'number',
                          min: '1',
                          onInput: handleNumberInput,
                          onKeyDown: handleNumberKeyDown,
                          onPaste: handlePasteFormat,
                        }
                      : { 'data-testid': `input-${item.nameInput}` }
                  }
                  name={item.nameInput}
                  disabled={!stateFilter[item.nameCheckBox]}
                  placeholder={item.placeholder}
                  value={stateFilter[item.nameInput]}
                  onChange={(e) => {
                    events.handleChange({
                      key: e.target.name,
                      value: e.target.value,
                      filter: stateFilter,
                      setFilter,
                    })
                  }}
                />
              )}
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
    )
  })
}

export const ListType = ({ type, filter, setFilter }) => {
  return arrayType.map((item, index) => {
    return (
      <FormControlLabel
        key={index}
        label={item.label}
        control={
          <Checkbox
            inputProps={{ 'data-testid': `check-${item.name}` }}
            name={item.name}
            checked={type[item.name]}
            onChange={(e) => {
              events.handleChange({
                value: e.target.checked,
                key: e.target.name,
                listKey: 'type',
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}

export const ListStatus = ({ drawerType, status, filter, setFilter }) => {
  return arrayStatus.map((item, index) => {
    if (drawerType === 'eevaluation' && index <= 1) {
      return
    }
    return (
      <FormControlLabel
        key={index}
        label={item.label}
        control={
          <Checkbox
            inputProps={{ 'data-testid': `check-${item.name}` }}
            name={item.name}
            checked={status[item.name]}
            onChange={(e) => {
              events.handleChange({
                value: e.target.checked,
                key: e.target.name,
                listKey: 'status',
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}

const FilterDrawer = ({ open, table, drawerType }) => {
  const dispatch = useDispatch()
  const [filter, setFilter] = useState(defaultFilter[drawerType])
  const [filterMemo, setFilterMemo] = useState()
  const { id } = useParams()

  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter(defaultFilter[drawerType])
  }, [open])

  return (
    <StyledDrawer
      open={open}
      onClose={() => dispatch(setToggleFilterDrawer(false))}
    >
      <StyledHeadLabel>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setToggleFilterDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      <ListCheckbox
        arrayCheckbox={arrayCheckbox[drawerType]}
        stateFilter={filter}
        setFilter={setFilter}
        isFeedback={
          drawerType === 'feedbackClass' || drawerType === 'feedbackStandalone'
        }
      />

      {drawerType === 'eevaluation' && (
        <StyledGroupBox sx={{ flexDirection: 'column' }}>
          <FormControlLabel
            label="ประเภท"
            control={
              <Checkbox
                inputProps={{
                  'data-testid': 'check-typeChecked',
                }}
                name="typeChecked"
                checked={filter.typeChecked}
                onChange={(e) => {
                  events.handleChange({
                    value: e.target.checked,
                    key: e.target.name,
                    filter,
                    setFilter,
                  })
                }}
              />
            }
          />
          {filter.typeChecked && (
            <StyledList>
              <ListType
                drawerType={drawerType}
                type={filter.type}
                filter={filter}
                setFilter={setFilter}
              />
            </StyledList>
          )}
        </StyledGroupBox>
      )}

      {drawerType !== 'feedbackClass' && drawerType !== 'feedbackStandalone' && (
        <StyledGroupBox sx={{ flexDirection: 'column', mb: 2 }}>
          <FormControlLabel
            label="สถานะ"
            control={
              <Checkbox
                inputProps={{
                  'data-testid': 'check-statusChecked',
                }}
                name="statusChecked"
                checked={filter.statusChecked}
                onChange={(e) => {
                  events.handleChange({
                    value: e.target.checked,
                    key: e.target.name,
                    filter,
                    setFilter,
                  })
                }}
              />
            }
          />
          {filter.statusChecked && (
            <StyledList>
              <ListStatus
                drawerType={drawerType}
                status={filter.status}
                filter={filter}
                setFilter={setFilter}
              />
            </StyledList>
          )}
        </StyledGroupBox>
      )}

      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => setFilter(defaultFilter[drawerType])}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          onClick={() => {
            dispatch(events.handleFiltering(table, filter, drawerType, id))
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
