import { handleSearch } from '../../../../src/redux/slices/manage/prospectProfile'

export const onEnterSearch = (e, searchKey) => (dispatch) => {
  if (e.charCode === 13) {
    dispatch(
      handleSearch({
        key:
          searchKey === 'courseSearch'
            ? 'handleCourseSearch'
            : 'handleExamSearch',
        value: e.target.value,
      }),
    )
  }
}
