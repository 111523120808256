import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import { useParams } from 'react-router-dom'

import Header from '../Header'
import { useSelector, useDispatch } from 'react-redux'

import { getQueryParams } from '../../../../utils/lib'
import Table from '../../../../components/redux/Table'

import { LoadingPage, BoxContent } from '../../Calendar/Styled'

import { fetchDataList } from './events'
const Index = () => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const date = getQueryParams('date')
  const round = getQueryParams('round')
  const label = `ครั้งที่ ${round} - ${date}`

  const isLoading = useSelector((state) => state.attendanceDialog.isLoading)
  const { table, search } = useSelector((state) => state.table)
  const { limit, order, page, sort } = table
  const { searchText } = search
  const props = {
    uuid,
    date,
    round,
    searchText,
  }

  useEffect(() => {
    dispatch(fetchDataList({ table, page, ...props }))
  }, [limit, order, page, sort])

  return (
    <Box sx={{ mx: 3 }}>
      <BoxContent isLoading={isLoading}>
        <Header label={label} />
        <Card sx={{ mt: 2 }}>
          <Table />
        </Card>
      </BoxContent>
      <LoadingPage isLoading={isLoading} />
    </Box>
  )
}

export default Index
