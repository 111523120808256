export const defaultFilter = {
  yearBudget: '',
  costCenter: '',
  costCenterName: '',
  oldAccountCode: '',
  newAccountCode: '',
  category: '',
  createdAt: {},
  updatedAt: {},
  updatedBy: '',
  yearBudgetChecked: false,
  costCenterChecked: false,
  costCenterNameChecked: false,
  oldAccountCodeChecked: false,
  newAccountCodeChecked: false,
  categoryChecked: false,
  createdAtChecked: false,
  updatedAtChecked: false,
  updatedByChecked: false,
  statusChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
}
