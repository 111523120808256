import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import useTheme from '@mui/styles/useTheme'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import ErrorIcon from '@mui/icons-material/Error'
import ArrowForward from '@mui/icons-material/ArrowForward'
import { Column, Row } from '../../../../../components/Layout/Styled'
import {
  StyledDrawer,
  StyledHeadLabel,
  StyledContent,
  DetailContainer,
  StyledChip,
  DotStyle,
} from './Styled'
import { convertFormatDateTime } from '../../../../../utils/lib'
import { handleClickCreate } from './events'
import { useParams } from 'react-router-dom'

const InventoryDrawer = ({ isOpen, onCloseDrawer }) => {
  const { id: uuid } = useParams()
  const { tickets, manageClassData } = useSelector(
    (state) => ({
      tickets: state.manageClassInventory.tickets,
      manageClassData: state.manageClassDetail.data,
    }),
    shallowEqual,
  )
  const monthlyPlan = _.get(manageClassData, 'monthlyPlan', '')
  const startDate = _.get(monthlyPlan, 'startDate', '')
  const courseName = _.get(monthlyPlan, 'course.englishName', '')
  const station = _.get(monthlyPlan, 'area.station.station', '')
  return (
    <StyledDrawer open={isOpen} onClose={onCloseDrawer}>
      <StyledContent>
        <StyledHeadLabel>
          <Box id="header">
            <Typography variant="h5">รายการเบิกทรัพย์สิน</Typography>
            <IconButton
              data-testid="btn-close-drawer"
              color="primary"
              component="span"
              onClick={onCloseDrawer}
            >
              <Close />
            </IconButton>
          </Box>
          <Divider />
        </StyledHeadLabel>
        <Column sx={{ p: 3, gap: 2 }}>
          <Button
            variant="contained"
            sx={{ width: 'fit-content' }}
            endIcon={<OpenInNewIcon />}
            onClick={() =>
              handleClickCreate({
                date: startDate,
                course: `${courseName} (${station})`,
                uuid: uuid,
              })
            }
          >
            สร้างรายการเบิก
          </Button>
          <Typography
            sx={{ my: '3px' }}
          >{`${tickets.length} รายการ`}</Typography>
          {tickets.map((ticket, ind) => {
            return <Detail ticket={ticket} key={ind} />
          })}
        </Column>
      </StyledContent>
    </StyledDrawer>
  )
}

export default InventoryDrawer

const Detail = ({ ticket }) => {
  return (
    <DetailContainer>
      <Typography>{`Ticket ID : ${ticket?.id}`}</Typography>
      <Row>
        <Typography>ผู้รับผิดชอบ : </Typography>
        {ticket?.responsibility ? (
          <StyledChip
            label={`${ticket.responsibility.firstNameTH} ${ticket.responsibility.lastNameTH}`}
          />
        ) : (
          '-'
        )}
      </Row>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          width: '80%',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >{`สร้างโดย ${ticket?.createdBy?.name}`}</Typography>
      <Typography variant="body2" color="text.secondary">
        {convertFormatDateTime({ value: ticket?.createdAt, type: 'dateTime' })}
      </Typography>
      <Status status={ticket?.status} remainDate={ticket?.remainDate} />
      <IconButton
        size="small"
        color="primary"
        width={18}
        onClick={() =>
          window.open(`/inventory/requisition/view/${ticket.uuid}`, '_blank')
        }
      >
        <ArrowForward />
      </IconButton>
    </DetailContainer>
  )
}

const Status = ({ status, remainDate }) => {
  const theme = useTheme()
  const color = {
    WAITING_REQUISITION_COMFIRM: theme.palette?.primary?.main,
    WAITING_REQUISITION_APPROVE: theme.palette?.primary?.main,
    WAITING_RETURN: theme.palette?.secondary?.main,
    WAITING_RETURN_APPROVE: theme.palette?.primary?.main,
    COMPLETED: theme.palette?.success?.main,
    COMPLETED_WITH_CONDITION: theme.palette?.success?.main,
    REJECTED: theme.palette?.error?.main,
    CANCELED: theme.palette?.primary?.main,
  }
  const statusText = {
    WAITING_REQUISITION_COMFIRM: 'รอยืนยันเบิก', //TO ยืนยัน
    WAITING_REQUISITION_APPROVE: 'รออนุมัติเบิก', //Admin อนุมัติ
    WAITING_RETURN: 'รอคืน',
    WAITING_RETURN_APPROVE: 'รออนุมัติคืน', //reject แล้วกลับไป รอคืน
    COMPLETED: 'เสร็จสิ้น',
    COMPLETED_WITH_CONDITION: 'เสร็จสิ้น',
    REJECTED: 'ปฏิเสธ', //เกิดตอนรออนุมัติเบิก
    CANCELED: 'ยกเลิก',
  }
  return (
    <Row sx={{ gap: 1 }}>
      <DotStyle color={color[status]} />
      <Typography color={color[status]}>{statusText[status]}</Typography>
      {status === 'WAITING_RETURN' && remainDate >= 0 && (
        <Typography color="warning.light">
          {`เหลือ (${remainDate} วัน)`}
        </Typography>
      )}
      {status === 'WAITING_RETURN' && remainDate < 0 && (
        <Typography color="error.main">
          {`เกิน (${Math.abs(remainDate)} วัน)`}
        </Typography>
      )}
      {(status === 'WAITING_REQUISITION_COMFIRM' || remainDate < 0) && (
        <ErrorIcon sx={{ fontSize: 20, color: theme.palette?.text?.error }} />
      )}
    </Row>
  )
}
