export const initialState = {
  isLoading: false,
  dataList: {
    document: [],
    firstNameTH: '',
    lastNameTH: '',
    isConfirm: false,
    isMoreReject: true,
    trainingPlanUuid: '',
  },
  documentBody: {
    learnerDocumentList: [],
    remark: '',
  },
}
