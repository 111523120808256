import _ from 'lodash'
import { enumStatus } from '../../../../../components/CRUD/enum/status'

export const postBodyMutation = (data) => {
  const sortCourse = _.sortBy(
    _.map(data.contents, (obj, index) => {
      if (obj.content.type === 'ELearning' || obj.content.type === 'E-Learning')
        return {
          type: 'ELearning',
          sequence: index,
          uuid: obj.content.collectionCourseUuid,
          id: obj.content.collectionCourseId,
          eLearningCourse: obj.content,
        }
      else if (obj.type === 'LEARNING_PATH')
        return {
          type: 'LEARNING_PATH',
          sequence: index,
          uuid: obj.content.collectionCourseUuid,
          id: obj.content.collectionCourseId,
          eLearningLearningPath: obj.content,
        }
      else
        return {
          type: obj.content.type,
          sequence: index,
          uuid: obj.content.collectionCourseUuid,
          id: obj.content.collectionCourseId,
          courseVersion: obj.content,
        }
    }),
    (obj) => {
      return _.toNumber(obj.sequence)
    },
  )

  return {
    //collection page
    name: _.get(data, 'name', ''),
    nameForBanner: _.get(data, 'nameForBanner', ''),
    detailForBanner: _.get(data, 'detailForBanner', ''),
    description: _.get(data, 'description', ''),
    fileName: _.get(data, 'fileName', ''),
    coverImage: _.get(data, 'coverImage', ''),
    linkExampleVideo: _.get(data, 'linkExampleVideo', ''),
    status: _.get(data, 'status', enumStatus.INACTIVE),
    eLearningCollectionCourses: sortCourse,
    //setting page
    periodStart: _.get(data, 'periodStart', ''),
    remark: _.get(data, 'remark', ''),
    setting: _.get(data, 'setting', { tag: [], distribution: [], remark: '' }),
    acquiredSkill: _.get(data, 'acquiredSkill', []),
    productType: _.get(data, 'productType', []),
    levelOfLearner: _.get(data, 'levelOfLearner', []),
    dueDateType: _.get(data, 'dueDateType', []),
    dueDate: _.get(data, 'dueDate', null),
    uuid: _.get(data, 'uuid', undefined),
  }
}
