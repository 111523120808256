import axios from 'axios'
import dayjs from 'dayjs'
import _ from 'lodash'
import {
  setPayloadKey,
  startLoading,
  stopLoading,
} from '../../../redux/slices/manage/agentProfile'

import {
  agentProfileLicenseView,
  agentProfileView,
  postBadgeByUuid,
} from '../../../utils/apiPath'

export const fetchData =
  ({ uuid, courseSearch, examSearch, pagination, isSwitch }) =>
  async (dispatch) => {
    dispatch(startLoading())
    const body = {
      ...pagination,
      agentUuid: uuid,
      courseSearch: _.defaultTo(courseSearch, ''),
      examSearch: _.defaultTo(examSearch, ''),
      courseOriginal: isSwitch,
    }
    const agentView = await axios.post(agentProfileView, body)
    const bodyLicense = {
      agentUuid: uuid,
      order: 'ASC',
      sort: 'updatedAt',
    }
    const agentLicense = await axios.post(agentProfileLicenseView, bodyLicense)
    const badgeView = await axios.post(postBadgeByUuid, {
      userUuid: agentView?.data?.result?.info?.uuid,
    })
    dispatch(
      setPayloadKey({ key: 'contact', data: agentView?.data?.result?.contact }),
    )
    dispatch(
      setPayloadKey({
        key: 'educational',
        data: agentView?.data?.result?.educational,
      }),
    )
    dispatch(
      setPayloadKey({ key: 'info', data: agentView?.data?.result?.info }),
    )
    dispatch(
      setPayloadKey({ key: 'info2', data: agentView?.data?.result?.info2 }),
    )
    dispatch(
      setPayloadKey({ key: 'updated', data: agentView?.data?.result?.updated }),
    )
    dispatch(
      setPayloadKey({ key: 'work', data: agentView?.data?.result?.work }),
    )
    dispatch(
      setPayloadKey({ key: 'license', data: agentLicense?.data?.license }),
    )
    dispatch(setPayloadKey({ key: 'isSwitch', data: isSwitch }))
    dispatch(
      setPayloadKey({
        key: 'division',
        data: _.get(agentView?.data?.result, 'work.division', ''),
      }),
    )
    dispatch(
      setPayloadKey({
        key: 'institution',
        data: agentView?.data?.result?.institution,
      }),
    )
    dispatch(
      setPayloadKey({
        key: 'qualificationName',
        data: agentView?.data?.result?.qualificationName,
      }),
    )
    dispatch(
      setPayloadKey({ key: 'between', data: agentView?.data?.result?.between }),
    )
    dispatch(
      setPayloadKey({ key: 'branch', data: agentView?.data?.result?.branch }),
    )
    dispatch(setPayloadKey({ key: 'gpa', data: agentView?.data?.result?.gpa }))
    dispatch(
      setPayloadKey({ key: 'point', data: agentView?.data?.result?.point }),
    )
    dispatch(setPayloadKey({ key: 'badge', data: badgeView?.data }))

    dispatch(stopLoading())
  }

export const formatDateDisplay = (date) =>
  dayjs(new Date(date)).format(window.__env__.REACT_APP_DATE_SHOW)
