import { store } from '../../../../../App'
import _ from 'lodash'
import { enumProductType } from '../../../../../modules/Inventory/constant'
import { STATUS_ITEMS } from '../model/status'

export const mutateFilterPropToBody = () => {
  const { filterProp } = store.getState().table.filter
  const { searchText } = store.getState().table.search
  const { crudConfig } = store.getState().crud

  return {
    warehouseUuid: _.get(crudConfig, 'warehouseUuid', ''),
    productUuid: _.get(crudConfig, 'productUuid', ''),
    warehouseStatus: _.get(filterProp, 'status', [
      'ACTIVE',
      'INACTIVE',
      'DELETED',
    ]),
    productStatus: _.get(filterProp, 'status', [
      'ACTIVE',
      'INACTIVE',
      'DELETED',
    ]),
    search: searchText ? searchText : _.get(filterProp, 'search', ''),
    startCreatedDate: _.get(filterProp, 'startCreatedDate', ''),
    endCreatedDate: _.get(filterProp, 'endCreatedDate', ''),
    startUpdatedDate: _.get(filterProp, 'startUpdatedDate', ''),
    endUpdatedDate: _.get(filterProp, 'endUpdatedDate', ''),
    productType: _.get(filterProp, 'productType', [
      enumProductType.STOCK,
      enumProductType.STOCK_ASSET,
      enumProductType.ASSET,
      enumProductType.LICENSE,
    ]),

    totalFrom: _.get(filterProp, 'totalFrom', ''),
    totalTo: _.get(filterProp, 'totalTo', ''),
    availableFrom: _.get(filterProp, 'availableFrom', ''),
    availableTo: _.get(filterProp, 'availableTo', ''),
    unitPriceFrom: _.get(filterProp, 'unitPriceFrom', ''),
    unitPriceTo: _.get(filterProp, 'unitPriceTo', ''),
    refundYear: _.get(filterProp, 'refundYear', ''),
    refundMonth: _.get(filterProp, 'refundMonth', ''),
    refundDay: _.get(filterProp, 'refundDay', ''),
    contractStartDate: _.get(filterProp, 'contractStartDate', ''),
    contractEndDate: _.get(filterProp, 'contractEndDate', ''),
    renewContractStartDate: _.get(filterProp, 'renewContractStartDate', ''),
    renewContractEndDate: _.get(filterProp, 'renewContractEndDate', ''),
    holder: _.get(filterProp, 'holder', ''),
    updatedBy: _.get(filterProp, 'updatedBy', ''),
    statusItems: _.get(filterProp, 'statusItems', [
      STATUS_ITEMS.NORMAL,
      STATUS_ITEMS.LOW,
    ]),
  }
}
