import _ from 'lodash'
import { BGM_STATUS } from '../../../../constants/budgetManagement/budgetManagement'
import { defaultBudgetDetails } from '../model/defaultBudgetDetails'
import { defaultBudgetSummary } from '../model/defaultBudgetSummary'

export const getBodyMutation = (data) => {
  return {
    yearBudget: _.get(data, 'yearBudget', {
      label: new Date().getFullYear().toString(),
      value: new Date().getFullYear(),
    }),
    costCenter: _.get(data, 'costCenter', ''),
    accountCode: _.get(data, 'accountCode', ''),
    categoryAccountCode: _.get(data, 'categoryAccountCode', ''),
    createdAt: _.get(data, 'createdAt', {}),
    updatedAt: _.get(data, 'updatedAt', {}),
    updatedBy: _.get(data, 'updatedBy', ''),
    status: _.get(data, 'status', BGM_STATUS.INACTIVE),
    budgetDetails: _.get(data, 'budgetDetails', defaultBudgetDetails),
    budgetSummary: _.get(data, 'budgetSummary', defaultBudgetSummary),
    remark: _.get(data, 'remark', null),
  }
}
