import React from 'react'
import { Box, Card } from '@mui/material'
import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import {
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../../../utils/lib'
import { regex } from '../../../../../../components/CRUD/enum/regex'
import { handleInputNumber } from '../../../../../../components/CRUD/handler/handleInputNumber'
import { handleRemoveOutsideContact } from '../../handler/handleRemoveOutsideContact'
import ClearIcon from '@mui/icons-material/Clear'

export const OutsideContactInfo = ({ index }) => {

  return (
    <Card sx={{ p: 2 }}>
      <Box display="flex" gap={3}>
        <Box display="flex" alignItems="start" width={'100%'}>
          <CustomInput
            required
            // disabled={!hasPermissionCreate}
            fieldName={`outsideContacts[${index}].name`}
            labelName="ชื่อผู้ติดต่อ"
            placeholder="ระบุชื่อผู้ติดต่อ"
          />
        </Box>
        <Box
          position="relative"
          width="30px"
          onClick={() => handleRemoveOutsideContact(index)}
        >
          <ClearIcon
            sx={{ position: 'absolute', top: '30px', cursor: 'pointer' }}
          />
        </Box>
      </Box>
      <Box display="flex" gap={3} mt={2}>
        <CustomInput
          required
          // disabled={!hasPermissionCreate}
          fieldName={`outsideContacts[${index}].phone`}
          labelName="เบอร์โทรศัพท์"
          placeholder="ระบุเบอร์โทรศัพท์"
          inputProps={{
            'data-testid': `outsideContacts[${index}].phone`,
            onInput: (e) => {
              handleInputNumber(e, regex.NUMBER)
            },
            onKeyDown: (e) => {
              handleNumberKeyDown(e, ['.'])
            },
            onPaste: handlePasteFormat,
          }}
        />
        <CustomInput
          required
          // disabled={!hasPermissionCreate}
          fieldName={`outsideContacts[${index}].email`}
          labelName="E-mail"
          placeholder="ระบุ E-mail"
        />
      </Box>
    </Card>
  )
}
