import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
} from '../../components/FilterDrawer/Styled'

const FilterDrawer = ({ open, onClose, onFilterClick, isPermission }) => {
  const defaultFilter = {
    agentCodeChecked: false,
    agentCode: '',
    firstNameChecked: false,
    firstName: '',
    lastNameChecked: false,
    lastName: '',
    firstNameTHChecked: false,
    firstNameTH: '',
    lastNameTHChecked: false,
    lastNameTH: '',
    jobTitleChecked: false,
    jobTitle: '',
    departmentChecked: false,
    department: '',
    reportToChecked: false,
    reportTo: '',
    stationChecked: false,
    station: '',
    statusChecked: false,
    status: {
      activeChecked: false,
      deletedChecked: false,
    },
  }

  const arrayCheckbox = isPermission
    ? [
        {
          label: 'รหัสพนักงาน',
          nameCheckBox: 'agentCodeChecked',
          nameInput: 'agentCode',
        },
        {
          label: 'First Name',
          nameCheckBox: 'firstNameChecked',
          nameInput: 'firstName',
        },
        {
          label: 'Last Name',
          nameCheckBox: 'lastNameChecked',
          nameInput: 'lastName',
        },
        {
          label: 'ชื่อ',
          nameCheckBox: 'firstNameTHChecked',
          nameInput: 'firstNameTH',
        },
        {
          label: 'นามสกุล',
          nameCheckBox: 'lastNameTHChecked',
          nameInput: 'lastNameTH',
        },
        {
          label: 'Job Title',
          nameCheckBox: 'jobTitleChecked',
          nameInput: 'jobTitle',
        },
        {
          label: 'Department',
          nameCheckBox: 'departmentChecked',
          nameInput: 'department',
        },
        {
          label: 'Station',
          nameCheckBox: 'stationChecked',
          nameInput: 'station',
        },
        {
          label: 'Report to',
          nameCheckBox: 'reportToChecked',
          nameInput: 'reportTo',
        },
      ]
    : [
        {
          label: 'รหัสพนักงาน',
          nameCheckBox: 'agentCodeChecked',
          nameInput: 'agentCode',
        },
        {
          label: 'First Name',
          nameCheckBox: 'firstNameChecked',
          nameInput: 'firstName',
        },
        {
          label: 'Last Name',
          nameCheckBox: 'lastNameChecked',
          nameInput: 'lastName',
        },
        {
          label: 'ชื่อ',
          nameCheckBox: 'firstNameTHChecked',
          nameInput: 'firstNameTH',
        },
        {
          label: 'นามสกุล',
          nameCheckBox: 'lastNameTHChecked',
          nameInput: 'lastNameTH',
        },
        {
          label: 'Job Title',
          nameCheckBox: 'jobTitleChecked',
          nameInput: 'jobTitle',
        },
        {
          label: 'Department',
          nameCheckBox: 'departmentChecked',
          nameInput: 'department',
        },
        {
          label: 'Station',
          nameCheckBox: 'stationChecked',
          nameInput: 'station',
        },
      ]

  const [filter, setFilter] = useState(defaultFilter)
  const [filterMemo, setFilterMemo] = useState()

  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter(defaultFilter)
  }, [open])

  const handleChange = ({ key, value, isStatus = false }) => {
    let objState = {
      ...filter,
      [key]: value,
    }
    if (isStatus) {
      objState = {
        ...filter,
        status: {
          ...filter.status,
          [key]: value,
        },
      }
    }
    setFilter(objState)
  }

  const onClearClick = () => {
    setFilter(defaultFilter)
  }

  const listCheckbox = ({ arrayCheckbox, stateFilter }) => {
    return arrayCheckbox.map((item, index) => {
      return (
        <StyledGroupBox key={index}>
          <FormGroup sx={{ width: '100%' }}>
            <FormControlLabel
              control={
                <Checkbox
                  data-testid={`checkbox-staffProfile-name-${index}`}
                  name={item.nameCheckBox}
                  defaultChecked
                  checked={stateFilter[item.nameCheckBox]}
                  onChange={(e) => {
                    handleChange({
                      value: e.target.checked,
                      key: e.target.name,
                    })
                  }}
                />
              }
              label={item.label}
              sx={!stateFilter[item.nameCheckBox] ? { marginBottom: -1 } : {}}
            />

            {stateFilter[item.nameCheckBox] && (
              <>
                {!item.isData && (
                  <InputBase
                    sx={{ borderRadius: 0 }}
                    type="basic"
                    name={item.nameInput}
                    disabled={!stateFilter[item.nameCheckBox]}
                    placeholder={'ค้นหา'}
                    value={filter[item.nameInput]}
                    onChange={(e) => {
                      handleChange({
                        key: e.target.name,
                        value: e.target.value,
                      })
                    }}
                  />
                )}
              </>
            )}
          </FormGroup>
        </StyledGroupBox>
      )
    })
  }
  return (
    <StyledDrawer open={open} onClose={onClose}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid={`btn-close-drawer`}
            color="primary"
            component="span"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      {listCheckbox({ arrayCheckbox: arrayCheckbox, stateFilter: filter })}
      <StyledGroupBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          label="สถานะการทำงาน"
          control={
            <Checkbox
              data-testid="checkbox-staffProfile-status"
              name="statusChecked"
              checked={filter.statusChecked}
              onChange={(e) => {
                handleChange({
                  value: e.target.checked,
                  key: e.target.name,
                })
              }}
            />
          }
        />
        {filter.statusChecked && (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column', mx: 3 }}>
              <FormControlLabel
                label="ยังเป็นพนักงานอยู่"
                control={
                  <Checkbox
                    data-testid="checkbox-status-active"
                    name="activeChecked"
                    checked={filter.status.activeChecked}
                    onChange={(e) => {
                      handleChange({
                        value: e.target.checked,
                        key: e.target.name,
                        isStatus: true,
                      })
                    }}
                  />
                }
              />
              <FormControlLabel
                label="ลาออกแล้ว"
                control={
                  <Checkbox
                    data-testid="checkbox-status-deleted"
                    name="deletedChecked"
                    checked={filter.status.deletedChecked}
                    onChange={(e) => {
                      handleChange({
                        value: e.target.checked,
                        key: e.target.name,
                        isStatus: true,
                      })
                    }}
                  />
                }
              />
            </Box>
          </>
        )}
      </StyledGroupBox>
      <StyledFooter>
        <Button variant="outlined" onClick={onClearClick}>
          ล้าง
        </Button>
        <Button
          onClick={() => {
            onClose()
            onFilterClick(filter)
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
