import axios from 'axios'
import _ from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  reportCourseSettingDashboard,
  reportCourseSettingFilter,
  reportCourseSettingGetOptions,
} from '../../utils/apiPath'

export const filterReportCourseSetting = createAsyncThunk(
  'reportCourseSetting/filterReportCourseSetting',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(reportCourseSettingFilter, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getReportCourseSettingDashboard = createAsyncThunk(
  'reportCourseSettingDashboard/getReportCourseSettingDashboard',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(reportCourseSettingDashboard, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getOptionsReportCourseSettingByKey = createAsyncThunk(
  'reportCourseSettingDashboard/getOptionsReportCourseSettingByKey',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(reportCourseSettingGetOptions, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
