import React from 'react'
import { path } from './path'

import AppRegistration from '@mui/icons-material/AppRegistration'
import EventBusy from '@mui/icons-material/EventBusy'
import HourglassBottomTwoTone from '@mui/icons-material/HourglassBottomTwoTone'
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew'
import Settings from '@mui/icons-material/Settings'
import Summarize from '@mui/icons-material/Summarize'

import { themeColor } from '../utils/themeData'

export const headCells = [
  {
    id: 'round',
    disablePadding: false,
    label: 'ครั้งที่',
    width: '120px',
  },
  {
    id: 'capacity',
    label: 'เช็คแล้ว/ทั้งหมด',
    disablePadding: false,
    width: '245px',
  },
  {
    id: 'qrCodeBody',
    label: 'QR Code',
    disablePadding: false,
    width: '325px',
  },
  {
    id: 'expiredDate',
    label: 'วันหมดอายุ',
    disablePadding: false,
    width: '305px',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '100px',
    hideDelete: true,
    hideEdit: true,
    hideSortIcon: true,
    textAlign: 'center',
    viewPath: path.manageClass,
  },
]

export const headCellsAttendanceList = [
  {
    id: 'codeId',
    disablePadding: false,
    label: 'ID',
    width: '120px',
  },
  {
    id: 'firstName',
    label: 'ชื่อ',
    disablePadding: false,
    width: '300px',
  },
  {
    id: 'lastName',
    label: 'นามสกุล',
    disablePadding: false,
    width: '300px',
  },
  {
    id: 'attendStatus',
    label: 'ผลการเช็คชื่อ',
    disablePadding: false,
    width: '300px',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '80px',
    hideDelete: true,
    hideEdit: true,
    hideSortIcon: true,
    textAlign: 'center',
    viewPath: path.manageClass + '/learner/detail',
  },
]

export const headCellsHistory = [
  {
    id: 'historyName',
    label: 'ผู้แก้ไข',
    disablePadding: false,
  },
  {
    id: 'historyDate',
    label: 'วันเวลาที่แก้ไข',
    disablePadding: false,
  },
]

export const statusTheme = {
  UNASSIGN: {
    title: 'Unassigned',
    color: themeColor.info.main,
    image: '/image/background/bg_blue_status.png',
    background: themeColor.manageClass.blueStatus,
  },
  TODO: {
    title: 'To Do',
    color: themeColor.info.main,
    image: '/image/background/bg_blue_status.png',
    background: themeColor.manageClass.blueStatus,
  },
  INPROGRESS: {
    title: 'In Progress',
    color: themeColor.info.main,
    image: '/image/background/bg_blue_status.png',
    background: themeColor.manageClass.blueStatus,
  },
  OPENREGISTRATION: {
    title: 'Open Registration',
    color: themeColor.success.main,
    image: '/image/background/bg_green_status.png',
    background: themeColor.manageClass.greenStatus,
  },
  CLOSEREGISTRATION: {
    title: 'Close Registration',
    color: themeColor.success.main,
    image: '/image/background/bg_green_status.png',
    background: themeColor.manageClass.greenStatus,
  },
  STARTCLASS: {
    title: 'Start Class',
    color: themeColor.success.main,
    image: '/image/background/bg_green_status.png',
    background: themeColor.manageClass.greenStatus,
  },
  ENDCLASS: {
    title: 'End Class',
    color: themeColor.success.main,
    image: '/image/background/bg_green_status.png',
    background: themeColor.manageClass.greenStatus,
  },
  CLOSED: {
    title: 'Closed',
    color: themeColor.error.main,
    image: '/image/background/bg_red_status.png',
    background: themeColor.manageClass.redStatus,
  },
  CANCELED: {
    title: 'Cancelled',
    color: themeColor.action.active,
    image: '/image/background/bg_gray_status.png',
    background: themeColor.manageClass.grayStatus,
  },
}

export const manageClassTheme = {
  learner: {
    border: '#A4DBA9',
    leftBorder: themeColor.axa.teal,
    background: themeColor.manageClass.learner,
    icon: 'primary',
  },
  userCheck: {
    border: '#FFDA61',
    leftBorder: themeColor.other.ratingActive,
    background: themeColor.manageClass.userCheck,
    icon: 'warning',
  },
  document: {
    border: '#CCB3F2',
    leftBorder: themeColor.monthlyPlan.birthdayLeaveBorder,
    background: themeColor.manageClass.document,
    icon: 'primary',
  },
  courseDetail: {
    border: '#A2CBE8',
    leftBorder: themeColor.blue.oceanBlue,
    background: themeColor.manageClass.courseDetail,
    icon: 'primary',
  },
  classDetail: {
    border: '#9DD9E0',
    leftBorder: themeColor.monthlyPlan.annualLeaveBorder,
    background: themeColor.manageClass.classDetail,
    icon: 'primary',
  },
  inventory: {
    border: themeColor.primary.main,
    leftBorder: themeColor.primary.main,
    background: themeColor.manageClass.inventory,
    icon: 'white',
  },
}

export const manageClassIcon = {
  learner: '/icon/ic_learner.svg',
  userCheck: '/icon/ic_user_check.svg',
  document: '/icon/ic_book.svg',
  courseDetail: '/icon/ic_speaker.svg',
  classDetail: '/icon/ic_detail.svg',
}

export const eventIcon = {
  CREATE_TRAINING_PLAN: <Settings />,
  UPDATE_TRAINING_PLAN: <Settings />,
  OPEN_REGISTRATION: <AppRegistration />,
  CLOSE_REGISTRATION: <AppRegistration />,
  ANNOUNCE: <img src="/icon/ic_megaphone_active.svg" />,
  ANNOUNCE_TRAINING_PLAN: <img src="/icon/ic_megaphone_active.svg" />,
  CLOSED_TRAINING_PLAN: <PowerSettingsNew />,
  OPEN_ETESTING: <Summarize />,
  CLOSE_ETESTING: <Summarize />,
  OPEN_EVALUATION: <Summarize />,
  CLOSE_EVALUATION: <Summarize />,
  CANCELED_CLASS: <EventBusy />,
}

export const headCellsEvent = [
  {
    id: 'author',
    label: 'ผู้ดำเนินการ',
    disablePadding: false,
    hideSortIcon: true,
    minWidth: 140,
  },
  {
    id: 'event',
    label: 'รายการ',
    disablePadding: false,
    hideSortIcon: true,
    minWidth: 300,
  },
  {
    id: 'remark',
    label: 'เหตุผล',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'createdAt',
    label: 'วันเวลา',
    disablePadding: false,
    hideSortIcon: true,
    minWidth: 140,
  },
]

export const headCellsLearner = {
  CR: [
    {
      id: 'codeId',
      disablePadding: false,
      label: 'ID',
      width: '80px',
    },
    {
      id: 'prefixTH',
      label: 'คำนำหน้า',
      disablePadding: false,
    },
    {
      id: 'firstNameTH',
      label: 'ชื่อ',
      disablePadding: false,
    },
    {
      id: 'lastNameTH',
      label: 'นามสกุล',
      disablePadding: false,
    },
    {
      id: 'agentCode',
      label: 'Agent Code',
      disablePadding: false,
    },
    {
      id: 'unitNumber',
      label: 'Unit Number',
      disablePadding: false,
    },
    {
      id: 'RAMCode',
      label: 'Ram',
      disablePadding: false,
    },
    {
      id: 'mobileNo',
      label: 'Phone Number',
      disablePadding: false,
    },
    {
      id: 'statusLearner',
      label: 'สถานะ',
      disablePadding: false,
    },
    {
      id: 'attendance',
      label: 'การเข้าเรียน',
      disablePadding: false,
    },
    {
      id: 'classLearnerCourseStatus',
      label: 'ผลการอบรม',
      disablePadding: false,
    },
    {
      id: 'actionLearner',
      disablePadding: false,
      label: 'คำสั่ง',
      width: '90px',
      hideEdit: true,
      hideSortIcon: true,
      enableDelete: true,
      viewPath: `${path.manageClass}/learner/detail`,
    },
  ],
  VC: [
    {
      id: 'codeId',
      disablePadding: false,
      label: 'ID',
      width: '80px',
    },
    {
      id: 'prefixTH',
      label: 'คำนำหน้า',
      disablePadding: false,
    },
    {
      id: 'firstNameTH',
      label: 'ชื่อ',
      disablePadding: false,
    },
    {
      id: 'lastNameTH',
      label: 'นามสกุล',
      disablePadding: false,
    },
    {
      id: 'agentCode',
      label: 'Agent Code',
      disablePadding: false,
    },
    {
      id: 'unitNumber',
      label: 'Unit Number',
      disablePadding: false,
    },
    {
      id: 'RAMCode',
      label: 'Ram',
      disablePadding: false,
    },
    {
      id: 'mobileNo',
      label: 'Phone Number',
      disablePadding: false,
    },
    {
      id: 'statusLearner',
      label: 'สถานะ',
      disablePadding: false,
    },
    {
      id: 'onlineRoom',
      label: 'ห้องเรียน',
      disablePadding: false,
    },
    {
      id: 'attendance',
      label: 'การเข้าเรียน',
      disablePadding: false,
    },
    {
      id: 'classLearnerCourseStatus',
      label: 'ผลการอบรม',
      disablePadding: false,
    },
    {
      id: 'actionLearner',
      disablePadding: false,
      label: 'คำสั่ง',
      width: '90px',
      hideEdit: true,
      hideSortIcon: true,
      enableDelete: true,
      viewPath: `${path.manageClass}/learner/detail`,
    },
  ],
}

export const uploadStatus = {
  SUCCESS: 'สำเร็จ',
  NOTFOUND: 'ไม่พบรายชื่ออยู่ในระบบ',
  DUPLICATE: 'รายชื่อซ้ำ',
  NOTQUALIFIED: 'ไม่ผ่านคุณสมบัติการเข้าอบรม',
  TERMINATE: 'ผู้ใช้งานนี้มีสถานะ Terminate',
  NOSEAT: 'ไม่มีที่นั่ง',
}

export const headCellsETestEEva = [
  {
    id: 'status',
    label: 'สถานะ',
    width: 100,
    disablePadding: false,
    hideSortIcon: true,
    isETestEEva: true,
  },
  {
    id: 'name',
    label: 'ชื่อ',
    minWidth: 240,
    disablePadding: false,
    hideSortIcon: true,
    isETestEEva: true,
  },
  {
    id: 'startDate',
    label: 'ระยะเวลาเปิดล่าสุด',
    minWidth: 240,
    disablePadding: false,
    hideSortIcon: true,
    isETestEEva: true,
  },
  {
    id: 'type',
    label: 'รูปแบบ',
    disablePadding: false,
    hideSortIcon: true,
    isETestEEva: true,
  },
  {
    id: 'setPassScore',
    label: 'เกณฑ์ผ่าน',
    minWidth: 130,
    disablePadding: false,
    hideSortIcon: true,
    isETestEEva: true,
  },
  {
    id: 'submitCount',
    label: 'ส่งแล้ว',
    disablePadding: false,
    hideSortIcon: true,
    isETestEEva: true,
  },
  {
    id: 'waitingToCheck',
    label: 'รอตรวจ',
    minWidth: 110,
    disablePadding: false,
    hideSortIcon: true,
    isETestEEva: true,
  },
  {
    id: 'waitingToAnnounce',
    label: 'รอประกาศ',
    minWidth: 115,
    disablePadding: false,
    hideSortIcon: true,
    isETestEEva: true,
  },
  {
    id: 'action',
    label: 'คำสั่ง',
    minWidth: 68,
    disablePadding: false,
    hideSortIcon: true,
    isETestEEva: true,
  },
]

export const eTestEEvaText = {
  E_TESTING: 'แบบทดสอบ',
  E_EVALUATION: 'แบบประเมิน',
}

export const oicStatus = {
  ACTIVE: 'เพิ่มได้',
  INACTIVE: 'ข้อมูลซ้ำ',
  ERROR: 'ข้อมูลไม่ถูกต้อง',
}

export const examStatus = {
  รอผู้เรียนส่งข้อสอบ: {
    icon: <HourglassBottomTwoTone fontSize="20px" />,
    color: 'text.silver',
  },
  รอตรวจ: {
    icon: <HourglassBottomTwoTone fontSize="20px" />,
    color: 'secondary.main',
  },
  ผ่านการทดสอบ: {
    icon: <img src="/icon/main_success.svg" width={20} height={20} />,
    color: 'success.main',
  },
  ไม่ผ่านการทดสอบ: {
    icon: <img src="/icon/main_fail.svg" width={20} height={20} />,
    color: 'error.main',
  },
}

export const examLabel = {
  ALL: 'ทั้งหมด',
  WAITING: 'รอตรวจ',
  PASS: 'ผ่าน',
  FAIL: 'ไม่ผ่าน',
  NOT_SENT: 'ยังไม่ได้ส่ง',
}

export const headCellsCheckHistory = [
  {
    id: 'author',
    disablePadding: false,
    minWidth: 160,
    label: 'ผู้แก้ไข',
  },
  {
    id: 'createdAt',
    label: 'วันเวลาที่ประกาศ',
    minWidth: 148,
    disablePadding: false,
  },
  {
    id: 'pass',
    label: 'ผ่าน',
    disablePadding: false,
    width: '80px',
  },
  {
    id: 'notPass',
    label: 'ไม่ผ่าน',
    disablePadding: false,
  },
]

export const headCellsLearnerCheckHistory = [
  {
    id: 'author',
    disablePadding: false,
    minWidth: 160,
    label: 'ผู้แก้ไข',
  },
  {
    id: 'createdAt',
    label: 'วันเวลาที่แก้ไข',
    minWidth: 143,
    disablePadding: false,
  },
  {
    id: 'sequence',
    label: 'ข้อ',
    disablePadding: false,
    width: '80px',
  },
  {
    id: 'score',
    label: 'คะแนน',
    disablePadding: false,
  },
  {
    id: 'reasonToChange',
    label: 'เหตุผล',
    disablePadding: false,
  },
]

export const learnerStatus = {
  ELIGIBLE_ANNOUNCE: 'ประกาศสิทธิ์แล้ว',
  WAITING_CONFIRM_IDENTITY: 'รอยืนยันตัวตน',
  WAITING_ANNOUNCE: 'รอประกาศสิทธิ์',
  WAITING_CHECK: 'รอตรวจสอบเอกสาร',
  REJECTED_DOCUMENT: 'เอกสารไม่ผ่าน',
  CANCEL: 'ยกเลิกการสมัคร',
  CANCELED: 'ถูกยกเลิกการสมัคร',
  INELIGIBLE: 'ไม่มีสิทธิ์เข้าเรียน',
  CANCEL_CLASS: 'ยกเลิกคลาส',
}

export const courseStatusCheckbox = [
  { label: 'เปิดรับสมัครแล้ว', value: 'OPEN' },
  { label: 'ปิดรับสมัครแล้ว', value: 'CLOSED' },
]
