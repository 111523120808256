import React from 'react'
import {
  COURSE_STATUS,
  COURSE_TYPE,
  MODULE_STATUS,
} from '../../../constants/eLearning'
import { COURSE_TYPE_TH } from './Form/model'

export const breadcrumbList = [
  { title: 'E-Learning', link: '/', pointer: false },
  { title: 'Course', link: '/', pointer: false },
]

export const breadcrumbCreate = (createType) => [
  { title: 'E-Learning', link: '/', pointer: false },
  { title: 'Course', link: '/e-learning/course', pointer: true },
  {
    title: `สร้าง Course (${COURSE_TYPE_TH[createType] || ''})`,
    link: '/',
    pointer: false,
  },
]

export const breadcrumbEdit = (createType) => [
  { title: 'E-Learning', link: '/', pointer: false },
  { title: 'Course', link: '/e-learning/course', pointer: true },
  {
    title: `แก้ไข Course (${COURSE_TYPE_TH[createType] || ''})`,
    link: '/',
    pointer: false,
  },
]

export const breadcrumbView = [
  { title: 'E-Learning', link: '/', pointer: false },
  { title: 'Course', link: '/e-learning/course', pointer: true },
  { title: 'รายละเอียด Course', link: '/', pointer: false },
]

export const DEFAULT_COURSE_TYPE = [COURSE_TYPE.GENERAL, COURSE_TYPE.OIC]
export const DEFUALT_MODULE_STATUS = [
  MODULE_STATUS.ACTIVE,
  MODULE_STATUS.INACTIVE,
  MODULE_STATUS.DELETED,
  COURSE_STATUS.DRAFT,
]
export const DEFUALT_COURSE_STATUS = [
  COURSE_STATUS.DRAFT,
  COURSE_STATUS.WAITING,
  COURSE_STATUS.OPENREGISTRATION,
  COURSE_STATUS.CLOSEREGISTRATION,
  COURSE_STATUS.INPROGRESS,
  COURSE_STATUS.SUCCESS,
  COURSE_STATUS.COMPLETED,
  COURSE_STATUS.CANCEL,
]

export const createOptions = [
  {
    text: 'หลักสูตรทั่วไป',
    icon: <img src="/icon/general_course.svg" />,
    link: '/e-learning/course/form/general',
  },
  {
    text: 'หลักสูตรระดับสูง',
    icon: <img src="/icon/oic_course.svg" />,
    link: '/e-learning/course/form/oic',
  },
]
