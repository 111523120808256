import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { openDialog } from '../../../../../redux/slices/dialog'
import CheckDialog from '../Drawer/CheckDialog'
import _ from 'lodash'
// import { assetTypeEnum } from '../enum/assetTypEnum'
import { uploadProductWarehouse } from '../../../../../services/inventory'
import {
  PRODUCT_TYPE_TRANSFER,
  PRODUCT_TYPE,
} from '../../../../../constants/stock'

export const handleImportAssetFile = (e) => async (dispatch) => {
  const { type, from, to, productStockAsset, productAsset, productLicense } =
    store.getState().crud.formData

  const formData = new FormData()
  const productType = PRODUCT_TYPE_TRANSFER[type]
  if (productType === PRODUCT_TYPE.STOCK_ASSET) {
    formData.append('productUuid', productStockAsset.value)
  } else if (productType === PRODUCT_TYPE.ASSET) {
    formData.append('productUuid', productAsset.value)
  } else if (productType === PRODUCT_TYPE.LICENSE) {
    formData.append('productUuid', productLicense.value)
  }
  formData.append('file', e.target.files[0])
  formData.append('formUuid', from.value)
  formData.append('toUuid', to.value)
  formData.append('productType', productType)
  let response = await dispatch(uploadProductWarehouse(formData))
  const results = _.get(response, 'payload.data', {})
  // store.dispatch(setFieldValue({ key: 'importData', value: results }))
  e.target.value = null
  if (_.isNil(response.error)) {
    store.dispatch(
      setFieldValue({
        key: 'importData',
        value: {
          data: results.result,
          status: results.status,
        },
      }),
    )
    store.dispatch(
      openDialog({
        type: 'mini',
        maxWidth: 'lg',
        disableButton: true,
        content: <CheckDialog />,
      }),
    )
  }
}
