import React from 'react'
import { Box, Typography } from '@mui/material'
import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import { CustomDropdown } from '../../../../../../components/CRUD/components/CustomDropdown'
import {
  handleNumberKeyDown,
  handlePasteFormat,
  validatePermission,
} from '../../../../../../utils/lib'
import { CancelButton } from '../../../../../../components/CRUD/components/FormFooter/CancelButton'
import { SubmitButton } from '../../../../../../components/CRUD/components/FormFooter/SubmitButton'
import { openStockDrawer } from '../../handler/openStockDrawer'
import { handleSubmitDrawer } from '../../handler/handleSubmitDrawer'
import {
  drawerFormValidationSchema,
  validateStockNotImport,
} from '../../schema/schema'
import { regex } from '../../../../../../components/CRUD/enum/regex'
import { handleInputNumber } from '../../../../../../components/CRUD/handler/handleInputNumber'
import { handleInputNumberChange } from '../../handler/handleInputNumberChange'
import { shallowEqual, useSelector } from 'react-redux'
import { fromTypeEnum } from '../../enum/assetTypEnum'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../../../constants/stock'
import { checkAvailableProduct } from '../../handler/checkAvailableProduct'

const Form = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermissionCreate = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN, STOCK_PERMISSION.CREATE],
  })

  const { from, productMasterData, assetData, productAvailable } = useSelector(
    (state) => ({
      from: state.crud.formData.from,
      assetData: state.crud.assetData,
      productMasterData: state.crud.masterData.product,
      productAvailable: state.crud.formData.productAvailable,
    }),
    shallowEqual,
  )
  const formValidation =
    from?.value === fromTypeEnum.IMPORT_PRODUCT
      ? drawerFormValidationSchema
      : validateStockNotImport

  let productOptions = { isLoading: false, options: [] }
  if (productMasterData) productOptions = productMasterData
  if (assetData && assetData?.length > 0) {
    const selectedProd = assetData.map((item) => item?.product?.value)
    const filteredProductOptions = productMasterData?.options.filter((item) => {
      return !selectedProd.includes(item?.value)
    })
    productOptions = {
      isLoading: false,
      options: filteredProductOptions,
    }
  }
  return (
    <>
      <Typography variant="body1b" mt={3}>
        ข้อมูลทรัพย์สิน
      </Typography>
      <Box display="flex" mb={2} gap={3} alignItems="start">
        <CustomDropdown
          required
          labelName="ชื่อทรัพย์สิน"
          fieldName="product"
          placeholder="เลือกทรัพย์สิน"
          isCustomOption={true}
          customOptions={productOptions}
          handleExtraChange={checkAvailableProduct}
        />
        <CustomInput
          required
          disabled={!hasPermissionCreate}
          fieldName="quantity"
          labelName="จำนวน"
          placeholder="ระบุจำนวน"
          inputProps={{
            'data-testid': 'input-quantity',
            onInput: (e) => {
              handleInputNumber(e, regex.NUMBER)
            },
            onKeyDown: (e) => {
              handleNumberKeyDown(e, ['.'])
            },
            onPaste: handlePasteFormat,
            onChange: (e) => {
              handleInputNumberChange(e, 'quantity')
            },
          }}
        />
      </Box>
      {from?.value === fromTypeEnum.IMPORT_PRODUCT && (
        <>
          <Box display="flex" gap={3} alignItems="start">
            <CustomInput
              required
              disabled={!hasPermissionCreate}
              fieldName="unitPrice"
              labelName="มูลค่าต่อหน่วย (฿)"
              placeholder="ระบุมูลค่าต่อหน่วย (฿)"
              inputProps={{
                'data-testid': 'input-unitPrice',
                onInput: (e) => {
                  handleInputNumber(e, regex.FLOAT)
                },
                onKeyDown: (e) => {
                  handleNumberKeyDown(e)
                },
                onPaste: handlePasteFormat,
                onChange: (e) => {
                  handleInputNumberChange(e, 'unitPrice')
                },
              }}
            />
            <CustomInput
              required
              disabled={!hasPermissionCreate}
              fieldName="stockValue"
              labelName="มูลค่ารวม"
              placeholder="ระบุมูลค่ารวม"
              inputProps={{
                'data-testid': 'input-stockValue',
                onInput: (e) => {
                  handleInputNumber(e, regex.FLOAT)
                },
                onKeyDown: (e) => {
                  handleNumberKeyDown(e)
                },
                onPaste: handlePasteFormat,
                onChange: (e) => {
                  handleInputNumberChange(e, 'stockValue')
                },
              }}
            />
          </Box>
        </>
      )}

      {from?.value !== fromTypeEnum.IMPORT_PRODUCT && (
        <Box>
          <Box display="flex">
            <Typography variant="body1b">จำนวนพร้อมใช้งาน</Typography>
            <Typography color={'red'}> *</Typography>
          </Box>
          <Typography>{productAvailable ?? '-'}</Typography>
        </Box>
      )}
      <Box display="flex" gap={3} justifyContent="end" mt={3}>
        <CancelButton onClick={() => openStockDrawer(false)} />
        <SubmitButton
          disabled={!hasPermissionCreate}
          handleSubmitClick={() => handleSubmitDrawer(formValidation)}
        />
      </Box>
    </>
  )
}

export default Form
