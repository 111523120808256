import React from 'react'
import { ViewRow } from '../../../../../../../../components/CRUD/components/StyledViewBox'
import { Box, Card, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import _ from 'lodash'
import { PRODUCT_TYPE_VIEW_TEXT } from '../../../../../../../../constants/stock'
import { StatusComponent } from '../../../../../../../../components/CRUD/components/StatusComponent'
import { shallowEqual, useSelector } from 'react-redux'
import SkuForm from './form'
import {
  convertFloatWithComma,
  convertFormatDateTime,
} from '../../../../../../../../utils/lib'
import dayjs from 'dayjs'
import {
  paymentMethodEnum,
  paymentMethodTxt,
} from '../../../../../../../../modules/Inventory/Transfer/Form/enum/paymentMethod'

const index = (props) => {
  const { item, productType } = props
  const { isShowEditForm } = useSelector(
    (state) => ({
      isShowEditForm: state.crud.isShowEditForm,
    }),
    shallowEqual,
  )

  const startDate = convertFormatDateTime({
    value: _.get(item, 'contractStartDate', null),
    type: 'date',
  })
  const endDate = convertFormatDateTime({
    value: _.get(item, 'contractEndDate', null),
    type: 'date',
  })
  const renewDate = convertFormatDateTime({
    value: _.get(item, 'renewContractDate', null),
    type: 'date',
  })

  const convertJoinDate = (start, end) => {
    let workStart = ''
    const startDate = dayjs(new Date(start))
    const endDate = dayjs(new Date(end))
    const years = endDate.diff(startDate, 'year')
    const months = endDate.diff(startDate, 'month') - years * 12
    const days = endDate.diff(
      startDate.add(years, 'year').add(months, 'month'),
      'day',
    )

    if (years > 0) {
      workStart += `${years} ปี `
    }
    if (months > 0) {
      workStart += `${months} เดือน `
    }
    if (days > 0) {
      workStart += `${days} วัน`
    }
    return workStart
  }
  const paymentType = _.get(item, 'paymentType', '-')
  return (
    <>
      <Box
        sx={{ mt: 2, display: 'flex', gap: 2, flexDirection: 'column', px: 2 }}
      >
        <ViewRow
          title="ประเภททรัพย์สิน"
          variant="variant"
          value={PRODUCT_TYPE_VIEW_TEXT?.[productType] ?? '-'}
        />

        {!isShowEditForm && (
          <>
            <ViewRow
              title="รหัสทรัพย์สิน"
              variant="variant"
              value={_.get(item, 'skuNo', '')}
            />

            <ViewRow
              title="มูลค่าต่อหน่วย (฿) รหัสทรัพย์สิน"
              variant="variant"
              value={`${convertFloatWithComma(
                _.get(item, 'unitPrice', ''),
              )} บาท`}
            />

            <ViewRow
              title="Cost center"
              variant="variant"
              value={`${_.get(item, 'costCenter.descriptionName', '-')}`}
            />
          </>
        )}

        <ViewRow
          title="ชื่อซอฟต์แวร์"
          variant="variant"
          value={`${_.get(item, 'productWarehouse.product.productName', '-')}`}
        />

        <ViewRow
          title="Vendor"
          variant="variant"
          value={`${_.get(item, 'productWarehouse.product.vendor', '-')}`}
        />

        <ViewRow
          title="หมวดหมู่"
          variant="variant"
          value={`${_.get(
            item,
            'productWarehouse.product.category.name',
            '-',
          )}`}
        />

        <ViewRow
          title="หมวดหมู่ย่อย"
          variant="variant"
          value={`${_.get(
            item,
            'productWarehouse.product.subCategory.name',
            '-',
          )}`}
        />

        <ViewRow
          title="สถานะทรัพย์สิน"
          variant="variant"
          value={StatusComponent(item?.productWarehouse?.product)}
        />

        <ViewRow
          boxSx={{ display: 'flex', flexDirection: 'column' }}
          title="หมายเหตุ"
          variant="variant"
          value={`${_.get(item, 'productWarehouse.product.remark', '-')}`}
        />

        {!isShowEditForm && (
          <>
            <ViewRow
              title="สถานะ รหัสทรัพย์สิน"
              variant="variant"
              valueComponent={StatusComponent(item)}
            />

            <ViewRow
              boxSx={{ display: 'flex', flexDirection: 'column' }}
              title="เหตุผลการลบทรัพย์สิน"
              variant="variant"
              value={`${_.get(item, 'reason', '-')}`}
            />
          </>
        )}
      </Box>

      {!isShowEditForm && (
        <>
          <Box my={2}>
            <Typography variant="body1b">ระยะสัญญา</Typography>
            <Box pl={3}>
              <ViewRow
                variant="body1"
                title="วันที่เริ่มต้น"
                value={startDate ?? '-'}
              />
              <ViewRow
                variant="body1"
                title="วันที่สิ้นสุด"
                value={endDate ?? '-'}
              />
              <ViewRow
                variant="body1"
                title="วันที่ต่อระยะ"
                value={renewDate ?? '-'}
              />
              <Divider sx={{ mt: 2 }}></Divider>
              <ViewRow
                variant="body1b"
                title="สรุประยะเวลา"
                value={convertJoinDate(
                  item?.contractStartDate,
                  item?.contractEndDate,
                )}
                boxSx={{ mt: 2 }}
              />
            </Box>
          </Box>
          <Box my={2}>
            <Typography variant="body1b">การชำระเงิน</Typography>
            <Box pl={3}>
              <ViewRow
                variant="body1"
                title="วิธีชำระเงิน"
                value={`${paymentMethodTxt?.[paymentType] ?? '-'}`}
              />

              {paymentType == paymentMethodEnum.CASH_INVOICE && (
                <ViewRow
                  variant="body1"
                  title={`${'ไฟล์ Invoice/eContent'}`}
                  valueComponent={
                    <>
                      <img
                        src={`${
                          window.__env__.REACT_APP_API_URL
                        }/file/get${_.get(item, 'paymentFile.key', '-')}`}
                        height={150}
                        style={{ objectFit: 'cover', maxWidth: '300px' }}
                      />
                    </>
                  }
                />
              )}
              {(paymentType == paymentMethodEnum.CREDIT_CARD ||
                paymentType == paymentMethodEnum.OTHER) && (
                <ViewRow
                  variant="body1"
                  title={`${
                    paymentType == paymentMethodEnum.CREDIT_CARD
                      ? 'ชื่อ (Credit Card Name)'
                      : paymentType == paymentMethodEnum.OTHER
                      ? 'รายละเอียด'
                      : ''
                  }`}
                  value={`${
                    paymentType == paymentMethodEnum.CREDIT_CARD
                      ? _.get(item, 'creditCardName', '-')
                      : paymentType == paymentMethodEnum.OTHER
                      ? _.get(item, 'creditCardDescription', '-')
                      : '-'
                  }`}
                />
              )}
            </Box>
          </Box>
          <Box>
            <Typography variant="body1b">ผู้ชำระเงิน</Typography>
          </Box>
          <Box>
            <Card sx={{ my: 3, p: 2 }}>
              <Box display="flex" justifyContent="start" mb={2}>
                <Typography variant="body2" mr={2} minWidth="120px">
                  คำนำหน้าชื่อ
                </Typography>
                <Typography variant="body2" mr={2} minWidth="120px">
                  {_.get(item, 'payerInfo.prefixTH', '-')}
                </Typography>
                <Typography variant="body2" mr={2} minWidth="120px">
                  ชื่อ-สกุล
                </Typography>
                <Typography variant="body2" mr={2} minWidth="120px">
                  {_.get(item, `payerInfo.firstNameTH`, '')}{' '}
                  {_.get(item, `payerInfo.lastNameTH`, '')}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="start">
                <Typography variant="body2" mr={2} minWidth="120px">
                  เบอร์โทรศัพท์
                </Typography>
                <Typography variant="body2" mr={2} minWidth="120px">
                  {_.get(item, 'payerInfo.tel', '-')}
                </Typography>
                <Typography variant="body2" mr={2} minWidth="120px">
                  Email
                </Typography>
                <Typography variant="body2" mr={2} minWidth="120px">
                  {_.get(item, 'payerInfo.email', '-')}
                </Typography>
              </Box>
            </Card>
          </Box>
          <Box>
            <Typography variant="body1b">ผู้ถือครอง</Typography>
          </Box>
          <Box>
            <Card sx={{ my: 3, p: 2 }}>
              <Box display="flex" justifyContent="start" mb={2}>
                <Typography variant="body2" mr={2} minWidth="120px">
                  คำนำหน้าชื่อ
                </Typography>
                <Typography variant="body2" mr={2} minWidth="120px">
                  {_.get(item, 'holderInfo.prefixTH', '-')}
                </Typography>
                <Typography variant="body2" mr={2} minWidth="120px">
                  ชื่อ-สกุล
                </Typography>
                <Typography variant="body2" mr={2} minWidth="120px">
                  {_.get(item, `holderInfo.firstNameTH`, '')}{' '}
                  {_.get(item, `holderInfo.lastNameTH`, '')}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="start">
                <Typography variant="body2" mr={2} minWidth="120px">
                  เบอร์โทรศัพท์
                </Typography>
                <Typography variant="body2" mr={2} minWidth="120px">
                  {_.get(item, 'holderInfo.tel', '-')}
                </Typography>
                <Typography variant="body2" mr={2} minWidth="120px">
                  Email
                </Typography>
                <Typography variant="body2" mr={2} minWidth="120px">
                  {_.get(item, 'holderInfo.email', '-')}
                </Typography>
              </Box>
            </Card>
          </Box>
        </>
      )}
      {isShowEditForm && <SkuForm />}
    </>
  )
}

export default index
