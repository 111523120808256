export const jobTitleCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'sequence',
    label: 'ลำดับ',
    width: '80px',
  },
  {
    id: 'jobTitle',
    label: 'Job Title',
  },
  {
    id: 'level.level',
    label: 'Level',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    viewPath: `/master-data/job-title`,
    editPath: `/master-data/job-title/edit`,
  },
]

export const licenseCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'licenseId',
    disablePadding: false,
    label: 'ID',
    width: '80px',
  },
  {
    id: 'description',
    label: 'ชื่อใบอนุญาต',
  },
  {
    id: 'category',
    label: 'ประเภทใบอนุญาต',
  },
  {
    id: 'createdBy',
    label: 'สร้างโดย',
  },
  {
    id: 'updatedBy',
    label: 'แก้ไขล่าสุดโดย',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    viewPath: `/master-data/license/view`,
    editPath: `/master-data/license/edit`,
  },
]

export const meetingRoomCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'station',
    label: 'Station',
    width: '160px',
  },
  {
    id: 'roomName',
    label: 'ชื่อห้อง',
    width: '290px',
  },
  {
    id: 'capacityClassroom',
    label: 'ความจุ (คน)',
    width: '160px',
  },
  {
    id: 'roomFunction',
    label: 'ฟังก์ชันห้อง',
    width: '240px',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '140px',
    hideSortIcon: true,
    viewPath: `/master-data/meeting-room/view`,
    editPath: `/master-data/meeting-room/edit`,
  },
]

export const examinationAssocCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'regionName',
    label: 'ภูมิภาค',
  },
  {
    id: 'provinceName',
    label: 'จังหวัด',
  },
  {
    id: 'locationCode',
    label: 'รหัสสถานที่สอบ',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    viewPath: `/master-data/examination/view`,
    editPath: `/master-data/examination/edit`,
  },
]

export const examinationCoCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'id',
    label: 'ID',
    width: '80px',
  },
  {
    id: 'academyStationName',
    label: 'ชื่อสเตชั่นสำหรับสนามสอบ',
  },
  {
    id: 'locationCode',
    label: 'รหัสสถานที่สอบ',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    viewPath: `/master-data/station/view`,
    editPath: `/master-data/station/edit`,
  },
]

export const stationCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'initial',
    label: 'ชื่อย่อ',
  },
  {
    id: 'academyStationName',
    label: 'ชื่อสเตชั่นสำหรับสนามสอบ',
  },
  {
    id: 'station',
    label: 'ชื่อสเตชั่น EN สำหรับ Academy',
  },
  {
    id: 'locationCode',
    label: 'รหัสสถานที่สอบ',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    viewPath: `/master-data/station/view`,
    editPath: `/master-data/station/edit`,
  },
]

