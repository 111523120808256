import _ from 'lodash'
import { store } from '../../../../../App'
import { STATUS } from '../model/status'

export const mutateFilterSateToProp = () => {
  const { filterState } = store.getState().table
  let status = []
  if (filterState.statusChecked) {
    if (filterState.status.activeChecked) {
      status.push(STATUS.ACTIVE)
    }
    if (filterState.status.inActiveChecked) {
      status.push(STATUS.ACTIVE)
    }
    if (filterState.status.draftChecked) {
      status.push(STATUS.DRAFT)
    }
    if (filterState.status.deletedChecked) {
      status.push(STATUS.DELETED)
    }
  } else {
    status = []
  }

  let isActive = []
  if (filterState.statusChecked) {
    if (filterState.status.activeChecked) {
      isActive.push(true)
    }
    if (filterState.status.inActiveChecked) {
      isActive.push(false)
    }
    if (_.isEmpty(isActive)) {
      isActive = ''
    }
  } else {
    isActive = ''
  }

  return {
    name: filterState.nameChecked ? filterState.name : '',
    category: filterState.categoryChecked ? filterState.category : '',
    subCategory: filterState.subCategoryChecked ? filterState.subCategory : '',
    tagName: filterState.tagNameChecked ? filterState.tagName : '',
    updateStartDate: filterState.updateDateChecked
      ? filterState.updateStartDate
      : '',
    updateFinishDate: filterState.updateDateChecked
      ? filterState.updateFinishDate
      : '',
    startPublishDate: filterState.publishDateChecked
      ? filterState.startPublishDate
      : '',
    endPublishDate: filterState.publishDateChecked
      ? filterState.endPublishDate
      : '',
    updatedBy: filterState.updatedByChecked ? filterState.updatedBy : '',
    status: status,
    isActive: isActive,
  }
}
