export const defaultFilter = {
  categoryChecked: false,
  category: '',
  subCategoryChecked: false,
  subCategory: '',
  vendorChecked: false,
  vendor: '',
  stockValueChecked: false,
  stockValueFrom: '',
  stockValueTo: '',
  createdByChecked: false,
  createdBy: '',
  createDateChecked: false,
  updateDateChecked: false,
  updatedByChecked: false,
  updatedBy: '',
  statusChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
  stationChecked: false,
  station: {},
  warehouseChecked: false,
  warehouse: '',
  costCenterChecked: false,
  costCenter: '',
  descriptionChecked: false,
  description: '',
  returnFlagChecked: false,
  returnYear: '',
  returnMonth: '',
  returnDay: '',
  totalChecked: false,
  totalFrom: '',
  totalTo: '',
  quantityAlertChecked: false,
  quantityAlertFrom: '',
  quantityAlertTo: '',
  unitPriceChecked: false,
  unitPriceFrom: '',
  unitPriceTo: '',
}
