import _ from 'lodash'
import { Box, Divider, Typography } from '@mui/material'
import { StyledColumn, StyledRow } from '../../../Styled'
import useTheme from '@mui/system/useTheme'
import {
  BOOKING_PERIOD_TYPE,
  ROOM_LAYOUT,
} from '../../../../../constants/roomManagement'
import { RowLabels } from './RowLabels'
import { ContentRow } from './ContentRow'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { isSameDate } from '../../../../../utils/lib'

export const RoomBookingList = (props) => {
  const theme = useTheme()
  const { index } = props
  const { roomBooking, startDate, endDate, bookingPeriod } = useSelector(
    (state) => ({
      roomBooking:
        state.roomManagementDrawer.bookingInformation.roomBookingList[index],
      startDate: state.roomManagementDrawer.bookingInformation.startDate,
      endDate: state.roomManagementDrawer.bookingInformation.endDate,
      bookingPeriod:
        state.roomManagementDrawer.bookingInformation.bookingPeriod,
    }),
  )

  let dateRange = ''
  if (bookingPeriod === BOOKING_PERIOD_TYPE.SAME_TIME) {
    if (isSameDate(startDate, endDate)) {
      dateRange = dayjs(startDate).format('DD/MM/YY')
    } else {
      dateRange =
        dayjs(startDate).format('DD/MM/YY') +
        ' - ' +
        dayjs(endDate).format('DD/MM/YY')
    }
  } else {
    dateRange = dayjs(roomBooking.bookingDate).format('DD/MM/YY')
  }

  const trainingTime =
    roomBooking.startTimeTraining + ' - ' + roomBooking.endTimeTraining
  return (
    <Box
      sx={{
        border: `dashed 1px ${theme.palette?.primary?.main}`,
        borderRadius: 2,
      }}
    >
      <StyledRow
        sx={{
          p: 1,
          gap: 3,
          background: theme.palette?.primary?.outlinedHoverBackground,
          height: 40,
        }}
      >
        <RowLabels
          title={'ใช้งานวันที่'}
          label={dateRange}
          titleSx={{ color: theme.palette?.primary?.main }}
        />
        <RowLabels
          title={'เริ่มใช้ห้องตั้งแต่'}
          label={roomBooking.startTimeRoom + ' - ' + roomBooking.endTimeRoom}
          titleSx={{ color: theme.palette?.primary?.main }}
        />
        <RowLabels title={'เวลาอบรม'} label={trainingTime} />
      </StyledRow>

      {_.map(roomBooking.rooms, (m, idx) => {
        const {
          meetingRoomUuid,
          meetingRoom,
          meetingRoomOther,
          numberOfAttendees,
          numberOfGroup,
          attendeeInGroup,
          roomLayout,
          additionalEquipment,
          isOnline,
        } = m
        const floor = _.get(meetingRoom, 'floor', '')
        let roomLabel = ''
        switch (meetingRoomUuid) {
          case 'OTHER':
            roomLabel = 'Other'
            break
          case 'ONLINE':
            roomLabel = 'Online'
            break
          default:
            roomLabel = `${meetingRoom?.station?.station} - ${meetingRoom?.roomName} (ชั้น ${floor})`
            break
        }
        return (
          <div key={idx}>
            <Box sx={{ p: 2 }}>
              <ContentRow
                columnLeft={
                  <RowLabels title={`ห้องที่ ${idx + 1}`} label={roomLabel} />
                }
                columnRight={
                  meetingRoomUuid === 'OTHER' ? (
                    <RowLabels
                      titleSx={{ minWidth: 59 }}
                      title={'ชื่อสถานที่'}
                      label={meetingRoomOther}
                    />
                  ) : (
                    <></>
                  )
                }
              />
              {!isOnline && (
                <>
                  <ContentRow
                    columnLeft={
                      <RowLabels
                        title={'จำนวนผู้เข้าร่วม'}
                        label={
                          numberOfAttendees
                            ? Number(numberOfAttendees).toLocaleString()
                            : '-'
                        }
                      />
                    }
                    columnRight={
                      <RowLabels
                        title={'รูปแบบการจัดห้อง'}
                        label={roomLayout ? ROOM_LAYOUT[roomLayout] : '-'}
                      />
                    }
                  />
                  {roomLayout === 'GROUP' && (
                    <ContentRow
                      columnLeft={
                        <RowLabels
                          title={'จำนวนกลุ่ม (กลุ่ม)'}
                          label={numberOfGroup}
                        />
                      }
                      columnRight={
                        <RowLabels
                          title={'กลุ่มละ (คน)'}
                          label={attendeeInGroup}
                        />
                      }
                    />
                  )}
                  {meetingRoom?.meetingRoomEquipment?.length > 0 && (
                    <StyledRow sx={{ gap: 1, mt: 1 }}>
                      <Typography variant="body2b">อุปกรณ์ภายในห้อง</Typography>

                      <StyledRow sx={{ gap: 2 }}>
                        <StyledRow
                          sx={{
                            borderRadius: 1,
                            px: 2,
                            py: 1,
                            gap: 2,
                            border: `1px solid ${theme.palette?.blue?.blueLine}`,
                            height: 40,
                          }}
                        >
                          {_.map(
                            meetingRoom?.meetingRoomEquipment,
                            (e, index) => (
                              <StyledRow key={index}>
                                <Typography variant="body1">
                                  {e.equipment.equipmentName}{' '}
                                </Typography>
                                <Typography variant="body1b">
                                  {e.quantity}
                                </Typography>
                                {meetingRoom?.meetingRoomEquipment?.length -
                                  1 !==
                                  index && (
                                  <Divider
                                    sx={{
                                      pl: 2,
                                      my: 'auto',
                                      height: 24,
                                      borderColor: theme.palette?.blue?.blueLine,
                                    }}
                                    orientation="vertical"
                                    flexItem
                                  />
                                )}
                              </StyledRow>
                            ),
                          )}
                        </StyledRow>
                      </StyledRow>
                    </StyledRow>
                  )}
                  {!_.isEmpty(additionalEquipment) && (
                    <StyledColumn sx={{ mt: 1 }}>
                      <Typography variant="body1b">
                        รายละเอียดเพิ่มเติม
                      </Typography>
                      <Typography sx={{ pl: 2 }}>
                        {additionalEquipment}
                      </Typography>
                    </StyledColumn>
                  )}
                </>
              )}
            </Box>
            {roomBooking.rooms?.length - 1 !== idx && (
              <Divider sx={{ mx: 2 }} />
            )}
          </div>
        )
      })}
    </Box>
  )
}
