import React from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import _ from 'lodash'
import { handleChangePage, handleChangeRowsPerPage } from '../../event'
import TableReportRow from './TableReportRow'
import NotFoundData from './NotFoundData'
import { ROW_PAGE_PER_LIST } from '../../../../../constants/table'
import { TableCellHeader } from './TableCellHeader'

const TableReport = () => {
  const dispatch = useDispatch()

  const { columns, reportsData, tablePage, page, rowsPerPage, allCount } =
    useSelector(
      (state) => ({
        columns: state.reportCourseSetting.columns,
        reportsData: state.reportCourseSetting.reportsData,
        tablePage: state.reportCourseSetting.tablePage,
        page: state.reportCourseSetting.page,
        rowsPerPage: state.reportCourseSetting.rowsPerPage,
        allCount: state.reportCourseSetting.allCount,
      }),
      shallowEqual,
    )

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 650 }}>
        <Table stickyHeader>
          <TableHead sx={{ whiteSpace: 'nowrap' }}>
            <TableRow>
              {
                _.map(columns, (column, idx) => (
                  <TableCellHeader column={column} key={idx} />
                ))
                // const type = _.get(column, 'type', 'default')
                // const showTooltip = type === 'custom'
                // const labelCustomColumn = handleLabelCustomColumn(column)

                // return (
                //   <Tooltip
                //     title={
                //       showTooltip ? (
                //         <Typography variant="tooltip">
                //           {labelCustomColumn}
                //         </Typography>
                //       ) : null
                //     }
                //     followCursor
                //     componentsProps={{
                //       tooltip: {
                //         sx: {
                //           background: theme?.palette?.primary?.main,
                //           color: theme?.palette?.common?.white,
                //           boxShadow: theme?.palette?.boxShadow?.main,
                //         },
                //       },
                //     }}
                //   >
                //     <TableCell
                //       key={column?.id}
                //       align={column?.align}
                //       style={{
                //         minWidth: column?.minWidth,
                //       }}
                //     >
                //       {column?.label}
                //     </TableCell>
                //   </Tooltip>
                // )
                // })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {reportsData?.length > 0 ? (
              _.map(reportsData, (report, idx) => (
                <TableReportRow report={report} key={idx} />
              ))
            ) : (
              <NotFoundData />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={_.filter(
          ROW_PAGE_PER_LIST,
          (item) => item.value !== -1,
        )}
        component="div"
        count={allCount}
        rowsPerPage={rowsPerPage.value}
        page={tablePage < 0 ? 1 : +tablePage}
        labelRowsPerPage={
          <Typography variant="caption" color="text.secondary">
            แถวต่อหน้า :{' '}
          </Typography>
        }
        labelDisplayedRows={() => {
          const realPage = tablePage <= 0 ? 1 : tablePage + 1
          let minRowsShowed = page < 0 ? 1 : rowsPerPage.value * tablePage + 1
          let maxRowsShowed =
            rowsPerPage.value * realPage > allCount
              ? allCount
              : rowsPerPage.value * realPage

          if (maxRowsShowed < 0) {
            maxRowsShowed = allCount
          }
          if (minRowsShowed > maxRowsShowed) minRowsShowed = 0

          return (
            <Typography variant="caption">
              {minRowsShowed}-{maxRowsShowed} of {allCount}
            </Typography>
          )
        }}
        onPageChange={(e, newPage) => dispatch(handleChangePage(e, newPage))}
        onRowsPerPageChange={(e) => dispatch(handleChangeRowsPerPage(e))}
      />
    </Paper>
  )
}

export default TableReport
