import { Button, Typography } from '@mui/material'
import _ from 'lodash'
import { useState } from 'react'

const ViewString = ({ value = '', limit = 200 }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const renderValue = () => {
    if (_.isEmpty(value)) {
      return '-'
    }
    if (isExpanded || value.length <= limit) {
      return value
    }
    return `${value.substring(0, limit)}...`
  }

  return (
    <Typography variant="body1" sx={{ lineBreak: 'anywhere' }}>
      {renderValue()}
      {value.length > limit && (
        <Button
          color="primary"
          variant="text"
          sx={{ ml: 1 }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? 'ย่อลง' : 'ดูเพิ่ม'}
        </Button>
      )}
    </Typography>
  )
}

export default ViewString
