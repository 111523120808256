import { TicketStatus } from '../../../../../constants/stock'
import { handleChangeStatus } from './handleChangeStatus'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import _ from 'lodash'
import { setHeadCells } from '../../../../../redux/slices/table'

export const handleClickApproveDropdown = (uuid, status) => {
  const { isHideEditAndApprove, formData } = store.getState().crud
  const { headCells } = store.getState().table.table
  if (status === TicketStatus.EDITED_APPROVED) {
    if (isHideEditAndApprove) {
      store.dispatch(
        setFieldValue({
          key: 'isHideEditAndApprove',
          value: false,
        }),
      )
      const modifiedHeadCells = _.defaultTo(headCells, []).map((item) => {
        let label = item?.label
        if (item.id === 'transferEditInput') label = 'แก้ไข'
        if (item.id === 'transferEditRemark') label = 'สาเหตุ'
        return {
          ...item,
          label,
        }
      })
      store.dispatch(setHeadCells(modifiedHeadCells))
    } else {
      handleChangeStatus(uuid, status, formData)
    }
  } else {
    handleChangeStatus(uuid, status)
  }
}
