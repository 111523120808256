import {
  distributionFilter,
  filterAcquiredSkill,
  filterLevelOfLearner,
  filterProductType,
  filterRoadMap,
  filterTrainingPlatform,
} from '../../utils/apiPath'

export const bodyLevel = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'title',
  title: '',
  status: ['ACTIVE'],
}

export const bodyDistribution = {
  abbreviation: '',
  distribution: '',
  level: [],
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: ['ACTIVE'],
}

export const bodyProductType = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'id',
  productType: '',
  status: ['ACTIVE'],
}

export const bodyRoadMap = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'id',
  title: '',
  abbreviation: '',
  distribution: [],
  status: ['ACTIVE'],
}

export const bodyTrainingPlatform = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'id',
  status: ['ACTIVE'],
  trainingPlatform: '',
  abbreviation: '',
}

export const bodyAcquiredSkill = {
  acquiredSkill: '',
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: ['ACTIVE'],
}

export const listAPIFetchMasterDataOptions = [
  {
    body: bodyRoadMap,
    url: filterRoadMap,
    method: 'post',
    name: 'roadMap',
  },
  {
    body: bodyTrainingPlatform,
    url: filterTrainingPlatform,
    method: 'post',
    name: 'trainingPlatform',
  },
  {
    body: bodyProductType,
    url: filterProductType,
    method: 'post',
    name: 'productType',
  },
  {
    body: bodyDistribution,
    url: distributionFilter,
    method: 'post',
    name: 'distribution',
  },
  {
    body: bodyAcquiredSkill,
    url: filterAcquiredSkill,
    method: 'post',
    name: 'acquiredSkill',
  },
  {
    body: bodyLevel,
    url: filterLevelOfLearner,
    method: 'post',
    name: 'levelOfLearner',
  },
]
