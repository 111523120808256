import _ from 'lodash'
import { enumStatus } from '../../../../../../components/CRUD/enum/status'
import { PRODUCT_TYPE } from '../../../../../../constants/stock'

export const postBodyMutation = (data) => {
  return {
    productType:
      _.get(data, 'isSku') === 'ACTIVE'
        ? PRODUCT_TYPE.STOCK_ASSET
        : PRODUCT_TYPE.STOCK,
    productName: _.get(data, 'productName', ''),
    productImage: _.get(data, 'productImage', ''),
    category: _.get(data, 'category.value', null),
    subCategory: _.get(data, 'subCategory.value', null),
    minimum: _.get(data, 'minimum', ''),
    description: _.get(data, 'description', ''),
    status: _.get(data, 'status', enumStatus.INACTIVE),
    statusSystem: _.get(data, 'statusSystem', enumStatus.INACTIVE),
    costCenter: _.get(data, 'costCenter.value', null),
    statusFlagReturn: _.get(data, 'statusFlagReturn', enumStatus.ACTIVE),
    isSku: _.get(data, 'isSku', enumStatus.INACTIVE),
    hasPeriodFlagReturn: _.get(data, 'hasPeriodFlagReturn', false),
    refundYear: _.get(data, 'refundYear', null),
    refundMonth: _.get(data, 'refundMonth', null),
    refundDay: _.get(data, 'refundDay', null),
    remark: _.get(data, 'remark', ''),
    uuid: _.get(data, 'uuid', undefined),
    accountCode: _.get(data, 'accountCode.value', null),
    quantityAlert: _.get(data, 'quantityAlert', null),
    unitPrice: _.get(data, 'unitPrice', 0),
    stockValue: _.get(data, 'stockValue', 0),
    total: _.get(data, 'total', 0),
    available: _.get(data, 'available', 0),
  }
}
