import dayjs from 'dayjs'
import { store } from '../../../../App'
import _ from 'lodash'
import { getManageClassByUuid } from '../../../../services/manageClass/form'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

export const filterTrainingPlan = async (trainingPlans) => {
  const { trainingDate } = store.getState().crud.formData

  return await _.filter(trainingPlans, (tp) =>
    dayjs(trainingDate).isBetween(
      _.get(tp, 'startDate'),
      _.get(tp, 'finishDate'),
      'day',
      '[]',
    ),
  )
}

export const checkSelectedDates = (trainingDate, courseSelected) => {
  return dayjs(trainingDate).isBetween(
    _.get(courseSelected, 'startDate'),
    _.get(courseSelected, 'finishDate'),
    'day',
    '[]',
  )
}

export const getStaffListOptions = async (uuid) => {
  const results = await store.dispatch(getManageClassByUuid(uuid))
  const classDetails = _.get(results, 'payload.data.monthlyPlan.dates', [])

  return _.reduce(
    classDetails,
    (acc, item) => {
      const speakers = _.get(item, 'speakers', [])
      const staffs = _.map(speakers, (sp) => {
        const chkDuplicate = _.includes(
          _.map(acc, 'uuid', ''),
          _.get(sp, 'staff.uuid'),
        )
        if (!chkDuplicate)
          return {
            value: _.get(sp, 'staff.uuid'),
            uuid: _.get(sp, 'staff.uuid'),
            label: `${_.get(sp, 'staff.firstNameTH')} ${_.get(
              sp,
              'staff.lastNameTH',
            )}`,
          }
      })

      const filteredStaff = _.filter(staffs, (st) => !_.isUndefined(st))
      return [...acc, ...filteredStaff]
    },
    [],
  )
}
