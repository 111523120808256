import React, { useEffect, useState, useContext } from 'react'
import Typography from '@mui/material/Typography'
import { useHistory } from 'react-router-dom'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import AddIcon from '@mui/icons-material/Add'
import { useLocalStorage } from '@rehooks/local-storage'
import useTheme from '@mui/system/useTheme'
import { DialogContext } from '../../context/dialog-context'
import Table from '../../components/Table'
import FilterDrawer from './FilterDrawer'
import Breadcrumbs from '../../components/Breadcrumbs'
import {
  trainingPlatform,
  downloadTrainingPlatform,
  filterTrainingPlatform,
} from '../../utils/apiPath'
import {
  callAPI,
  handleDownload,
  hasPermissionCreateMasterData,
} from '../../utils/lib'
import { handleFilterTotal } from '../../redux/slices/table/events'

export const headCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'id',
    disablePadding: false,
    label: 'ID',
    width: '80px',
  },
  {
    id: 'trainingPlatform',
    label: 'รูปแบบการสอน',
  },
  {
    id: 'abbreviation',
    label: 'ชื่อย่อ',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    deletePermission: 'DELETE',
    deleteModuleType: 'MASTER_DATA',
  },
]

export const breadcrumbList = [
  { title: 'Master Data', link: '/', pointer: false },
  { title: 'รูปแบบการสอน', link: '/', pointer: false },
]

export const onView = (row, history, setDialog) => {
  history.push('/master-data/training-platform/' + row.uuid)

  setDialog && setDialog({ open: false })
}
export const onEdit = (row, history) => {
  history.push('/master-data/training-platform/edit/' + row.uuid)
}

export const onDelete = async ({
  row,
  setDialog,
  setIsLoading,
  handleDelete,
}) => {
  setIsLoading(false)
  setDialog({
    open: true,
    content: 'คุณต้องการลบรายการนี้หรือไม่',
    variant: 'delete',
    onConfirmClick: () => handleDelete(row),
    onCancelClick: () =>
      setDialog({
        variant: 'delete',
        content: 'คุณต้องการลบรายการนี้หรือไม่',
        open: false,
      }),
  })
}

export const mainHandleDelete = async (row, setIsLoading, setDialog) => {
  const path = trainingPlatform + `/${row.uuid}`
  setIsLoading && setIsLoading(true)
  callAPI({
    url: path,
    method: 'delete',
    onSuccess: () => {
      setDialog({
        variant: 'success',
        content: 'ถูกลบแล้ว',
        open: true,
        isLoading: false,
        onConfirmClick: () => window.location.reload(),
      })
    },
    onFinally: () => {
      setIsLoading && setIsLoading(false)
    },
  })
}

export const onFilterClick = async ({
  limit,
  order,
  sort,
  filter,
  setSearchText,
  setAbbreviationText,
  setText,
  setStatus,
  setTablePage,
  fetchTrainingPlatformList,
  setFilterTotal,
}) => {
  setSearchText('')
  setText('')
  const statusList = []
  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      statusList.push('ACTIVE')
    }
    if (filter.status.inActiveChecked) {
      statusList.push('INACTIVE')
    }
    if (filter.status.deletedChecked) {
      statusList.push('DELETED')
    }
  }
  setStatus(statusList)
  const trainingPlatformText = filter?.trainingPlatformChecked
    ? filter.trainingPlatformText
    : ''

  const abbreviation = filter?.abbreviationChecked
    ? filter.abbreviationText
    : ''

  setSearchText(trainingPlatformText)
  setAbbreviationText(abbreviation)
  setTablePage(0)
  fetchTrainingPlatformList({
    method: 'filter',
    statusList,
    valueLimit: limit,
    valueOrder: order,
    valuePage: 1,
    valueSort: sort,
    search: trainingPlatformText,
    abbreviation,
  })
  setFilterTotal(handleFilterTotal(filter))
}

export const handleAddClick = () => {
  window.location.href = '/master-data/training-platform/form'
}

export const handleQuickSearch = async ({
  limit,
  order,
  sort,
  valueText,
  setAbbreviationText,
  setTablePage,
  fetchTrainingPlatformList,
}) => {
  setTablePage(0)
  setAbbreviationText('')
  const statusList = []
  fetchTrainingPlatformList({
    method: 'search',
    statusList,
    valueLimit: limit,
    valueOrder: order,
    valuePage: 1,
    valueSort: sort,
    search: valueText,
    abbreviation: '',
  })
}

const TrainingPlatformList = () => {
  const theme = useTheme()
  const history = useHistory()
  const [user] = useLocalStorage('user')
  const hasPermissionCreate = hasPermissionCreateMasterData({ user })
  const [isLoading, setIsLoading] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [limit, setLimit] = useState('100')
  const [order, setOrder] = useState('DESC')
  const [page, setPage] = useState(-1)
  const [sort, setSort] = useState('')
  const [trainingPlatformList, setTrainingPlatformList] = useState([])
  const [allCount, setAllCount] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [abbreviationText, setAbbreviationText] = useState('')
  const [text, setText] = useState('')
  const [status, setStatus] = useState([])
  const [isFilterDrawer, setIsFilterDrawer] = useState(false)
  const [tablePage, setTablePage] = useState(0)
  const [filterTotal, setFilterTotal] = useState(0)
  const { setContext: setDialog } = useContext(DialogContext)
  useEffect(() => {
    setIsPageLoading(true)
    fetchTrainingPlatformList({
      method: 'fetch',
      statusList: status,
      valueLimit: limit,
      valueOrder: order,
      valuePage: page,
      valueSort: sort,
      abbreviation: abbreviationText,
    })
  }, [limit, order, sort, page])

  const onDownload = (selected) => {
    const body = {
      trainingPlatform: '',
      abbreviation: '',
      order: order.toUpperCase(),
      sort: sort === '' ? 'updatedAt' : sort,
      status: [],
      type: 'CUSTOM',
      list: selected,
    }
    handleDownload({
      url: downloadTrainingPlatform,
      body: body,
      fileName: `รายการรูปแบบการสอน.xlsx`,
    })
  }

  const fetchTrainingPlatformList = async ({
    method,
    statusList,
    valueLimit,
    valueOrder,
    valuePage,
    valueSort,
    search,
    abbreviation,
  }) => {
    const sortBy = valueSort === '' ? 'updatedAt' : valueSort
    const realPage = valuePage <= 0 ? 1 : +valuePage
    setIsPageLoading(true)

    const body = {
      trainingPlatform: method !== 'fetch' ? search : searchText,
      abbreviation: method !== 'fetch' ? abbreviation : abbreviationText,
      limit: valueLimit,
      order: valueOrder.toString().toUpperCase(),
      page: realPage,
      sort: sortBy,
      status: statusList,
    }

    callAPI({
      url: filterTrainingPlatform,
      method: 'POST',
      body: body,
      onSuccess: (res) => {
        setTrainingPlatformList(res.result)
        setAllCount(res.totalCount)
      },
      onFinally: () => {
        setIsPageLoading(false)
      },
    })
  }

  return (
    <Box sx={{ pb: 2 }}>
      <Box sx={isPageLoading ? { opacity: 0.4, pointerEvents: 'none' } : {}}>
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">รูปแบบการสอน (Training Platform)</Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />

        <Button
          data-testid={`btn-add-training-platform`}
          sx={{
            ml: 2,
            [theme.breakpoints.up('md')]: {
              position: 'absolute',
              mt: -10,
              mr: 3,
            },
            right: 0,
          }}
          startIcon={<AddIcon />}
          variant="contained"
          disabled={!hasPermissionCreate}
          onClick={handleAddClick}
        >
          เพิ่มรูปแบบการสอน
        </Button>
        <Card sx={{ minWidth: 275, mx: 3, mt: 3 }}>
          <Table
            data={trainingPlatformList}
            headCells={headCells}
            onView={(row) => onView(row, history)}
            onEdit={(row) => onEdit(row, history)}
            onDelete={(row) => {
              onDelete({
                row,
                setIsLoading,
                setDialog,
                handleDelete: () =>
                  mainHandleDelete(row, setIsLoading, setDialog),
              })
            }}
            placeholder="ค้นหารูปแบบการสอน"
            searchKey={'trainingPlatform'}
            setLimit={setLimit}
            order={order}
            setOrder={setOrder}
            page={page}
            setPage={setPage}
            sort={sort}
            setSort={setSort}
            allCount={allCount}
            handleSearch={(valueText) =>
              handleQuickSearch({
                limit,
                order,
                sort,
                valueText,
                setAbbreviationText,
                setTablePage,
                fetchTrainingPlatformList,
              })
            }
            onDownload={onDownload}
            setIsFilterDrawer={setIsFilterDrawer}
            searchText={searchText}
            setSearchText={setSearchText}
            tablePage={tablePage}
            setTablePage={setTablePage}
            text={text}
            setText={setText}
            filterTotal={filterTotal}
          />
        </Card>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading && isLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <FilterDrawer
        open={isFilterDrawer}
        onClose={() => setIsFilterDrawer(false)}
        onFilterClick={(filter) =>
          onFilterClick({
            limit,
            order,
            sort,
            filter,
            setSearchText,
            setAbbreviationText,
            setText,
            setStatus,
            setTablePage,
            fetchTrainingPlatformList,
            setFilterTotal,
          })
        }
      />
    </Box>
  )
}
export default TrainingPlatformList
