import { store } from '../../../App'
import { setFieldValue } from '../../../redux/slices/crud'
import { callAPI } from '../../../utils/lib'
import { handleFetchError } from './handlerFetchError'
import { CRUDListFilterPath } from '../../../utils/apiPath'
import { validatePermission } from '../../../utils/lib'
import _ from 'lodash'
import { INVENTORY_SETTING, STOCK_PERMISSION } from '../../../constants/stock'

export const fetchDetail = async (uuid, getBodyMutation, user) => {
  startDialogLoading()

  try {
    return await fetchData(uuid, getBodyMutation, user)
  } catch (error) {
    console.log(error)
    handleFetchError(error)
  } finally {
    stopDialogLoading()
  }
}

const startDialogLoading = () => {
  store.dispatch(setFieldValue({ key: 'isLoading', value: true }))
}

const stopDialogLoading = () => {
  store.dispatch(setFieldValue({ key: 'isLoading', value: false }))
}

const fetchData = async (uuid = '', getBodyMutation, user) => {
  const { crudConfig } = store.getState().crud
  if (!crudConfig) return
  const url = `/crud/${uuid}`
  const headers = { 'x-type': crudConfig.moduleId }
  const response = await callAPI({
    method: 'get',
    url,
    headers,
  })
  store.dispatch(
    setFieldValue({ key: 'formData', value: getBodyMutation(response, user) }),
  )
  return response
}

export const fetchMasterDataDropdown = async (
  customMasterDataModuleId = null,
) => {
  const { crudConfig, masterData } = store.getState().crud
  let masterDataValue = masterData
  const user = JSON.parse(localStorage.getItem('user'))
  const staffStationUuid = _.get(user, 'stationUuid', '')
  const hasPermissionAdmin = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN],
  })

  startDialogLoading()
  const masterDataModuleId =
    customMasterDataModuleId || _.get(crudConfig, 'masterDataModuleId', [])
  await Promise.all(
    _.map(masterDataModuleId, async (moduleData) => {
      const url = _.get(moduleData, 'filterPath', CRUDListFilterPath)
      const headers = { 'x-type': moduleData.moduleId }

      let body = {
        limit: '-1',
        page: 1,
        order: 'DESC',
        sort: 'updatedAt',
        quickSearch: '',
        name: '',
        status: ['ACTIVE'],
        createdBy: '',
        updatedBy: '',
        stockCategoryName: '',
        search: '',
        productType: [],
        startCreatedDate: '',
        endCreatedDate: '',
        startUpdatedDate: '',
        endUpdatedDate: '',
      }

      if (!_.isNil(moduleData.body)) {
        body = {
          ...body,
          ...moduleData.body,
        }
      }

      const response = await callAPI({
        method: 'post',
        url,
        headers,
        body,
      })

      let result = _.map(_.get(response, 'result', []), (item) => {
        if (moduleData.moduleId === 'INVENTORY_PAYER_HOLDER') {
          const name = _.get(item, 'name', '').split(' ')
          return {
            prefix: _.get(item, 'prefix'),
            label: _.get(item, 'label'),
            value: _.get(item, 'value'),
            name: name?.[0] ?? '',
            lastname: _.get(item, 'lastname'),
            email: _.get(item, 'email'),
            tel: _.get(item, 'tel'),
          }
        }
        if (
          moduleData.key === 'to' ||
          moduleData.key === 'from' ||
          moduleData.key === 'warehouse'
        ) {
          return {
            label: _.get(item, 'warehouseName'),
            value: _.get(item, 'uuid'),
            warehouseType: _.get(item, 'warehouseType'),
            stationUuid: _.get(item, 'station.uuid'),
          }
        }

        if (
          moduleData.moduleId === 'INVENTORY_PRODUCT_ALL' &&
          _.get(moduleData, 'body.isRequisition', false)
        ) {
          return item
        }

        if (moduleData.moduleId === 'STOCK_SUB_CATEGORY') {
          return {
            label: _.get(item, 'name'),
            value: _.get(item, 'uuid'),
            categoryUuid: _.get(item, 'stockCategory.uuid'),
          }
        }

        return {
          label:
            moduleData.moduleId === 'COST_CENTER'
              ? _.get(item, 'descriptionName')
              : moduleData.moduleId === 'INVENTORY_WAREHOUSE'
              ? _.get(item, 'warehouseName')
              : moduleData.moduleId === 'INVENTORY_PRODUCT_ALL'
              ? _.get(item, 'productName')
              : _.get(item, 'name'),
          value:
            moduleData.moduleId === 'INVENTORY_RESPONSIBLE_USER'
              ? _.get(item, 'value')
              : _.get(item, 'uuid'),
        }
      })

      if (moduleData.key === 'from') {
        if (!hasPermissionAdmin) {
          result = result.filter(
            (item) => (item?.stationUuid === staffStationUuid && item.warehouseType != 'PROVIDER'),
          )
        }
        result.unshift({
          label: 'นำเข้าคลัง',
          value: 'IMPORT_PRODUCT',
        })
      }

      if (moduleData.key === 'to') {
        if (!hasPermissionAdmin) {
          result = result.filter((item) => item.warehouseType != 'PROVIDER')
        }
      }

      if(moduleData.key === 'warehouse'){
        if (!hasPermissionAdmin) {
          result = result.filter(
            (item) => (item?.stationUuid === staffStationUuid && item.warehouseType != 'PROVIDER'),
          )
        }
      }

      masterDataValue = {
        ...masterDataValue,
        [moduleData.key]: {
          isLoading: false,
          options: result,
        },
      }
    }),
  )
  stopDialogLoading()
  store.dispatch(setFieldValue({ key: 'masterData', value: masterDataValue }))
}
