import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { TYPE_OF_VALUE } from '../../../../constants/reportCourseSetting/reportCourseSetting'
import _ from 'lodash'
import { Box, TextField } from '@mui/material'
import TextInput from '../../../../components/Input/TextInput'
import DateRangeInput from '../../../../components/DateRangeInput'
import SelectGroupChip from '../../../../components/SelectGroupChip'
import Autocomplete from '../../../../components/Input/Autocomplete'
import Select from '../../../../components/Input/Select'
import { handleChangeValue, handleNumberInput } from '../event'
import dayjs from 'dayjs'

const ValueOfColumn = () => {
  const dispatch = useDispatch()
  const {
    columnSelected,
    typeOfValue,
    value,
    error,
    masterDataOptions,
    options,
  } = useSelector(
    (state) => ({
      columnSelected: state.reportCourseSetting.columnSelected,
      typeOfValue: state.reportCourseSetting.typeOfValue,
      value: state.reportCourseSetting.value,
      error: state.reportCourseSetting.error,
      masterDataOptions: state.reportCourseSetting.masterDataOptions,
      options: state.reportCourseSetting.options,
    }),
    shallowEqual,
  )
  const displayValue =
    typeOfValue === TYPE_OF_VALUE.DROPDOWN_SINGLE ? value : value || ''

  return (
    <Box sx={{ width: '50%' }}>
      {typeOfValue === TYPE_OF_VALUE.DATE_RANGE && (
        <DateRangeInput
          labelText="ข้อมูล"
          placeholder="เลือกวันที่"
          dateState={{
            startDate: !displayValue?.startDate
              ? new Date()
              : displayValue?.startDate,
            endDate: !displayValue?.endDate
              ? new Date()
              : displayValue?.endDate,
            display: false,
            key: 'selection',
          }}
          onChange={(selectedDates) => {
            const startDate = dayjs(
              _.get(selectedDates, 'startDate', ''),
            ).format('YYYY-MM-DD')
            const endDate = dayjs(_.get(selectedDates, 'endDate', '')).format(
              'YYYY-MM-DD',
            )
            dispatch(
              handleChangeValue({ startDate: startDate, endDate: endDate }),
            )
          }}
        />
      )}

      {typeOfValue === TYPE_OF_VALUE.INPUT_NUMBER && (
        <TextInput
          sxTextError={{ mb: 0 }}
          type="basic"
          name="value"
          labelText="ข้อมูล"
          disabled={_.isNull(columnSelected)}
          textError={error}
          isShowTextError={!_.isNull(error)}
          value={displayValue}
          inputProps={{
            type: 'text',
            onInput: (e) => {
              if (typeOfValue === TYPE_OF_VALUE.INPUT_NUMBER)
                handleNumberInput(e)
            },
          }}
          onChange={(event) => {
            const value = _.get(event, 'target.value', null)
            dispatch(handleChangeValue(value))
          }}
        />
      )}

      {typeOfValue === TYPE_OF_VALUE.INPUT_TEXT && (
        <Autocomplete
          id="value"
          name="value"
          labelText="ข้อมูล"
          options={options}
          defaultValue={{ value: displayValue, label: displayValue }}
          isHideSort={true}
          renderInput={(params) => (
            <TextField placeholder="กรุณาเลือก" {...params} />
          )}
          handleChange={(e) => {
            const value = _.get(e, 'target.id', '')
            const label =
              _.find(options, (opt) => opt.value === value)?.label || ''
            dispatch(handleChangeValue(label))
          }}
        />
      )}

      {typeOfValue === TYPE_OF_VALUE.DROPDOWN_MULTI && (
        <SelectGroupChip
          boxSx={{ mb: 3 }}
          id="value"
          name="value"
          labelText="ข้อมูล"
          placeholder="กรุณาเลือก"
          options={[...masterDataOptions]}
          value={displayValue}
          onChange={(list) => dispatch(handleChangeValue(list))}
          textError={error}
        />
      )}

      {typeOfValue === TYPE_OF_VALUE.DROPDOWN_SINGLE && (
        <Select
          dataTestid="select-value"
          id="value"
          name="value"
          placeholder="กรุณาเลือก"
          labelText="ข้อมูล"
          options={options}
          value={displayValue}
          defaultValue={value}
          handleChange={(e) => {
            const value = _.get(e, 'target.value', '')
            dispatch(handleChangeValue(value))
          }}
        />
      )}
    </Box>
  )
}

export default ValueOfColumn
