import React from 'react'
import { CPageContainer } from '../../../../components/CRUD/components/PageContainer'
import { CHeader } from '../../../../components/CRUD/components/Header'
import { breadcrumb } from './model/breadcrumb'
import { Content } from './Content'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  fetchDetail,
  fetchMasterDataDropdown,
} from '../../../../components/CRUD/handler/fetchDetail'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { crudConfig as initialCrudConfig } from '../crudConfig'
import { setFieldValue } from '../../../../redux/slices/crud'
import { getBodyMutation } from './mutation/get'
import { getModuleTitle } from '../utils'
import { Button } from '@mui/material'
import { validationSchema, validationSchemaOther } from '../Form/schema/schema'
import { AssetList } from './components/AssetList'
import { tabTypeEnum } from './enum/requisitionTypeEnum'
import { handleSubmitForm } from './handler/handleSubmitForm'
import { getQueryParams, validatePermission } from '../../../../utils/lib'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
  PRODUCT_TYPE,
  RequisitionStatus,
} from '../../../../constants/stock'
import { handleFetchTrainingPlan } from './handler/handleFetchTrainingPlan'
import { handleFilterFromManageClass } from './handler/handleFilterFromManageClass'

export const InventoryRequisitionForm = ({ isMonthly }) => {
  const { uuid } = useParams()
  const { crudConfig, warehouseMasterData, formData } = useSelector(
    (state) => ({
      crudConfig: state.crud.crudConfig,
      formData: state.crud.formData,
      warehouseMasterData: state.crud.masterData.warehouse,
    }),
    shallowEqual,
  )
  const dispatch = useDispatch()
  const user = JSON.parse(localStorage.getItem('user'))
  const startDate = getQueryParams('date')
  const courseUuid = getQueryParams('uuid')
  const courseName = getQueryParams('course')
  const staffStationUuid = _.get(user, 'stationUuid', '')
  const hasPermissionAdmin = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN],
  })
  const validateSchema = !isMonthly ? validationSchemaOther : validationSchema

  const initializeCrud = async () => {
    const type = isMonthly ? tabTypeEnum.MONTHLY : tabTypeEnum.OTHER
    setFieldValue({
      key: 'formData.requisitionType',
      value: type,
    })
    initialConfig(initialCrudConfig)
    let trainingPlan = ''
    await fetchMasterDataDropdown().then()
    await handleFetchTrainingPlan().then((trainingPlans) => {
      if (courseUuid) {
        trainingPlan = _.defaultTo(trainingPlans, []).find((item) => {
          return item?.value === courseUuid
        })
        handleFilterFromManageClass({ trainingPlans, courseUuid })
      }
    })
    if (uuid && !_.isUndefined(crudConfig)) {
      fetchDetail(uuid, getBodyMutation).then((value) => {
        fetchMasterDataDropdown([
          {
            key: 'assets',
            moduleId: 'INVENTORY_PRODUCT_ALL',
            body: {
              isRequisition: true,
              productType: [PRODUCT_TYPE.STOCK, PRODUCT_TYPE.STOCK_ASSET],
              warehouseUuid: value.warehouse.uuid,
            },
          },
        ])
      })
    } else {
      dispatch(
        setFieldValue({
          key: 'formData',
          value: getBodyMutation({
            requisitionType: type,
            trainingDate: startDate || '',
            station: courseUuid ? { value: courseUuid, label: courseName } : '',
            trainingPlan: trainingPlan,
          }),
        }),
      )
    }
    if (warehouseMasterData && !hasPermissionAdmin) {
      const filterdWarehouse = _.defaultTo(
        warehouseMasterData?.options,
        [],
      ).filter((item) => {
        return staffStationUuid === item?.stationUuid
      })
      dispatch(
        setFieldValue({
          key: 'masterData.warehouse',
          value: {
            isLoading: false,
            options: filterdWarehouse,
          },
        }),
      )
    }
  }

  useEffect(initializeCrud, [crudConfig])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <>
      <CPageContainer
        gap="3"
        header={
          <CHeader
            title={getModuleTitle(uuid, crudConfig.modulesTitleShort)}
            breadcrumbs={breadcrumb(uuid)}
            action={
              <>
                {![
                  RequisitionStatus.WAITING_APPROVE,
                  RequisitionStatus.WAITING_REQUISITION_APPROVE,
                  RequisitionStatus.WAITING_REQUISITION_COMFIRM,
                  RequisitionStatus.WAITING_RETURN_APPROVE,
                ].includes(_.get(formData, 'status', '')) && (
                  <Button
                    sx={{ mr: 2 }}
                    variant="outlined"
                    onClick={() =>
                      handleSubmitForm({
                        uuid,
                        validationSchema: validateSchema,
                        isDraft: true,
                      })
                    }
                  >
                    บันทึกฉบับร่าง
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={() =>
                    handleSubmitForm({
                      uuid,
                      validationSchema: validateSchema,
                    })
                  }
                >
                  บันทึก
                </Button>
              </>
            }
          />
        }
        content={<Content />}
      />
      <CPageContainer gap="3" content={<AssetList uuid={uuid} />} />
    </>
  )
}
