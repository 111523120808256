import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'

const reportCourseSetting = createSlice({
  name: 'reportCourseSetting',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startLoadingDrawer: (state) => {
      state.isLoadingDrawer = true
    },
    stopLoadingDrawer: (state) => {
      state.isLoadingDrawer = false
    },
    setIsFilter: (state, { payload }) => {
      state.isFilter = payload
    },
    setIsOpenFilterDrawer: (state, { payload }) => {
      state.isOpenFilterDrawer = payload
    },
    setIsOpenManageColumnDrawer: (state, { payload }) => {
      state.isOpenManageColumnDrawer = payload
    },
    setTabActiveCol: (state, { payload }) => {
      const { key, value } = payload
      state[key] = value
    },
    setReportsData: (state, { payload }) => {
      state.reportsData = payload
    },
    setColumns: (state, { payload }) => {
      state.columns = payload
    },
    setFiltersData: (state, { payload }) => {
      state.filters = payload
    },
    setColumnSelected: (state, { payload }) => {
      state.columnSelected = payload
    },
    setTypeOfValue: (state, { payload }) => {
      state.typeOfValue = payload
    },
    setValue: (state, { payload }) => {
      state.value = payload
    },
    setReduxValue: (state, { payload }) => {
      state[payload.key] = payload.value
    },
  },
})

export const {
  startLoading,
  stopLoading,
  startLoadingDrawer,
  stopLoadingDrawer,
  setIsOpenFilterDrawer,
  setIsOpenManageColumnDrawer,
  setTabActiveCol,
  setReportsData,
  setColumns,
  setFiltersData,
  setColumnSelected,
  setTypeOfValue,
  setValue,
  setReduxValue,
  setIsFilter,
} = reportCourseSetting.actions

export default reportCourseSetting.reducer
