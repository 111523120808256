import { path } from '../path'
export const applicationDocuments = [
  {
    id: 'codeId',
    label: 'ID',
    disablePadding: false,
  },
  {
    id: 'firstNameTH',
    label: 'ชื่อ',
    disablePadding: false,
  },
  {
    id: 'lastNameTH',
    label: 'นามสกุล',
    disablePadding: false,
  },
  {
    id: 'round',
    label: 'ส่งเอกสารครั้งที่',
    disablePadding: false,
  },
  {
    id: 'documentStatus',
    label: 'สถานะ',
    disablePadding: false,
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideDelete: true,
    hideEdit: true,
    hideSortIcon: true,
    viewPath: `${path.manageClass}/learner/document`,
    textAlign: 'center',
  },
]

export const filterBody = {
  courseCode: '',
  course: '',
  assignNee: '',
  startDate: '',
  finishDate: '',
  startRegister: '',
  finishRegister: '',
  checkDocuments: false,
  waitingExam: false,
  highClass: false,
  myJob: false,
  station: [],
  trainingPlatForm: [],
  status: [],
}

export const defaultQuickFilter = [
  {
    title: 'รอเอกสาร',
    count: 0,
    field: 'WAITING_DOCUMENT',
    clicked: false,
  },
  {
    title: 'รอตรวจสอบ',
    count: 0,
    field: 'WAITING_CHECK',
    clicked: false,
  },
  {
    title: 'รอคอนเฟิร์มตัวแทน',
    count: 0,
    field: 'WAITING_CONFIRM',
    clicked: false,
    info: 'คลิกเพื่อใช้ปุ่มอนุมัติและส่งอีเมลพร้อมกันทั้งหมด',
  },
  {
    title: 'เอกสารไม่ผ่าน',
    count: 0,
    field: 'REJECTED',
    clicked: false,
  },
  {
    title: 'ผ่านการตรวจสอบ',
    count: 0,
    field: 'PASS_CHECKED',
    clicked: false,
  },
  {
    title: 'ไม่มีสิทธิ์เข้าเรียน',
    count: 0,
    field: 'INELIGIBLE',
    clicked: false,
  },
  {
    title: 'ยกเลิกการสมัครด้วยตนเอง',
    count: 0,
    field: 'CANCELED_DOCUMENT_BY_USER',
    clicked: false,
  },
  {
    title: 'ถูกยกเลิกการสมัคร',
    count: 0,
    field: 'CANCELED_DOCUMENT_BY_ADMIN',
    clicked: false,
  },
]
