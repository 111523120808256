import * as yup from 'yup'
import dayjs from 'dayjs'

export const drawerFormValidationSchemaSKU = yup.object({
  skuNoForm: yup
    .string()
    .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร')
    .nullable()
    .required('กรุณาระบุ รหัสทรัพย์สิน'),
  costCenterForm: yup
    .object({
      value: yup.string().nullable().required('กรุณาระบุ Cost center'),
    })
    .nullable()
    .required('กรุณาระบุ Cost center'),
  unitPriceForm: yup
    .number()
    .typeError('ต้องเป็นตัวเลขเท่านั้น')
    .transform((value) => (isNaN(value) ? undefined : value))
    .test(
      'quantity',
      'กรุณากรอกข้อมูลไม่เกิน 20 หลัก',
      (val) => val && val.toString().length <= 20,
    )
    .nullable()
    .required('กรุณาระบุมูลค่าต่อหน่วย'),
})

export const drawerFormValidationSchemaAsset = yup.object({
  skuNoForm: yup
    .string()
    .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร')
    .nullable()
    .required('กรุณาระบุ รหัสทรัพย์สิน'),
  assetNoForm: yup
    .string()
    .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร')
    .nullable()
    .required('กรุณาระบุ Item Name'),
  costCenterForm: yup
    .object({
      value: yup.string().nullable().required('กรุณาระบุ Cost center'),
    })
    .nullable()
    .required('กรุณาระบุ Cost center'),
  unitPriceForm: yup
    .number()
    .typeError('ต้องเป็นตัวเลขเท่านั้น')
    .transform((value) => (isNaN(value) ? undefined : value))
    .test(
      'quantity',
      'กรุณากรอกข้อมูลไม่เกิน 20 หลัก',
      (val) => val && val.toString().length <= 20,
    )
    .nullable()
    .required('กรุณาระบุมูลค่าต่อหน่วย'),
  contractStartDateForm: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
  contractEndDateForm: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .when('contractStartDate', (contractStartDate, schema) => {
      if (contractStartDate && dayjs(contractStartDate).isValid()) {
        const dayAfter = new Date(contractStartDate.getTime() + 86400000)
        return schema
          .min(dayAfter, 'กรุณาระบุวันที่สิ้นสุดให้มากกว่าวันที่เริ่มต้น')
          .nullable()
      }
      return schema
    }),
  renewContractDateForm: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .when('contractStartDate', (contractStartDate, schema) => {
      if (contractStartDate && dayjs(contractStartDate).isValid()) {
        const startDateDate = new Date(contractStartDate)
        return schema
          .min(startDateDate, 'กรุณาระบุวันที่ให้มากกว่าวันที่เริ่มต้น')
          .nullable()
      }
      return schema.nullable()
    }),
  holderForm: yup
    .object({
      value: yup.string().nullable().required('กรุณาเลือก ข้อมูลผู้ถือครอง'),
    })
    .nullable()
    .required('กรุณาเลือก ข้อมูลผู้ถือครอง'),
})

export const drawerFormValidationSchemaLicense = yup.object({
  skuNoForm: yup
    .string()
    .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร')
    .nullable()
    .required('กรุณาระบุ รหัสทรัพย์สิน'),
  costCenterForm: yup
    .object({
      value: yup.string().nullable().required('กรุณาระบุ Cost center'),
    })
    .nullable()
    .required('กรุณาระบุ Cost center'),
  unitPriceForm: yup
    .number()
    .typeError('ต้องเป็นตัวเลขเท่านั้น')
    .transform((value) => (isNaN(value) ? undefined : value))
    .test(
      'quantity',
      'กรุณากรอกข้อมูลไม่เกิน 20 หลัก',
      (val) => val && val.toString().length <= 20,
    )
    .nullable()
    .required('กรุณาระบุมูลค่าต่อหน่วย'),
  contractStartDateForm: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null)),
  contractEndDateForm: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .when('contractStartDate', (contractStartDate, schema) => {
      if (contractStartDate && dayjs(contractStartDate).isValid()) {
        const dayAfter = new Date(contractStartDate.getTime() + 86400000)
        return schema
          .min(dayAfter, 'กรุณาระบุวันที่สิ้นสุดให้มากกว่าวันที่เริ่มต้น')
          .nullable()
      }
      return schema
    }),
  renewContractDateForm: yup
    .date()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .when('contractStartDate', (contractStartDate, schema) => {
      if (contractStartDate && dayjs(contractStartDate).isValid()) {
        const startDateDate = new Date(contractStartDate)
        return schema
          .min(startDateDate, 'กรุณาระบุวันที่ให้มากกว่าวันที่เริ่มต้น')
          .nullable()
      }
      return schema.nullable()
    }),
  holderForm: yup
    .object({
      value: yup.string().nullable().required('กรุณาเลือก ข้อมูลผู้ถือครอง'),
    })
    .nullable()
    .required('กรุณาเลือก ข้อมูลผู้ถือครอง'),
  payerForm: yup
    .object({
      value: yup.string().nullable().required('กรุณาเลือก ผู้ชำระเงิน'),
    })
    .nullable()
    .required('กรุณาเลือก ข้อมูลผู้ชำระเงิน'),
  paymentTypeForm: yup
    .object({
      value: yup.string().nullable().required('กรุณาเลือก วิธีชำระเงิน'),
    })
    .nullable()
    .required('กรุณาเลือก วิธีการชำระเงิน'),
  paymentDescForm: yup
    .string()
    .nullable()
    .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
})
