import React from 'react'
import { resetForm } from '../../../../../redux/slices/manageClassLearner'
import { openDialog } from '../../../../../redux/slices/dialog'
import DeleteCourseBatchLearnerDialog from '../../../../../modules/ELearning/Course/Form/BatchTab/Learner/DeleteDialog'
import _ from 'lodash'

export const handleIsCanDelete = (row) => {
  const status = _.get(row, 'status', '')
  return status != 'CANCEL' && status != 'CANCELED'
}

export const handleDeleteDialog = (uuid) => (dispatch) => {
  dispatch(resetForm())
  dispatch(
    openDialog({
      title: 'ลบผู้เรียนออกจากรายการ',
      type: 'mini',
      maxWidth: 'form',
      disableButton: true,
      content: <DeleteCourseBatchLearnerDialog uuid={uuid} />,
    }),
  )
}
