export const arrayStatus = [
  {
    label: 'ฉบับร่าง',
    name: 'draftChecked',
  },
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const defaultFilter = {
  nameChecked: false,
  name: '',
  pointChecked: false,
  point: '',
  qtyChecked: false,
  qty: '',
  statusChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
}

export const arrayCheckbox = [
  {
    label: 'ชื่อของราวัล',
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    placeholder: 'ค้นหาชื่อของรางวัล',
    type: 'text',
  },
  {
    label: 'คะแนนที่ใช้แลก',
    nameCheckBox: 'pointChecked',
    nameInput: 'point',
    placeholder: 'ค้นหาคะแนนที่ใช้แลก',
    type: 'text',
  },
  {
    label: 'จำนวนรางวัล',
    nameCheckBox: 'qtyChecked',
    nameInput: 'qty',
    placeholder: 'ค้นหาจำนวนรางวัล',
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: arrayStatus,
  },
]
