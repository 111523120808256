import _ from 'lodash'
import { setReduxValue } from '.'
import { store } from '../../../App'

export const handleColumnOptions = () => (dispatch) => {
  const { isOpenFilterDrawer, defaultColumns, filterColumnOptions } =
    store.getState().reportComp

  const options = isOpenFilterDrawer ? filterColumnOptions : defaultColumns
  const sortedColumns = handleSortColumns([...options])

  dispatch(setReduxValue({ key: 'columnOptions', value: sortedColumns }))
}

export const handleSortColumns = (columns) => {
  return columns.sort((a, b) => a.label.localeCompare(b.label))
}

export const handleDisabledColumnOptions = () => (dispatch) => {
  const { displayColumns, columnOptions } = store.getState().reportComp
  const idColumnArr = _.map(displayColumns, 'name')
  const newColumnOptions = _.map(columnOptions, (column) => {
    let disabled = _.includes(idColumnArr, column?.name)

    return { ...column, disabled: disabled }
  })

  const sortedColumns = handleSortColumns(newColumnOptions)
  dispatch(setReduxValue({ key: 'columnOptions', value: sortedColumns }))
}
