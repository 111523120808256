import { store } from '../../../App'
import { openErrorDialog } from '../../../redux/slices/dialog'
import { setFieldUploadValue } from '../../../redux/slices/uploadCropper'
import { checkTypeFile } from './checkTypeFile'
import _ from 'lodash'

export default async function handleOpenCropperDialog({
  files,
  fileRejections,
}) {
  if (fileRejections.length) {
    store.dispatch(
      openErrorDialog({
        message:
          'กรุณาตรวจสอบว่าขนาดไฟล์, จำนวนไฟล์, หรือประเภทไฟล์ของคุณว่าเป็นไปตามที่ระบบกำหนดหรือไม่',
        title: 'ไม่สามารถดำเนินการได้',
        isCloseDialog: false,
      }),
    )
    return
  }
  if (files.length === 0) return

  store.dispatch(setFieldUploadValue({ key: 'percent', value: 0 }))

  const file = _.get(files, '[0]', {})

  const fileName = _.get(file, 'name', '')
  store.dispatch(
    setFieldUploadValue({ key: 'prepareFileName', value: fileName }),
  )

  const getExtensionImage = fileName.split('.').pop()
  if (!checkTypeFile(getExtensionImage)) {
    store.dispatch(
      setFieldUploadValue({ key: 'uploadError', value: 'ERROR_FILE_TYPE' }),
    )
    return
  }

  store.dispatch(setFieldUploadValue({ key: 'imageLocalSrc', value: file }))
  store.dispatch(setFieldUploadValue({ key: 'isOpen', value: true }))
}
