import { store } from '../../../App'
import { filterReportCourseSetting } from '../../../services/reportCourseSetting'
import _ from 'lodash'
import {
  setColumns,
  setReduxValue,
  setReportsData,
  startLoading,
  stopLoading,
} from '../../../redux/slices/reportCourseSetting/List'
import { reportCourseSettingDownload } from '../../../utils/apiPath'
import { downloadFile } from '../../../services/util'
import dayjs from 'dayjs'
import { COLUMN_NAME } from '../../../constants/reportCourseSetting/reportCourseSetting'

export const fetchReportCourseSetting = () => async (dispatch) => {
  dispatch(startLoading())
  const {
    columns,
    filters,
    hasSortedColumns,
    rowsPerPage,
    tablePage,
    isFilter,
  } = store.getState().reportCourseSetting

  if (columns?.length === 0) return
  const bodyFilters = handleBodyFilters(filters)

  const { payload: response } = await dispatch(
    filterReportCourseSetting({
      columns,
      filters: bodyFilters,
      hasSortedColumns,
      limit: isFilter ? 100 : rowsPerPage.value,
      page: isFilter ? 0 : tablePage,
    }),
  )

  let newColumns = _.filter(columns, (col) => {
    return (
      col.value !== COLUMN_NAME.STAFF_ID_LIST &&
      col.value !== COLUMN_NAME.STAFF_NAME_LIST
    )
  })
  const reports = _.get(response, 'results', [])
  const allCount = _.get(response, 'count', 0)

  let filteredColumns = []
  let differenceColumns = []
  let columnsList = newColumns

  if (!hasSortedColumns) {
    filteredColumns = _.filter(
      newColumns,
      (col) =>
        col.value === COLUMN_NAME.CREATED_AT ||
        col.value === COLUMN_NAME.CREATED_BY ||
        col.value === COLUMN_NAME.UPDATED_AT ||
        col.value === COLUMN_NAME.UPDATED_BY,
    )
    differenceColumns = _.difference(newColumns, filteredColumns)
    columnsList = [...differenceColumns, ...filteredColumns]
  }

  await dispatch(setColumns(columnsList))
  await dispatch(setReportsData(reports))
  await dispatch(setReduxValue({ key: 'allCount', value: allCount }))
  await dispatch(setReduxValue({ key: 'isInitial', value: false }))
  await dispatch(stopLoading())
}

export const onDownload = () => async (dispatch) => {
  const { columns, filters, hasSortedColumns } =
    store.getState().reportCourseSetting

  dispatch(startLoading())

  const bodyFilters = handleBodyFilters(filters)

  const body = {
    columns,
    filters: bodyFilters,
    hasSortedColumns,
  }

  await dispatch(
    downloadFile({
      url: reportCourseSettingDownload,
      body: body,
      fileName: `Report Course Setting ${dayjs().format('DDMMYYYY')}.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const handleChangePage = (event, newPage) => (dispatch) => {
  const tablePage = newPage < 0 ? 1 : +newPage
  dispatch(setReduxValue({ key: 'page', value: newPage }))
  dispatch(setReduxValue({ key: 'tablePage', value: tablePage }))
  dispatch(fetchReportCourseSetting())
}

export const handleChangeRowsPerPage = (event) => async (dispatch) => {
  const { isInitial } = store.getState().reportCourseSetting
  const rowsPerPageChange =
    parseInt(event.target.value, 10) > 0 ? parseInt(event.target.value, 10) : -1

  await dispatch(
    setReduxValue({
      key: 'rowsPerPage',
      value: { label: '' + rowsPerPageChange, value: rowsPerPageChange },
    }),
  )
  await dispatch(setReduxValue({ key: 'page', value: -1 }))
  await dispatch(setReduxValue({ key: 'tablePage', value: 0 }))
  if (!isInitial) dispatch(fetchReportCourseSetting())
}

export const handleBodyFilters = (filters) => {
  const groupedByKey = _.groupBy(filters, 'key')

  if (groupedByKey.abbreviationDistribution) {
    const modifiedAbbreviationDistribution = _.map(
      groupedByKey[COLUMN_NAME.ABBREVIATION_DISTRIBUTION],
      (item) => ({
        ...item,
        key: 'distribution',
      }),
    )
    const combinedDistribution = groupedByKey?.distribution?.concat(
      modifiedAbbreviationDistribution,
    )

    _.set(groupedByKey, 'distribution', combinedDistribution)
    delete groupedByKey.abbreviationDistribution
  }

  if (groupedByKey.abbreviationRoadMap) {
    const modifiedAbbreviationRoadmap = _.map(
      groupedByKey[COLUMN_NAME.ABBREVIATION_ROADMAP],
      (item) => ({
        ...item,
        key: 'roadMap',
      }),
    )
    const combinedRoadmap = groupedByKey?.roadMap?.concat(
      modifiedAbbreviationRoadmap,
    )

    _.set(groupedByKey, 'roadMap', combinedRoadmap)
    delete groupedByKey.abbreviationRoadMap
  }

  const bodyFilters = _.reduce(
    groupedByKey,
    (acc, filters, key) => {
      let newFilters = filters
      if (
        key === COLUMN_NAME.DISTRIBUTION ||
        key === COLUMN_NAME.ABBREVIATION_DISTRIBUTION ||
        key === COLUMN_NAME.ROADMAP ||
        key === COLUMN_NAME.ABBREVIATION_ROADMAP ||
        key === COLUMN_NAME.COURSE_LEVEL_CONTROL
      ) {
        newFilters = _.reduce(
          filters,
          (acc, filter) => {
            let existingItem = _.find(acc, (item) => item.key === filter.key)
            if (existingItem) {
              existingItem = {
                ...existingItem,
                value: [...existingItem.value, ...filter.value],
              }
              acc = acc.map((item) =>
                item.key === existingItem.key ? existingItem : item,
              )
            } else {
              acc.push(filter)
            }
            return acc
          },
          [],
        )
      }
      return [...acc, ...newFilters]
    },
    [],
  )
  return bodyFilters
}
