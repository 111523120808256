import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { FORM_TAB } from '../../../modules/EContent/Content/Form/components/tabMenu/model'
import { LIST_TAB } from '../../../modules/EContent/Content/components/tabMenu/model'

const initialState = {
  formData: {},
  formErrors: [],
  masterData: {},
  tab: FORM_TAB.content,
  listTab: LIST_TAB.eContent,
  pathCreateCourse: '/course-setting/form',
  isLoading: false,
  isSubmitting: false,
  isShowHistoryDrawer: false,
  crudConfig: {},
  bodyMutation: undefined,
}

const CrudSlice = createSlice({
  name: 'crud',
  initialState,
  reducers: {
    setFieldValue: (state, { payload }) => {
      const { key, value } = payload
      _.set(state, key, value)
    },
    setInitialConfig: (state, { payload }) => {
      if (payload.isClearData) {
        _.set(state, 'formData', {})
        _.set(state, 'formErrors', [])
      }
      _.set(state, 'crudConfig', payload.crudConfig)
    },
    replaceFieldError: (state, { payload }) => {
      state.formErrors = payload
    },
    setFieldError: (state, { payload }) => {
      state.formErrors = [...state.formErrors, ...payload]
    },
    clearFieldValue: (state) => {
      Object.assign(state, initialState)
    },
  },
})
export const {
  setFieldValue,
  setFieldError,
  replaceFieldError,
  setInitialConfig,
  clearFieldValue,
} = CrudSlice.actions

export default CrudSlice.reducer
