import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Table from '../../components/redux/Table'
import Header from './HeaderList'
import FilterDrawer from './FilterDrawerList'
import { fetchDataList } from './events'
import { LoadingPage } from './Styled'
import { MANAGE_CLASS_ROW_PAGE_PER_LIST } from '../../constants/table'
import { setChangeRowsPerPage } from '../../redux/slices/table'
import _ from 'lodash'

const CheckExam = ({ isStandalone }) => {
  const dispatch = useDispatch()
  const [isInitial, setInitial] = useState(true)

  const { table, search, filter } = useSelector(
    (state) => state.table,
    shallowEqual,
  )

  const { limit, order, page, sort, isLoading } = table
  const { filterProp, isFilterDrawer } = filter
  const { searchText } = search

  useEffect(() => {
    dispatch(
      setChangeRowsPerPage({
        defaultRowsPerPage: 10,
        limit: '10',
        rowsPerPage: { label: '10', value: 10 },
      }),
    )
    dispatch(
      fetchDataList({
        method: 'initial',
        table,
        page: page,
        filterProp,
        keyword: searchText,
        setInitial,
        isStandalone,
      }),
    )
  }, [])

  useEffect(() => {
    if (!isInitial) {
      dispatch(
        fetchDataList({
          method: 'fetch',
          table,
          page: page,
          filterProp,
          keyword: searchText,
          isStandalone,
        }),
      )
    }
  }, [limit, order, page, sort])

  return (
    <>
      <Box sx={{ mx: 3 }}>
        <Box
          sx={{
            opacity: isLoading ? 0.4 : 'unset',
            pointerEvents: isLoading ? 'none' : 'unset',
          }}
        >
          <Header isStandalone={isStandalone} />

          <Card sx={{ mt: 3 }}>
            <Table
              rowsPerPageOptions={_.filter(
                MANAGE_CLASS_ROW_PAGE_PER_LIST,
                (item) => item.value !== -1,
              )}
            />
          </Card>

          <FilterDrawer
            isStandalone={isStandalone}
            open={isFilterDrawer}
            table={table}
          />
        </Box>
        <LoadingPage isLoading={isLoading} />
      </Box>
    </>
  )
}

export default CheckExam
