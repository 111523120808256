import { TYPE_OF_VALUE } from '../../../../constants/reportCourseSetting/reportCourseSetting'

import {
  distributionFilter,
  filterAcquiredSkill,
  filterLevelOfLearner,
  filterProductType,
  filterRoadMap,
  filterTrainingPlatform,
} from '../../../../utils/apiPath'

export const bodyLevel = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'title',
  title: '',
  status: ['ACTIVE'],
}

export const bodyDistribution = {
  abbreviation: '',
  distribution: '',
  level: [],
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: ['ACTIVE'],
}

export const bodyProductType = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'id',
  productType: '',
  status: ['ACTIVE'],
}

export const bodyRoadMap = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'id',
  title: '',
  abbreviation: '',
  distribution: [],
  status: ['ACTIVE'],
}

export const bodyTrainingPlatform = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'id',
  status: ['ACTIVE'],
  trainingPlatform: '',
  abbreviation: '',
}

export const bodyAcquiredSkill = {
  acquiredSkill: '',
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: ['ACTIVE'],
}

export const listAPIFetchOptions = [
  {
    body: bodyRoadMap,
    url: filterRoadMap,
    method: 'post',
  },
  {
    body: bodyTrainingPlatform,
    url: filterTrainingPlatform,
    method: 'post',
  },
  {
    body: bodyProductType,
    url: filterProductType,
    method: 'post',
  },
  {
    body: bodyDistribution,
    url: distributionFilter,
    method: 'post',
  },
  {
    body: bodyAcquiredSkill,
    url: filterAcquiredSkill,
    method: 'post',
  },
  {
    body: bodyLevel,
    url: filterLevelOfLearner,
    method: 'post',
  },
]

export const courseLevelControlOptions = [
  { label: 'ระดับสูง', value: 'ระดับสูง', courseLevelControl: 'ระดับสูง' },
  { label: 'ระดับกลาง', value: 'ระดับกลาง', courseLevelControl: 'ระดับกลาง' },
  { label: 'ระดับต่ำ', value: 'ระดับต่ำ', courseLevelControl: 'ระดับต่ำ' },
]

export const approvalOptions = [
  {
    label: 'มีการอนุมัติ',
    value: true,
  },
  {
    label: 'ไม่มีการอนุมัติ',
    value: false,
  },
]

export const isAvailableOptions = [
  {
    label: 'มี',
    value: true,
  },
  {
    label: 'ไม่มี',
    value: false,
  },
]

export const requireOptions = [
  {
    label: 'ต้องการ',
    value: true,
  },
  {
    label: 'ไม่ต้องการ',
    value: false,
  },
]

export const isAttendanceOptions = [
  {
    label: 'กำหนด',
    value: true,
  },
  {
    label: 'ไม่กำหนด',
    value: false,
  },
]

export const statusOptions = [
  {
    label: 'เปิดใช้งาน',
    value: 'ACTIVE',
  },
  {
    label: 'ปิดใช้งาน',
    value: 'INACTIVE',
  },
  {
    label: 'รอเปิดใช้งาน',
    value: 'WAITING_FOR_ACTIVE',
  },
  {
    label: 'ถูกลบแล้ว',
    value: 'DELETED',
  },
  {
    label: 'ฉบับร่าง',
    value: 'DRAFT',
  },
]

export const initialState = {
  isLoading: false,
  isLoadingDrawer: false,
  isOpenFilterDrawer: false,
  isOpenManageColumnDrawer: false,
  tabActiveColDrawer: 0,
  tabActiveColCard: 0,
  columns: [],
  displayColumns: [],
  columnOptions: [],
  filters: [],
  displayFilters: [],
  filterList: [],
  reportsData: [],
  allCount: 0,
  columnSelected: null,
  typeOfValue: TYPE_OF_VALUE.INPUT_NUMBER,
  value: null,
  error: null,
  masterDataOptions: [],
  fetchMasterDataOptions: [],
  options: [],
  newColumnName: null,
  firstColumnSelected: null,
  secondaryColumnSelected: null,
  tablePage: 0,
  page: -1,
  rowsPerPage: { label: '100', value: 100 },
  hasSortedColumns: false,
  disabledAddBtn: true,
  isInitial: true,
  isFilter: false,
}
