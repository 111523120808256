import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import TextInput from '../../../../../../../components/Input/TextInput'
import { handleDelete, handleEnableButton, handleSuccess } from './events'
import { StyledDialog } from './Styled'
import {
  StyledCancelButton,
  StyledDialogActions,
  StyledLoadingButton,
} from '../../../../../../../components/redux/PopUpDialog/Styled'
import { closeDialog } from '../../../../../../../redux/slices/dialog'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export const DeleteCourseBatchLearnerDialog = ({ uuid }) => {
  const { isLoading, disableButton } = useSelector((state) => ({
    isLoading: state.dialog.isLoading,
    disableButton: state.dialog.disableButton,
  }))
  const [remark, setRemark] = useState('')
  const { isSuccess } = useSelector(
    (state) => ({
      isSuccess: state.manageClassLearner.isSuccess,
    }),
    shallowEqual,
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(handleSuccess(isSuccess))
  }, [isSuccess])

  return (
    <>
      <Box id="dialog-header">
        <Typography variant="h6">ลบผู้เรียนออกจากรายการ</Typography>
      </Box>
      <StyledDialog>
        <TextInput
          required
          labelText="เหตุผล"
          type="basic"
          multiline
          rows={3}
          maxlength={255}
          isShowTextError={false}
          id="remark"
          name="remark"
          value={remark}
          onChange={(e) => {
            dispatch(handleEnableButton(e))
            setRemark(e.target.value)
          }}
        />
      </StyledDialog>

      <StyledDialogActions sx={{ mt: 2.5 }}>
        {!isLoading && (
          <StyledCancelButton
            data-testid="btn-disagree"
            onClick={() => dispatch(closeDialog())}
            variant="outlined"
            endIcon=""
          >
            ยกเลิก
          </StyledCancelButton>
        )}
        <StyledLoadingButton
          data-testid="btn-agree"
          color="primary"
          endIcon=""
          loading={isLoading}
          disabled={disableButton}
          onClick={() => dispatch(handleDelete(uuid, remark))}
        >
          บันทึก
        </StyledLoadingButton>
      </StyledDialogActions>
    </>
  )
}

export default DeleteCourseBatchLearnerDialog
