export const COURSE_TYPE_TH = {
  oic: 'หลักสูตรระดับสูง',
  general: 'หลักสูตรทั่วไป',
}

export const FORM_TAB = {
  course: 'course',
  setting: 'setting',
  batch: 'batch',
  learner: 'learner',
}

export const FORM_TAB_TH = {
  course: 'หลักสูตร',
  setting: 'ตั้งค่า',
  batch: 'รอบอบรม',
  learner: 'ผู้เรียน',
}

export const tabs = [
  { label: FORM_TAB_TH.course, value: FORM_TAB.course },
  { label: FORM_TAB_TH.setting, value: FORM_TAB.setting },
  { label: FORM_TAB_TH.batch, value: FORM_TAB.batch },
  { label: FORM_TAB_TH.learner, value: FORM_TAB.learner },
]

export const viewTabs = [
  { label: FORM_TAB_TH.course, value: FORM_TAB.course },
  { label: FORM_TAB_TH.setting, value: FORM_TAB.setting },
  { label: FORM_TAB_TH.learner, value: FORM_TAB.learner },
]

export const viewOicTabs = [
  { label: FORM_TAB_TH.course, value: FORM_TAB.course },
  { label: FORM_TAB_TH.setting, value: FORM_TAB.setting },
  { label: FORM_TAB_TH.batch, value: FORM_TAB.batch },
]

export const courseTabFields = [
  'name',
  'subject',
  'coverImage',
  'linkExampleVideo',
  'eLearningCourseLesson',
  'point',
  'learningPointUuid',
]

export const settingTabFields = [
  'totalHour',
  'totalMinute',
  'periodStart',
  'periodEnd',
  'dueDate',
  'timeSpanType',
  'timeSpan',
  'setting',
  'levelOfLearner',
  'acquiredSkill',
  'productType',
]
