import { CContainer } from '../../../../components/CRUD/components/Container'
import { crudConfig as initialCrudConfig } from '../crudConfig'
import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import CFilterDrawer from '../../../../components/CRUD/components/FilterDrawer'
import { filterItems } from './model/filterItems'
import { defaultFilter } from './model/defaultFilter'
import { handleChange } from '../../../../components/CRUD/handler/handleChange'
import { handleFiltering } from '../../../../components/CRUD/handler/handleFiltering'
import { fetchDataList } from '../../../../components/CRUD/handler/fetchDataList'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { mutateFilterPropToBody } from './mutation/filterPropToBody'
import { mutateFilterSateToProp } from './mutation/filterStateToProp'
import { resetTable } from '../../../../redux/slices/table'
import { store } from '../../../../App'

export const KnowledgeBaseListTab = ({ tab }) => {
  const { page, sort, order, limit } = useSelector(
    (state) => ({
      page: state.table.table.page,
      sort: state.table.table.sort,
      order: state.table.table.order,
      limit: state.table.table.limit,
    }),
    shallowEqual,
  )
  const { crudConfig } = useSelector(
    (state) => ({
      crudConfig: state.crud.crudConfig,
    }),
    shallowEqual,
  )

  useEffect(() => {
    store.dispatch(resetTable())
    initialConfig(initialCrudConfig)
  }, [])

  useEffect(() => {
    if (!_.isUndefined(crudConfig) && tab === 'knowledge Base') {
      fetchDataList(mutateFilterPropToBody, {}, 'Knowledge Base').then()
    }
  }, [limit, order, page, sort, crudConfig])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  console.log('render KnowledgeBaseListTab')

  return (
    <CContainer
      contentSx={{ mx: 0 }}
      drawer={
        <CFilterDrawer
          handleChange={handleChange}
          handleFiltering={() =>
            handleFiltering(
              mutateFilterSateToProp,
              mutateFilterPropToBody,
              'Knowledge Base',
            )
          }
          filterItems={filterItems}
          defaultFilter={defaultFilter}
        />
      }
    />
  )
}
