import axios from 'axios'

import { manageClassEvaStatus } from '../../../utils/apiPath'
import {
  setInitialTable,
  startLoading,
  stopLoading,
} from '../../../redux/slices/table'
import { evaStatusHeadCells } from '../../../constants/manageClass/evaStatus'
import _ from 'lodash'
export const fetchDataList =
  (method, table, trainingPlan, uuid, tab) => async (dispatch) => {
    dispatch(startLoading())

    const { page, limit, sort } = table
    const realPage = page <= -1 ? 1 : +page
    const body = {
      limit: Number(limit),
      page: realPage,
      sort: formatSort(sort),
      order: _.get(table, 'order', 'desc').toString().toUpperCase(),
      type: tab,
    }

    await axios
      .post(`${manageClassEvaStatus}/${trainingPlan}/${uuid}`, body)
      .then(({ data }) => {
        dispatch(
          setInitialTable({
            rows: _.get(data, 'returnLearner', []),
            allCount: _.get(data, 'getLearnerCount', 0),
            headCells: evaStatusHeadCells,
            isCheckBox: false,
          })
        )
      })
      .catch(() =>
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: evaStatusHeadCells,
          })
        )
      )

    dispatch(stopLoading())
  }

export const fetchDataCount = (trainingPlan, uuid, setData) => async () => {
  const body = {
    limit: 1000,
    page: 1,
    sort: 'status',
    order: 'ASC',
    type: '',
  }

  await axios
    .post(`${manageClassEvaStatus}/${trainingPlan}/${uuid}`, body)
    .then(({ data }) => {
      setData(data)
    })
    .catch(() => setData({ title: '-' }))
}

export const formatSort = (field) => {
  let data = ''
  switch (field) {
    case 'codeId':
      data = 'classLearner.codeId'
      break
    case 'firstNameTH':
      data = 'userProfile.firstNameTH'
      break
    case 'lastNameTH':
      data = 'userProfile.lastNameTH'
      break
    default:
      data = 'status'
      break
  }

  return data
}
