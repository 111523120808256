import { FORM_TAB } from '../../../../modules/ELearning/Course/Form/model'
import { MODULE_STATUS } from '../../../../constants/eLearning'

export const initialModule = {
  sequence: 1,
  id: '1',
  moduleType: '',
  eLearningModule: { uuid: '' },
}

export const initialLesson = {
  sequence: 1,
  id: '1',
  name: '',
  description: '',
  eLearningLessonRefModule: [{ ...initialModule }],
}

export const initialValues = {
  courseCode: null,
  courseType: '',
  name: '',
  nameForLearner: '',
  subject: '',
  description: '',
  fileName: '',
  coverImage: '',
  linkExampleVideo: '',
  remark: '',
  periodStart: '',
  totalHour: '',
  totalMinute: '',
  setting: {
    assignees: [],
    tag: [],
    distribution: [],
    remark: '',
    collectHours: { isCollect: false, hours: null, description: null },
  },
  levelOfLearner: [],
  acquiredSkill: [],
  productType: [],
  coursePrerequisite: [],
  eCertificationVersion: undefined,
  expireECertificationYear: undefined,
  certificateLanguage: undefined,
  dueDate: null,
  dueDateType: 'NONE',
  courseAccessExpiration: 'NONE',
  timeSpan: null,
  timeSpanType: null,
  status: MODULE_STATUS.INACTIVE,
  eLearningCourseLesson: [{ ...initialLesson }],
  pointStatus: 'INACTIVE',
  pointType: 'CUSTOM',
  point: '',
  learningPointUuid: '',
}

export const initialState = {
  isLoading: false,
  isOptionLoading: false,
  isECertLoading: false,
  isSettingLoading: false,
  isUploadLoading: false,
  isUploadSuccess: false,
  isDisableButton: true,
  isDrawerOpen: false,
  onGoing: false, // new flow no on-going
  tab: FORM_TAB.course,
  uploadError: null,
  percent: null,
  body: initialValues,
  oldPeriodStart: null,
  oldDueDate: null,
  batchData: {},
  batchDisableDate: [],
  cropper: {
    isOpen: false,
    imageSrc: null,
    crop: { x: 0, y: 0 },
    zoom: 1,
    rotation: 0,
    croppedImage: null,
  },
  moduleOption: [],
  distributionOption: [],
  levelOfLearnerOption: [],
  licenseList: [],
  allCourseList: [],
  eCertificateList: [],
  speakerList: [],
  adminStaffList: [],
  trainerQuestionList: [],
  acquireSkillList: [],
  productTypeList: [],
  learningPointList: [],
}

export const RESPONSIBLE_TYPE = {
  ADMIN: 'ADMIN',
  TRAINER: 'TRAINER',
  SPEAKER: 'SPEAKER',
  TRAINERQUESTION: 'TRAINERQUESTION',
}
