import React, { useEffect, useState, useContext } from 'react'
import Card from '@mui/material/Card'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import useTheme from '@mui/system/useTheme'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { useLocalStorage } from '@rehooks/local-storage'
import { DialogContext } from '../../context/dialog-context'
import Table from '../../components/Table'
import TableOrder from './TableOrder'
import FilterDrawer from './FilterDrawer'
import AlertDialog from '../../components/AlertDialog'
import Breadcrumbs from '../../components/Breadcrumbs'
import _ from 'lodash'

import {
  roadMap,
  downloadRoadMap,
  filterRoadMap,
  distributionFilter,
} from '../../utils/apiPath'
import {
  callAPI,
  handleDownload,
  hasPermissionCreateMasterData,
} from '../../utils/lib'
import { handleFilterTotal } from '../../redux/slices/table/events'

export const headCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'order',
    disablePadding: false,
    label: 'ลำดับ',
    width: '80px',
  },
  {
    id: 'title',
    label: 'หัวข้อ',
    disablePadding: false,
  },
  {
    id: 'abbreviation',
    label: 'ชื่อย่อ',
    disablePadding: false,
  },
  {
    id: 'distribution',
    label: 'ช่องทางการจำหน่าย',
    disablePadding: false,
    isArray: true,
    hideSortIcon: true,
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    deletePermission: 'DELETE',
    deleteModuleType: 'MASTER_DATA',
  },
]

export const headCellsOrder = [
  {
    id: 'sort',
    label: 'Sort',
    width: '80px',
  },
  {
    id: 'status',
    label: '',
    width: '20px',
  },
  {
    id: 'order',
    disablePadding: false,
    label: 'ลำดับ',
    width: '80px',
  },
  {
    id: 'title',
    label: 'หัวข้อ',
    disablePadding: false,
    width: '300px',
  },
  {
    id: 'abbreviation',
    label: 'ชื่อย่อ',
    disablePadding: false,
    width: '300px',
  },
  {
    id: 'distribution',
    label: 'ช่องทางการจำหน่าย',
    disablePadding: false,
    isArray: true,
  },
]

export const breadcrumbList = [
  { title: 'Master Data', link: '/', pointer: false },
  { title: 'กลุ่มหลักสูตร', link: '/', pointer: false },
]

export const onView = (row, history) => {
  history.push('/master-data/road-map/' + row.uuid)
}
export const onEdit = (row, history) => {
  history.push('/master-data/road-map/edit/' + row.uuid)
}
export const onDelete = async ({ row, setDialog, handleDelete }) => {
  setDialog({
    content: 'คุณต้องการลบรายการนี้หรือไม่',
    variant: 'delete',
    open: true,
    onConfirmClick: () => {
      setDialog({
        variant: 'delete',
        content: 'คุณต้องการลบรายการนี้หรือไม่',
        open: true,
        isLoading: true,
      })
      handleDelete(row)
    },
    onCancelClick: () =>
      setDialog({
        variant: 'delete',
        content: 'คุณต้องการลบรายการนี้หรือไม่',
        open: false,
      }),
  })
}

export const mainHandleDelete = async (row, setDialog) => {
  const path = roadMap + `/${row.uuid}`

  callAPI({
    url: path,
    method: 'delete',
    onSuccess: () => {
      setDialog({
        variant: 'success',
        content: 'ถูกลบแล้ว',
        open: true,
        isLoading: false,
        onConfirmClick: () => window.location.reload(),
      })
    },
  })
}

export const onFilterClick = async ({
  limit,
  order,
  sort,
  filter,
  setSearchText,
  setText,
  setStatus,
  setTablePage,
  fetchRoadMapList,
  setTextFilter,
  setFilterTotal,
}) => {
  setSearchText('')
  setText('')
  const statusList = []
  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      statusList.push('ACTIVE')
    }
    if (filter.status.inActiveChecked) {
      statusList.push('INACTIVE')
    }
    if (filter.status.deletedChecked) {
      statusList.push('DELETED')
    }
  }
  setStatus(statusList)

  const objDistribution = filter?.distributionChannel || {}
  let listDistribution = []

  if (
    filter.distributionChannelChecked &&
    Object.keys(objDistribution).length
  ) {
    for (let keyIndex in objDistribution) {
      const value = objDistribution[keyIndex]
      if (value.status) {
        listDistribution.push(value.uuid)
      }
    }
  }

  const filterText = {
    title: filter.titleChecked ? filter.titleText : '',
    abbreviation: filter.abbreviationChecked ? filter.abbreviationText : '',
    distribution: listDistribution,
  }
  setSearchText(filterText.title)
  setTextFilter(filterText)
  setTablePage(0)
  fetchRoadMapList({
    method: 'filter',
    statusList,
    valueLimit: limit,
    valueOrder: order,
    valuePage: 1,
    valueSort: sort,
    search: filterText.title,
    filter: filterText,
    abbreviation: filterText.abbreviation,
    distribution: filterText.distribution,
    isUnlimit: false,
  })
  setFilterTotal(handleFilterTotal(filter))
}

export const handleQuickSearch = async ({
  limit,
  order,
  sort,
  valueText,
  setTextFilter,
  setTablePage,
  fetchRoadMapList,
}) => {
  setTablePage(0)
  const filterText = {
    title: '',
    abbreviation: '',
    distribution: [],
  }
  setTextFilter(filterText)
  const statusList = []
  fetchRoadMapList({
    method: 'search',
    statusList,
    valueLimit: limit,
    valueOrder: order,
    valuePage: 1,
    valueSort: sort,
    search: valueText,
    filter: filterText,
    isUnlimit: false,
  })
}

export const filterDistributionName = ({ listLevel }) => {
  let result = []
  if (listLevel.length > 0) {
    listLevel.filter((item) => {
      result.push(item.abbreviation || '')
    })
  }
  return result
}

export const handleAddClick = () => {
  window.location.href = '/master-data/road-map/form'
}

export const handleClose = (setDialog) => {
  setDialog({ open: false })
}

export const OrderButton = ({ setIsOrderMode }) => {
  return (
    <Button
      data-testid={'btn-order'}
      sx={{ px: 3 }}
      variant="text"
      startIcon={<img src="/icon/ic_sort.svg" />}
      onClick={() => {
        setIsOrderMode(true)
      }}
    >
      เรียงลำดับ
    </Button>
  )
}

const RoadMapList = () => {
  const theme = useTheme()
  const history = useHistory()
  const [user] = useLocalStorage('user')
  const hasPermissionCreate = hasPermissionCreateMasterData({ user })
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [limit, setLimit] = useState('100')
  const [order, setOrder] = useState('ASC')
  const [page, setPage] = useState(-1)
  const [sort, setSort] = useState('')
  const [dataList, setDataList] = useState([])
  const [orderList, setOrderList] = useState([])
  const [allCount, setAllCount] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [textFilter, setTextFilter] = useState({
    title: '',
    abbreviation: '',
    distribution: [],
  })
  const [text, setText] = useState('')
  const [status, setStatus] = useState([])
  const [isFilterDrawer, setIsFilterDrawer] = useState(false)
  const [tablePage, setTablePage] = useState(0)
  const [isOrderMode, setIsOrderMode] = useState(false)
  const [distributionChannelList, setDistributionChannelList] = useState([])
  const [filterTotal, setFilterTotal] = useState(0)
  const { context, setContext: setDialog } = useContext(DialogContext)
  const dialog = context.dialog

  useEffect(async () => {
    setIsPageLoading(true)

    await fetchRoadMapList({
      method: 'fetch',
      statusList: status,
      valueLimit: limit,
      valueOrder: order,
      valuePage: page,
      valueSort: sort,
      filter: textFilter,
      isUnlimit: false,
    })
    await fetchRoadMapList({
      method: 'fetch',
      statusList: status,
      valueLimit: limit,
      valueOrder: order,
      valuePage: page,
      valueSort: sort,
      filter: textFilter,
      isUnlimit: true,
    })
    setIsPageLoading(false)
  }, [limit, order, sort, page])

  const onDownload = (selected) => {
    const body = {
      title: '',
      abbreviation: '',
      distribution: [],
      order: order.toUpperCase(),
      sort: sort === '' ? 'order' : sort,
      status: [],
      type: 'CUSTOM',
      list: selected,
    }

    handleDownload({
      url: downloadRoadMap,
      body: body,
      fileName: `รายการกลุ่มหลักสูตร.xlsx`,
    })
  }

  const getDistributionChannelList = async () => {
    const body = {
      abbreviation: '',
      distribution: '',
      level: [],
      limit: -1,
      order: 'ASC',
      page: 1,
      sort: 'id',
      status: ['ACTIVE'],
    }

    await callAPI({
      url: distributionFilter,
      method: 'POST',
      body: body,
      onSuccess: (res) => setDistributionChannelList(res.result || []),
    })
  }

  const fetchRoadMapList = async ({
    method,
    statusList,
    valueLimit,
    valueOrder,
    valuePage,
    valueSort,
    search,
    filter,
    isUnlimit,
  }) => {
    const sortBy = valueSort === '' ? 'order' : valueSort
    const realPage = valuePage <= 0 ? 1 : +valuePage
    const body = {
      title: method !== 'fetch' ? search : searchText,
      abbreviation: filter.abbreviation,
      distribution: filter.distribution,
      limit: isUnlimit ? -1 : valueLimit,
      order: valueOrder.toString().toUpperCase(),
      page: realPage,
      sort: sortBy,
      status: statusList,
    }
    if (!isUnlimit && method === 'fetch') await getDistributionChannelList()

    callAPI({
      url: filterRoadMap,
      method: 'POST',
      body: body,
      onSuccess: (res) => {
        let resultFilter = res.result || []
        if (resultFilter.length) {
          resultFilter = resultFilter.filter((item) => {
            item.distribution = filterDistributionName({
              listLevel: item.distribution || [],
            })
            return item
          })
        }
        if (isUnlimit) {
          setOrderList(_.orderBy(resultFilter, ['order'], ['asc']))
        } else {
          setDataList(resultFilter)
          setAllCount(res.totalCount)
        }
      },
    })
  }

  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">กลุ่มหลักสูตร (Road Map)</Typography>
        </Box>

        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />

        <Button
          data-testid={`btn-add-road-map`}
          sx={{
            ml: 3,
            [theme.breakpoints.up('md')]: {
              position: 'absolute',
              mt: -10,
              mr: 3,
            },
            right: 0,
          }}
          disabled={!hasPermissionCreate}
          startIcon={<AddIcon />}
          variant="contained"
          onClick={handleAddClick}
        >
          เพิ่มกลุ่มหลักสูตร
        </Button>

        <Card sx={{ minWidth: 275, mx: 3, mt: 3 }}>
          {!isOrderMode && (
            <Table
              data={dataList}
              headCells={headCells}
              onView={(row) => onView(row, history)}
              onEdit={(row) => onEdit(row, history)}
              onDelete={(row) => {
                onDelete({
                  row,
                  setDialog,
                  handleDelete: () => mainHandleDelete(row, setDialog),
                })
              }}
              placeholder="ค้นหาหัวข้อ"
              searchKey={'title'}
              setLimit={setLimit}
              order={order}
              setOrder={setOrder}
              page={page}
              setPage={setPage}
              sort={sort}
              setSort={setSort}
              allCount={allCount}
              handleSearch={(valueText) =>
                handleQuickSearch({
                  limit,
                  order,
                  sort,
                  valueText,
                  setTextFilter,
                  setTablePage,
                  fetchRoadMapList,
                })
              }
              onDownload={onDownload}
              setIsFilterDrawer={setIsFilterDrawer}
              searchText={searchText}
              setSearchText={setSearchText}
              tablePage={tablePage}
              setTablePage={setTablePage}
              text={text}
              setText={setText}
              customToolbar={<OrderButton setIsOrderMode={setIsOrderMode} />}
              filterTotal={filterTotal}
            />
          )}
          {isOrderMode && (
            <TableOrder
              headCellsOrder={headCellsOrder}
              orderList={orderList}
              setOrderList={setOrderList}
              setIsOrderMode={setIsOrderMode}
              setDialog={setDialog}
              type="roadMap"
            />
          )}
        </Card>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <FilterDrawer
        open={isFilterDrawer}
        onClose={() => setIsFilterDrawer(false)}
        onFilterClick={(filter) =>
          onFilterClick({
            limit,
            order,
            sort,
            filter,
            setSearchText,
            setText,
            setStatus,
            setTablePage,
            fetchRoadMapList,
            setTextFilter,
            setFilterTotal,
          })
        }
        distributionChannelList={distributionChannelList}
      />
      <AlertDialog
        open={dialog.open}
        setOpen={setDialog}
        handleClose={() => handleClose(setDialog)}
        title={dialog.title}
        content={dialog.content}
        variant={dialog.variant}
        onConfirmClick={dialog.onConfirmClick}
        onCancelClick={dialog.onCancelClick}
        isLoading={dialog.isLoading}
      />
    </Box>
  )
}
export default RoadMapList
