import React from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Column, Row } from '../../../../../components/Preview/Layout/Styled'
import { StyledDivider } from '../../../components/View/Styled'
import { StyledCard, StyledFormColumn } from '../../../Styled'
import BasicTable from '../BasicTable'
import { TABLE_KEY } from '../model'
import { isOic } from '../events'
import FaceHistory from './FaceHistory'
import ClickHistory from './ClickHistory'
import AttendHistory from './AttendHistory'
import { fetchLearnerDetail } from './events'

const AttendanceTab = () => {
  const { isLoading, enrollData, learningRound, expandedRound } = useSelector(
    (state) => ({
      isLoading: state.eLearningCourseBatchLearnerDetail.isLoading,
      enrollData: state.eLearningCourseBatchLearnerDetail.enrollData,
      learningRound: state.eLearningCourseBatchLearnerDetail.learningRound,
      expandedRound: state.eLearningCourseBatchLearnerDetail.expandedRound,
    }),
  )
  const isTypeOic = isOic()
  const dispatch = useDispatch()

  return (
    <StyledFormColumn>
      {learningRound.map((item, index) => {
        const uuid = item.uuid
        const isExpand = isTypeOic || uuid === expandedRound
        const eLearningCourse = isTypeOic
          ? _.get(enrollData, 'eLearningCourseBatch.eLearningCourse', {})
          : _.get(item, 'eLearningCourse', {})
        return (
          <StyledCard key={index} sx={{ mt: 0, p: 3 }}>
            <Column sx={{ gap: 3 }}>
              <Row sx={{ justifyContent: 'space-between' }}>
                <Typography variant="h6">
                  หลักสูตร : {_.get(eLearningCourse, 'name', '')}
                  {!isTypeOic && ` (ครั้งที่ ${index + 1})`}
                </Typography>
                {!isTypeOic && (
                  <IconButton
                    onClick={() => dispatch(fetchLearnerDetail(isExpand, uuid))}
                  >
                    {isExpand ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                )}
              </Row>
              {!isLoading && isExpand && <ExpandedContent />}
            </Column>
          </StyledCard>
        )
      })}
    </StyledFormColumn>
  )
}

export default AttendanceTab

export const ExpandedContent = () => {
  const isTypeOic = isOic()

  return (
    <>
      {isTypeOic && (
        <>
          <StyledDivider />
          <FaceHistory />

          <StyledDivider />
          <ClickHistory />
        </>
      )}

      <StyledDivider />
      <AttendHistory />

      <StyledDivider />
      <Column sx={{ gap: 1 }}>
        <Typography variant="h6">โมดูล</Typography>
        <BasicTable keyTable={TABLE_KEY.module} />
      </Column>
    </>
  )
}
