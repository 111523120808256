import _ from 'lodash'
import { fetchCourseAndStaff } from '../../../services/eEvaluation/form'
import { setToggleFilterDrawer } from '../../../redux/slices/table'
import { onFilterClick } from '../List/event'
import { onFilterClick as onFilterClickVersion } from '../Version/event'
import { onFilterClick as onFilterClickFeedback } from '../Form/Feedback/events'

export const fetchCourseOrDepartment =
  (uuid, setOption) => async (dispatch) => {
    const body = { courseUuid: '' }
    const response = await dispatch(fetchCourseAndStaff({ uuid, body }))
    const courseList = _.get(response, 'payload.courses', undefined)
    const departmentList = _.get(response, 'payload', undefined)

    if (courseList) {
      const optionCourses = courseList.map((item) => ({
        label: item.englishName,
        value: item.uuid,
      }))
      setOption(optionCourses)
    } else {
      const optionDepartments = departmentList?.map((item) => ({
        label: `(${item.initial}) ${item.department}`,
        value: item.uuid,
      }))
      setOption(optionDepartments)
    }
  }

export const fetchStaffs =
  (uuid, courseUuid, setStaffOption) => async (dispatch) => {
    const body = { courseUuid }
    const response = await dispatch(fetchCourseAndStaff({ uuid, body }))
    const staffList = _.get(response, 'payload.staffs', [])
    const optionStaffs = staffList.map((item) => ({
      label: `${item.firstNameTH} ${item.lastNameTH}`,
      value: item.uuid,
    }))
    setStaffOption(optionStaffs)
  }

export const handleFiltering =
  (table, filter, drawerType, id) => (dispatch) => {
    dispatch(setToggleFilterDrawer(false))

    if (drawerType == 'versions') {
      dispatch(onFilterClickVersion(table, filter, id))
    } else if (drawerType == 'eevaluation') {
      dispatch(onFilterClick(table, filter))
    } else dispatch(onFilterClickFeedback(filter))
  }

export const handleChange = ({ key, value, filter, setFilter, listKey }) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (key === 'courseChecked' && !value) {
    objState = {
      ...filter,
      [key]: value,
      course: '',
      staffChecked: false,
      staff: [],
    }
  }

  if (key === 'course') {
    objState = {
      ...filter,
      [key]: value,
      staff: [],
    }
  }
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  }
  setFilter(objState)
}

export const handleSelectDate = (selectedDates, filter, setFilter) => {
  let objState = {
    ...filter,
    startDate: selectedDates.startDate,
    toDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }
  setFilter(objState)
}
