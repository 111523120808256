import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { openStockDrawer } from './openStockDrawer'

export const handleTypeChange = () => {
  store.dispatch(setFieldValue({
    key: 'assetData',
    value: [],
  }))
  store.dispatch(setFieldValue({
    key: 'formData.productStockAsset',
    value: '',
  }))
  store.dispatch(setFieldValue({
    key: 'formData.productAsset',
    value: '',
  }))
  store.dispatch(setFieldValue({
    key: 'formData.productLicense',
    value: '',
  }))
  openStockDrawer(false)
}
