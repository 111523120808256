import React from 'react'
import Drawer from '@mui/material/Drawer'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { StyledHeadLabel } from './Styled'
import { openAssetHistoryDrawer } from '../../handler/openHistoryDrawer'
import Table from './Table'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { ViewRow } from '../../../../../../components/CRUD/components/StyledViewBox'

const index = ({ open }) => {
  const { assetDrawerInfo } = useSelector(
    (state) => ({
      assetDrawerInfo: state.crud.assetDrawerInfo,
    }),
    shallowEqual,
  )
  let product = _.get(assetDrawerInfo, 'product', '')
  if (assetDrawerInfo?.productWarehouse) {
    product = _.get(assetDrawerInfo, 'productWarehouse.product', '')
  }
  return (
    <>
      <Drawer
        sx={{ height: '100vh' }}
        anchor="right"
        open={open}
        onClose={() => openAssetHistoryDrawer(false)}
        PaperProps={{
          sx: {
            height: '100%',
          },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', lg: '100%' },
            position: 'relative',
          }}
        >
          <StyledHeadLabel>
            <Box display="flex" justifyContent="space-between" pb={2}>
              <Typography variant="h5">ประวัติรหัสทรัพย์สิน</Typography>
              <IconButton
                data-testid="btn-close-drawer"
                color="primary"
                component="span"
                onClick={() => openAssetHistoryDrawer(false)}
              >
                <Close />
              </IconButton>
            </Box>
            <Divider />
          </StyledHeadLabel>
          <Box sx={{ display: 'flex', flexDirection: 'column', mx: 3, mb: 2 }}>
            <Typography variant="h6">ข้อมูลพื้นฐาน</Typography>
            <ViewRow
              titleSx={{ minWidth: '200px' }}
              variant="body1"
              title="ชื่อทรัพย์สิน"
              value={_.get(product, 'productName', '-')}
            />
            <ViewRow
              titleSx={{ minWidth: '200px' }}
              variant="body1"
              title="รหัสทรัพย์สิน"
              value={_.get(assetDrawerInfo, 'assetCode', '-')}
              required
            />
            <ViewRow
              titleSx={{ minWidth: '200px' }}
              variant="body1"
              title="คลังทรัพย์สินปัจจุบัน"
              value={_.get(assetDrawerInfo, 'warehouseName', '-')}
              required
            />
            <ViewRow
              titleSx={{ minWidth: '200px' }}
              variant="body1"
              title="มูลค่าปัจจุบันในคลัง (฿)"
              value={`${_.get(assetDrawerInfo, 'unitPrice', '-')} บาท`}
              required
            />
          </Box>
          <Box mx={3}>
            <Table />
          </Box>
          {/* <LoadingExaminer isLoading={isLoadingDrawer} /> */}
        </Box>
      </Drawer>
    </>
  )
}

export default index
