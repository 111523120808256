import { CS_USER_PROFILE_SETTING } from '../permission/csUserProfile'
import { PROSPECT_PROFILE_SETTING } from '../permission/prospectProfile'

export const prospectProfileCells = [
  {
    id: 'tempCode',
    disablePadding: false,
    label: 'Temp Code',
    width: '140px',
  },
  {
    id: 'firstNameTH',
    label: 'ชื่อ',
  },
  {
    id: 'lastNameTH',
    label: 'นามสกุล',
  },
  {
    id: 'mobileNo',
    label: 'เบอร์โทรศัพท์',
  },
  {
    id: 'email',
    label: 'อีเมล',
  },
  {
    id: 'unitNumber',
    label: 'Unit Number',
  },
  {
    id: 'actionManage',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideDelete: true,
    hideSortIcon: true,
    viewPath: `/manage/prospect-profile/view`,
    editPath: `/manage/prospect-profile/edit`,
    module: PROSPECT_PROFILE_SETTING,
  },
]

export const prospectProfileCourse = [
  {
    id: 'courseCode',
    label: 'รหัสหลักสูตร',
  },
  {
    id: 'englishName',
    label: 'หลักสูตร',
  },
  {
    id: 'nameForLearner',
    label: 'ชื่อหลักสูตร (สำหรับ Learner)',
  },
  {
    id: 'registerDate',
    label: 'วันที่สมัคร',
  },
  {
    id: 'betweenDate',
    label: 'วันที่จัดอบรม',
  },
  {
    id: 'hours',
    label: 'จำนวนชั่วโมง',
  },
  {
    id: 'result',
    label: 'สถานะคลาส',
  },
  {
    id: 'certificateExpiryDate',
    label: 'ประกาศนียบัตรหมดอายุ',
  },
  {
    id: 'action',
    disablePadding: false,
    hideSortIcon: true,
    label: 'คำสั่ง',
    width: '90px',
    viewPath: `/manage-class/learner/detail`,
  },
]

export const csUserProfileCells = [
  {
    id: 'tempCode',
    disablePadding: false,
    label: 'Temp Code',
    width: '140px',
  },
  {
    id: 'firstNameTH',
    label: 'ชื่อ',
  },
  {
    id: 'lastNameTH',
    label: 'นามสกุล',
  },
  {
    id: 'mobileNo',
    label: 'เบอร์โทรศัพท์',
  },
  {
    id: 'email',
    label: 'อีเมล',
  },
  {
    id: 'unitNumber',
    label: 'Broker Number',
  },
  {
    id: 'actionManage',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideDelete: true,
    hideSortIcon: true,
    viewPath: `/manage/cs-user-profile/view`,
    editPath: `/manage/cs-user-profile/edit`,
    module: CS_USER_PROFILE_SETTING,
  },
]
