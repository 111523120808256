import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { memo, useCallback, useEffect, useState } from 'react'
import TextInput from '../../Input/TextInput'
import { useInputField } from '../hooks/useInputField'
import { createDebouncedSetField } from '../handler/createDebouncedSetField'
import { Box } from '@mui/system'

const CustomInput = memo(
  ({
    required = false,
    disabled,
    fieldName,
    labelName,
    hideLabel = false,
    textInfo,
    placeholder,
    boxSx,
    inputProps,
    sxTextError,
    ...rest
  }) => {
    const dispatch = useDispatch()
    const { value, isError, message } = useInputField(fieldName)
    const { crudConfig } = useSelector((state) => state.crud, shallowEqual)
    const [inputValue, setInputValue] = useState(value)

    const debouncedSetField = useCallback(
      createDebouncedSetField(dispatch, fieldName),
      [dispatch],
    )

    function handleChange(event) {
      setInputValue(event.target.value)
      debouncedSetField(event.target.value)
    }

    useEffect(() => {
      setInputValue(value)
    }, [value])

    return (
      <Box
        {...rest}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          ...boxSx,
        }}
      >
        <TextInput
          disabled={disabled}
          required={required}
          name={fieldName}
          type="basic"
          labelText={hideLabel ? '' : labelName}
          placeholder={
            placeholder
              ? placeholder
              : `${required ? 'กรุณากรอก ' : ''}${labelName}`
          }
          id={`${crudConfig.moduleSlug}-${fieldName}`}
          value={inputValue ?? ''}
          textError={message}
          textInfo={textInfo}
          isShowTextError={isError}
          onChange={handleChange}
          inputProps={inputProps}
          sxTextError={sxTextError}
        />
      </Box>
    )
  },
)
CustomInput.displayName = 'InputName'
export { CustomInput }
