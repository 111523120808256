import { path } from './path'

export const COURSE_TYPE_TH = {
  GENERAL: 'หลักสูตรทั่วไป',
  OIC: 'หลักสูตรระดับสูง',
}

export const headCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'moduleCode',
    label: 'รหัสโมดูล',
    disablePadding: false,
  },
  {
    id: 'name',
    label: 'ชื่อโมดูล',
    disablePadding: false,
  },
  {
    id: 'moduleType',
    label: 'รูปแบบโมดูล',
    disablePadding: false,
  },
  {
    id: 'createdAt',
    label: 'วันที่สร้าง',
    disablePadding: false,
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    viewPath: `${path.eLearningModule}/view`,
    editPath: `${path.eLearningModule}/form`,
  },
]

export const headCellsCourse = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'courseCode',
    label: 'รหัสหลักสูตร',
    width: '125px',
    minWidth: '125px',
    disablePadding: false,
  },
  {
    id: 'name',
    label: 'ชื่อหลักสูตร',
    disablePadding: false,
  },
  {
    id: 'nameForLearner',
    label: 'ชื่อหลักสูตร (สำหรับ Learner)',
    width: '320px',
    minWidth: '320px',
    disablePadding: false,
  },
  {
    id: 'courseType',
    label: 'ประเภท',
    minWidth: '120px',
    disablePadding: false,
  },
  {
    id: 'question',
    label: 'คำถาม',
    disablePadding: false,
  },
  {
    id: 'createdAt',
    label: 'วันที่สร้าง',
    width: '110px',
    minWidth: '110px',
    disablePadding: false,
  },
  {
    id: 'actionELearning',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    viewPath: `${path.eLearningCourse}/view`,
    editPath: `${path.eLearningCourse}/edit`,
  },
]

export const headCellsLearningPath = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'id',
    label: 'ID',
    width: '125px',
    minWidth: '125px',
    disablePadding: false,
  },
  {
    id: 'name',
    label: 'ชื่อ Learning path',
    disablePadding: false,
  },
  {
    id: 'description',
    label: 'คำอธิบาย',
    minWidth: '200px',
    disablePadding: false,
  },
  {
    id: 'coursesCount',
    label: 'จำนวนหลักสูตร',
    disablePadding: false,
  },
  {
    id: 'createdAt',
    label: 'วันที่สร้าง',
    width: '110px',
    minWidth: '110px',
    disablePadding: false,
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    viewPath: `${path.eLearningLearningPath}/view`,
    editPath: `${path.eLearningLearningPath}/edit`,
  },
]

export const headCellsLearner = [
  {
    id: 'codeId',
    label: 'รหัสตัวแทน',
    width: '125px',
    minWidth: '125px',
    disablePadding: false,
  },
  {
    id: 'prefixTH',
    label: 'คำนำหน้าชื่อ',
    disablePadding: false,
  },
  {
    id: 'firstNameTH',
    label: 'ชื่อ',
    disablePadding: false,
  },
  {
    id: 'lastNameTH',
    label: 'นามสกุล',
    disablePadding: false,
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    hideEdit: true,
    hideDelete: true,
    viewPath: `${path.eLearningCourseLearner}/view`,
  },
]

export const MODULE_TYPE = {
  E_TESTING: 'E_TESTING',
  E_EVALUATION: 'E_EVALUATION',
  EMBED_CONTENT: 'EMBED_CONTENT',
  LINK_TO: 'LINK_TO_ANOTHER_WEBSITE',
  UPLOAD_FILE: 'UPLOAD_FILE',
  SCORM: 'SCORM',
}

export const MODULE_TYPE_TH = {
  [MODULE_TYPE.E_TESTING]: 'แบบทดสอบ',
  [MODULE_TYPE.E_EVALUATION]: 'แบบประเมิน',
  [MODULE_TYPE.EMBED_CONTENT]: 'ฝังเนื้อหา',
  [MODULE_TYPE.LINK_TO]: 'ลิงก์เว็บไซต์ภายนอก',
  [MODULE_TYPE.UPLOAD_FILE]: 'อัปโหลดไฟล์',
  [MODULE_TYPE.SCORM]: 'Scorm',
}

export const UPLOAD_TYPE = {
  GENERAL: 'GENERAL',
  SCORM: 'SCORM',
}

export const MODULE_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
}

export const COURSE_TYPE = {
  GENERAL: 'GENERAL',
  OIC: 'OIC',
}

export const COURSE_STATUS = {
  DRAFT: 'DRAFT',
  WAITING: 'WAITING',
  OPENREGISTRATION: 'OPENREGISTRATION',
  CLOSEREGISTRATION: 'CLOSEREGISTRATION',
  INPROGRESS: 'INPROGRESS',
  SUCCESS: 'SUCCESS',
  COMPLETED: 'COMPLETED',
  CANCEL: 'CANCEL',
  INACTIVE: 'INACTIVE',
}

export const FILE_TYPE = {
  DOC: 'DOC',
  DOCX: 'DOCX',
  PPT: 'PPT',
  PPTX: 'PPTX',
  PDF: 'PDF',
  ZIP: 'ZIP',
}

export const headCellsBatch = [
  {
    id: 'batchStatus',
    label: 'สถานะรอบอบรม',
    width: '120px',
  },
  {
    id: 'batchName',
    label: 'ชื่อรอบอบรม',
    width: '130px',
    disablePadding: false,
  },
  {
    id: 'periodStart',
    label: 'ช่วงเวลารอบอบรม',
    width: '180px',
    disablePadding: false,
  },
  {
    id: 'assigneeCount',
    label: 'ผู้รับผิดชอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'staffCount',
    label: 'จำนวนผู้เรียน',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'question',
    label: 'คำถาม',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'onShelfStatus',
    label: 'การรับสมัคร',
    disablePadding: false,
  },
  {
    id: 'actionBatch',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    viewPath: `${path.eLearningCourseBatch}/view`,
  },
]

export const headCellsBatchView = [
  {
    id: 'batchStatus',
    label: 'สถานะรอบอบรม',
    width: '120px',
  },
  {
    id: 'batchName',
    label: 'ชื่อรอบอบรม',
    width: '130px',
    disablePadding: false,
  },
  {
    id: 'periodStart',
    label: 'ช่วงเวลารอบอบรม',
    width: '180px',
    disablePadding: false,
  },
  {
    id: 'assigneeCount',
    label: 'ผู้รับผิดชอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'staffCount',
    label: 'จำนวนผู้เรียน',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'question',
    label: 'คำถาม',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'onShelfStatus',
    label: 'การรับสมัคร',
    disablePadding: false,
  },
  {
    id: 'actionBatch',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    hideEdit: true,
    hideDelete: true,
    viewPath: `${path.eLearningCourseBatch}/view`,
  },
]

export const RADIO_TYPE_ENUM = {
  NONE: 'NONE',
  SPECIFIC_DATE: 'SPECIFIC_DATE',
  TIME_SPAN: 'TIME_SPAN',
}

export const RADIO_TYPE_LABEL = {
  NONE: 'ไม่จำกัด',
  SPECIFIC_DATE: 'จำกัด',
  TIME_SPAN: 'ระยะเวลาการเข้าถึงหลักสูตร (นับจากวันเริ่มต้นลงทะเบียน)',
  ALLOW: 'อนุญาต',
  NOT_ALLOW: 'ไม่อนุญาต',
}

export const RADIO_TRUE_FALSE_LABEL = {
  true: 'ใช่',
  false: 'ไม่ใช่',
}

export const COURSE_BATCH_STATUS = {
  ACTIVE: 'เปิดใช้งาน',
  WAITING: 'รอเปิดใช้งาน',
  DELETED: 'ถูกลบแล้ว',
}

export const headCellsOICLearner = [
  {
    id: 'codeId',
    disablePadding: false,
    label: 'รหัสตัวแทน',
    width: '80px',
  },
  {
    id: 'prefixTH',
    label: 'คำนำหน้า',
    disablePadding: false,
  },
  {
    id: 'firstNameTH',
    label: 'ชื่อ',
    disablePadding: false,
  },
  {
    id: 'lastNameTH',
    label: 'นามสกุล',
    disablePadding: false,
  },
  {
    id: 'statusLearner',
    label: 'สถานะ',
    disablePadding: false,
  },
  {
    id: 'statusAnnounce',
    label: 'รอประกาศ',
    disablePadding: true,
  },
  {
    id: 'attendance',
    label: 'การเข้าเรียนล่าสุด',
    disablePadding: false,
  },
  {
    id: 'classLearnerCourseStatus',
    label: 'ผลการอบรม',
    disablePadding: false,
  },
  {
    id: 'actionBatchLearner',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideEdit: true,
    hideSortIcon: true,
    viewPath: `${path.eLearningCourseBatch}/learner/view`,
  },
]
export const COURSE_BATCH_SHELF_STATUS = {
  ACTIVE: 'เปิด',
  INACTIVE: 'ปิด',
}

export const QUESTION_STATUS = {
  SUCCESS: 'SUCCESS',
  WAITING: 'WAITING',
}

export const headCellQuestion = [
  {
    id: 'nameQuestion',
    label: 'ชื่อคอร์ส',
    disablePadding: false,
    width: '230px',
    minWidth: '230px',
  },
  {
    id: 'batch',
    label: 'รอบ',
    disablePadding: false,
    width: '100px',
    minWidth: '100px',
  },
  {
    id: 'question',
    label: 'คำถาม',
    disablePadding: false,
    width: '220px',
    minWidth: '220px',
  },
  {
    id: 'questionBy',
    label: 'ผู้ถาม',
    disablePadding: false,
    width: '194px',
    minWidth: '194px',
    hideSortIcon: true,
  },
  {
    id: 'statusQuestionTH',
    label: 'สถานะ',
    disablePadding: false,
    hideSortIcon: true,
    width: '194px',
    minWidth: '194px',
  },
  {
    id: 'actionQuestion',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    hideEdit: true,
    hideDelete: true,
  },
]

export const EXPIRATION_TYPE = {
  TIME_SPAN: 'TIME_SPAN',
  NONE: 'NONE',
}

export const TIME_SPAN_TYPE = {
  MONTH: 'เดือน',
  YEAR: 'ปี',
}

export const E_LEARNING_LEARNER_LOG_RESULT = {
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE',
  INPROGRESS: 'INPROGRESS',
  SUCCESS: 'SUCCESS',
  PASS: 'PASS',
  FAIL: 'FAIL',
  NOT_PASS: 'NOT_PASS',
}

export const E_LEARNING_LEARNER_LOG_RESULT_TH = {
  PENDING: 'ยังไม่ทำ',
  COMPLETE: 'ผ่าน',
  INPROGRESS: 'กำลังเรียน',
  PASS: 'ผ่าน',
  FAIL: 'ไม่ผ่าน',
  NOT_PASS: 'ไม่ผ่าน',
}
