import React, { useEffect } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Breadcrumbs from '../../../../../../components/Breadcrumbs'
import { StyledViewTitle } from '../../../../../../modules/ETesting/Version/View/Styled'
import { StyledContainer, StyledContent } from '../../../../Styled'
import { breadcrumbView } from '../model'
import Header from './Header'
import Content from './Content'
import { fetchCourseBatchDetail } from './events'

const View = () => {
  const { id: uuid } = useParams()
  const { result, isLoading } = useSelector(
    (state) => state.eLearningCourseBatchDetail,
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCourseBatchDetail(uuid))
  }, [])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <Box>
          <StyledViewTitle sx={{ width: 'calc(100% - 420px)' }} variant="h4">
            รายละเอียดรอบอบรม : {_.get(result, 'name', '')}
          </StyledViewTitle>
        </Box>
        <Breadcrumbs menuList={breadcrumbView()} />
        <Header />
        {result && <Content detail={result} />}
      </StyledContent>
    </StyledContainer>
  )
}

export default View
