import React, { useRef, useState, useEffect } from 'react'
import Select from '../../../../components/Input/Select'
import InputText from '../../../../components/Input/TextInput'
import { certLanguage } from '../../../../constants/eCertification'
import _ from 'lodash'
import Search from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import * as event from './event'
import { StyledCard } from './Styled'

const ECertificationForm = ({
  eCertification,
  setInitialValues,
  formik,
  setOpen,
  setisSelectVersion,
  isSelectVersion,
  disabled,
  initialExpireECertificationYear,
  initECertificationVersionUuid,
  setInitECertificationVersionUuid,
}) => {
  const [layouts, setLayouts] = useState([])
  const [name, setName] = useState('')
  const defaultLang =
    _.get(formik.values, 'certificateLanguage', certLanguage.TH) ||
    certLanguage.TH
  const [expiration, setExpiration] = useState(initialExpireECertificationYear)
  const [language, setLanguage] = useState(defaultLang)
  const componentRef = useRef()
  const { width } = event.useContainerDimensions(componentRef)

  useEffect(() => {
    if (_.isEmpty(name)) {
      setLayouts(eCertification)
    } else {
      const filter = _.filter(
        eCertification,
        (ec) =>
          String(ec.name).toLowerCase().indexOf(String(name).toLowerCase()) !==
          -1
      )
      setLayouts(filter)
    }
  }, [name, eCertification])

  const handleChange = (name, uuid) => {
    setInitialValues((prev) => ({ ...prev, [`${name}`]: uuid }))
    formik.setFieldValue(name, uuid)
  }

  const optionExpire = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: 'ไม่มีวันหมดอายุ', value: null },
  ]

  return (
    <StyledCard ref={componentRef}>
      <Typography
        sx={{ display: 'flex' }}
        variant="body2"
        color="text.secondary"
      >
        เทมเพลต
        <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
          *
        </Typography>
      </Typography>
      <InputText
        id="name"
        name="name"
        type="basic"
        endAdornmentText={<Search color="primary" />}
        onChange={(e) => setName(e.target.value)}
      />
      <event.LayoutOption
        layouts={layouts}
        width={width}
        setSelectLayout={setisSelectVersion}
        isSelectLayout={isSelectVersion}
      />
      <Box
        sx={{
          display: 'flex',
          marginTop: '24px',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Select
          boxSx={{ maxWidth: 320 }}
          id="type"
          name="type"
          labelText="อายุของประกาศนียบัตร (จำนวนปี) นับจากวันที่ปิดคลาส"
          type="basic"
          required
          options={optionExpire}
          handleChange={(e) => setExpiration(e.target.value)}
          placeholder="กรุณาเลือก"
          value={expiration}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'end', gap: 0.5 }}>
        <Typography variant="body2" color="text.secondary">
          ภาษาที่ใช้สำหรับชื่อ-นามสกุล
        </Typography>
        <Typography color="error.main">*</Typography>
      </Box>
      <RadioGroup
        sx={{ label: { marginLeft: '0 !important' } }}
        value={language}
        onChange={(e) => setLanguage(e.target.value)}
      >
        <FormControlLabel
          value={certLanguage.TH}
          control={<Radio />}
          label="ภาษาไทย"
        />
        <FormControlLabel
          value={certLanguage.EN}
          control={<Radio />}
          label="ภาษาอังกฤษ"
        />
      </RadioGroup>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
        <Button
          variant="outlined"
          size="m"
          sx={{ mr: 3 }}
          onClick={() => {
            setOpen((o) => !o)
            setisSelectVersion(initECertificationVersionUuid)
          }}
        >
          ยกเลิก
        </Button>
        <Button
          variant="contained"
          disabled={isSelectVersion === 0 || disabled || expiration === 0}
          size="m"
          onClick={() => {
            setInitECertificationVersionUuid(isSelectVersion)
            handleChange('eCertificationVersion', isSelectVersion)
            handleChange('expireECertificationYear', expiration)
            handleChange('certificateLanguage', language)
          }}
        >
          ยืนยัน
        </Button>
      </Box>
    </StyledCard>
  )
}

export default ECertificationForm
