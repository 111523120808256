import _ from 'lodash'
import { StyledRow } from '../../../Styled'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { defaultColumns } from '../../../../../constants/report/courseSetting'
import { COLUMN_NAME } from '../../../../../constants/reportCourseSetting/reportCourseSetting'
import { handleChange } from '../../event'
import { handleSortColumns } from '../../../../../redux/slices/reportCourseSetting/List/event'
import ColumnSelect from '../../../../../modules/ReportCourseSetting/components/ColumnSelect'

const SelectMergeColumn = () => {
  const dispatch = useDispatch()
  const { firstColumnSelected, secondaryColumnSelected } = useSelector(
    (state) => ({
      firstColumnSelected: state.reportCourseSetting.firstColumnSelected,
      secondaryColumnSelected:
        state.reportCourseSetting.secondaryColumnSelected,
    }),
    shallowEqual,
  )

  const filteredColumns = _.filter(
    defaultColumns,
    (col) => col.name !== COLUMN_NAME.NO,
  )

  const sortedColumns = handleSortColumns(filteredColumns)

  return (
    <StyledRow sx={{ gap: 3 }}>
      <ColumnSelect
        hasTwoBoxes={true}
        columnSelected={firstColumnSelected}
        options={sortedColumns}
        handleChange={(e) => {
          let value = _.get(e, 'target.id', '')
          if (value.length === 0) {
            value = null
          }
          dispatch(handleChange({ key: 'firstColumnSelected', value: value }))
        }}
      />
      และ
      <ColumnSelect
        hasTwoBoxes={true}
        columnSelected={secondaryColumnSelected}
        options={sortedColumns}
        handleChange={(e) => {
          let value = _.get(e, 'target.id', '')
          if (value.length === 0) {
            value = null
          }
          dispatch(
            handleChange({ key: 'secondaryColumnSelected', value: value }),
          )
        }}
      />
    </StyledRow>
  )
}

export default SelectMergeColumn
