import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledEditorBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'textError',
})(({ theme, textError }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '& .tox-tinymce': {
    borderColor: textError && textError != '' && theme?.palette?.error?.main,
  },
}))
