import { CustomDateInput } from '../../../../../../components/CRUD/components/CustomDateInput'
import { CustomCheckbox } from '../../../../../../components/CRUD/components/CustomCheckbox'
import { handleCheckEndDate } from '../../handler/handleCheckEndDate'
import { handleEndDateChange } from '../../handler/handleEndDateChange'
import { validatePermission } from '../../../../../../utils/lib'
import { useSelector, shallowEqual } from 'react-redux'
import { Box, Typography } from '@mui/material'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../../../constants/stock'
import { convertJoinDate } from '../../utils'

export const InputDateGroup = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermissionCreate = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN, STOCK_PERMISSION.CREATE],
  })

  const { checkEndDate, contractStartDate, contractEndDate } = useSelector(
    (state) => ({
      checkEndDate: state.crud.formData.checkEndDate,
      contractStartDate: state.crud.formData.contractStartDate,
      contractEndDate: state.crud.formData.contractEndDate,
    }),
    shallowEqual,
  )

  return (
    <Box mt={3}>
      <Typography variant="body1b" mt={3}>
        ระยะสัญญา
      </Typography>
      <Box display="flex" gap={3} alignItems="start">
        <CustomDateInput
          disabled={!hasPermissionCreate}
          fieldName="contractStartDate"
          labelName="วันเริ่มต้น"
          // handleExtraChange=""
          placeholder="เลือกวันที่"
          required={false}
        />
        <CustomDateInput
          disabled={!hasPermissionCreate}
          fieldName="contractEndDate"
          labelName="วันสิ้นสุด"
          handleExtraChange={handleEndDateChange}
          placeholder="เลือกวันที่"
          required={false}
        />
      </Box>
      <Box display="flex" gap={3} alignItems="center">
        <Box sx={{ width: '49%', height: '100px' }}>
          <CustomDateInput
            disabled={!hasPermissionCreate || checkEndDate}
            fieldName="renewContractDate"
            labelName="วันที่ต่อระยะ"
            // handleExtraChange=""
            placeholder="เลือกวันที่"
            required={false}
          />
          <Typography mt={'-20px'} variant="body2" color={'text.secondary'}>
            (ระบบจะส่ง E-mail แจ้งเตือนหากมีการระบุวันที่ต่อระยะ)
          </Typography>
        </Box>
        <Box sx={{ height: '100px', display: 'flex', alignItems: 'center' }}>
          <CustomCheckbox
            disabled={!hasPermissionCreate}
            fieldName="checkEndDate"
            labelText="วันเดียวกับวันที่สิ้นสุด"
            handleExtraChange={handleCheckEndDate}
          />
        </Box>
      </Box>
      <Typography variant="body1b" mt={2} mb={3}>
        สรุประยะเวลา {convertJoinDate(contractStartDate, contractEndDate)}
      </Typography>
    </Box>
  )
}
