import React from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import { formatPhoneNo, formatIdCard } from '../../../../../utils/lib'

import { ButtonAction } from '../../Document/Content/ContentTableLeft'
import { BoxLabelText } from './Styled'
import { getFormatDate } from '../../../../../utils/lib'

export const BoxLabel = ({ labelLeft, labelRight, isIcon }) => {
  return (
    <BoxLabelText>
      <Box sx={{ width: 450 }}>
        <Typography variant="body1" color="text.secondary">
          {labelLeft}
        </Typography>
      </Box>
      {isIcon ? (
        labelRight
      ) : (
        <Typography variant="body1">{labelRight}</Typography>
      )}
    </BoxLabelText>
  )
}

const Index = () => {
  const initialState = useSelector(
    (state) => state.manageClassLearnerDetail.initialState,
  )
  const learnerDetail = _.get(initialState, 'learnerDetail', {})
  const documentList = _.get(initialState, 'document', [])
  const name = `${_.get(learnerDetail, 'firstNameTH', '-')} ${
    _.get(learnerDetail, 'lastNameTH', '') || ''
  }`

  return (
    <Card sx={{ mt: 2 }}>
      <Box sx={{ m: 3 }}>
        {/* personal information */}
        <Typography variant="h5" sx={{ mb: 1 }}>
          ข้อมูลส่วนตัว
        </Typography>
        <BoxLabel
          labelLeft="คำนำหน้าชื่อ"
          labelRight={_.get(learnerDetail, 'prefixTH', '-')}
        />
        <BoxLabel labelLeft="ชื่อ - นามสกุล" labelRight={name} />
        <BoxLabel
          labelLeft="เลขบัตรประชาชน"
          labelRight={formatIdCard(_.get(learnerDetail, 'idCardNo', '-'))}
        />
        <BoxLabel
          labelLeft="วัน เดือน ปี เกิด"
          labelRight={getFormatDate(
            _.get(learnerDetail, 'dateOfBirth', '-'),
            'DD MMMM BBBB',
          )}
        />
        <BoxLabel
          labelLeft="เบอร์โทรศัพท์"
          labelRight={formatPhoneNo(_.get(learnerDetail, 'mobileNo', '-'))}
        />
        <BoxLabel
          labelLeft="อีเมล"
          labelRight={_.get(learnerDetail, 'email', '-')}
        />

        {/* detail */}
        <Typography variant="h5" sx={{ mb: 1, mt: 1 }}>
          ข้อมูลการทำงาน
        </Typography>
        <BoxLabel
          labelLeft="สังกัดหน่วย"
          labelRight={_.get(learnerDetail, 'unitNumber', '-')}
        />
        <BoxLabel labelLeft="เลขใบอนุญาต" labelRight={'-'} />
        <BoxLabel
          labelLeft="รหัสตัวแทน"
          labelRight={_.get(learnerDetail, 'userCode', '-')}
        />

        {/* experience */}
        <Typography variant="h5" sx={{ mb: 1, mt: 1 }}>
          ประสบการณ์
        </Typography>
        <BoxLabel labelLeft="วุฒิการศึกษา" labelRight="-" />

        {/* document */}
        <Typography variant="h5" sx={{ mb: 1 }}>
          เอกสาร
        </Typography>
        {documentList.length > 0 &&
          documentList.map((item, index) => {
            const titleDownload = `${_.get(item, 'title', '')}_${_.get(
              learnerDetail,
              'firstNameTH',
              '-',
            )}`

            return (
              <>
                <Box key={index}>
                  <BoxLabel
                    isIcon={true}
                    labelLeft={_.get(item, 'title', '')}
                    labelRight={
                      <ButtonAction
                        indexDoc={index}
                        title={_.get(item, 'title', '')}
                        titleDownload={titleDownload}
                        url={_.get(item, 'key', '')}
                        isFooter={true}
                        footer={{
                          status: _.get(item, 'status', ''),
                          remark: _.get(
                            item,
                            'classLearnerDocument.remark',
                            '',
                          ),
                        }}
                      />
                    }
                  />
                </Box>
              </>
            )
          })}
        {!documentList.length && (
          <BoxLabel labelLeft="ไม่มีข้อมูลเอกสาร" labelRight="" />
        )}
      </Box>
    </Card>
  )
}

export default Index
