import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone'
import EditIcon from '@mui/icons-material/Edit'
import { shallowEqual, useSelector } from 'react-redux'
import { handleClickEditAsset } from '../../../handler/handleClickEditAsset'
import { openAssetHistoryDrawer } from '../../../components/DropdownButton/events'

export const ActionButtons = () => {
  const { isShowEditForm, assetDetailDrawer } = useSelector(
    (state) => ({
      isShowEditForm: state.crud.isShowEditForm,
      assetDetailDrawer: state.crud.assetDetailDrawer,
    }),
    shallowEqual,
  )

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={3}
      >
        <Grid item style={{ flexGrow: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box>
              <Typography variant="body1b">ข้อมูลพื้นฐาน</Typography>
            </Box>
          </Box>
        </Grid>

        {!isShowEditForm && (
          <Grid item>
            <Button
              startIcon={<RestoreTwoToneIcon />}
              variant="outlined"
              sx={{ mr: 2 }}
              onClick={() => openAssetHistoryDrawer(assetDetailDrawer)}
            >
              ประวัติการเคลื่อนไหว
            </Button>
            <Button
              startIcon={<EditIcon />}
              variant="contained"
              onClick={() => handleClickEditAsset()}
              data-testid="btn-edit"
            >
              แก้ไข
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  )
}
