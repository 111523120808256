import React from 'react'
import Add from '@mui/icons-material/Add'
import _ from 'lodash'
import dayjs from 'dayjs'
import { store } from '../../../../../App'
import {
  COURSE_BATCH_SHELF_STATUS,
  COURSE_STATUS,
  headCellsBatch,
  headCellsBatchView,
} from '../../../../../constants/eLearning'
import {
  resetTablePage,
  setDefaultSort,
  setFilterProp,
  setFilterTotal,
  setInitialTable,
  setOrder,
  setSearchText,
  setStatusFilter,
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/table'
import {
  resetBatchData,
  setOpenDrawer,
} from '../../../../../redux/slices/eLearning/courseForm'
import {
  loadingDialog,
  openDialog,
  stopLoadingDialog,
  closeDialog,
} from '../../../../../redux/slices/dialog'
import { postGetCourseBatch } from '../../../../../services/eLearning/course/form'
import { downloadFile } from '../../../../../services/util'
import { eLearningCourseBatchDownload } from '../../../../../utils/apiPath'
import { validateEPermission } from '../../../../../utils/lib'
import { deleteCourseBatch } from '../../../../../services/eLearning/course/form'
import { putCompleteCourseBatch } from '../../../../../services/eLearning/course/detail'
import { formatDBDate } from '../../../components/events'
import { DEFUALT_COURSE_STATUS } from '../../model'
import { getCourseFormState, getStateValue } from '../events'
import { fetchELearningCourseBatch } from '../BatchTab/View/events'

export const getTableState = () => {
  const state = store.getState()
  return _.get(state, 'table', {})
}

export const handleBatchTable = (props) => async (dispatch) => {
  const tableState = getTableState()
  const { table, filter, search } = tableState
  const page = _.get(props, 'page', _.get(table, 'page', -1))
  const realPage = page <= 0 ? 1 : +page
  const sort = _.get(table, 'sort', 'updatedAt')
  const filterProp = _.get(filter, 'filterProp', {})
  const body = {
    uuid: getStateValue('uuid'),
    limit: _.get(table, 'limit', '20'),
    page: realPage,
    order: _.get(table, 'order', 'desc').toString().toUpperCase(),
    sort:
      sort === 'batchName' ? 'name' : sort === 'batchStatus' ? 'status' : sort,
    status: _.get(filterProp, 'status', DEFUALT_COURSE_STATUS),
    name: search.searchText ? search.searchText : _.get(filterProp, 'name', ''),
    nameSpearker: _.get(filterProp, 'nameSpearker', ''),
    periodStart: _.get(filterProp, 'periodStart', ''),
    periodEnd: _.get(filterProp, 'periodEnd', ''),
  }

  dispatch(startLoading())
  const response = await dispatch(postGetCourseBatch(body))
  dispatch(stopLoading())
  const initTable = dispatch(handleInitTable(response))
  dispatch(setInitialTable(initTable))
}

export const handleInitTable = (response) => (dispatch) => {
  const body = getCourseFormState()
  const isHeadAdmin = validateEPermission({
    module: 'E_LEARNING',
    permission: 'HEAD_ADMIN',
    data: body,
  })
  const isAdmin = validateEPermission({
    module: 'E_LEARNING',
    permission: 'ADMIN',
    data: body,
  })
  const hasCreate = isHeadAdmin || isAdmin
  const payload = _.get(response, 'payload', {})
  const rows = _.map(_.get(payload, 'result', []), (item) => {
    const periodStart = dayjs(item.periodStart).format(
      window.__env__.REACT_APP_DATE_SHOW,
    )
    const periodEnd = dayjs(item.periodEnd).format(
      window.__env__.REACT_APP_DATE_SHOW,
    )
    const staffs = _.get(item, 'eLearningCourseBatchLearner', []).length
    const questions = _.get(item, 'eLearningCourseBatchQuestion', []).length
    const assignees = _.get(item, 'eLearningCourseBatchResponsible', []).length
    return {
      ...item,
      batchStatus: item.status,
      batchName: item.name,
      periodStart: `${periodStart} - ${periodEnd}`,
      assigneeCount: assignees,
      staffCount: staffs > 0 ? staffs : '-',
      question: questions > 0 ? questions : '-',
      onShelfStatus: COURSE_BATCH_SHELF_STATUS[item.onShelfStatus] || '-',
    }
  })
  const isView = window.location.href.includes('/e-learning/course/view')

  return {
    rows,
    allCount: _.get(payload, 'totalCount', 0),
    headCells: isView ? headCellsBatchView : headCellsBatch,
    placeholder: 'ค้นหาจากชื่อรอบอบรม',
    searchKey: 'name',
    tableHeadText: 'รอบอบรม',
    tableHeadVariant: 'h6',
    buttonHeadText: hasCreate && !isView && 'เพิ่มรอบอบรม',
    buttonProps: {
      size: 'm',
      startIcon: <Add />,
      onClick: () => {
        dispatch(resetBatchData())
        dispatch(setOpenDrawer(true))
      },
    },
    onDownload: (selected, sort, order) =>
      dispatch(onDownload(selected, sort, order)),
    handleSearch: (text) => dispatch(handleQuickSearch(text)),
    onDelete: (row) => dispatch(handleDeleteCourseBatch(row)),
  }
}

export const onFilterClick = (filter) => (dispatch) => {
  dispatch(setSearchText(''))

  let status = []
  if (filter.statusChecked) {
    if (filter.status.draftChecked) {
      status.push(COURSE_STATUS.DRAFT)
    }
    if (filter.status.waitingChecked) {
      status.push(COURSE_STATUS.WAITING)
    }
    if (filter.status.openChecked) {
      status.push(COURSE_STATUS.OPENREGISTRATION)
    }
    if (filter.status.clossChecked) {
      status.push(COURSE_STATUS.CLOSEREGISTRATION)
    }
    if (filter.status.inProgressChecked) {
      status.push(COURSE_STATUS.INPROGRESS)
    }
    if (filter.status.successChecked) {
      status.push(COURSE_STATUS.SUCCESS)
    }
    if (filter.status.completChecked) {
      status.push(COURSE_STATUS.COMPLETED)
    }
    if (filter.status.cancelChecked) {
      status.push(COURSE_STATUS.CANCEL)
    }
  } else {
    status = DEFUALT_COURSE_STATUS
  }
  dispatch(setStatusFilter(status))

  const filterProp = {
    name: filter.nameChecked ? filter.name : '',
    nameSpearker: filter.nameSpearkerChecked ? filter.nameSpearker : '',
    periodStart: filter.dateChecked ? formatDBDate(filter.startDate) : '',
    periodEnd: filter.dateChecked ? formatDBDate(filter.endDate) : '',
    status,
  }
  dispatch(setFilterProp(filterProp))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(handleBatchTable({ page: 1 }))
}

export const handleQuickSearch = (keyword) => (dispatch) => {
  const tableState = getTableState()
  const sort = _.get(tableState.table, 'sort', 'updatedAt')
  dispatch(setSearchText(keyword))
  dispatch(resetTabelState())
  if (sort === 'updatedAt') dispatch(handleBatchTable({ page: 1 }))
}

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    order: order.toUpperCase(),
    sort: sort,
    type: 'CUSTOM',
    list: selected,
  }

  await dispatch(
    downloadFile({
      url: eLearningCourseBatchDownload,
      body,
      fileName: 'รายการรอบอบรม.xlsx',
    }),
  )
  dispatch(stopLoading())
}

export const handleCloseCourseBatch = (row) => async (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันปิดรอบอบรม',
      message: 'คุณต้องการปิดรอบอบรมใช่หรือไม่',
      handleConfirm: () => dispatch(handleConfirmClose(row.uuid)),
    }),
  )
}

export const handleConfirmClose = (uuid) => async (dispatch) => {
  dispatch(startLoading())
  dispatch(loadingDialog())
  const payload = { courseBatchUuid: uuid }
  await dispatch(putCompleteCourseBatch(payload))
  await dispatch(fetchELearningCourseBatch(uuid))
  dispatch(stopLoading())
  dispatch(stopLoadingDialog())
  dispatch(closeDialog())
}

export const handleDeleteCourseBatch = (row) => async (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันลบรอบอบรม',
      message: 'คุณต้องการลบรอบอบรมใช่หรือไม่',
      colorDisagreeText: 'error',
      colorAgreeText: 'error',
      handleConfirm: () => dispatch(handleConfirmDelete(row.uuid)),
    }),
  )
}

export const handleConfirmDelete = (uuid) => async (dispatch) => {
  dispatch(startLoading())
  dispatch(loadingDialog())
  await dispatch(deleteCourseBatch(uuid))
  await dispatch(fetchELearningCourseBatch(uuid))
  dispatch(stopLoading())
  dispatch(stopLoadingDialog())
  dispatch(closeDialog())
  dispatch(resetTabelState())
  dispatch(handleBatchTable({ page: 1 }))
}

export const resetTabelState = () => (dispatch) => {
  dispatch(setFilterProp(null))
  dispatch(setFilterTotal(0))
  dispatch(setDefaultSort({ state: 0, id: '', active: false }))
  dispatch(resetTablePage())
  dispatch(setOrder({ order: 'desc', sort: 'updatedAt' }))
}

export const handleGetCourseUuid = () => {
  const { eLearningCourseBatchDetail } = store.getState()
  return _.get(eLearningCourseBatchDetail, 'result.eLearningCourse.uuid', '')
}
