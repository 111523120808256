import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import MoreVert from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import History from '../History'
import { handleOnClickMenu } from './events'
import { useParams } from 'react-router-dom'

const DropdownButton = ({ isStandalone }) => {
  const idProps = useParams()
  const [isOpen, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()
  const { type, learnerList } = useSelector(
    (state) => ({
      type: state.manageClassCheckExam.eTestingType,
      learnerList: state.manageClassCheckExam.learners.learnerAll,
    }),
    shallowEqual
  )

  return (
    <>
      <IconButton
        aria-label="more"
        data-testid="btn-more"
        id="more"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVert />
      </IconButton>
      <Menu
        MenuListProps={{ 'aria-labelledby': 'more' }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {createOptions.map((option, index) => {
          if ((type != 'ASSESSMENT' || learnerList.length == 0) && index == 1) {
            return
          }

          return (
            <MenuItem
              key={index}
              data-testid={`menu-${index}`}
              onClick={() => {
                dispatch(handleOnClickMenu(option.value, setOpen, idProps))
                setAnchorEl(null)
              }}
            >
              {option.text}
            </MenuItem>
          )
        })}
      </Menu>
      <History
        isStandalone={isStandalone}
        isOpen={isOpen}
        onCloseDrawer={() => setOpen(false)}
      />
    </>
  )
}

export default DropdownButton

export const createOptions = [
  { text: 'ประวัติการประกาศผลสอบ', value: 'history' },
  { text: 'ปรับสถานะไม่ผ่าน', value: 'fail' },
]
