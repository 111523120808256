import React from 'react'
import { Box, Typography } from '@mui/material'
import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import { CustomDropdown } from '../../../../../../components/CRUD/components/CustomDropdown'
import { validatePermission } from '../../../../../../utils/lib'
import { CancelButton } from '../../../../../../components/CRUD/components/FormFooter/CancelButton'
import { SubmitButton } from '../../../../../../components/CRUD/components/FormFooter/SubmitButton'
import { openStockDrawer } from '../../handler/openStockDrawer'
import { handleSubmitDrawer } from '../../handler/handleSubmitDrawer'
import { drawerFormValidationSchemaAsset } from '../../schema/schema'
import {
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../../../utils/lib'
import { regex } from '../../../../../../components/CRUD/enum/regex'
import { handleInputNumber } from '../../../../../../components/CRUD/handler/handleInputNumber'
import Divider from '@mui/material/Divider'
import { InputDateGroup } from '../../components/InputDateGroup'
import { OwnerInfo } from '../../components/OwnerInfo'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../../../constants/stock'
import { getSkuNo } from '../../utils'

const Form = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermissionCreate = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN, STOCK_PERMISSION.CREATE],
  })
  return (
    <>
      <Typography variant="body1b" mt={3}>
        ข้อมูลรหัสทรัพย์สิน
      </Typography>
      <Box display="flex" gap={3} alignItems="start">
        <CustomDropdown
          required
          labelName="ชื่อทรัพย์สินที่เพิ่ม รหัสทรัพย์สิน"
          fieldName="productAsset"
          placeholder="เลือกทรัพย์สิน"
          handleExtraChange={getSkuNo}
        />
        <CustomInput
          required
          disabled={!hasPermissionCreate}
          fieldName="skuNo"
          labelName="รหัสทรัพย์สิน"
          placeholder="ระบุ รหัสทรัพย์สิน"
        />
      </Box>
      <Box display="flex" gap={3} alignItems="start">
        <CustomInput
          required
          disabled={!hasPermissionCreate}
          fieldName="assetNo"
          labelName="Item Name"
          placeholder="ระบุ Item Name"
        />
        <CustomDropdown
          required
          labelName="Cost center"
          fieldName="costCenter"
          placeholder="เลือก Cost center"
        />
      </Box>
      <Box display="flex" gap={3} alignItems="start" width={"46.5%"}>
        <CustomInput
          required
          disabled={!hasPermissionCreate}
          fieldName="unitPrice"
          labelName="มูลค่าต่อหน่วย (฿)"
          placeholder="ระบุมูลค่าต่อหน่วย (฿)"
          inputProps={{
            'data-testid': 'input-quantity',
            onInput: (e) => {
              handleInputNumber(e, regex.NUMBER)
            },
            onKeyDown: (e) => {
              handleNumberKeyDown(e, ['.'])
            },
            onPaste: handlePasteFormat,
          }}
        />
      </Box>
      <InputDateGroup />
      <Divider />
      <OwnerInfo />
      <Box display="flex" my={3} gap={3} justifyContent="end">
        <CancelButton onClick={() => openStockDrawer(false)} />
        <SubmitButton
          disabled={!hasPermissionCreate}
          handleSubmitClick={() =>
            handleSubmitDrawer(drawerFormValidationSchemaAsset)
          }
        />
      </Box>
    </>
  )
}

export default Form
