import { listAPIFetchMasterDataOptions } from '../../../constants/report/listAPIFetchMasterDataOptions'
import { store } from '../../../App'
import _ from 'lodash'
import axios from 'axios'
import { getOptionsKey } from '../../../utils/lib'

export const fetchMasterDataOptions = async () => {
  const { columnSelected, columnName } = store.getState().reportComp

  const { method, url, body } = _.find(
    listAPIFetchMasterDataOptions,
    (item) => _.get(item, 'name') === _.get(columnSelected, 'masterDataName'),
  )
  return axios({ method: method, url: url, data: body }).then((res) =>
    getOptionsKey({
      data: _.get(res, 'data.result', []),
      label:
        _.get(columnSelected, 'name') === columnName.ABBREVIATION_ROADMAP ||
        _.get(columnSelected, 'name') === columnName.ABBREVIATION_DISTRIBUTION
          ? 'abbreviation'
          : _.get(columnSelected, 'name'),
      key:
        _.get(columnSelected, 'name') === columnName.COURSE_LEVEL_CONTROL
          ? 'value'
          : 'uuid',
    }),
  )
}
