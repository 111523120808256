import { Box, Divider, Typography, Card } from '@mui/material'
import { CustomInput } from '../../../../../../../../components/CRUD/components/CustomInput'
import { CustomDropdown } from '../../../../../../../../components/CRUD/components/CustomDropdown'
import CustomSwitchInLine from '../../../../../../../../components/CRUD/components/CustomSwitchInLine'
import {
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../../../../../utils/lib'
import { regex } from '../../../../../../../../components/CRUD/enum/regex'
import { handleInputNumber } from '../../../../../../../../components/CRUD/handler/handleInputNumber'
import { InputDateGroup } from '../InputDateGroup'
import { OwnerInfo } from '../OwnerInfo'
import React, { useEffect } from 'react'
import { store } from '../../../../../../../../App'
import { setFieldValue } from '../../../../../../../../redux/slices/crud'
import { paymentMethodEnum } from '../../../../../../../../modules/Inventory/Transfer/Form/enum/paymentMethod'
import { shallowEqual } from 'react-redux'
import { handleUploadFile } from '../../../../handler/handleUploadFile'
import { useSelector } from 'react-redux'
import { handlePayerChange } from '../../../../handler/handlePayerChange'
import UploadFile from '../../../../../../../../components/UploadFile'

const form = () => {
  const { payerForm, paymentTypeForm, paymentImgForm } = useSelector(
    (state) => ({
      payerForm: state.crud.formData.payerForm,
      paymentTypeForm: state.crud.formData.paymentTypeForm,
      paymentImgForm: state.uploadFile.formData.paymentImgForm,
    }),
    shallowEqual,
  )
  useEffect(() => {
    if (!paymentImgForm?.isUploading && paymentImgForm) {
      handleUploadFile(paymentImgForm)
    }
  }, [paymentImgForm])
  return (
    <>
      <Box my={2}>
        <Typography variant="body1b">ข้อมูลรหัสทรัพย์สิน</Typography>
        <Box display="flex" gap={3} alignItems="start" mt={2}>
          <CustomInput
            required
            fieldName="skuNoForm"
            labelName="รหัสทรัพย์สิน"
            placeholder="ระบุ รหัสทรัพย์สิน"
          />
          <CustomInput
            required
            fieldName="unitPriceForm"
            labelName="มูลค่าต่อหน่วย (฿)"
            placeholder="ระบุมูลค่าต่อหน่วย (฿)"
            inputProps={{
              'data-testid': 'input-quantity',
              onInput: (e) => {
                handleInputNumber(e, regex.NUMBER)
              },
              onKeyDown: (e) => {
                handleNumberKeyDown(e, ['.'])
              },
              onPaste: handlePasteFormat,
            }}
          />
        </Box>
        <Box display="flex" gap={3} alignItems="start" mt={2}>
          <CustomDropdown
            required
            labelName="Cost center"
            fieldName="costCenterForm"
            placeholder="เลือก Cost center"
            boxSx={{ width: '49%' }}
          />
        </Box>
        <Box my={2}>
          <CustomSwitchInLine
            fieldName="skuStatusForm"
            title="สถานะ รหัสทรัพย์สิน"
          />
        </Box>
        <Divider />
        <Box my={2}>
          <InputDateGroup />
        </Box>
        <Divider />
        <Box my={2}>
          <Typography variant="body1b" mt={3}>
            การชำระเงิน
          </Typography>
          <Box display="column" alignItems="start">
            <CustomDropdown
              required
              labelName="วิธีชำระเงิน"
              fieldName="paymentTypeForm"
              placeholder="เลือก วิธีชำระเงิน"
              handleExtraChange={() => {
                store.dispatch(
                  setFieldValue({
                    key: 'formData.paymentDescForm',
                    value: '',
                  }),
                )
              }}
            />
          </Box>
          {paymentTypeForm?.value === paymentMethodEnum.CASH_INVOICE && (
            <Box sx={{ width: '855px', maxWidth: '100%' }}>
              <UploadFile
                sx={{ boxShadow: 'none', border: 'none', width: '100%', p: 0 }}
                folder="transferLicense"
                maxFileSize={{ size: 2, sizeType: 'MB' }}
                accept={{
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpeg'],
                  'image/jpg': ['.jpg'],
                  'application/pdf': ['.pdf'],
                }}
                acceptLabel=".jpg, .jpeg, .png, .pdf"
                fieldName="paymentImgForm"
                maxLength={1}
                isForImage
              />
            </Box>
          )}

          {(paymentTypeForm?.value === paymentMethodEnum.CREDIT_CARD ||
            paymentTypeForm?.value === paymentMethodEnum.OTHER) && (
            <Box>
              <CustomInput
                required
                fieldName="paymentDescForm"
                labelName={`${
                  paymentTypeForm?.value === paymentMethodEnum.OTHER
                    ? 'รายละเอียด'
                    : 'ชื่อ (Credit Card Name)'
                }`}
                placeholder={`กรอก${
                  paymentTypeForm?.value === paymentMethodEnum.OTHER
                    ? 'รายละเอียด'
                    : 'ชื่อบนบัตร'
                }`}
              />
            </Box>
          )}
        </Box>
        <Divider />
        <Box my={2}>
          <Typography variant="body1b" mt={3}>
            ผู้ชำระเงิน
          </Typography>
          <Box display="column" alignItems="start">
            <CustomDropdown
              required
              labelName="ชื่อผู้ชำระเงิน"
              fieldName="payerForm"
              placeholder="เลือก ชื่อผู้ชำระเงิน"
              handleExtraChange={handlePayerChange}
            />
          </Box>
          {payerForm?.label && (
            <>
              <Card sx={{ my: 3, p: 2 }}>
                <Box display="flex" justifyContent="start" mb={2}>
                  <Typography variant="body2" mr={2} minWidth="120px">
                    คำนำหน้าชื่อ
                  </Typography>
                  <Typography variant="body2" mr={2} minWidth="120px">
                    {payerForm?.prefix}
                  </Typography>
                  <Typography variant="body2" mr={2} minWidth="120px">
                    ชื่อ-สกุล
                  </Typography>
                  <Typography variant="body2" mr={2} minWidth="120px">
                    {payerForm?.label}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="start">
                  <Typography variant="body2" mr={2} minWidth="120px">
                    เบอร์โทรศัพท์
                  </Typography>
                  <Typography variant="body2" mr={2} minWidth="120px">
                    {payerForm?.tel}
                  </Typography>
                  <Typography variant="body2" mr={2} minWidth="120px">
                    Email
                  </Typography>
                  <Typography variant="body2" mr={2} minWidth="120px">
                    {payerForm?.email}
                  </Typography>
                </Box>
              </Card>
            </>
          )}
        </Box>
        <Box my={2}>
          <OwnerInfo />
        </Box>
      </Box>
    </>
  )
}

export default form
