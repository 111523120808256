import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

export const StyledDialog = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(2),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  gap: theme.spacing(2),
  p: {
    'span:nth-child(1)': { color: theme?.palette?.success?.main },
    'span:nth-child(2)': { color: theme?.palette?.error?.main },
  },
}))

export const StyledTable = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  borderRadius: theme.spacing(1),
  marginLeft: theme.spacing(3),
  maxHeight: 590,
  overflowY: 'auto',
}))

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  minHeight: 56,
  width: '100%',
  display: 'flex',
  borderColor: theme.palette?.blue?.blueLine,
  borderBottom: 'solid 1px',
  borderBottomColor: theme.palette?.blue?.blueLine,
  gap: theme.spacing(2),
  '& > tr > th > .MuiTypography-root': {
    width: '100%',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '24px',
  },
}))

export const StyledTableData = styled(TableRow)(({ theme, status }) => {
  let color = theme?.palette?.error?.main
  if (isSuccess(status)) color = theme?.palette?.success?.main
  else if (status == 'NOSEAT') color = theme?.palette?.warning?.main
  return {
    width: '100%',
    display: 'flex',
    borderColor: theme.palette?.blue?.blueLine,
    '& > td > .MuiTypography-root': {
      fontSize: 18,
      lineHeight: '20px',
    },
    '& > td': {
      background: isSuccess(status)
        ? theme?.palette?.success?.outlinedHoverBackground
        : 'transparent',
    },
    '& > #status': { color },
  }
})

export const StyledTableBodyCell = styled((props) => (
  <TableCell key="no-data" align="center" {...props} />
))(({ theme }) => ({
  '& > .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& .MuiSvgIcon-root': {
      color: theme?.palette?.text?.silver,
      width: '27px',
      height: '27px',
    },
  },
}))

export const isSuccess = (status) => status == 'SUCCESS'
