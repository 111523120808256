import { List } from '@mui/material'
import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Draggable } from 'react-beautiful-dnd'
import _ from 'lodash'
import ColumnListItem from './ColumnListItem'

const ColumnList = ({ provided }) => {
  const { displayColumns } = useSelector(
    (state) => ({
      displayColumns: state.reportCourseSetting.displayColumns,
    }),
    shallowEqual,
  )

  return (
    <List
      sx={{ pt: 0 }}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      {_.map(displayColumns, (col, index) => {
        return (
          <Draggable
            key={col.id}
            draggableId={col.id.toString()}
            index={index}
            isDragDisabled={displayColumns?.length === 1}
          >
            {(provided) => <ColumnListItem provided={provided} col={col} />}
          </Draggable>
        )
      })}
    </List>
  )
}

export default ColumnList
