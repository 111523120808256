import React, { useEffect } from 'react'
import {
  StyledContainer,
  StyledContent,
  StyledHeader,
  StyledCard,
} from '../Styled'
import { Box, Typography } from '@mui/material'
import Breadcrumbs from '../../../components/Breadcrumbs'
import { breadcrumbReportCourseSetting } from './model'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import FilterDrawer from '../FilterDrawer'
import ManageColumnDrawer from '../ManageColumnDrawer'
import MultiActionButtons from './components/MultiActionButtons'
import TableReport from './components/TableReport'
import _ from 'lodash'
import { setColumns } from '../../../redux/slices/reportCourseSetting/List'
import { Loading } from '../components/Loading'
import { setReportConfig } from '../../../redux/slices/reportComp'
import { useReportConstants } from '../../../components/Report/hooks/useReportConstants'
// import Report from '../../../components/Report'
import { reportConfig } from './reportConfig'

const Index = () => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.reportCourseSetting.isLoading,
    }),
    shallowEqual,
  )
  const { defaultColumns } = useReportConstants('COURSE_SETTING')

  useEffect(() => {
    const columnsStorage = localStorage.getItem('columns')
    if (_.isNull(columnsStorage)) {
      dispatch(setColumns(defaultColumns))
    } else {
      dispatch(setColumns(JSON.parse(columnsStorage)))
    }
  }, [])

  useEffect(() => {
    dispatch(setReportConfig({ reportConfig: reportConfig }))
  }, [])

  return (
    <>
      <StyledContainer>
        <StyledContent isLoading={isLoading}>
          <StyledHeader>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h4">รายงาน </Typography>
              <Breadcrumbs menuList={breadcrumbReportCourseSetting()} />
            </Box>
          </StyledHeader>

          <StyledCard>
            <MultiActionButtons />
            <TableReport />
          </StyledCard>
          <FilterDrawer />
          <ManageColumnDrawer />
        </StyledContent>
        <Loading isLoading={isLoading} />
      </StyledContainer>
      {/* <Report /> */}
    </>
  )
}
export default Index
