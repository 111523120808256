import { stockDownload } from '../../../../utils/apiPath'
import {
  headCells,
  STOCK_STATUS,
  STOCK_TYPE_TEXT,
  STOCK_TYPE,
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../constants/stock'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setStatusFilter,
  setWarehouseTypeFilter,
  startLoading,
  stopLoading,
  setFilterTotal,
  setSearchDate,
} from '../../../../redux/slices/table'
import { downloadFile } from '../../../../services/util'
import { initialState } from '../../../../redux/slices/table/model'
import { getStockListFilter, deleteStock } from '../../../../services/inventory'
import _ from 'lodash'
import { store } from '../../../../App'
import {
  closeDialog,
  loadingDialog,
  openDialog,
  stopLoadingDialog,
} from '../../../../redux/slices/dialog'
import dayjs from 'dayjs'
import { getStationFilterDefault } from '../../../../services/masterData/meetingRoom'
import {
  setStationList,
  setStockList,
  setInitialStockList,
} from '../../../../redux/slices/inventory/stock/list'
import { validatePermission } from '../../../../utils/lib'

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const { eExamType } = store.getState().eExamList
  const body = {
    order: order.toUpperCase(),
    sort: sort,
    type: 'CUSTOM',
    list: selected,
    schedule: eExamType,
  }

  await dispatch(
    downloadFile({
      url: stockDownload,
      body: body,
      fileName: `รายการคลังทรัพย์สิน.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const fetchStockListFilter = (props) => async (dispatch) => {
  dispatch(startLoading())
  const {
    method,
    table,
    page,
    statusList,
    filterProp,
    keyword,
    warehouseTypeList,
  } = props
  const isInitial = method == 'initial'
  const tableProps = isInitial ? initialState.table : table
  const filter = isInitial ? null : filterProp
  const search = isInitial || method == 'filter' ? '' : keyword

  const checkSortValue = _.find(headCells, (h) => {
    return h.id === _.get(tableProps, 'sort')
  })
  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermission = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN, STOCK_PERMISSION.CREATE, STOCK_PERMISSION.MP],
  })

  const body = {
    search: _.defaultTo(search, ''),
    status: _.defaultTo(statusList, []),
    warehouseType: !hasPermission
      ? [STOCK_TYPE.PROVIDER]
      : _.defaultTo(warehouseTypeList, []),
    station: _.get(filter, 'station', []),
    vendor: _.get(filter, 'vendor', ''),
    startCreatedDate: _.get(filter, 'startCreatedDate', ''),
    endCreatedDate: _.get(filter, 'endCreatedDate', ''),
    startUpdatedDate: _.get(filter, 'startUpdatedDate', ''),
    endUpdatedDate: _.get(filter, 'endUpdatedDate', ''),
    createdBy: _.get(filter, 'createdBy', ''),
    updatedBy: _.get(filter, 'updatedBy', ''),
    limit: _.get(tableProps, 'limit', '20'),
    order: _.get(tableProps, 'order', 'DESC').toString().toUpperCase(),
    page: _.defaultTo(page, 1),
    sort: _.isEmpty(checkSortValue)
      ? 'updatedAt'
      : _.get(tableProps, 'sort', 'updatedAt'),
  }

  const response = await dispatch(getStockListFilter(body))
  let results = _.get(response, 'payload.data.result', [])
  const totalCount = _.get(response, 'payload.data.totalCount', 0)

  if (isInitial) {
    const responseStation = await dispatch(getStationFilterDefault())
    const stationList = _.get(responseStation, 'payload', []).map((item) => ({
      stationName: item.station,
      uuid: item.uuid,
    }))
    dispatch(setStationList(stationList))
  }
  results = _.map(results, (item) => {
    return {
      ...item,
      stationName: _.get(item, 'station.station', '-'),
      warehouseType: STOCK_TYPE_TEXT[_.get(item, 'warehouseType', '')],
    }
  })
  dispatch(setStockList(results))
  dispatch(
    setInitialTable({
      rows: results,
      allCount: totalCount,
      headCells,
      placeholder: 'ค้นหาด้วยคีย์เวิร์ด',
      searchKey: 'name',
      status: statusList,
      handleSearch: (text) =>
        dispatch(
          handleQuickSearch(
            tableProps,
            text,
            filter,
            statusList,
            warehouseTypeList,
          ),
        ),
      onDownload: (selected, sort, order) =>
        dispatch(onDownload(selected, sort, order)),
      onDelete: (row) => dispatch(handleDeleteModule(row, tableProps)),
    }),
  )
  isInitial && dispatch(setInitialStockList(false))
  dispatch(stopLoading())
}

export const fetchDataList =
  (method, table, statusList) => async (dispatch) => {
    const isInitial = method == 'initial'
    const tableProps = isInitial ? initialState.table : table
    dispatch(
      setInitialTable({
        rows: [],
        allCount: 0,
        headCells: headCells,
        placeholder: 'ค้นหา',
        searchKey: 'name',
        status: statusList,
        handleSearch: (text) => dispatch(handleQuickSearch(tableProps, text)),
        onDownload: (selected, sort, order) =>
          dispatch(onDownload(selected, sort, order)),
      }),
    )
    dispatch(stopLoading())
  }

export const onFilterClick = (table, filter) => (dispatch) => {
  dispatch(setSearchText(''))
  dispatch(setSearchDate(''))
  const statusList = []
  const warehouseTypeList = []

  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      statusList.push(STOCK_STATUS.ACTIVE)
    }
    if (filter.status.inActiveChecked) {
      statusList.push(STOCK_STATUS.INACTIVE)
    }
    if (filter.status.deletedChecked) {
      statusList.push(STOCK_STATUS.DELETED)
    }
  }

  dispatch(setStatusFilter(statusList))

  if (filter.warehouseTypeChecked) {
    if (filter.warehouseType === 'ACADEMY') {
      warehouseTypeList.push(STOCK_TYPE.ACADEMY)
    }
    if (filter.warehouseType === 'PROVIDER') {
      warehouseTypeList.push(STOCK_TYPE.PROVIDER)
    }
  }

  dispatch(setWarehouseTypeFilter(warehouseTypeList))

  const filterProp = {
    search: '',
    stationChecked: filter.stationChecked,
    station: filter.stationChecked ? _.get(filter, 'station', []) : [],
    startCreatedDate:
      filter.createdAtChecked && _.get(filter, 'createdAt.startDate', '') !== ''
        ? dayjs(_.get(filter, 'createdAt.startDate', '')).format(
            window.__env__.REACT_APP_DATE_DB,
          )
        : '',
    endCreatedDate:
      filter.createdAtChecked && _.get(filter, 'createdAt.toDate', '') !== ''
        ? dayjs(_.get(filter, 'createdAt.toDate', '')).format(
            window.__env__.REACT_APP_DATE_DB,
          )
        : '',
    startUpdatedDate:
      filter.updatedAtChecked && _.get(filter, 'updatedAt.startDate', '') !== ''
        ? dayjs(_.get(filter, 'updatedAt.startDate', '')).format(
            window.__env__.REACT_APP_DATE_DB,
          )
        : '',
    endUpdatedDate:
      filter.updatedAtChecked && _.get(filter, 'updatedAt.toDate', '') !== ''
        ? dayjs(_.get(filter, 'updatedAt.toDate', '')).format(
            window.__env__.REACT_APP_DATE_DB,
          )
        : '',
    updatedBy: filter.updatedByChecked ? _.get(filter, 'updatedBy', '') : '',
    vendor: filter.vendorChecked ? _.get(filter, 'vendor', '') : '',
  }

  dispatch(setFilterProp(filter))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(
    fetchStockListFilter({
      method: 'filter',
      table,
      page: 1,
      statusList,
      filterProp,
      keyword: '',
      warehouseTypeList,
    }),
  )
}

export const handleQuickSearch =
  (table, text, filter, statusList, warehouseTypeList) => (dispatch) => {
    dispatch(setSearchText(text))
    // dispatch(setFilterProp(null))
    dispatch(resetTablePage())
    dispatch(
      fetchStockListFilter({
        method: 'search',
        table,
        page: 1,
        statusList,
        filterProp: filter,
        keyword: text,
        warehouseTypeList,
      }),
    )
  }

export const handleDeleteModule = (row, table) => async (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันลบข้อมูล',
      message: 'คุณต้องการลบรายการใช่หรือไม่',
      colorDisagreeText: 'error',
      colorAgreeText: 'error',
      agreeText: 'ลบ',
      disagreeText: 'ยกเลิก',
      handleConfirm: () => dispatch(handleConfirmDelete(row.uuid, table)),
    }),
  )
}

export const resetFilter = (table) => (dispatch) => {
  dispatch(
    fetchStockListFilter({
      method: 'initial',
      table,
      page: 1,
      statusList: [],
      warehouseTypeList: [],
      keyword: '',
    }),
  )
  dispatch(stopLoadingDialog())
  dispatch(closeDialog())
}

export const handleConfirmDelete = (uuid, table) => async (dispatch) => {
  dispatch(startLoading())
  dispatch(loadingDialog())
  const response = await dispatch(deleteStock({ uuid }))
  if (!response?.error) {
    dispatch(
      openDialog({
        type: 'success',
        title: 'สำเร็จ',
        message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        handleConfirm: () => {
          dispatch(resetFilter(table))
        },
      }),
    )
  } else {
    dispatch(
      openDialog({
        type: 'fail',
        title: 'ผิดพลาด',
        message:
          'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากพบปัญหาบางอย่าง โปรดลองใหม่อีกครั้ง',
        handleConfirm: () => {
          dispatch(resetFilter(table))
        },
      }),
    )
  }
}
