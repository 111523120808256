import {
  resetTablePage,
  setFilterProp,
  setFilterTotal,
  setSearchText,
  setToggleFilterDrawer,
} from '../../../../../redux/slices/table'
import { store } from '../../../../../App'
import { fetchDataList } from '../../../../../components/CRUD/handler/fetchDataList'

export const handleFiltering =
  (mutateFilterSateToProp, mutateFilterPropToBody, props) => (dispatch) => {
    const { filterState } = store.getState().table
    dispatch(setSearchText(''))
    dispatch(setToggleFilterDrawer(false))
    const filterProp = mutateFilterSateToProp()
    dispatch(setFilterProp(filterProp))
    dispatch(setFilterTotal(filterState))
    dispatch(resetTablePage())
    fetchDataList(mutateFilterPropToBody, props).then()
  }
