import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import CheckCircle from '@mui/icons-material/CheckCircle'
import ErrorOutline from '@mui/icons-material/ErrorOutline'

import {
  StyledCancelButton,
  StyledDialogActions,
  StyledLoadingButton,
} from '../../../../../components/redux/PopUpDialog/Styled'
import { uploadStatus } from '../../../../../constants/manageClass'
import { handleSuccess } from '../events'
import {
  handleCloseDialog,
  handleEnableButton,
  handleNumber,
  handleSubmitAdd,
} from './events'
import {
  StyledDialog,
  StyledTable,
  StyledTableData,
  StyledTableHead,
} from './Styled'
import _ from 'lodash'

export const CheckDialog = () => {
  const { isLoading, disableButton } = useSelector((state) => ({
    isLoading: state.dialog.isLoading,
    disableButton: state.dialog.disableButton,
  }))
  const { uploadResult, isSuccess } = useSelector(
    (state) => ({
      uploadResult: state.manageClassLearner.uploadResult,
      isSuccess: state.manageClassLearner.isSuccess,
    }),
    shallowEqual,
  )
  const dispatch = useDispatch()
  const status = _.get(uploadResult, 'status', '')
  const result = _.get(uploadResult, 'result', [])
  const successCount = useMemo(() => {
    const successList = result.filter((item) => item.status === 'SUCCESS')
    return successList.length
  }, [result])
  const uploadSuccess = status === 'SUCCESS'

  useEffect(() => {
    dispatch(handleSuccess(isSuccess))
  }, [isSuccess])

  return (
    <>
      {uploadSuccess ? <SuccessDialogHeader /> : <WarningDialogHeader />}
      <StyledDialog>
        {!uploadSuccess && (
          <Box sx={{ ml: 3, px: 1.5 }}>
            <Typography>
              สามารถเพิ่มผู้เรียนได้เพียง <span>{successCount} รายชื่อ</span>{' '}
              จากไฟล์ที่คุณเลือก
            </Typography>
          </Box>
        )}
        <StyledTable>
          <StyledTableHead>
            <Typography>No.</Typography>
            <Typography>Agent Code</Typography>
            <Typography>สถานะการเพิ่มผู้เรียน</Typography>
          </StyledTableHead>
          {result.map((item) => (
            <StyledTableData status={item.status} key={item.index}>
              <Typography>{handleNumber(item.index)}</Typography>
              <Typography>{item.code}</Typography>
              <Typography id="status">{uploadStatus[item.status]}</Typography>
            </StyledTableData>
          ))}
        </StyledTable>
        {successCount > 0 && (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  inputProps={{ 'data-testid': 'checkbox-status' }}
                  defaultChecked={false}
                  onChange={(e) => dispatch(handleEnableButton(e))}
                />
              }
              label="เพิ่มรายชื่อผู้เรียนที่สำเร็จลงในคลาสเรียนนี้"
            />
          </Box>
        )}
      </StyledDialog>
      <StyledDialogActions sx={{ mt: 2.5 }}>
        {!isLoading && (
          <StyledCancelButton
            data-testid="btn-disagree"
            onClick={() => dispatch(handleCloseDialog())}
            variant="outlined"
            endIcon=""
          >
            ยกเลิกดำเนินการ
          </StyledCancelButton>
        )}
        {successCount > 0 && (
          <StyledLoadingButton
            data-testid="btn-agree"
            loading={isLoading}
            onClick={() => dispatch(handleSubmitAdd(uploadResult))}
            color="primary"
            disabled={disableButton}
            endIcon=""
          >
            บันทึก
          </StyledLoadingButton>
        )}
      </StyledDialogActions>
    </>
  )
}

export default CheckDialog

export const WarningDialogHeader = () => {
  return (
    <Box id="dialog-header">
      <ErrorOutline color="error" />
      <Typography variant="h6">กรุณาตรวจสอบรายชื่อ</Typography>
    </Box>
  )
}

export const SuccessDialogHeader = () => {
  return (
    <Box id="dialog-header">
      <CheckCircle color="primary" />
      <Typography variant="h6">สามารถเพิ่มผู้เรียนได้ทั้งหมด</Typography>
    </Box>
  )
}
