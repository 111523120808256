export const defaultStaffProfileform = {
  staffID: '',
  agentCode: '',
  prefix: '',
  firstName: '',
  lastName: '',
  prefixTH: '',
  firstNameTH: '',
  lastNameTH: '',
  position: '',
  permissionGroup: [],
  jobTitles: {},
  joinDate: null,
  costCenter: '',
  reportTo: {
    label: '',
    value: '',
  },
  level: '',
  tel: '',
  email: '',
  deskNumber: '',
  birthOfDate: null,
  nationality: '',
  address: '',
  image: '',
  imageFull: '',
  department: '',
  areaUuid: '',
  defaultAreaIndex: 0,
  area: [
    {
      id: 0,
      uuid: '',
      department: {
        uuid: '',
        department: '',
        initial: '',
        status: 'ACTIVE',
      },
      station: {
        uuid: '',
        station: '',
        initial: '',
        status: 'ACTIVE',
      },
      default: false,
      stationOptions: [],
    },
  ],
  status: '',
  acquiredSkill: [],
  educational: [
    { institution: '', between: '', education: '', branch: '', GPA: '' },
  ],
  workHistory: [
    {
      organization: '',
      position: '',
      business: '',
      between: '',
    },
  ],
  experienced: [{ experiencedUuid: '', amount: '', unit: '' }],
  training: [{ institution: '', year: '', course: [{}] }],
  lecturer: [{ institution: '', year: '', course: [{}] }],
  institute: [{ profession: '', qualification: '', license: '' }],
}
