import _ from 'lodash'
import { eTestingDownload, eTestingFilter } from '../../../utils/apiPath'
import { headCells } from '../../../constants/eTesting'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setStatusFilter,
  startLoading,
  stopLoading,
  setFilterTotal,
} from '../../../redux/slices/table'
import { downloadFile } from '../../../services/util'
import { initialState } from '../../../redux/slices/table/model'
import callAxios from '../../../utils/baseService'

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    order: order.toUpperCase(),
    sort: sort,
    type: 'CUSTOM',
    list: selected,
  }

  await dispatch(
    downloadFile({
      url: eTestingDownload,
      body: body,
      fileName: `รายการE-Testing.xlsx`,
    })
  )
  dispatch(stopLoading())
}

export const fetchDataList =
  (method, table, page, statusList, filterProp, keyword, setInitial) =>
  async (dispatch) => {
    const isInitial = method == 'initial'
    const realPage = page <= 0 ? 1 : +page
    const tableProps = isInitial ? initialState.table : table
    const filter = isInitial ? null : filterProp
    const title = isInitial ? '' : _.get(filter, 'name', keyword)
    const body = {
      limit: _.get(tableProps, 'limit', '100'),
      page: realPage,
      order: tableProps.order.toString().toUpperCase(),
      sort: _.get(tableProps, 'sort', 'updatedAt'),
      status: isInitial ? [] : statusList,
      typeOfETesting: _.get(filter, 'typeList', []),
      name: method == 'filter' ? keyword : title,
      codeId: _.get(filter, 'codeId', ''),
      numberOfVersions: _.get(filter, 'numberOfVersions', -1),
      createdBy: _.get(filter, 'createdBy', ''),
      updatedBy: _.get(filter, 'updatedBy', ''),
    }

    dispatch(startLoading())
    const isCancel = await callAxios
      .post(eTestingFilter, body)
      .then(({ data }) => {
        data.result.map((item) => {
          item.updatedBy = item.updatedBy.name
          item.typeOfETesting = _.capitalize(_.lowerCase(item.typeOfETesting))
        })
        dispatch(
          setInitialTable({
            rows: data.result,
            allCount: data.totalCount,
            headCells: headCells,
            placeholder: 'ค้นหาชื่อแบบทดสอบ',
            searchKey: 'name',
            status: statusList,
            handleSearch: (text) =>
              dispatch(handleQuickSearch(tableProps, text)),
            onDownload: (selected, sort, order) =>
              dispatch(onDownload(selected, sort, order)),
          })
        )
      })
      .catch((e) => {
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: headCells,
          })
        )
        return e.message?.includes('method')
      })

    setInitial && setInitial(false)
    if (!isCancel) dispatch(stopLoading())
  }

export const onFilterClick = (table, filter) => (dispatch) => {
  dispatch(setSearchText(''))
  const statusList = []

  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      statusList.push('ACTIVE')
    }
    if (filter.status.inActiveChecked) {
      statusList.push('INACTIVE')
    }
    if (filter.status.deletedChecked) {
      statusList.push('DELETED')
    }
  } else {
    statusList.push('ACTIVE', 'INACTIVE')
  }
  dispatch(setStatusFilter(statusList))

  const typeList = []
  if (filter.typeChecked) {
    if (filter.type.onlineTestChecked) {
      typeList.push('ONLINE_TEST')
    }
    if (filter.type.assessmentChecked) {
      typeList.push('ASSESSMENT')
    }
    if (filter.type.standaloneChecked) {
      typeList.push('STANDALONE')
    }
  } else {
    typeList.push('ONLINE_TEST', 'ASSESSMENT', 'STANDALONE')
  }

  const filterText = {
    codeId: filter.codeIdChecked ? filter.codeId : '',
    name: filter.nameChecked ? filter.name : '',
    createdBy: filter.createdByChecked ? filter.createdBy : '',
    updatedBy: filter.updatedByChecked ? filter.updatedBy : '',
    numberOfVersions: filter.numberOfVersionsChecked
      ? +filter.numberOfVersions
      : -1,
    typeList,
  }
  dispatch(setFilterProp(filterText))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(
    fetchDataList('filter', table, 1, statusList, filterText, filterText.name)
  )
}

export const handleQuickSearch = (table, text) => (dispatch) => {
  dispatch(setSearchText(text))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  const statusList = ['ACTIVE', 'INACTIVE', 'DELETED']
  dispatch(fetchDataList('search', table, 1, statusList, null, text))
}
