export const evaStatusHeadCells = [
  {
    id: 'codeId',
    label: 'ID',
    disablePadding: false,
  },
  {
    id: 'firstNameTH',
    label: 'ชื่อ',
    disablePadding: false,
  },
  {
    id: 'lastNameTH',
    label: 'นามสกุล',
    disablePadding: false,
  },
  {
    id: 'evaStatusList',
    label: 'สถานะ',
    disablePadding: false,
    width: '200px',
  },
  {
    id: 'actionEvaStatus',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    viewPath: `/manage-class/learner/detail`,
    textAlign: 'center',
  },
]
