import { store } from '../../../../../App'
import {
  openDialog,
  closeDialog,
  loadingDialog,
} from '../../../../../redux/slices/dialog'
import { transferTicketStatus } from '../../../../../utils/apiPath'
import callAxios from '../../../../../utils/baseService'
import { TicketStatus, TicketStatusText } from '../../../../../constants/stock'
import { initializePage } from './initializePage'
import _ from 'lodash'

export const handleChangeStatus = (uuid, status, formData = null) => {
  confirmDialog(uuid, status, formData)
}

function confirmDialog(uuid, status, formData) {
  const dialogText = getDialogText(status)
  store.dispatch(
    openDialog({
      type: dialogText.type,
      title: dialogText.DIALOG_TITLE,
      message: `คุณต้องการ${TicketStatusText?.[status]}รายการนี้ใช่หรือไม่`,
      agreeText: dialogText.agreeText,
      disagreeText: dialogText.disagreeText,
      handleConfirm: () => {
        changeTransferStatus(uuid, status, formData)
      },
      isCloseDialog: false,
    }),
  )
}

const getDialogText = (status) => {
  let DIALOG_TITLE = `ยืนยันลบข้อมูล`
  let agreeText = 'บันทึก'
  let disagreeText = 'ยกเลิก'
  let type = 'delete'
  if (TicketStatus.CANCELED === status) {
    DIALOG_TITLE = `ยืนยันการยกเลิก`
    agreeText = 'ยืนยัน'
    disagreeText = 'ปฏิเสธ'
  } else if (TicketStatus.REJECTED === status) {
    DIALOG_TITLE = `ยืนยันการปฏิเสธ`
    agreeText = 'ยืนยัน'
    disagreeText = 'ยกเลิก'
  } else if (
    [TicketStatus.APPROVED, TicketStatus.EDITED_APPROVED].includes(status)
  ) {
    DIALOG_TITLE = `ยืนยันอนุมัติรายการ`
    agreeText = 'อนุมัติ'
    disagreeText = 'ยกเลิก'
    type = 'confirm'
  }

  return {
    DIALOG_TITLE,
    agreeText,
    disagreeText,
    type,
  }
}

const changeTransferStatus = async (uuid, status, formData) => {
  const body = {
    uuid,
    status,
    products: _.get(formData, 'products', []).map((item) => {
      const quantity =
        item?.quantity && typeof item?.quantity === 'string'
          ? Number(item?.quantity.replace(/,/g, ''))
          : item?.quantity
      return {
        ...item,
        quantity: quantity,
      }
    }),
  }
  store.dispatch(loadingDialog())
  await callAxios
    .put(transferTicketStatus, body)
    .then(() => {
      store.dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          handleConfirm: () => {
            store.dispatch(closeDialog())
            initializePage(uuid)
          },
        }),
      )
    })
    .catch(() => {
      store.dispatch(
        openDialog({
          type: 'fail',
          title: 'ผิดพลาด',
          message: 'ระบบขัดข้อง',
        }),
      )
    })
}
