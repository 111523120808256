export const headCells = [
  {
    id: 'createdAt',
    label: 'วันเวลา',
    isCustom: true,
    minWidth: '200px',
  },
  {
    id: 'movementType',
    label: 'รายการ',
  },
  {
    id: 'form.warehouseName',
    label: 'จาก',
  },
  {
    id: 'to.warehouseName',
    label: 'ไป',
  },
  {
    id: 'ticket.id',
    label: 'Ticket ID',
  },
  {
    id: 'remark',
    label: 'หมายเหตุ',
  },
  {
    id: 'dropdownAction',
    label: 'คำสั่ง',
    disablePadding: false,
    hideSortIcon: true,
    custom: true,
  },
]