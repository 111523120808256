import { store } from '../../App'
import { closeDialog, openDialog } from '../../redux/slices/dialog'
import {
  setIsOpenFilterDrawer,
  setIsOpenManageColumnDrawer,
} from '../../redux/slices/reportCourseSetting/List'

export const handleConfirmCancelDraft = () => (dispatch) => {
  const { displayFilters, isOpenFilterDrawer, isOpenManageColumnDrawer } =
    store.getState().reportCourseSetting

  if (
    (displayFilters.length > 0 && isOpenFilterDrawer) ||
    isOpenManageColumnDrawer
  ) {
    dispatch(
      openDialog({
        type: 'confirm',
        title: 'ยืนยันการละทิ้ง',
        message:
          'เนื้อหาที่สร้างไว้ จะไม่สามารถกู้คืนได้ คุณต้องการละทิ้งใช่หรือไม่',
        colorDisagreeText: 'error',
        colorAgreeText: 'error',
        handleConfirm: () => {
          dispatch(setIsOpenFilterDrawer(false))
          dispatch(setIsOpenManageColumnDrawer(false))
          dispatch(closeDialog())
        },
        handleCancel: () => {
          dispatch(closeDialog())
        },
      }),
    )
  } else {
    dispatch(setIsOpenFilterDrawer(false))
    dispatch(setIsOpenManageColumnDrawer(false))
  }
}
