import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'

export const COLUMN_NAME = {
  NO: 'no',
  START_DATE: 'startDate',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
  COURSE_VERSION: 'courseVersion',
  BREAK_MEAL_BUDGET: 'breakMealBudget',
  ATTENDANCE_COUNT: 'attendanceCount',
  ATTENDANCE_CRITERIA: 'attendanceCriteria',
  QR_CODE_TIME: 'qrCodeTime',
  COUNT_HRS: 'countHrs',
  COURSE_CODE: 'courseCode',
  COURSE_NAME_EN: 'courseNameEN',
  COURSE_NAME_TH: 'courseNameTH',
  COURSE_NAME_FOR_LEARNER: 'courseNameForLearner',
  CERTIFICATE_NAME_TH: 'certificateNameTH',
  CERTIFICATE_NAME_EN: 'certificateNameEN',
  PRE_TEST_NAME: 'preTestName',
  POST_TEST_NAME: 'postTestName',
  ASSESSMENT_NAME: 'assessmentName',
  OTHER_TEST_NAME: 'otherTestName',
  EVALUATION_NAME: 'evaluationName',
  DOCUMENT_LIST: 'documentList',
  STAFF_ID_LIST: 'staffIdList',
  STAFF_NAME_LIST: 'staffNameList',
  STAFF_ID_1: 'staffId_1',
  STAFF_ID_2: 'staffId_2',
  STAFF_ID_3: 'staffId_3',
  STAFF_ID_4: 'staffId_4',
  STAFF_ID_5: 'staffId_5',
  STAFF_NAME_1: 'staffName_1',
  STAFF_NAME_2: 'staffName_2',
  STAFF_NAME_3: 'staffName_3',
  STAFF_NAME_4: 'staffName_4',
  STAFF_NAME_5: 'staffName_5',
  CREATED_BY: 'createdBy',
  UPDATED_BY: 'updatedBy',
  DISTRIBUTION: 'distribution',
  ABBREVIATION_DISTRIBUTION: 'abbreviationDistribution',
  ROADMAP: 'roadMap',
  ABBREVIATION_ROADMAP: 'abbreviationRoadMap',
  LEVEL_OF_LEARNER: 'levelOfLearner',
  OTHER_LEVEL_OF_LEARNER: 'otherLevelOfLearner',
  TRAINING_PLATFORM: 'trainingPlatform',
  ACQUIRED_SKILL: 'acquiredSkill',
  PRODUCT_TYPE: 'productType',
  COURSE_LEVEL_CONTROL: 'courseLevelControl',
  IS_APPROVED: 'isApproved',
  HAS_PREREQUISITE: 'hasPrerequisite',
  HAS_BREAK_MEAL: 'hasBreakMeal',
  REQUIRE_OIC_EXAM: 'requireOicExam',
  AGENT_CONFIRMATION: 'agentConfirmation',
  IS_ATTENDANCE: 'isAttendance',
  HAS_DOCUMENT: 'hasDocument',
  STATUS: 'status',
  REQUIRE_DOCUMENT: 'requireDocument',
  COURSE_OUTLINE: 'courseOutline',
  COURSE_PURPOSE: 'coursePurpose',
}

export const COLUMN_NAME_TH = {
  NO: 'No',
  START_DATE: 'วันเริ่มต้น',
  CREATED_AT: 'วันที่สร้าง',
  UPDATED_AT: 'วันที่แก้ไขล่าสุด',
  COURSE_VERSION: 'เวอร์ชัน',
  BREAK_MEAL_BUDGET: 'ค่าใช้จ่ายต่อหัว',
  ATTENDANCE_COUNT: 'จำนวนการเช็คชื่อ',
  ATTENDANCE_CRITERIA: 'เกณฑ์ผ่าน (จำนวนครั้งของการเข้าเรียนทั้งหมด)',
  QR_CODE_TIME: 'อายุของ QR Code',
  COUNT_HRS: 'จำนวนชั่วโมง (ไม่รวมพักเที่ยง)',
  COURSE_CODE: 'รหัสหลักสูตร',
  COURSE_NAME_EN: 'ชื่อหลักสูตร (EN)',
  COURSE_NAME_TH: 'ชื่อหลักสูตร (TH)',
  COURSE_NAME_FOR_LEARNER: 'ชื่อหลักสูตร (สำหรับ Learner)',
  CERTIFICATE_NAME_TH: 'E-Certificate Template',
  CERTIFICATE_NAME_EN: 'ชื่อ Certificate',
  PRE_TEST_NAME: 'แบบทดสอบ (Pre-Test)',
  POST_TEST_NAME: 'แบบทดสอบ (Post-Test)',
  ASSESSMENT_NAME: 'แบบทดสอบ (Assessment)',
  OTHER_TEST_NAME: 'แบบทดสอบ (Other)',
  EVALUATION_NAME: 'แบบประเมิน',
  DOCUMENT_LIST: 'รายการเอกสาร',
  STAFF_ID_LIST: 'Staff ID ผู้จัดทำ',
  STAFF_NAME_LIST: 'ชื่อผู้จัดทำ',
  STAFF_ID_1: 'Staff ID ผู้จัดทำ (1)',
  STAFF_ID_2: 'Staff ID ผู้จัดทำ (2)',
  STAFF_ID_3: 'Staff ID ผู้จัดทำ (3)',
  STAFF_ID_4: 'Staff ID ผู้จัดทำ (4)',
  STAFF_ID_5: 'Staff ID ผู้จัดทำ (5)',
  STAFF_NAME_1: 'ชื่อผู้จัดทำ (1)',
  STAFF_NAME_2: 'ชื่อผู้จัดทำ (2)',
  STAFF_NAME_3: 'ชื่อผู้จัดทำ (3)',
  STAFF_NAME_4: 'ชื่อผู้จัดทำ (4)',
  STAFF_NAME_5: 'ชื่อผู้จัดทำ (5)',
  CREATED_BY: 'สร้างโดย',
  UPDATED_BY: 'แก้ไขล่าสุดโดย',
  DISTRIBUTION: 'ช่องทางการจำหน่าย',
  ABBREVIATION_DISTRIBUTION: 'ชื่อย่อของช่องทางการจำหน่าย',
  ROADMAP: 'กลุ่มหลักสูตร',
  ABBREVIATION_ROADMAP: 'ชื่อย่อของกลุ่มหลักสูตร',
  LEVEL_OF_LEARNER: 'ระดับของผู้เรียน',
  OTHER_LEVEL_OF_LEARNER: 'ระดับของผู้เรียนช่องทางการจำหน่ายอื่น',
  TRAINING_PLATFORM: 'รูปแบบของการสอน',
  ACQUIRED_SKILL: 'ระดับหลักสูตร',
  PRODUCT_TYPE: 'ทักษะที่ได้จากหลักสูตร',
  COURSE_LEVEL_CONTROL: 'ระดับการควบคุมหลักสูตร',
  IS_APPROVED: 'การอนุมัติหลักสูตร',
  HAS_PREREQUISITE: 'เงื่อนไขการเข้าอบรม',
  HAS_BREAK_MEAL: 'การเตรียมอาหาร Break / Lunch',
  REQUIRE_OIC_EXAM: 'ผลสอบใบอนุญาตตัวแทน',
  AGENT_CONFIRMATION: 'ขั้นตอนการคอนเฟิร์มตัวแทน',
  IS_ATTENDANCE: 'เกณฑ์การเข้าเรียน',
  HAS_DOCUMENT: 'เอกสารประกอบการเรียนสำหรับผู้เข้าอบรม',
  STATUS: 'สถานะ',
  REQUIRE_DOCUMENT: 'เอกสารการสมัคร',
  COURSE_OUTLINE: 'โครงร่างหลักสูตร',
  COURSE_PURPOSE: 'จุดประสงค์หลักสูตร',
}

export const TYPE_OF_VALUE = {
  DATE_RANGE: 'DATE_RANGE',
  INPUT_NUMBER: 'INPUT_NUMBER',
  INPUT_TEXT: 'INPUT_TEXT',
  DROPDOWN_MULTI: 'DROPDOWN_MULTI',
  DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
}

export const courseLevelControlOptions = [
  { label: 'ระดับสูง', value: 'ระดับสูง', courseLevelControl: 'ระดับสูง' },
  { label: 'ระดับกลาง', value: 'ระดับกลาง', courseLevelControl: 'ระดับกลาง' },
  { label: 'ระดับต่ำ', value: 'ระดับต่ำ', courseLevelControl: 'ระดับต่ำ' },
]

export const approvalOptions = [
  {
    label: 'มีการอนุมัติ',
    value: true,
  },
  {
    label: 'ไม่มีการอนุมัติ',
    value: false,
  },
]

export const isAvailableOptions = [
  {
    label: 'มี',
    value: true,
  },
  {
    label: 'ไม่มี',
    value: false,
  },
]

export const requireOptions = [
  {
    label: 'ต้องการ',
    value: true,
  },
  {
    label: 'ไม่ต้องการ',
    value: false,
  },
]

export const isAttendanceOptions = [
  {
    label: 'กำหนด',
    value: true,
  },
  {
    label: 'ไม่กำหนด',
    value: false,
  },
]

export const statusOptions = [
  {
    label: 'เปิดใช้งาน',
    value: 'ACTIVE',
  },
  {
    label: 'ปิดใช้งาน',
    value: 'INACTIVE',
  },
  {
    label: 'รอเปิดใช้งาน',
    value: 'WAITING_FOR_ACTIVE',
  },
  {
    label: 'ถูกลบแล้ว',
    value: 'DELETED',
  },
  {
    label: 'ฉบับร่าง',
    value: 'DRAFT',
  },
]

export const defaultColumns = [
  {
    id: 1,
    name: COLUMN_NAME.NO,
    value: COLUMN_NAME.NO,
    label: 'No',
    minWidth: 60,
    align: 'center',
  },
  {
    id: 2,
    name: COLUMN_NAME.COURSE_CODE,
    value: COLUMN_NAME.COURSE_CODE,
    label: 'รหัสหลักสูตร',
    minWidth: 160,
  },
  {
    id: 3,
    name: COLUMN_NAME.COURSE_VERSION,
    value: COLUMN_NAME.COURSE_VERSION,
    label: 'เวอร์ชัน',
    minWidth: 20,
    align: 'center',
  },
  {
    id: 4,
    name: COLUMN_NAME.COURSE_NAME_EN,
    value: COLUMN_NAME.COURSE_NAME_EN,
    label: 'ชื่อหลักสูตร (EN)',
    minWidth: 160,
  },
  {
    id: 5,
    name: COLUMN_NAME.COURSE_NAME_TH,
    value: COLUMN_NAME.COURSE_NAME_TH,
    label: 'ชื่อหลักสูตร (TH)',
    minWidth: 160,
  },
  {
    id: 6,
    name: COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
    value: COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
    label: 'ชื่อหลักสูตร (สำหรับ Learner)',
    minWidth: 160,
  },
  {
    id: 7,
    name: COLUMN_NAME.START_DATE,
    value: COLUMN_NAME.START_DATE,
    label: 'วันเริ่มต้น',
    minWidth: 160,
  },
  {
    id: 8,
    name: COLUMN_NAME.COUNT_HRS,
    value: COLUMN_NAME.COUNT_HRS,
    label: 'จำนวนชั่วโมง (ไม่รวมพักเที่ยง)',
    minWidth: 160,
  },
  {
    id: 9,
    name: COLUMN_NAME.CERTIFICATE_NAME_EN,
    value: COLUMN_NAME.CERTIFICATE_NAME_EN,
    label: 'ชื่อ Certificate',
    minWidth: 160,
  },
  {
    id: 10,
    name: COLUMN_NAME.CERTIFICATE_NAME_TH,
    value: COLUMN_NAME.CERTIFICATE_NAME_TH,
    label: 'E-Certificate Template',
    minWidth: 160,
  },
  {
    id: 11,
    name: COLUMN_NAME.DISTRIBUTION,
    value: COLUMN_NAME.DISTRIBUTION,
    label: 'ช่องทางการจำหน่าย',
    minWidth: 160,
  },
  {
    id: 12,
    name: COLUMN_NAME.ABBREVIATION_DISTRIBUTION,
    value: COLUMN_NAME.ABBREVIATION_DISTRIBUTION,
    label: 'ชื่อย่อของช่องทางการจำหน่าย',
    minWidth: 160,
  },
  {
    id: 13,
    name: COLUMN_NAME.ROADMAP,
    value: COLUMN_NAME.ROADMAP,
    label: 'กลุ่มหลักสูตร',
    minWidth: 160,
  },
  {
    id: 14,
    name: COLUMN_NAME.ABBREVIATION_ROADMAP,
    value: COLUMN_NAME.ABBREVIATION_ROADMAP,
    label: 'ชื่อย่อของกลุ่มหลักสูตร',
    minWidth: 160,
  },
  {
    id: 15,
    name: COLUMN_NAME.LEVEL_OF_LEARNER,
    value: COLUMN_NAME.LEVEL_OF_LEARNER,
    label: 'ระดับของผู้เรียน',
    minWidth: 160,
  },
  {
    id: 16,
    name: COLUMN_NAME.OTHER_LEVEL_OF_LEARNER,
    value: COLUMN_NAME.OTHER_LEVEL_OF_LEARNER,
    label: 'ระดับของผู้เรียนช่องทางการจำหน่ายอื่น',
    minWidth: 160,
  },
  {
    id: 17,
    name: COLUMN_NAME.TRAINING_PLATFORM,
    value: COLUMN_NAME.TRAINING_PLATFORM,
    label: 'รูปแบบของการสอน',
    minWidth: 160,
  },
  {
    id: 18,
    name: COLUMN_NAME.ACQUIRED_SKILL,
    value: COLUMN_NAME.ACQUIRED_SKILL,
    label: 'ระดับหลักสูตร',
    minWidth: 160,
  },
  {
    id: 19,
    name: COLUMN_NAME.PRODUCT_TYPE,
    value: COLUMN_NAME.PRODUCT_TYPE,
    label: 'ทักษะที่ได้จากหลักสูตร',
    minWidth: 160,
  },
  {
    id: 20,
    name: COLUMN_NAME.COURSE_PURPOSE,
    value: COLUMN_NAME.COURSE_PURPOSE,
    label: 'จุดประสงค์หลักสูตร',
    minWidth: 160,
  },
  {
    id: 21,
    name: COLUMN_NAME.COURSE_OUTLINE,
    value: COLUMN_NAME.COURSE_OUTLINE,
    label: 'โครงร่างหลักสูตร',
    minWidth: 160,
  },
  {
    id: 22,
    name: COLUMN_NAME.HAS_PREREQUISITE,
    value: COLUMN_NAME.HAS_PREREQUISITE,
    label: 'เงื่อนไขการเข้าอบรม',
    minWidth: 160,
  },
  {
    id: 23,
    name: COLUMN_NAME.COURSE_LEVEL_CONTROL,
    value: COLUMN_NAME.COURSE_LEVEL_CONTROL,
    label: 'ระดับการควบคุมหลักสูตร',
    minWidth: 160,
  },
  {
    id: 24,
    name: COLUMN_NAME.IS_APPROVED,
    value: COLUMN_NAME.IS_APPROVED,
    label: 'การอนุมัติหลักสูตร',
    minWidth: 160,
  },
  {
    id: 25,
    name: COLUMN_NAME.HAS_BREAK_MEAL,
    value: COLUMN_NAME.HAS_BREAK_MEAL,
    label: 'การเตรียมอาหาร Break / Lunch',
    minWidth: 160,
  },
  {
    id: 26,
    name: COLUMN_NAME.BREAK_MEAL_BUDGET,
    value: COLUMN_NAME.BREAK_MEAL_BUDGET,
    label: 'ค่าใช้จ่ายต่อหัว',
    minWidth: 160,
  },
  {
    id: 27,
    name: COLUMN_NAME.REQUIRE_OIC_EXAM,
    value: COLUMN_NAME.REQUIRE_OIC_EXAM,
    label: 'ผลสอบใบอนุญาตตัวแทน',
    minWidth: 160,
  },
  {
    id: 28,
    name: COLUMN_NAME.AGENT_CONFIRMATION,
    value: COLUMN_NAME.AGENT_CONFIRMATION,
    label: 'ขั้นตอนการคอนเฟิร์มตัวแทน',
    minWidth: 160,
  },
  {
    id: 29,
    name: COLUMN_NAME.REQUIRE_DOCUMENT,
    value: COLUMN_NAME.REQUIRE_DOCUMENT,
    label: 'เอกสารการสมัคร',
    minWidth: 160,
  },
  {
    id: 30,
    name: COLUMN_NAME.DOCUMENT_LIST,
    value: COLUMN_NAME.DOCUMENT_LIST,
    label: 'รายการเอกสาร',
    minWidth: 160,
  },
  {
    id: 31,
    name: COLUMN_NAME.IS_ATTENDANCE,
    value: COLUMN_NAME.IS_ATTENDANCE,
    label: 'เกณฑ์การเข้าเรียน',
    minWidth: 160,
  },
  {
    id: 32,
    name: COLUMN_NAME.ATTENDANCE_COUNT,
    value: COLUMN_NAME.ATTENDANCE_COUNT,
    label: 'จำนวนการเช็คชื่อ',
    minWidth: 160,
  },
  {
    id: 33,
    name: COLUMN_NAME.ATTENDANCE_CRITERIA,
    value: COLUMN_NAME.ATTENDANCE_CRITERIA,
    label: 'เกณฑ์ผ่าน (จำนวนครั้งของการเข้าเรียนทั้งหมด)',
    minWidth: 160,
  },
  {
    id: 34,
    name: COLUMN_NAME.QR_CODE_TIME,
    value: COLUMN_NAME.QR_CODE_TIME,
    label: 'อายุของ QR Code',
    minWidth: 160,
  },
  {
    id: 35,
    name: COLUMN_NAME.HAS_DOCUMENT,
    value: COLUMN_NAME.HAS_DOCUMENT,
    label: 'เอกสารประกอบการเรียนสำหรับผู้เข้าอบรม',
    minWidth: 160,
  },
  {
    id: 36,
    name: COLUMN_NAME.PRE_TEST_NAME,
    value: COLUMN_NAME.PRE_TEST_NAME,
    label: 'แบบทดสอบ (Pre-Test)',
    minWidth: 160,
  },
  {
    id: 37,
    name: COLUMN_NAME.POST_TEST_NAME,
    value: COLUMN_NAME.POST_TEST_NAME,
    label: 'แบบทดสอบ (Post-Test)',
    minWidth: 160,
  },
  {
    id: 38,
    name: COLUMN_NAME.ASSESSMENT_NAME,
    value: COLUMN_NAME.ASSESSMENT_NAME,
    label: 'แบบทดสอบ (Assessment)',
    minWidth: 160,
  },
  {
    id: 39,
    name: COLUMN_NAME.OTHER_TEST_NAME,
    value: COLUMN_NAME.OTHER_TEST_NAME,
    label: 'แบบทดสอบ (Other)',
    minWidth: 160,
  },
  {
    id: 40,
    name: COLUMN_NAME.EVALUATION_NAME,
    value: COLUMN_NAME.EVALUATION_NAME,
    label: 'แบบประเมิน',
    minWidth: 160,
  },
  {
    id: 41,
    name: COLUMN_NAME.STATUS,
    value: COLUMN_NAME.STATUS,
    label: 'สถานะ',
    minWidth: 160,
  },
  {
    id: 42,
    name: COLUMN_NAME.STAFF_ID_1,
    value: COLUMN_NAME.STAFF_ID_1,
    label: COLUMN_NAME_TH.STAFF_ID_1,
    minWidth: 160,
  },
  {
    id: 43,
    name: COLUMN_NAME.STAFF_ID_2,
    value: COLUMN_NAME.STAFF_ID_2,
    label: COLUMN_NAME_TH.STAFF_ID_2,
    minWidth: 160,
  },
  {
    id: 44,
    name: COLUMN_NAME.STAFF_ID_3,
    value: COLUMN_NAME.STAFF_ID_3,
    label: COLUMN_NAME_TH.STAFF_ID_3,
    minWidth: 160,
  },
  {
    id: 45,
    name: COLUMN_NAME.STAFF_ID_4,
    value: COLUMN_NAME.STAFF_ID_4,
    label: COLUMN_NAME_TH.STAFF_ID_4,
    minWidth: 160,
  },
  {
    id: 46,
    name: COLUMN_NAME.STAFF_ID_5,
    value: COLUMN_NAME.STAFF_ID_5,
    label: COLUMN_NAME_TH.STAFF_ID_5,
    minWidth: 160,
  },
  {
    id: 47,
    name: COLUMN_NAME.STAFF_NAME_1,
    value: COLUMN_NAME.STAFF_NAME_1,
    label: COLUMN_NAME_TH.STAFF_NAME_1,
    minWidth: 160,
  },
  {
    id: 48,
    name: COLUMN_NAME.STAFF_NAME_2,
    value: COLUMN_NAME.STAFF_NAME_2,
    label: COLUMN_NAME_TH.STAFF_NAME_2,
    minWidth: 160,
  },
  {
    id: 49,
    name: COLUMN_NAME.STAFF_NAME_3,
    value: COLUMN_NAME.STAFF_NAME_3,
    label: COLUMN_NAME_TH.STAFF_NAME_3,
    minWidth: 160,
  },
  {
    id: 50,
    name: COLUMN_NAME.STAFF_NAME_4,
    value: COLUMN_NAME.STAFF_NAME_4,
    label: COLUMN_NAME_TH.STAFF_NAME_4,
    minWidth: 160,
  },
  {
    id: 51,
    name: COLUMN_NAME.STAFF_NAME_5,
    value: COLUMN_NAME.STAFF_NAME_5,
    label: COLUMN_NAME_TH.STAFF_NAME_5,
    minWidth: 160,
  },
  {
    id: 52,
    name: COLUMN_NAME.CREATED_AT,
    value: COLUMN_NAME.CREATED_AT,
    label: 'วันที่สร้าง',
    minWidth: 160,
  },
  {
    id: 53,
    name: COLUMN_NAME.CREATED_BY,
    value: COLUMN_NAME.CREATED_BY,
    label: 'สร้างโดย',
    minWidth: 160,
  },
  {
    id: 54,
    name: COLUMN_NAME.UPDATED_AT,
    value: COLUMN_NAME.UPDATED_AT,
    label: 'วันที่แก้ไขล่าสุด',
    minWidth: 160,
  },
  {
    id: 55,
    name: COLUMN_NAME.UPDATED_BY,
    value: COLUMN_NAME.UPDATED_BY,
    label: 'แก้ไขล่าสุดโดย',
    minWidth: 160,
  },
]

export const filterColumnOptions = [
  {
    id: 1,
    name: COLUMN_NAME.COURSE_CODE,
    value: COLUMN_NAME.COURSE_CODE,
    label: 'รหัสหลักสูตร',
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_TEXT,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 2,
    name: COLUMN_NAME.COURSE_VERSION,
    value: COLUMN_NAME.COURSE_VERSION,
    label: 'เวอร์ชัน',
    minWidth: 20,
    align: 'center',
    type: TYPE_OF_VALUE.INPUT_NUMBER,
  },
  {
    id: 3,
    name: COLUMN_NAME.COURSE_NAME_EN,
    value: COLUMN_NAME.COURSE_NAME_EN,
    label: 'ชื่อหลักสูตร (EN)',
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_TEXT,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 4,
    name: COLUMN_NAME.COURSE_NAME_TH,
    value: COLUMN_NAME.COURSE_NAME_TH,
    label: 'ชื่อหลักสูตร (TH)',
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_TEXT,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 5,
    name: COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
    value: COLUMN_NAME.COURSE_NAME_FOR_LEARNER,
    label: 'ชื่อหลักสูตร (สำหรับ Learner)',
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_TEXT,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 6,
    name: COLUMN_NAME.START_DATE,
    value: COLUMN_NAME.START_DATE,
    label: 'วันเริ่มต้น',
    minWidth: 160,
    type: TYPE_OF_VALUE.DATE_RANGE,
  },
  {
    id: 7,
    name: COLUMN_NAME.COUNT_HRS,
    value: COLUMN_NAME.COUNT_HRS,
    label: 'จำนวนชั่วโมง (ไม่รวมพักเที่ยง)',
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_TEXT,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 8,
    name: COLUMN_NAME.CERTIFICATE_NAME_EN,
    value: COLUMN_NAME.CERTIFICATE_NAME_EN,
    label: 'ชื่อ Certificate',
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_TEXT,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 9,
    name: COLUMN_NAME.CERTIFICATE_NAME_TH,
    value: COLUMN_NAME.CERTIFICATE_NAME_TH,
    label: 'E-Certificate Template',
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_TEXT,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 10,
    name: COLUMN_NAME.DISTRIBUTION,
    value: COLUMN_NAME.DISTRIBUTION,
    label: 'ช่องทางการจำหน่าย',
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.DISTRIBUTION,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 11,
    name: COLUMN_NAME.ABBREVIATION_DISTRIBUTION,
    value: COLUMN_NAME.ABBREVIATION_DISTRIBUTION,
    label: 'ชื่อย่อของช่องทางการจำหน่าย',
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.DISTRIBUTION,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 12,
    name: COLUMN_NAME.ROADMAP,
    value: COLUMN_NAME.ROADMAP,
    label: 'กลุ่มหลักสูตร',
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.ROADMAP,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 13,
    name: COLUMN_NAME.ABBREVIATION_ROADMAP,
    value: COLUMN_NAME.ABBREVIATION_ROADMAP,
    label: 'ชื่อย่อของกลุ่มหลักสูตร',
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.ROADMAP,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 14,
    name: COLUMN_NAME.LEVEL_OF_LEARNER,
    value: COLUMN_NAME.LEVEL_OF_LEARNER,
    label: 'ระดับของผู้เรียน',
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.LEVEL_OF_LEARNER,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 15,
    name: COLUMN_NAME.OTHER_LEVEL_OF_LEARNER,
    value: COLUMN_NAME.OTHER_LEVEL_OF_LEARNER,
    label: 'ระดับของผู้เรียนช่องทางการจำหน่ายอื่น',
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.LEVEL_OF_LEARNER,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 16,
    name: COLUMN_NAME.TRAINING_PLATFORM,
    value: COLUMN_NAME.TRAINING_PLATFORM,
    label: 'รูปแบบของการสอน',
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.TRAINING_PLATFORM,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 17,
    name: COLUMN_NAME.ACQUIRED_SKILL,
    value: COLUMN_NAME.ACQUIRED_SKILL,
    label: 'ระดับหลักสูตร',
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.ACQUIRED_SKILL,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 18,
    name: COLUMN_NAME.PRODUCT_TYPE,
    value: COLUMN_NAME.PRODUCT_TYPE,
    label: 'ทักษะที่ได้จากหลักสูตร',
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    masterDataName: COLUMN_NAME.PRODUCT_TYPE,
    fetchOptions: () => fetchMasterDataOptions(),
  },
  {
    id: 19,
    name: COLUMN_NAME.HAS_PREREQUISITE,
    value: COLUMN_NAME.HAS_PREREQUISITE,
    label: 'เงื่อนไขการเข้าอบรม',
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_SINGLE,
    options: isAvailableOptions,
  },
  {
    id: 20,
    name: COLUMN_NAME.COURSE_LEVEL_CONTROL,
    value: COLUMN_NAME.COURSE_LEVEL_CONTROL,
    label: 'ระดับการควบคุมหลักสูตร',
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_MULTI,
    options: courseLevelControlOptions,
  },
  {
    id: 21,
    name: COLUMN_NAME.IS_APPROVED,
    value: COLUMN_NAME.IS_APPROVED,
    label: 'การอนุมัติหลักสูตร',
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_SINGLE,
    options: approvalOptions,
  },
  {
    id: 22,
    name: COLUMN_NAME.HAS_BREAK_MEAL,
    value: COLUMN_NAME.HAS_BREAK_MEAL,
    label: 'การเตรียมอาหาร Break / Lunch',
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_SINGLE,
    options: isAvailableOptions,
  },
  {
    id: 23,
    name: COLUMN_NAME.BREAK_MEAL_BUDGET,
    value: COLUMN_NAME.BREAK_MEAL_BUDGET,
    label: 'ค่าใช้จ่ายต่อหัว',
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_NUMBER,
  },
  {
    id: 24,
    name: COLUMN_NAME.REQUIRE_OIC_EXAM,
    value: COLUMN_NAME.REQUIRE_OIC_EXAM,
    label: 'ผลสอบใบอนุญาตตัวแทน',
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_SINGLE,
    options: requireOptions,
  },
  {
    id: 25,
    name: COLUMN_NAME.AGENT_CONFIRMATION,
    value: COLUMN_NAME.AGENT_CONFIRMATION,
    label: 'ขั้นตอนการคอนเฟิร์มตัวแทน',
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_SINGLE,
    options: isAvailableOptions,
  },
  {
    id: 26,
    name: COLUMN_NAME.REQUIRE_DOCUMENT,
    value: COLUMN_NAME.REQUIRE_DOCUMENT,
    label: 'เอกสารการสมัคร',
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_SINGLE,
    options: requireOptions,
  },
  {
    id: 27,
    name: COLUMN_NAME.DOCUMENT_LIST,
    value: COLUMN_NAME.DOCUMENT_LIST,
    label: 'รายการเอกสาร',
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_TEXT,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 28,
    name: COLUMN_NAME.IS_ATTENDANCE,
    value: COLUMN_NAME.IS_ATTENDANCE,
    label: 'เกณฑ์การเข้าเรียน',
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_SINGLE,
    options: isAttendanceOptions,
  },
  {
    id: 29,
    name: COLUMN_NAME.ATTENDANCE_COUNT,
    value: COLUMN_NAME.ATTENDANCE_COUNT,
    label: 'จำนวนการเช็คชื่อ',
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_NUMBER,
  },
  {
    id: 30,
    name: COLUMN_NAME.ATTENDANCE_CRITERIA,
    value: COLUMN_NAME.ATTENDANCE_CRITERIA,
    label: 'เกณฑ์ผ่าน (จำนวนครั้งของการเข้าเรียนทั้งหมด)',
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_NUMBER,
  },
  {
    id: 31,
    name: COLUMN_NAME.QR_CODE_TIME,
    value: COLUMN_NAME.QR_CODE_TIME,
    label: 'อายุของ QR Code',
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_NUMBER,
  },
  {
    id: 32,
    name: COLUMN_NAME.HAS_DOCUMENT,
    value: COLUMN_NAME.HAS_DOCUMENT,
    label: 'เอกสารประกอบการเรียนสำหรับผู้เข้าอบรม',
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_SINGLE,
    options: isAvailableOptions,
  },
  {
    id: 33,
    name: COLUMN_NAME.PRE_TEST_NAME,
    value: COLUMN_NAME.PRE_TEST_NAME,
    label: 'แบบทดสอบ (Pre-Test)',
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_TEXT,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 34,
    name: COLUMN_NAME.POST_TEST_NAME,
    value: COLUMN_NAME.POST_TEST_NAME,
    label: 'แบบทดสอบ (Post-Test)',
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_TEXT,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 35,
    name: COLUMN_NAME.ASSESSMENT_NAME,
    value: COLUMN_NAME.ASSESSMENT_NAME,
    label: 'แบบทดสอบ (Assessment)',
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_TEXT,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 36,
    name: COLUMN_NAME.OTHER_TEST_NAME,
    value: COLUMN_NAME.OTHER_TEST_NAME,
    label: 'แบบทดสอบ (Other)',
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_TEXT,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 37,
    name: COLUMN_NAME.EVALUATION_NAME,
    value: COLUMN_NAME.EVALUATION_NAME,
    label: 'แบบประเมิน',
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_TEXT,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 38,
    name: COLUMN_NAME.STATUS,
    value: COLUMN_NAME.STATUS,
    label: 'สถานะ',
    minWidth: 160,
    type: TYPE_OF_VALUE.DROPDOWN_SINGLE,
    options: statusOptions,
  },
  {
    id: 39,
    name: COLUMN_NAME.STAFF_NAME_LIST,
    value: COLUMN_NAME.STAFF_NAME_LIST,
    label: 'ชื่อผู้จัดทำ',
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_TEXT,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 40,
    name: COLUMN_NAME.CREATED_AT,
    value: COLUMN_NAME.CREATED_AT,
    label: 'วันที่สร้าง',
    minWidth: 160,
    type: TYPE_OF_VALUE.DATE_RANGE,
  },
  {
    id: 41,
    name: COLUMN_NAME.CREATED_BY,
    value: COLUMN_NAME.CREATED_BY,
    label: 'สร้างโดย',
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_TEXT,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
  {
    id: 42,
    name: COLUMN_NAME.UPDATED_AT,
    value: COLUMN_NAME.UPDATED_AT,
    label: 'วันที่แก้ไขล่าสุด',
    minWidth: 160,
    type: TYPE_OF_VALUE.DATE_RANGE,
  },
  {
    id: 43,
    name: COLUMN_NAME.UPDATED_BY,
    value: COLUMN_NAME.UPDATED_BY,
    label: 'แก้ไขล่าสุดโดย',
    minWidth: 160,
    type: TYPE_OF_VALUE.INPUT_TEXT,
    fetchOptions: () => fetchOptionsReportByKey(),
  },
]
