import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

import TextInput from '../../../components/Input/TextInput'
import DatePicker from '../../../components/Input/DatePicker'

const Personal = (props) => {
  const { formik, onDateChange, checkPermission } = props

  return (
    <>
      <Card
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          minWidth: 275,
          pt: 2,
          px: 3,
        }}
      >
        <Typography variant="h6">ข้อมูลส่วนตัว</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, mt: 2 }}>
          <DatePicker
            id="birthOfDate"
            name="birthOfDate"
            labelText="วัน/เดือน/ปี เกิด"
            boxSx={{ width: '50%' }}
            onChange={(value) => onDateChange(value, 'birthOfDate')}
            value={formik.values.birthOfDate}
            textError={formik?.errors?.birthOfDate}
            disabledInput={!checkPermission ? true : false}
          />
          <TextInput
            id="nationality"
            name="nationality"
            labelText="สัญชาติ"
            type="basic"
            disabled={!checkPermission ? true : false}
            boxSx={{ width: '50%' }}
            textError={formik?.errors?.nationality}
            value={formik.values.nationality}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
          <TextInput
            required
            maxlength={13}
            id="idCardNo"
            name="idCardNo"
            labelText="เลขบัตรประจำตัวประชาชน"
            type="basic"
            disabled={!checkPermission ? true : false}
            boxSx={{ width: '50%' }}
            textError={formik?.errors?.idCardNo}
            value={formik.values.idCardNo}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
          <TextInput
            id="address"
            name="address"
            labelText="ที่อยู่ปัจจุบัน"
            type="basic"
            disabled={!checkPermission ? true : false}
            multiline={true}
            rows={2}
            textError={formik?.errors?.address}
            value={formik.values.address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Box>
      </Card>
    </>
  )
}
export default Personal
