import _ from 'lodash'
import * as reducerTable from '../../../../redux/slices/table'
import {
  setLearnerList,
  setStopLoading,
  setStartLoading,
} from '../../../../redux/slices/manageClassAttendance'
import {
  getLearnerList,
  postDataAttendance,
} from '../../../../services/attendance'
import { headCellsAttendanceList } from '../../../../constants/manageClass'
import { setMapListOptions } from '../../../../utils/lib'
import { revertDate } from '../Header/DropDownButton/ContentDialog/events'

export const fetchDataList = (props) => async (dispatch) => {
  const { page, table, uuid, date, round, searchText } = props

  dispatch(setStartLoading())
  dispatch(reducerTable.startLoading())
  dispatch(reducerTable.setSearchText(searchText))

  const sortBy = table.sort
  const realPage = page <= 0 ? 1 : +page
  const body = {
    trainingPlanUuid: uuid,
    limit: Number(table.limit),
    page: realPage,
    order:
      sortBy === 'updatedAt' ? 'ASC' : table.order.toString().toUpperCase(),
    date: revertDate(date),
    round: Number(round),
    sort: sortBy === 'updatedAt' ? 'codeId' : sortBy,
    learnerName: searchText,
  }

  const data = await Promise.all([
    dispatch(postDataAttendance(body)),
    dispatch(getLearnerList(uuid)),
  ])
  dispatch(
    reducerTable.setInitialTable({
      rows: mapDataTable(_.get(data, '[0].payload.result', [])),
      allCount: _.get(data, '[0].payload.totalCount', 0),
      headCells: headCellsAttendanceList,
      placeholder: 'ค้นหา',
      searchKey: 'name',
      isCheckBox: false,
      handleSearch: (text) =>
        dispatch(
          handleQuickSearch({
            ...props,
            searchText: text,
          })
        ),
    })
  )

  dispatch(
    setLearnerList(
      setMapListOptions(_.get(data, '[1].payload.result', []), 'firstNameTH')
    )
  )
  dispatch(reducerTable.stopLoading())
  dispatch(setStopLoading())
}

export const handleQuickSearch = (props) => (dispatch) => {
  dispatch(reducerTable.setSearchText(props.searchText))
  dispatch(fetchDataList(props))
}

export const mapDataTable = (list) => {
  if (!list.length) return []
  return list.map((item) => {
    return {
      ...item,
      uuid: _.get(item, 'learnerUuid', ''),
    }
  })
}
