import { Box, Typography } from '@mui/material'
import React from 'react'
import SelectColumnSection from '../../components/SelectColumnSection'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { handleAddFilter } from '../event'
import { COLUMN_NAME } from '../../../../constants/reportCourseSetting/reportCourseSetting'
import _ from 'lodash'

const AddFilterSection = () => {
  const dispatch = useDispatch()
  const { columnOptions } = useSelector(
    (state) => ({
      columnOptions: state.reportCourseSetting.columnOptions,
    }),
    shallowEqual,
  )

  const filteredColumns = _.filter(
    columnOptions,
    (col) =>
      col.name !== COLUMN_NAME.NO &&
      col.name !== COLUMN_NAME.COURSE_PURPOSE &&
      col.name !== COLUMN_NAME.COURSE_OUTLINE &&
      col.name !== COLUMN_NAME.STAFF_ID_LIST,
  )

  return (
    <Box sx={{ p: 3, pt: 0, display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h6">เพิ่มตัวกรอง</Typography>
      <SelectColumnSection
        hasTwoBoxes={true}
        columnOptions={filteredColumns}
        handleAdd={() => dispatch(handleAddFilter())}
      />
    </Box>
  )
}

export default AddFilterSection
