import { Box, Divider, Typography } from '@mui/material'
import { CustomInput } from '../../../../../../../../components/CRUD/components/CustomInput'
import { CustomDropdown } from '../../../../../../../../components/CRUD/components/CustomDropdown'
import CustomSwitchInLine from '../../../../../../../../components/CRUD/components/CustomSwitchInLine'
import {
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../../../../../utils/lib'
import { regex } from '../../../../../../../../components/CRUD/enum/regex'
import { handleInputNumber } from '../../../../../../../../components/CRUD/handler/handleInputNumber'

import React from 'react'

const form = () => {
  return (
    <>
      <Box>
        <Divider sx={{ my: 2 }} />
        <Typography variant="body1b">ข้อมูลรหัสทรัพย์สิน</Typography>
        <Box display="flex" gap={3} alignItems="start" mt={2}>
          <CustomInput
            required
            fieldName="skuNoForm"
            labelName="รหัสทรัพย์สิน"
            placeholder="ระบุ รหัสทรัพย์สิน"
          />
          <CustomInput
            required
            fieldName="unitPriceForm"
            labelName="มูลค่าต่อหน่วย (฿)"
            placeholder="ระบุมูลค่าต่อหน่วย (฿)"
            inputProps={{
              'data-testid': 'input-quantity',
              onInput: (e) => {
                handleInputNumber(e, regex.NUMBER)
              },
              onKeyDown: (e) => {
                handleNumberKeyDown(e, ['.'])
              },
              onPaste: handlePasteFormat,
            }}
          />
        </Box>
        <Box>
          <CustomDropdown
            required
            labelName="Cost center"
            fieldName="costCenterForm"
            placeholder="เลือก Cost center"
            boxSx={{ width: '49%' }}
          />
        </Box>
        <Box>
          <CustomSwitchInLine
            fieldName="skuStatusForm"
            title="สถานะ รหัสทรัพย์สิน"
          />
        </Box>
        <Divider sx={{ my: 2 }} />
      </Box>
    </>
  )
}

export default form
