import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Close from '@mui/icons-material/Close'
import { Box, Button, Typography, IconButton } from '@mui/material'
import {
  StyledContentDrawer,
  StyledDrawer,
  StyledFooter,
  StyledHeadLabel,
} from '../Styled'
import { setIsOpenManageColumnDrawer } from '../../../redux/slices/reportCourseSetting/List'
import MainTabs from './components/MainTabs'
import { handleConfirmCancelDraft } from '../event'
import { handleSaveColumns } from './event'

const ManageColumnDrawer = () => {
  const dispatch = useDispatch()
  const { isOpenManageColumnDrawer, disabledAddBtn } = useSelector(
    (state) => ({
      isOpenManageColumnDrawer:
        state.reportCourseSetting.isOpenManageColumnDrawer,
      disabledAddBtn: state.reportCourseSetting.disabledAddBtn,
    }),
    shallowEqual,
  )

  return (
    <StyledDrawer
      open={isOpenManageColumnDrawer}
      onClose={() => dispatch(setIsOpenManageColumnDrawer(false))}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">จัดการคอลัมน์</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setIsOpenManageColumnDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
      </StyledHeadLabel>
      <StyledContentDrawer>
        <MainTabs />
      </StyledContentDrawer>
      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => dispatch(handleConfirmCancelDraft())}
        >
          ยกเลิก
        </Button>
        <Button
          data-testid="btn-submit"
          variant="contained"
          disabled={!disabledAddBtn}
          onClick={() => {
            dispatch(setIsOpenManageColumnDrawer(false))
            dispatch(handleSaveColumns())
          }}
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default ManageColumnDrawer
