export const arrayResult = [
  { label: 'ผ่าน', name: 'passChecked' },
  { label: 'ไม่ผ่าน', name: 'failChecked' },
]

export const arrayStatus = [
  { label: 'ประกาศสิทธิ์แล้ว', name: 'eligibleChecked' },
  { label: 'รอยืนยันตัวตน', name: 'waitConfirmChecked' },
  { label: 'รอประกาศสิทธิ์', name: 'waitAnnounceChecked' },
  { label: 'รอตรวจสอบเอกสาร', name: 'waitCheckChecked' },
  { label: 'เอกสารไม่ผ่าน', name: 'rejectedChecked' },
  { label: 'ยกเลิกการสมัคร', name: 'cancelChecked' },
  { label: 'ถูกยกเลิกการสมัคร', name: 'canceledChecked' },
  { label: 'ไม่มีสิทธิ์เข้าเรียน', name: 'ineligibleChecked' },
  { label: 'ยกเลิกคลาส', name: 'cancelClassChecked' },
]

export const arrayCheckbox = [
  {
    label: 'ชื่อผู้สมัคร',
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    placeholder: 'ค้นหาชื่อ-นามสกุล',
    type: 'text',
  },

  {
    label: 'การเข้าเรียน',
    nameCheckBox: 'attendanceChecked',
    nameInput: 'startDate',
    dateStateStart: 'startDate',
    dateStateFinish: 'finishDate',
    dateStateDisplay: 'displayDate',
    placeholder: 'วันที่เข้าเรียน',
    isDate: true,
  },
  {
    label: 'ผลการอบรม',
    nameCheckBox: 'trainingResultChecked',
    nameInput: 'trainingResult',
    type: 'checkbox',
    list: arrayResult,
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: arrayStatus,
  },
]

export const defaultFilter = {
  codeIdChecked: false,
  codeId: '',
  nameChecked: false,
  name: '',
  attendanceChecked: false,
  attendance: '',
  trainingResultChecked: false,
  trainingResult: {
    passChecked: false,
    failChecked: false,
  },
  statusChecked: false,
  startDate: '',
  finishDate: '',
  status: {
    eligibleChecked: false,
    waitConfirmChecked: false,
    waitAnnounceChecked: false,
    waitCheckChecked: false,
    rejectedChecked: false,
    cancelChecked: false,
    canceledChecked: false,
  },
}
