import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { StyledDialog, StyledInputImport } from '../Styled'
import Button from '@mui/material/Button'
import { onUploadTemplate, handleSubmitAdd } from './events'
import _ from 'lodash'
import { closeDialog } from '../../../redux/slices/dialog'
import {
  StyledDialogActions,
  StyledCancelButton,
  StyledLoadingButton,
} from '../../../components/redux/PopUpDialog/Styled'
import { IMPORT_EXAMINER_TYPE } from '../../../constants/eExamination'

const ImportExaminerTemplateDialog = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const { importExaminerFile, isLoading, disableButton, importExaminerType } =
    useSelector(
      (state) => ({
        importExaminerFile: state.eExamList.importExaminerFile,
        isLoading: state.dialog.isLoading,
        disableButton: state.dialog.disableButton,
        importExaminerType: state.eExamList.importExaminerType,
      }),
      shallowEqual,
    )

  return (
    <>
      <Box id="dialog-header">
        <Typography variant="h6">
          {importExaminerType === IMPORT_EXAMINER_TYPE.ANNOUNCEMENT
            ? 'นำเข้า TEMPLATE รายชื่อผู้มีสิทธิ์สอบ'
            : 'นำเข้า TEMPLATE ผลสอบ'}
        </Typography>
      </Box>
      <StyledDialog>
        <Box>
          <label
            style={{ display: 'flex', gap: 16, alignItems: 'center' }}
            htmlFor="import-template"
          >
            <Button variant="contained" size="s" component="span">
              เลือกไฟล์
            </Button>
            <StyledInputImport
              data-testid="import-template"
              id="import-template"
              accept=".xlsx, .xls, .csv"
              type="file"
              onChange={(e) => dispatch(onUploadTemplate(e))}
            />
            <Typography variant="body2" color="text.secondary">
              {_.get(importExaminerFile, 'fileName')}
            </Typography>
          </label>
        </Box>
        <StyledDialogActions sx={{ p: 0 }}>
          {!isLoading && (
            <StyledCancelButton
              data-testid="btn-disagree"
              onClick={() => dispatch(closeDialog())}
              color="primary"
              endIcon=""
            >
              ยกเลิก
            </StyledCancelButton>
          )}
          <StyledLoadingButton
            data-testid="btn-agree"
            loading={isLoading}
            onClick={() =>
              dispatch(
                handleSubmitAdd(
                  uuid,
                  _.get(importExaminerFile, 'file'),
                  importExaminerType,
                ),
              )
            }
            color="primary"
            disabled={disableButton}
            endIcon={<></>}
          >
            บันทึก
          </StyledLoadingButton>
        </StyledDialogActions>
      </StyledDialog>
    </>
  )
}
export default ImportExaminerTemplateDialog
