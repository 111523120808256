import { store } from '../../../../../App'
import { convertNumberWithComma } from '../../../../../utils/lib'
import _ from 'lodash'
import { setRows } from '../../../../../redux/slices/table'

export const handleSetDataRows = () => {
  const { rows } = store.getState().table.table
  const modifiedRows = _.defaultTo(rows, []).map((item) => {
    const total = item?.total ? convertNumberWithComma(item?.total) : '-'
    const available = item?.available
      ? convertNumberWithComma(item?.available)
      : '-'
    const stockValue = item?.stockValue
      ? convertNumberWithComma(item?.stockValue)
      : '-'
    return {
      ...item,
      total,
      available,
      stockValue,
    }
  })
  store.dispatch(setRows(modifiedRows))
}
