import { validatePermission } from '../../../../../utils/lib'
import { crudConfig } from '../../crudConfig'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../../constants/stock'

export const headerCells = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermissionDelete = validatePermission({
    user: user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.VM],
  })

  return [
    {
      id: 'status',
      label: 'สถานะ',
      width: '20px',
      hideSortIcon: true,
    },
    {
      id: 'id',
      label: 'ID',
      width: '30px',
      disablePadding: false,
    },
    {
      id: 'name',
      label: 'ชื่อบริษัท',
      disablePadding: false,
    },
    {
      id: 'station',
      label: 'Station',
      disablePadding: false,
    },
    {
      id: 'vendorType',
      label: 'ประเภท Vendor',
      disablePadding: false,
    },
    {
      id: 'action',
      disablePadding: false,
      label: 'คำสั่ง',
      width: '90px',
      hideSortIcon: true,
      viewPath: `${crudConfig.modulePath}/view`,
      editPath: `${crudConfig.modulePath}/form`,
      hideDelete: hasPermissionDelete ? false : true,
    },
  ]
}
