// import { path } from './path'

export const headCells = [
  {
    id: 'no',
    label: 'No',
    isCustom: true,
    customWidth: '20px',
  },
  {
    id: 'productName',
    label: 'ชื่อทรัพย์สิน',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'quantity',
    label: 'จำนวนทรัพย์สิน',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'unitPrice',
    label: 'มูลค่าต่อหน่วย (฿)',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'stockValue',
    label: 'มูลค่ารวม (฿)',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'actionTransferForm',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '50%',
    hideSortIcon: true,
    // viewPath: `${path.catalog}/view`,
    hideView: true,
    hideDelete: false,
  },
]

export const headCellsSKU = [
  {
    id: 'no',
    label: 'No',
    isCustom: true,
    customWidth: '20px',
  },
  {
    id: 'productName',
    label: 'ชื่อทรัพย์สิน',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'skuNo',
    label: 'รหัสทรัพย์สิน',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'costCenterName',
    label: 'Cost Center',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'unitPrice',
    label: 'มูลค่าต่อหน่วย (฿)',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'actionTransferForm',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '50%',
    hideSortIcon: true,
    // viewPath: `${path.catalog}/view`,
    hideView: true,
    hideDelete: false,
    // editPath: `${path.inventory}/transfer/edit`,
  },
]

export const headCellsAsset = [
  {
    id: 'no',
    label: 'No',
    isCustom: true,
    customWidth: '20px',
  },
  {
    id: 'productName',
    label: 'ชื่อทรัพย์สิน',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'skuNo',
    label: 'รหัสทรัพย์สิน',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'costCenterName',
    label: 'Cost Center',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'assetNo',
    label: 'Item Name',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'unitPrice',
    label: 'มูลค่าต่อหน่วย (฿)',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'contractStartDate',
    label: 'วันเริ่มต้นสัญญา',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'contractEndDate',
    label: 'วันสิ้นสุดสัญญา',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'renewContractDate',
    label: 'วันต่อสัญญา',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'ownerFName',
    label: 'ชื่อผู้ถือครอง',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'ownerLName',
    label: 'สกุลผู้ถือครอง',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'ownerEmail',
    label: 'Email ผู้ถือครอง',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'actionTransferForm',
    disablePadding: false,
    label: 'คำสั่ง',
    hideSortIcon: true,
    // viewPath: `${path.catalog}/view`,
    hideView: true,
    hideDelete: false,
  },
]

export const headCellsLicense = [
  {
    id: 'no',
    label: 'No',
    isCustom: true,
    customWidth: '20px',
  },
  {
    id: 'productName',
    label: 'ชื่อทรัพย์สิน',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'skuNo',
    label: 'รหัสทรัพย์สิน',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'costCenterName',
    label: 'Cost Center',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'unitPrice',
    label: 'มูลค่าต่อหน่วย (฿)',
  },
  {
    id: 'contractStartDate',
    label: 'วันเริ่มต้นสัญญา',
  },
  {
    id: 'contractEndDate',
    label: 'วันสิ้นสุดสัญญา',
  },
  {
    id: 'renewContractDate',
    label: 'วันต่อสัญญา',
  },
  {
    id: 'ownerFName',
    label: 'ชื่อผู้ถือครอง',
  },
  {
    id: 'ownerLName',
    label: 'สกุลผู้ถือครอง',
  },
  {
    id: 'ownerEmail',
    label: 'Email ผู้ถือครอง',
  },
  {
    id: 'payer.name',
    label: 'ชื่อผู้ชำระเงิน',
  },
  {
    id: 'payerLName',
    label: 'สกุลผู้ชำระเงิน',
  },
  {
    id: 'payerEmail',
    label: 'Email ผู้ชำระเงิน',
  },
  {
    id: 'paymentType',
    label: 'วิธีชำระเงิน',
  },
  {
    id: 'paymentDesc',
    label: 'รายละเอียด/ชื่อบัตร',
  },
  {
    id: 'actionTransferForm',
    disablePadding: false,
    label: 'คำสั่ง',
    hideSortIcon: true,
    // viewPath: `${path.catalog}/view`,
    hideView: true,
    hideDelete: false,
  },
]

export const headCellsFormNotImportModule = [
  {
    id: 'no',
    label: 'No',
    isCustom: true,
    customWidth: '20px',
  },
  {
    id: 'productName',
    label: 'ชื่อทรัพย์สิน',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'quantity',
    label: 'จำนวนทรัพย์สิน',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'actionTransferForm',
    disablePadding: false,
    label: 'คำสั่ง',
    hideSortIcon: true,
    // viewPath: `${path.catalog}/view`,
    hideView: true,
    hideDelete: false,
  },
]

export const headCellsFormNotImportModuleSKU = [
  {
    id: 'no',
    label: 'No',
    isCustom: true,
    customWidth: '20px',
  },
  {
    id: 'productName',
    label: 'ชื่อทรัพย์สิน',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'skuNo',
    label: 'รหัสทรัพย์สิน',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'actionTransferForm',
    disablePadding: false,
    label: 'คำสั่ง',
    hideSortIcon: true,
    // viewPath: `${path.catalog}/view`,
    hideView: true,
    hideDelete: false,
  },
]

export const headCellsNotImportModule = [
  {
    id: 'no',
    label: 'No',
    isCustom: true,
    customWidth: '20px',
  },
  {
    id: 'productName',
    label: 'ชื่อทรัพย์สิน',
    isCustom: true,
    customWidth: '180px',
  },
  {
    id: 'quantity',
    label: 'จำนวนทรัพย์สิน',
    isCustom: true,
    customWidth: '180px',
  },
]
