import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTrainingPlatform } from '../../../services/manageClass'
import FilterDrawer from './FilterDrawer'
import Header from './Header'
import Table from './Table'
import { LoadingPage, StyledContent } from './Styled'

const Index = ({ event = null }) => {
  const { filter, table } = useSelector((state) => state.table)
  const { isFilterDrawer } = filter
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTrainingPlatform())
  }, [])

  return (
    <Box>
      <StyledContent isLoading={table.isLoading}>
        {!table.isLoading && <Header />}
        <Table event={event} />
      </StyledContent>
      <FilterDrawer open={isFilterDrawer} />
      <LoadingPage isLoading={table.isLoading}></LoadingPage>
    </Box>
  )
}

export default Index
