import React, { useState, useEffect } from 'react'
import { styled } from '@mui/system'
import Typography from '@mui/material/Typography'
import _ from 'lodash'

import { themeColor } from '../../../utils/themeData'

export const CourseList = ({ list }) => {
  if (list.length) {
    return list.map((item, index) => {
      if (!_.isEmpty(item)) {
        return <li key={index}>{item}</li>
      } else {
        return 'ไม่ได้ระบุ'
      }
    })
  } else {
    return <>ไม่ได้ระบุ</>
  }
}

const validateEmpText = (text) => {
  return text !== '' ? text : 'ไม่ได้ระบุ'
}

const TableView = (props) => {
  const { initialValues } = props
  const [list, setList] = useState({
    educational: [],
    workHistory: [],
    institute: [],
    experienced: [],
    training: [],
    lecturer: [],
  })
  useEffect(() => {
    if (initialValues) {
      setList((prev) => ({
        ...prev,
        educational: initialValues?.educational || [],
        workHistory: initialValues?.workHistory || [],
        institute: initialValues?.institute || [],
        experienced: initialValues?.experienced || [],
        training: initialValues?.training || [],
        lecturer: initialValues?.lecturer || [],
      }))
    }
  }, [initialValues])

  return (
    <>
      <Typography variant="body1b" sx={{ ml: 3 }}>
        การศึกษาระดับสูงสุด
      </Typography>
      <Table sx={{ ml: 3 }}>
        <RowHead>
          <Col sx={{ width: '20%' }}>
            <Typography variant="body1b">สถานศึกษา (มหาวิทยาลัย)</Typography>
          </Col>
          <Col sx={{ width: '10%' }}>
            <Typography variant="body1b">ระหว่าง</Typography>
          </Col>
          <Col sx={{ width: '30%' }}>
            <Typography variant="body1b">วุฒิการศึกษา</Typography>
          </Col>
          <Col sx={{ width: '20%' }}>
            <Typography variant="body1b">สาขา</Typography>
          </Col>
          <Col sx={{ width: '10%' }}>
            <Typography variant="body1b">เกรดเฉลี่ย</Typography>
          </Col>
        </RowHead>
        {list.educational.length > 0 &&
          list.educational.map((item, index) => {
            return (
              <Row key={index}>
                <Col sx={{ width: '20%' }}>
                  <Typography>{validateEmpText(item.institution)}</Typography>
                </Col>
                <Col sx={{ width: '10%' }}>
                  <Typography>{validateEmpText(item.between)}</Typography>
                </Col>
                <Col sx={{ width: '30%' }}>
                  <Typography>{validateEmpText(item.education)}</Typography>
                </Col>
                <Col sx={{ width: '20%' }}>
                  <Typography>{validateEmpText(item.branch)}</Typography>
                </Col>
                <Col sx={{ width: '10%' }}>
                  <Typography>{validateEmpText(item.GPA)}</Typography>
                </Col>
              </Row>
            )
          })}
      </Table>

      <Typography variant="body1b" sx={{ ml: 3, mt: 1.5 }}>
        ประวัติการทำงาน
      </Typography>
      <Table sx={{ ml: 3 }}>
        <RowHead>
          <Col sx={{ width: '30%' }}>
            <Typography variant="body1b">ชื่อองค์กร</Typography>
          </Col>
          <Col sx={{ width: '30%' }}>
            <Typography variant="body1b">ตำแหน่ง (ฝ่าย)</Typography>
          </Col>
          <Col sx={{ width: '30%' }}>
            <Typography variant="body1b">ลักษณะธุรกิจ</Typography>
          </Col>
          <Col sx={{ width: '10%' }}>
            <Typography variant="body1b">ระหว่าง</Typography>
          </Col>
        </RowHead>
        {list.workHistory.length > 0 &&
          list.workHistory.map((item, index) => {
            return (
              <Row key={index}>
                <Col sx={{ width: '30%' }}>
                  <Typography>{validateEmpText(item.organization)}</Typography>
                </Col>
                <Col sx={{ width: '30%' }}>
                  <Typography>{validateEmpText(item.position)}</Typography>
                </Col>
                <Col sx={{ width: '30%' }}>
                  <Typography>{validateEmpText(item.business)}</Typography>
                </Col>
                <Col sx={{ width: '10%' }}>
                  <Typography>{validateEmpText(item.between)}</Typography>
                </Col>
              </Row>
            )
          })}
      </Table>

      <Table sx={{ ml: 3 }}>
        <RowHead>
          <Col>
            <Typography variant="body1b">สรุปประสบการณ์การทำงาน</Typography>
          </Col>
        </RowHead>
        {list.experienced.length > 0 &&
          list.experienced.map((item, index) => {
            return (
              <Row key={index}>
                <Col sx={{ width: '90%' }}>
                  {item.uuid === 'cb13bd07-9d4d-41a3-9d84-d3be2df74e4e' ? (
                    <Typography>{validateEmpText(item.detail)}</Typography>
                  ) : (
                    <Typography>{validateEmpText(item.experienced)}</Typography>
                  )}
                </Col>
                <Col textAlign="right" sx={{ width: '10%' }}>
                  <Typography>{`${validateEmpText(item.amount)} ${
                    item.unit
                  }`}</Typography>
                </Col>
              </Row>
            )
          })}
      </Table>
      <Typography variant="body1b" sx={{ ml: 3, mt: 1.5, width: '90%' }}>
        ประสบการณ์ด้านฝึกอบรมและการพัฒนา
      </Typography>
      <Table sx={{ ml: 3 }}>
        <RowHead>
          <Col sx={{ width: '40%' }}>
            <Typography variant="body1b">สถาบัน</Typography>
          </Col>
          <Col sx={{ width: '10%' }}>
            <Typography variant="body1b">ปีที่อบรม</Typography>
          </Col>
          <Col sx={{ width: '50%' }}>
            <Typography variant="body1b">หลักสูตร</Typography>
          </Col>
        </RowHead>
        {list.training.length > 0 &&
          list.training.map((item, index) => {
            return (
              <Row key={index}>
                <Col sx={{ width: '40%' }}>
                  <Typography>{validateEmpText(item.institution)}</Typography>
                </Col>
                <Col sx={{ width: '10%' }}>
                  <Typography>{validateEmpText(item.year)}</Typography>
                </Col>
                <Col sx={{ width: '50%' }}>
                  <CourseList list={item.course} />
                </Col>
              </Row>
            )
          })}
      </Table>

      <Typography variant="body1b" sx={{ ml: 3, mt: 1.5 }}>
        ประสบการณ์ด้านการเป็นวิทยากรในธุรกิจประกันชีวิต
      </Typography>
      <Table sx={{ ml: 3 }}>
        <RowHead>
          <Col sx={{ width: '40%' }}>
            <Typography variant="body1b">สถาบัน</Typography>
          </Col>
          <Col sx={{ width: '10%' }}>
            <Typography variant="body1b">ปีที่อบรม</Typography>
          </Col>
          <Col sx={{ width: '50%' }}>
            <Typography variant="body1b">หลักสูตร</Typography>
          </Col>
        </RowHead>
        {list.lecturer.length > 0 &&
          list.lecturer.map((item, index) => {
            return (
              <Row key={index}>
                <Col sx={{ width: '40%' }}>
                  <Typography>{validateEmpText(item.institution)}</Typography>
                </Col>
                <Col sx={{ width: '10%' }}>
                  <Typography>{validateEmpText(item.year)}</Typography>
                </Col>
                <Col sx={{ width: '50%' }}>
                  <CourseList list={item.course} />
                </Col>
              </Row>
            )
          })}
      </Table>

      <Typography variant="body1b" sx={{ ml: 3, mt: 1.5 }}>
        คุณวุฒิวิชาชีพ และ ใบอนุญาต (Certified Program)
      </Typography>
      <Table sx={{ ml: 3 }}>
        <RowHead>
          <Col sx={{ width: '35%' }}>
            <Typography variant="body1b">คุณวุฒิวิชาชีพ และใบอนุญาต</Typography>
          </Col>
          <Col sx={{ width: '35%' }}>
            <Typography variant="body1b">สถาบัน</Typography>
          </Col>
          <Col sx={{ width: '30%' }}>
            <Typography variant="body1b">เลขที่ทะเบียน / ใบอนุญาต</Typography>
          </Col>
        </RowHead>
        {list.institute.length > 0 &&
          list.institute.map((item, index) => {
            return (
              <Row key={index}>
                <Col sx={{ width: '35%' }}>
                  <Typography>{validateEmpText(item.institute)}</Typography>
                </Col>
                <Col sx={{ width: '35%' }}>
                  <Typography>{validateEmpText(item.qualification)}</Typography>
                </Col>
                <Col sx={{ width: '30%' }}>
                  <Typography>{validateEmpText(item.license)}</Typography>
                </Col>
              </Row>
            )
          })}
      </Table>
    </>
  )
}
export default TableView
const Table = styled('table')(() => ({
  borderSpacing: 0,
}))

const RowHead = styled('tr')({
  width: '100%',
  display: 'flex',
  backgroundColor: `${themeColor.background.blue}`,
})

const Row = styled('tr')(() => ({
  display: 'flex',
}))

const Col = styled('td')({
  width: '100%',
  padding: '0.5%',
  border: `0.1px solid #DBE4F1`,
  position: 'relative',
})
