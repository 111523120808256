import { StyledCardDrawer, StyledColumn } from '../../../Styled'
import { Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { onDragEnd } from '../../event'
import ColumnList from './ColumnList'

const DragDropColumnCard = () => {
  const dispatch = useDispatch()
  const { displayColumns, tabActiveColDrawer } = useSelector(
    (state) => ({
      displayColumns: state.reportCourseSetting.displayColumns,
      tabActiveColDrawer: state.reportCourseSetting.tabActiveColDrawer,
    }),
    shallowEqual,
  )

  return (
    <StyledCardDrawer>
      {tabActiveColDrawer === 1 && (
        <Typography variant="h6">รายการคอลัมน์</Typography>
      )}
      {displayColumns?.length > 0 && (
        <StyledColumn>
          <Typography variant="body2">จัดเรียง</Typography>
          <DragDropContext onDragEnd={(e) => dispatch(onDragEnd(e))}>
            <Droppable droppableId="tabs">
              {(provided) => <ColumnList provided={provided} />}
            </Droppable>
          </DragDropContext>
        </StyledColumn>
      )}
    </StyledCardDrawer>
  )
}

export default DragDropColumnCard
