import React from 'react'
import { StyledColumn, StyledRow } from '../Styled'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { Button } from '@mui/material'
import ValueOfColumn from '../FilterDrawer/components/ValueOfColumn'
import ColumnSelect from './ColumnSelect'
import { setColumnSelected } from '../../../redux/slices/reportCourseSetting/List'

const SelectColumnSection = ({
  hasTwoBoxes = false,
  columnOptions = [],
  handleAdd = () => {},
}) => {
  const dispatch = useDispatch()
  const { columnSelected, disabledAddBtn } = useSelector(
    (state) => ({
      columnSelected: state.reportCourseSetting.columnSelected,
      disabledAddBtn: state.reportCourseSetting.disabledAddBtn,
    }),
    shallowEqual,
  )

  return (
    <StyledColumn>
      <StyledRow sx={{ gap: 3, alignItems: 'stretch' }}>
        <ColumnSelect
          hasTwoBoxes={hasTwoBoxes}
          columnSelected={columnSelected}
          options={columnOptions}
          handleChange={(e) => {
            let value = _.get(e, 'target.id', '')
            if (value.length === 0) {
              value = null
            }
            dispatch(setColumnSelected(value))
          }}
        />
        {hasTwoBoxes && <ValueOfColumn />}
      </StyledRow>
      <Button
        sx={{ maxWidth: 105 }}
        variant="contained"
        size="s"
        disabled={disabledAddBtn}
        onClick={handleAdd}
      >
        เพิ่มในรายการ
      </Button>
    </StyledColumn>
  )
}

export default SelectColumnSection
