import _ from 'lodash'
import dayjs from 'dayjs'
import {
  resetTablePage,
  setFilterProp,
  setSearchText,
  setFilterTotal,
  setToggleFilterDrawer,
} from '../../../redux/slices/table'

import {
  fetchRoomRequestFilter,
  fetchMeetingRoomFilter,
} from '../../RoomManagement/BookingRoomSection/event'
import { ROOM_AVAILABLE } from '../../../constants/roomManagement'
import { setFilter } from '../../../redux/slices/roomManagement/Dashboard'

export const onFilterClick =
  ({ filter, station, typeFilter, stationUuid }) =>
  (dispatch) => {
    dispatch(setSearchText(''))
    const roomStatusList = []
    const statusList = []
    const stationList = []
    const roomFunctionList = []
    const roomTypeList = []

    let body = {
      stationUuid,
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      roomName: '',
      operator: '',
      status: [],
      limit: -1,
      page: 1,
    }

    const filterStarDate = _.get(filter, 'startDate', '')
    const filterEndDate = _.get(filter, 'endDate', '')

    if (filter?.dateChecked) {
      body = {
        ...body,
        startDate: !filterStarDate
          ? ''
          : dayjs(filter.startDate).format('YYYY-MM-DD'),
        endDate: !filterEndDate
          ? ''
          : dayjs(filter.endDate).format('YYYY-MM-DD'),
      }
    }
    if (filter?.dateChecked && filter?.dateRange) {
      body = {
        ...body,
        startTime: filter?.startTime ?? '',
        endTime: filter?.endTime ?? '',
      }
    }

    if (filter?.roomNameChecked) {
      body = {
        ...body,
        roomName: _.get(filter, 'roomName', ''),
      }
    }
    if (filter?.operatorChecked) {
      body = {
        ...body,
        operator: _.get(filter, 'operator', ''),
      }
    }

    if (filter?.roomStatusChecked) {
      if (filter.roomStatus?.inProgressChecked) {
        statusList.push('IN_PROGRESS')
      }
      if (filter.roomStatus?.completedChecked) {
        statusList.push('COMPLETED', 'CONFIRM')
      }
      if (filter.roomStatus?.cancelChecked) {
        statusList.push('CANCEL')
      }
      if (typeFilter === 'meetingRoom') {
        if (filter.roomStatus?.allAvailableChecked) {
          roomStatusList.push('ALL_AVAILABLE')
        }
        if (filter.roomStatus?.notAvailableChecked) {
          roomStatusList.push('NOT_AVAILABLE')
        }
        if (filter.roomStatus?.someTimeAvailableChecked) {
          roomStatusList.push('SOME_TIME_AVAILABLE')
          if (filter.roomStatus?.timeRange) {
            body = {
              ...body,
              startTime: filter?.startTime ?? '00:00',
              endTime: filter?.endTime ?? '23:59',
            }
          }
        }
        body = {
          ...body,
          roomStatus: roomStatusList,
        }
      }
      body = {
        ...body,
        status: statusList,
      }
    }

    if (filter.stationChecked) {
      for (const key in filter?.station) {
        if (!filter.station[key]) continue
        const item = station.find((e) => e.name == key)
        stationList.push(_.get(item, 'value', ''))
      }
      body = {
        ...body,
        station: stationList,
      }
    }

    if (filter.roomFunctionChecked) {
      if (filter.roomFunction?.singleChecked) {
        roomFunctionList.push('SINGLE_ROOM')
      }
      if (filter.roomFunction?.connectingChecked) {
        roomFunctionList.push('CONNECTING_ROOM')
      }
      if (filter.roomFunction?.roomFunctionOtherChecked) {
        roomFunctionList.push('OTHER')
      }
      body = {
        ...body,
        roomFunction: roomFunctionList,
      }
    }

    if (filter.roomTypeChecked) {
      if (filter.roomType?.classroomChecked) {
        roomTypeList.push('CLASSROOM')
      }
      if (filter.roomType?.computerRoomChecked) {
        roomTypeList.push('COMPUTER_ROOM')
      }
      if (filter.roomType?.meetingRoomChecked) {
        roomTypeList.push('MEETING_ROOM')
      }
      if (filter.roomType?.roomTypeOtherChecked) {
        roomTypeList.push('OTHER')
      }
      body = {
        ...body,
        roomType: roomTypeList,
      }
    }

    let roomStatusDefault = [
      ROOM_AVAILABLE.ALL_AVAILABLE,
      ROOM_AVAILABLE.SOME_TIME_AVAILABLE,
      ROOM_AVAILABLE.NOT_AVAILABLE,
    ]

    if (
      filter?.dateChecked &&
      !filter?.roomStatusChecked &&
      !filter.roomStatus?.notAvailableChecked
    ) {
      roomStatusDefault = roomStatusDefault.filter(
        (item) => item !== 'NOT_AVAILABLE',
      )
    }

    const filterText = {
      stationUuid: filter.stationChecked ? stationList : [stationUuid],
      roomName: filter.roomNameChecked ? filter.roomName : '',
      roomStatus: filter.roomStatusChecked ? roomStatusList : roomStatusDefault,
      roomFunction: filter.roomFunctionChecked ? roomFunctionList : [],
      roomType: filter.roomTypeChecked ? roomTypeList : [],
      status: filter.statusChecked ? statusList : [],
    }

    dispatch(
      setFilterProp({
        ...filterText,
        ...body,
      }),
    )

    dispatch(setFilterTotal(filter))
    dispatch(resetTablePage())
    if (typeFilter === 'roomRequest') {
      dispatch(fetchRoomRequestFilter(body))
    } else {
      body = {
        ...body,
        ...filterText,
      }
      dispatch(fetchMeetingRoomFilter(body))
    }
  }

export const handleFiltering =
  ({ table, filter, station, manageProps, typeFilter, stationUuid }) =>
  (dispatch) => {
    dispatch(setToggleFilterDrawer(false))
    dispatch(
      onFilterClick({
        table,
        filter,
        station,
        manageProps,
        typeFilter,
        stationUuid,
      }),
    )
  }

export const handleChange =
  ({ key, value, filter, listKey }) =>
  (dispatch) => {
    let objState = {
      ...filter,
      [key]: value,
    }
    if (!_.isNil(listKey)) {
      objState = {
        ...filter,
        [listKey]: {
          ...filter[listKey],
          [key]: value,
        },
      }
    }
    if (key === 'timeRange' && !value) {
      objState = {
        ...filter,
        startTime: '',
        endTime: '',
        [listKey]: {
          ...filter[listKey],
          [key]: value,
        },
      }
    }

    dispatch(setFilter(objState))
  }

export const handleSelectDate = (selectedDates, filter) => (dispatch) => {
  let objState = {
    ...filter,
    startDate: selectedDates.startDate,
    endDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }
  dispatch(setFilter(objState))
}
