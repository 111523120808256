import React from 'react'
import RadioButtonCheckedOutlined from '@mui/icons-material/RadioButtonCheckedOutlined'
import CheckBoxRounded from '@mui/icons-material/CheckBoxRounded'
import ShortTextRounded from '@mui/icons-material/ShortTextRounded'
import HighlightAlt from '@mui/icons-material/HighlightAlt'
import Rule from '@mui/icons-material/Rule'
import GridView from '@mui/icons-material/GridView'
import FormatListNumbered from '@mui/icons-material/FormatListNumbered'

import { path } from './path'

export const eTestingPermission = {
  E_TESTING_SETTING: 'E_TESTING_SETTING',
  E_TESTING_EXAM: 'E_TESTING_EXAM'
}

export const headCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'codeId',
    disablePadding: false,
    label: 'ID',
    width: '80px',
  },
  {
    id: 'name',
    label: 'ชื่อแบบทดสอบ',
    disablePadding: false,
  },
  {
    id: 'typeOfETesting',
    label: 'ประเภท',
    disablePadding: false,
  },
  {
    id: 'numberOfVersions',
    label: 'จำนวนเวอร์ชั่น',
    disablePadding: false,
  },
  {
    id: 'updatedBy',
    label: 'แก้ไขล่าสุดโดย',
    disablePadding: false,
  },
  {
    id: 'actionE',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideEdit: true,
    hideDelete: true,
    hideSortIcon: true,
    viewPath: path.eTesting,
  },
]

export const versionHeadCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'version',
    disablePadding: false,
    label: 'เวอร์ชั่น',
    width: '80px',
  },
  {
    id: 'name',
    label: 'ชื่อแบบทดสอบ',
    disablePadding: false,
  },
  {
    id: 'startDate',
    label: 'วันเริ่มต้นใช้งาน',
    disablePadding: false,
  },
  {
    id: 'updatedBy',
    label: 'แก้ไขล่าสุดโดย',
    disablePadding: false,
  },
  {
    id: 'versionStatus',
    label: 'สถานะ',
    disablePadding: false,
  },
  {
    id: 'actionEVersion',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideDelete: true,
    hideSortIcon: true,
    viewPath: `${path.eTesting}/version/view`,
    editPath: `${path.eTesting}/version/edit`,
    module: 'E_TESTING_SETTING',
  },
]

export const versionStatus = {
  ACTIVE: 'เปิดใช้งาน',
  INACTIVE: 'ปิดใช้งาน',
  WAITING: 'รอเปิดใช้งาน',
  DELETED: 'ถูกลบแล้ว',
  DRAFT: 'ฉบับร่าง',
  INPROGRESS: 'ไม่พร้อมใช้งาน'
}

export const typeOfETesting = {
  ONLINE_TEST: 'Online test',
  ASSESSMENT: 'Assessment',
  STANDALONE: 'Standalone',
}

export const questionType = {
  SINGLE: 'Single Choice',
  MULTIPLE: 'Multiple Choice',
  FREE_TEXT: 'Free Text',
  RATING: 'Rating',
  TRUE_FALSE: 'True/False',
  SEQUENCE: 'Sequence',
  MATCHING: 'MATCHING',
  FILL_IN_BLANK_CHOICE: 'Fill in blank (Choice)',
  FILL_IN_BLANK_DRAG: 'Fill in blank (Drag)',
}

export const questionTypeString = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
  FREE_TEXT: 'FREE_TEXT',
  TRUE_FALSE: 'TRUE_FALSE',
  SEQUENCE: 'SEQUENCE',
  MATCHING: 'MATCHING',
  FILL_IN_BLANK_CHOICE: 'FILL_IN_BLANK_CHOICE',
  FILL_IN_BLANK_DRAG: 'FILL_IN_BLANK_DRAG',
  TYPE_FILL: 'FILL_IN_BLANK',
}

export const listPosition = [
  {
    label: 'Left align',
    value: 'LEFT',
  },
  {
    label: 'Center align',
    value: 'CENTER',
  },
  {
    label: 'Right align',
    value: 'RIGHT',
  },
]

export const listChoice = [
  {
    label: 'Single Choice',
    value: 'SINGLE',
  },
  {
    label: 'Multiple Choice',
    value: 'MULTIPLE',
  },
  {
    label: 'Sequence',
    value: 'SEQUENCE',
  },
  {
    label: 'Free Text',
    value: 'FREE_TEXT',
  },
  {
    label: 'Matching',
    value: 'MATCHING',
  },
  {
    label: 'True/False',
    value: 'TRUE_FALSE',
  },
  {
    label: 'Fill in blank (Drag)',
    value: 'FILL_IN_BLANK_DRAG',
  },
  {
    label: 'Fill in blank (Choice)',
    value: 'FILL_IN_BLANK_CHOICE',
  },
]

export const defaultValue = {
  defaultTap: {
    testing: 'testing',
    setting: 'setting',
  },
  typeOfETesting: {
    onlineTest: 'ONLINE_TEST',
    assessment: 'ASSESSMENT',
    standalone: 'STANDALONE',
  },
  position: {
    left: 'LEFT',
    rihht: 'CENTER',
    center: 'RIGHT',
  },
  questionType: {
    single: 'SINGLE',
    multiple: 'MULTIPLE',
    freeText: 'FREE_TEXT',
    sequence: 'SEQUENCE',
    trueFalse: 'TRUE_FALSE',
    matching: 'MATCHING',
    fillInBlankChoice: 'FILL_IN_BLANK_CHOICE',
    fillInBlankDrag: 'FILL_IN_BLANK_DRAG',
  },
  mediaType: {
    image: 'VIDEO',
    video: 'IMAGE',
  },
  //setting
  displayQuestion: {
    all: 'ALL',
    one: 'ONE',
  },
  testResult: {
    now: 'NOW',
    later: 'LATER',
  },
  resultType: {
    showTrueFalse: 'SHOW_TRUE_FALSE',
    showFalseOnly: 'SHOW_FALSE_ONLY',
  },
  //preview
  defaultSection: {
    testing: 'TESTING',
    result: 'RESULT',
  },
}

export const optionQuestion = [
  {
    labelText: 'Single Choice',
    icon: <RadioButtonCheckedOutlined color="action" />,
    value: 'SINGLE',
  },
  {
    labelText: 'Multiple Choice',
    icon: <CheckBoxRounded color="action" />,
    value: 'MULTIPLE',
  },
  {
    labelText: 'Sequence',
    icon: <FormatListNumbered color="action" />,
    value: 'SEQUENCE',
  },
  {
    labelText: 'Free Text',
    icon: <ShortTextRounded color="action" />,
    value: 'FREE_TEXT',
  },
  {
    labelText: 'Matching',
    icon: <GridView color="action" />,
    value: 'MATCHING',
  },
  {
    labelText: 'True/False',
    icon: <Rule color="action" />,
    value: 'TRUE_FALSE',
  },
  {
    labelText: 'Fill in blank (Choice)',
    icon: <HighlightAlt color="action" />,
    value: 'FILL_IN_BLANK_CHOICE',
  },
  {
    labelText: 'Fill in blank (Drag)',
    icon: <HighlightAlt color="action" />,
    value: 'FILL_IN_BLANK_DRAG',
  },
]

export const optionSwap = [
  {
    label: 'ก่อน',
    value: 'BEFORE',
  },
  {
    label: 'หลัง',
    value: 'AFTER',
  },
]

export const headCellsHistory = [
  {
    id: 'historyName',
    label: 'ผู้แก้ไข',
    disablePadding: false,
  },
  {
    id: 'historyDate',
    label: 'วันเวลาที่แก้ไข',
    disablePadding: false,
  },
]

export const errorMessageField = {
  DATE: 'DATE',
  NAME: 'NAME',
  TITLE1: 'TITLE1',
  TITLE2: 'TITLE2',
  DESCRIPTION: 'DESCRIPTION',
  COVER_TEXT: 'COVER_TEXT',
  QUESTION: 'QUESTION',
  ANSWER: 'ANSWER',
  ANSWER_QUESTION: 'ANSWER_QUESTION',
  CORRECT: 'CORRECT',
  SUB_QUESTION: 'SUB_QUESTION',
  SUB_ANSWER: 'SUB_ANSWER',
  CHOICE_NUM: 'CHOICE_NUM',
  SUB_TITLE: 'SUB_TITLE',
}

export const tooltipText = `• เปิดใช้งาน เพื่อให้หลักสูตรนำไปใช้ได้
• ปิดใช้งาน จะไม่มีผลย้อนหลัง กับหลักสูตร หรือคลาสที่เลือกไปใช้`

export const standaloneCheckingState = {
  WAITING: 'WAITING',
  DONE: 'DONE',
  NO_SUBMIT: 'NO_SUBMIT',
}

export const checkingStateColor = {
  WAITING: 'secondary.main',
  DONE: 'text.silver'
}

export const alphabets = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'AA',
  'AB',
  'AC',
  'AD',
  'AE',
  'AF',
  'AG',
  'AH',
  'AI',
  'AJ',
  'AK',
  'AL',
  'AM',
  'AN',
  'AO',
  'AP',
  'AQ',
  'AR',
  'AS',
  'AT',
  'AU',
  'AV',
  'AW',
  'AX',
  'AY',
  'AZ',
  'BA',
  'BB',
  'BC',
  'BD',
  'BE',
  'BF',
  'BG',
  'BH',
  'BI',
  'BJ',
  'BK',
  'BL',
  'BM',
  'BN',
  'BO',
  'BP',
  'BQ',
  'BR',
  'BS',
  'BT',
  'BU',
  'BV',
  'BW',
  'BX',
  'BY',
  'BZ',
  'CA',
  'CB',
  'CC',
  'CD',
  'CE',
  'CF',
  'CG',
  'CH',
  'CI',
  'CJ',
  'CK',
  'CL',
  'CM',
  'CN',
  'CO',
  'CP',
  'CQ',
  'CR',
  'CS',
  'CT',
  'CU',
  'CV',
  'CW',
  'CX',
  'CY',
  'CZ',
  'DA',
  'DB',
  'DC',
  'DD',
  'DE',
  'DF',
  'DG',
  'DH',
  'DI',
  'DJ',
  'DK',
  'DL',
  'DM',
  'DN',
  'DO',
  'DP',
  'DQ',
  'DR',
  'DS',
  'DT',
  'DU',
  'DV',
  'DW',
  'DX',
  'DY',
  'DZ',
  'EA',
  'EB',
  'EC',
  'ED',
  'EE',
  'EF',
  'EG',
  'EH',
  'EI',
  'EJ',
  'EK',
  'EL',
  'EM',
  'EN',
  'EO',
  'EP',
  'EQ',
  'ER',
  'ES',
  'ET',
  'EU',
  'EV',
  'EW',
  'EX',
  'EY',
  'EZ',
  'FA',
  'FB',
  'FC',
  'FD',
  'FE',
  'FF',
  'FG',
  'FH',
  'FI',
  'FJ',
  'FK',
  'FL',
  'FM',
  'FN',
  'FO',
  'FP',
  'FQ',
  'FR',
  'FS',
  'FT',
  'FU',
  'FV',
  'FW',
  'FX',
  'FY',
  'FZ',
  'GA',
  'GB',
  'GC',
  'GD',
  'GE',
  'GF',
  'GG',
  'GH',
  'GI',
  'GJ',
  'GK',
  'GL',
  'GM',
  'GN',
  'GO',
  'GP',
  'GQ',
  'GR',
  'GS',
  'GT',
  'GU',
  'GV',
  'GW',
  'GX',
  'GY',
  'GZ',
  'HA',
  'HB',
  'HC',
  'HD',
  'HE',
  'HF',
  'HG',
  'HH',
  'HI',
  'HJ',
  'HK',
  'HL',
  'HM',
  'HN',
  'HO',
  'HP',
  'HQ',
  'HR',
  'HS',
  'HT',
  'HU',
  'HV',
  'HW',
  'HX',
  'HY',
  'HZ',
  'HA',
  'HB',
  'HC',
  'HD',
  'HE',
  'HF',
  'HG',
  'HH',
  'HI',
  'HJ',
  'HK',
  'HL',
  'HM',
  'HN',
  'HO',
  'HP',
  'HQ',
  'HR',
  'HS',
  'HT',
  'HU',
  'HV',
  'HW',
  'HX',
  'HY',
  'HZ',
]
