import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import _ from 'lodash'

export const handleViewUpdatedAt = () => {
  const { formData } = store.getState().crud
  const createdBy = _.get(formData, 'createdBy', '')
  const updatedBy = _.get(formData, 'updatedBy', '')
  setUpdatedAt('createdBy', createdBy)
  setUpdatedAt('updatedBy', updatedBy)
}

const  setUpdatedAt = (key, value) => {
  if (typeof value === 'string') {
    store.dispatch(
      setFieldValue({
        key: `formData.${key}`,
        value: value ?? '-',
      }),
    )
  } else if (
    typeof value === 'object' &&
    value !== null &&
    !Array.isArray(value)
  ) {
    store.dispatch(
      setFieldValue({
        key: `formData.${key}`,
        value: value?.name ?? '-',
      }),
    )
  }
}
