import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import styled from '@mui/system/styled'
export const BoxContent = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
  gap: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))

export const BoxTable = styled(Box)(({ theme }) => ({
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    gap: theme.spacing(2),
  },
}))

export const TableCheckbox = styled(Checkbox)(
  ({ theme, isChecked, checkedStatus, isApprove }) => ({
    color:
      isChecked && !checkedStatus
        ? theme?.palette?.error?.main
        : isApprove
        ? theme?.palette?.success?.main
        : theme?.palette?.error?.main,
    '&.Mui-checked': {
      color:
        isChecked && !checkedStatus
          ? theme?.palette?.error?.main
          : isApprove
          ? theme?.palette?.success?.main
          : theme?.palette?.error?.main,
    },
  })
)
