import React, { useState, useEffect, useRef } from 'react'
import {
  LoadingPage,
  StyledCard,
  StyledCardBoxPreview,
  StyledContent,
  StyledLayoutPreview,
  StyledLayoutPreviewBox,
  StyledModalHeader,
} from './Styled'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Close from '@mui/icons-material/Close'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import Edit from '@mui/icons-material/Edit'
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined'
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'
import { eCertificateMapObj, LayoutPreview, onDownload } from './event'
import { useContainerDimensions } from '../ECertificationForm/event'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useLocalStorage from '@rehooks/local-storage'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '1028px',
  backgroundColor: 'background.paper',
  boxShadow: '24',
  borderRadius: '8px',
  p: 3,
}

const ECertificationView = ({
  eCertificationVersionUuid,
  eCertification,
  formik,
  setInitialValues,
  setOpen,
  disabled,
  englishCertificateName,
  certificateLanguage,
  setInitECertificationVersionUuid,
}) => {
  const [version, setVersion] = useState()
  const [openModal, setOpenModal] = useState(false)
  const handleOpen = () => setOpenModal(true)
  const handleClose = () => setOpenModal(false)
  const componentRef = useRef()
  const { width } = useContainerDimensions(componentRef)
  useEffect(() => {
    if (!_.isEmpty(eCertificationVersionUuid)) {
      const filter = _.filter(
        eCertification,
        (ec) => ec.uuid === eCertificationVersionUuid
      )
      const version = _.get(filter, '[0]')
      setVersion(version)
    }
  }, [eCertificationVersionUuid])

  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.eCertificateForm.isLoading,
    }),
    shallowEqual
  )

  const handleChange = (name, uuid) => {
    setInitialValues((prev) => ({ ...prev, [`${name}`]: uuid }))
    formik.setFieldValue(name, uuid)
  }

  return (
    <StyledCard>
      <StyledCardBoxPreview>
        {!_.isEmpty(version) && (
          <LayoutPreview
            version={version}
            englishCertificateName={englishCertificateName}
            certificateLanguage={certificateLanguage}
            width={159}
          />
        )}
      </StyledCardBoxPreview>
      <StyledLayoutPreview>
        <Typography color="text.primary" variant="body1">
          E-Certificate
        </Typography>
        <StyledLayoutPreviewBox>
          <Box>
            <Button
              variant="contained"
              size="m"
              onClick={() => handleOpen()}
              startIcon={<RemoveRedEye />}
              disabled={disabled}
            >
              ดูตัวอย่าง
            </Button>
            <Button
              variant="outlined"
              size="m"
              sx={{ ml: 3 }}
              onClick={() => {
                setOpen(true)
                handleChange('eCertificationVersion', null)
                handleChange('certificateLanguage', certificateLanguage)
              }}
              disabled={disabled}
              startIcon={<Edit />}
            >
              แก้ไข
            </Button>
          </Box>
          <IconButton
            color="primary"
            component="span"
            disabled={disabled}
            onClick={() => {
              setOpen(false)
              setInitECertificationVersionUuid(null)
              handleChange('eCertificationVersion', null)
              handleChange('certificateLanguage', null)
              handleChange('expireECertificationYear', null)
            }}
          >
            <DeleteTwoTone color="action" />
          </IconButton>
        </StyledLayoutPreviewBox>
      </StyledLayoutPreview>

      <Box
        sx={{
          width: '80%',
          position: 'absolute',
          maxWidth: '1028px',
          top: -1,
        }}
        ref={componentRef}
      />

      <ModalCertification
        isLoading={isLoading}
        openModal={openModal}
        handleClose={handleClose}
        version={version}
        englishCertificateName={englishCertificateName}
        eCertificationVersionUuid={eCertificationVersionUuid}
        certificateLanguage={certificateLanguage}
        width={width}
      />
    </StyledCard>
  )
}

export const ModalCertification = ({
  isLoading,
  openModal,
  handleClose,
  version,
  englishCertificateName,
  certificateLanguage,
  eCertificationVersionUuid,
  width,
}) => {
  const dispatch = useDispatch()
  const [user] = useLocalStorage('user')

  return (
    <Modal open={openModal} onClose={handleClose}>
      <Box sx={style}>
        <StyledContent isLoading={isLoading}>
          <StyledModalHeader>
            <Typography variant="h5" color="text.primary">
              ตัวอย่าง E-Certificate
            </Typography>
            <Box>
              <Button
                variant="contained"
                onClick={() => {
                  const isThai =
                    _.get(version, 'layout.uuid', '') ===
                    '1a1dad0e-da84-408d-a11c-f623999b2c4f'
                  const mapObj = eCertificateMapObj(
                    englishCertificateName,
                    user,
                    isThai,
                    certificateLanguage
                  )
                  dispatch(onDownload(eCertificationVersionUuid, mapObj))
                }}
                size="m"
                sx={{ mr: 3 }}
                startIcon={<FileDownloadOutlined />}
              >
                ดาวน์โหลด
              </Button>
              <IconButton
                color="primary"
                component="span"
                onClick={() => handleClose()}
              >
                <Close color="action" />
              </IconButton>
            </Box>
          </StyledModalHeader>

          {!_.isEmpty(version) && (
            <LayoutPreview
              englishCertificateName={englishCertificateName}
              certificateLanguage={certificateLanguage}
              version={version}
              width={width - 50}
            />
          )}
        </StyledContent>
        <LoadingPage isLoading={isLoading} />
      </Box>
    </Modal>
  )
}

export default ECertificationView
