export const arrayRoomFunction = [
  {
    label: 'Single',
    name: 'singleChecked',
  },
  {
    label: 'Connecting',
    name: 'connectingChecked',
  },
  {
    label: 'Other',
    name: 'roomFunctionOtherChecked',
  },
]

export const arrayRoomType = [
  {
    label: 'Classroom',
    name: 'classroomChecked',
  },
  {
    label: 'Computer Room',
    name: 'computerRoomChecked',
  },
  {
    label: 'Meeting Room',
    name: 'meetingRoomChecked',
  },
  {
    label: 'Other',
    name: 'roomTypeOtherChecked',
  },
]

export const arrayRoomLayout = [
  {
    label: 'Classroom',
    name: 'classroomChecked',
  },
  {
    label: 'Group',
    name: 'groupChecked',
  },
  {
    label: 'U Shape',
    name: 'uShapeChecked',
  },
  {
    label: 'Theater',
    name: 'theaterChecked',
  },
]

export const arrayStatusMeetingRoom = [
  {
    label: 'ว่างทั้งวัน',
    name: 'allAvailableChecked',
  },
  {
    label: 'ว่างบางช่วงเวลา',
    name: 'someTimeAvailableChecked',
    list: [
      {
        label: 'ค้นหาเวลา',
        name: 'timeRange',
        type: 'time',
      },
    ],
    type: 'checkbox',
  },
  {
    label: 'ไม่ว่าง',
    name: 'notAvailableChecked',
  },
]

export const arrayStatusRoomRequest = [
  {
    label: 'In Progress',
    name: 'inProgressChecked',
  },
  {
    label: 'Completed',
    name: 'completedChecked',
  },
  {
    label: 'Cancel',
    name: 'cancelChecked',
  },
]

export const arrayCheckboxMeetingRoom = [
  {
    label: 'สถานะห้องตอนนี้',
    nameCheckBox: 'roomStatusChecked',
    nameInput: 'roomStatus',
    type: 'checkbox',
    list: arrayStatusMeetingRoom,
  },
  {
    label: 'วันที่ใช้ห้อง',
    nameCheckBox: 'dateChecked',
    nameInput: 'dateRange',
    placeholder: 'วันเริ่มต้น - วันสิ้นสุด',
    type: 'dateRange',
  },
  {
    label: 'ชื่อห้อง',
    nameCheckBox: 'roomNameChecked',
    nameInput: 'roomName',
    placeholder: 'ค้นหาชื่อห้อง',
    type: 'text',
  },
  {
    label: 'Station',
    nameCheckBox: 'stationChecked',
    nameInput: 'station',
    placeholder: 'ค้นหาชื่อห้อง',
    type: 'checkbox',
    list: [],
  },
  {
    label: 'ฟังก์ชันห้อง',
    nameCheckBox: 'roomFunctionChecked',
    nameInput: 'roomFunction',
    type: 'checkbox',
    list: arrayRoomFunction,
  },
  {
    label: 'ประเภทห้อง',
    nameCheckBox: 'roomTypeChecked',
    nameInput: 'roomType',
    type: 'checkbox',
    list: arrayRoomType,
  },
  {
    label: '',
    nameCheckBox: '',
    nameInput: 'startTime',
    type: 'hidden',
  },
  {
    label: '',
    nameCheckBox: '',
    nameInput: 'endTime',
    type: 'hidden',
  },
]

export const arrayCheckboxRoomRequest = [
  {
    label: 'วันที่ใช้ห้อง',
    nameCheckBox: 'dateChecked',
    nameInput: 'dateRange',
    placeholder: 'วันเริ่มต้น - วันสิ้นสุด',
    type: 'dateRange',
    list: [
      {
        label: 'ค้นหาเวลา',
        name: 'timeRange',
        type: 'time',
      },
    ],
  },
  {
    label: 'ชื่อห้อง',
    nameCheckBox: 'roomNameChecked',
    nameInput: 'roomName',
    placeholder: 'ค้นหาชื่อห้อง',
    type: 'text',
  },
  {
    label: 'ผู้ดำเนินการ',
    nameCheckBox: 'operatorChecked',
    nameInput: 'operator',
    placeholder: 'ค้นหาผู้ดำเนินการ',
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'roomStatusChecked',
    nameInput: 'roomStatus',
    type: 'checkbox',
    list: arrayStatusRoomRequest,
  },
  {
    label: '',
    nameCheckBox: '',
    nameInput: 'startTime',
    type: 'hidden',
  },
  {
    label: '',
    nameCheckBox: '',
    nameInput: 'endTime',
    type: 'hidden',
  },
]

export const defaultFilterRoomRequest = {
  roomNameChecked: false,
  operatorChecked: false,
  dateChecked: false,
  roomStatusChecked: false,
  roomStatus: {
    inProgressChecked: false,
    completedChecked: false,
    cancelChecked: false,
  },
  startDate: '',
  endDate: '',
}

export const defaultFilterMeetingRoom = {
  stationChecked: false,
  roomNameChecked: false,
  dateChecked: false,
  roomFunctionChecked: false,
  roomTypeChecked: false,
  roomStatusChecked: false,
  roomFunction: {
    singleChecked: false,
    connectingChecked: false,
    roomFunctionOtherChecked: false,
  },
  roomType: {
    classroomChecked: false,
    computerRoomChecked: false,
    meetingRoomChecked: false,
    roomTypeOtherChecked: false,
  },
  roomStatus: {
    allAvailableChecked: false,
    someTimeAvailableChecked: false,
    notAvailableChecked: false,
  },
  startDate: '',
  endDate: '',
}
