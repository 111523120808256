import { useLocalStorage } from '@rehooks/local-storage'
import { validatePermission } from '../../../../../utils/lib'
import CloseIcon from '@mui/icons-material/Close'
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone'
import Button from '@mui/material/Button'
import appHistory from '../../../../../appHistory'
import { crudConfig } from '../../crudConfig'
import EditIcon from '@mui/icons-material/Edit'
import { Box } from '@mui/system'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { useDispatch, useSelector } from 'react-redux'
import { viewTypeEnum } from '../enum/viewTypeEnum'
import { handleChangeStatus } from '../../../../../modules/Inventory/Requisition/View/handler/handlerChangeStatus'
import { statusValueEnum } from '../enum/statusEnum'
import {
  INVENTORY_SETTING,
  RequisitionStatus,
  STOCK_PERMISSION,
} from '../../../../../constants/stock'
import _ from 'lodash'
import { setAssets } from '../handler/setAssets'

export function ActionComponent({ uuid, formData }) {
  const [user] = useLocalStorage('user')
  const hasAdminPermission = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN],
  })
  const hasCreatePermission = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.CREATE],
  })
  const hasMpPermission = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.MP],
  })

  const dispatch = useDispatch()
  const { viewType } = useSelector((state) => state.crud)
  const isSameUser = user.uuid === _.get(formData, 'createdByInfo.uuid')
  const staffStationUuid = _.get(user, 'stationUuid', '')
  const toStationUuid = _.get(formData, 'warehouse.station.uuid', '')
  const checkStaffStationMatched = staffStationUuid === toStationUuid
  const checkPermissionObj = {
    formData,
    viewType,
    isSameUser,
    hasAdminPermission,
    hasMpPermission,
    checkStaffStationMatched,
    hasCreatePermission,
  }

  return (
    <Box display="flex" flexDirection="row" gap={3}>
      {viewType !== viewTypeEnum.RETURN && (
        <>
          <Button
            startIcon={<RestoreTwoToneIcon />}
            variant="outlined"
            onClick={() =>
              dispatch(
                setFieldValue({ key: 'isShowHistoryDrawer', value: true }),
              )
            }
          >
            ประวัติ
          </Button>
        </>
      )}

      {(isSameUser || hasAdminPermission) && (
        <>
          {(viewType === viewTypeEnum.WAITING_REQUISITION_APPROVE ||
            viewType === viewTypeEnum.WAITING_RETURN_APPROVE ||
            viewType === viewTypeEnum.WAITING_APPROVE ||
            viewType === viewTypeEnum.WAITING_REQUISITION_COMFIRM) && (
            <>
              <Button
                startIcon={<EditIcon />}
                variant="outlined"
                onClick={() => {
                  if (viewType === viewTypeEnum.WAITING_RETURN_APPROVE) {
                    dispatch(
                      setFieldValue({
                        key: 'viewType',
                        value: viewTypeEnum.RETURN,
                      }),
                    )
                  } else {
                    appHistory.push(crudConfig.modulePath + '/form/' + uuid)
                  }
                }}
                data-testid="btn-edit"
              >
                แก้ไข
              </Button>
              <Button
                startIcon={<CloseIcon />}
                variant="contained"
                onClick={() =>
                  handleChangeStatus(uuid, RequisitionStatus.REJECTED)
                }
              >
                ยกเลิกรายการ
              </Button>
            </>
          )}

          {viewType === viewTypeEnum.RETURN && (
            <>
              <Button
                variant="outlined"
                onClick={() =>
                  handleChangeStatus(uuid, RequisitionStatus.DRAFT, {
                    ...formData,
                    products: _.get(formData, 'assets', []),
                  })
                }
              >
                บันทึกฉบับร่าง
              </Button>
              <Button
                variant="contained"
                onClick={() =>
                  handleChangeStatus(
                    uuid,
                    statusValueEnum.WAITING_RETURN_APPROVE,
                    {
                      ...formData,
                      products: _.get(formData, 'assets', []),
                    },
                  )
                }
              >
                ยืนยันคืนทรัพย์สิน
              </Button>
            </>
          )}

          {viewType === viewTypeEnum.WAITING_RETURN && (
            <Button
              variant="contained"
              onClick={() => {
                dispatch(
                  setFieldValue({
                    key: 'viewType',
                    value: viewTypeEnum.RETURN,
                  }),
                )
                setAssets()
              }}
            >
              คืนทรัพย์สิน
            </Button>
          )}
        </>
      )}

      {(viewType === viewTypeEnum.WAITING_REQUISITION_APPROVE ||
        viewType === viewTypeEnum.WAITING_RETURN_APPROVE ||
        viewType === viewTypeEnum.WAITING_APPROVE ||
        viewType === viewTypeEnum.WAITING_REQUISITION_COMFIRM) && (
        <>
          {checkRejectAndApproveButtons({
            ...checkPermissionObj,
            buttonType: 'REJECT',
          }) && (
            <>
              <Button
                startIcon={<CloseIcon />}
                variant="outlined"
                onClick={() =>
                  handleChangeStatus(uuid, RequisitionStatus.REJECTED)
                }
              >
                ปฏิเสธ
              </Button>
            </>
          )}
        </>
      )}
      {(viewType === viewTypeEnum.WAITING_REQUISITION_APPROVE ||
        viewType === viewTypeEnum.WAITING_APPROVE ||
        viewType === viewTypeEnum.WAITING_REQUISITION_COMFIRM) && (
        <>
          {checkRejectAndApproveButtons({
            ...checkPermissionObj,
            buttonType: 'APPROVE_REQUISITION',
          }) && (
            <>
              <Button
                variant="contained"
                onClick={() =>
                  handleChangeStatus(uuid, RequisitionStatus.WAITING_RETURN)
                }
              >
                อนุมัติรายการเบิก
              </Button>
            </>
          )}
        </>
      )}

      {viewType === viewTypeEnum.WAITING_RETURN_APPROVE && (
        <>
          {checkRejectAndApproveButtons({
            ...checkPermissionObj,
            buttonType: 'APPROVE_RETURN',
          }) && (
            <>
              <Button
                variant="contained"
                onClick={() =>
                  handleChangeStatus(uuid, statusValueEnum.COMPLETED, {
                    ...formData,
                    products: _.get(formData, 'assets', []),
                  })
                }
              >
                อนุมัติคืนทรัพย์สิน
              </Button>
            </>
          )}
        </>
      )}
    </Box>
  )
}

export const checkRejectAndApproveButtons = (props) => {
  const {
    formData,
    viewType,
    isSameUser,
    hasAdminPermission,
    hasMpPermission,
    hasCreatePermission,
    checkStaffStationMatched,
    buttonType,
  } = props
  const monthlyPlan = formData?.monthlyPlan
  const status = viewType
  const isToInventory = hasMpPermission && hasCreatePermission
  const isMP = hasMpPermission
  let isShow = false
  if (hasAdminPermission) {
    isShow = true
  } else if (isToInventory) {
    if (['REJECT', 'APPROVE_REQUISITION'].includes(buttonType)) {
      if (
        [
          RequisitionStatus.WAITING_REQUISITION_APPROVE,
          RequisitionStatus.WAITING_REQUISITION_COMFIRM,
          RequisitionStatus.WAITING_RETURN_APPROVE,
        ].includes(status) &&
        (!isSameUser || (isSameUser && !monthlyPlan)) &&
        checkStaffStationMatched
      ) {
        isShow = true
      }
    }
    if (buttonType === 'APPROVE_RETURN') {
      if (
        [
          RequisitionStatus.WAITING_REQUISITION_APPROVE,
          RequisitionStatus.WAITING_REQUISITION_COMFIRM,
          RequisitionStatus.WAITING_RETURN_APPROVE,
        ].includes(status) &&
        checkStaffStationMatched
      ) {
        isShow = true
      }
    }
  } else if (isMP) {
    if (
      [
        RequisitionStatus.WAITING_REQUISITION_APPROVE,
        RequisitionStatus.WAITING_REQUISITION_COMFIRM,
      ].includes(status) &&
      !isSameUser &&
      monthlyPlan &&
      checkStaffStationMatched
    ) {
      isShow = true
    }
  }
  return isShow
}
