import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import _ from 'lodash'
import dayjs from 'dayjs'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Column, Row, StyledCardContent, StyledContentRow } from './Styled'
import { themeColor } from '../../../../utils/themeData'
import { versionStatus } from '../../../../constants/learningPoint'
import Dot from '../../../../components/redux/Dot'
import { Divider } from '@mui/material'

const Content = () => {
  const { view } = useSelector(
    (state) => ({
      view: state.catalogForm.view,
    }),
    shallowEqual,
  )
  return (
    <Column>
      <Row sx={{ p: 3 }}>
        <StyledCardContent>
          <Typography variant="h6">ข้อมูลของรางวัล</Typography>
          {!_.isEmpty(view.image) ? (
            <img
              src={`${window.__env__.REACT_APP_API_URL}/file/get${view.image.key}`}
              width={240}
              height={160}
              style={{
                objectFit: 'cover',
                border: '1px solid #0000003B',
                borderRadius: '4px',
              }}
            />
          ) : (
            <></>
          )}
          <ContentRow title="ID" content={_.get(view, 'id', '-')} />
          <ContentRow
            title="ชื่อของรางวัล"
            content={_.get(view, 'name', '-')}
          />
          <ContentRow
            title="คำอธิบาย"
            content={_.get(view, 'description', '-')}
          />
          <ContentRow
            title="คะแนนที่ใช้แลก"
            content={_.get(view, 'point', '-')}
          />
          <ContentRow
            title="จำนวนของรางวัลในสต๊อก"
            content={_.get(view, 'qty', '-')}
          />
          <ContentRow title="หมายเหตุ" content={_.get(view, 'remark', '-')} />
          <Column sx={{ mt: 1.5, gap: 1.5 }}>
            <Typography variant="h6">สถานะการจัดการสต๊อก</Typography>
            <StatusContent detail={view} />
            <ContentRow
              title="การเปิดใช้งาน"
              content={
                _.get(view, 'isActive', false) ? 'เปิดใช้งาน' : 'ปิดใช้งาน'
              }
              color="primary.main"
            />
            <ContentRow
              title="วันที่สร้าง"
              content={
                view.createdAt
                  ? dayjs(view.createdAt)
                      .subtract(7, 'hours')
                      .format('DD/MM/YYYY')
                  : '-'
              }
            />
            <ContentRow
              title="ผู้สร้าง"
              content={_.get(view, 'createdBy', '-')}
            />
            <ContentRow
              title="วันที่แก้ไขล่าสุด"
              content={
                view.updatedAt
                  ? dayjs(view.updatedAt)
                      .subtract(7, 'hours')
                      .format('DD/MM/YYYY')
                  : '-'
              }
            />
            <ContentRow
              title="แก้ไขล่าสุดโดย"
              content={_.get(view, 'updatedBy', '-')}
            />
          </Column>
        </StyledCardContent>
      </Row>
      <Divider />
      <Column sx={{ px: 3, pb: 3, pt: 1.5 }}>
        <Row sx={{ justifyContent: 'end' }}>
          <Typography variant="caption" color="text.lightGray">
            {`สร้างโดย ${_.get(view, 'createdBy', '')} ${
              view.createdAt
                ? dayjs(view.createdAt)
                    .subtract(7, 'hours')
                    .format('DD/MM/YYYY HH:mm')
                : ''
            }`}
          </Typography>
        </Row>
        <Row sx={{ justifyContent: 'end' }}>
          <Typography variant="caption" color="text.lightGray">
            {`แก้ไขโดย ${_.get(view, 'updatedBy', '')} ${
              view.updatedAt
                ? dayjs(view.updatedAt)
                    .subtract(7, 'hours')
                    .format('DD/MM/YYYY HH:mm')
                : ''
            }`}
          </Typography>
        </Row>
      </Column>
    </Column>
  )
}

export default Content

export const ContentRow = ({ title, content, children, color }) => {
  return (
    <StyledContentRow>
      <Typography id="label" variant="body1" color="text.secondary">
        {title}
      </Typography>
      {children ? (
        children
      ) : (
        <Typography variant="body1" color={color || 'text.primary'}>
          {content || '-'}
        </Typography>
      )}
    </StyledContentRow>
  )
}

export const StatusContent = ({ detail }) => {
  const status = _.get(detail, 'status', false)
  const color = {
    ACTIVE: themeColor?.success?.main,
    INACTIVE: themeColor?.text?.silver,
    DELETED: themeColor?.text?.white,
    DRAFT: themeColor?.text?.silver,
  }
  return (
    <ContentRow title="สถานะ">
      <Box
        sx={{ display: 'flex', gap: 1, fontColor: themeColor?.primary?.main }}
      >
        <Dot
          color={color[status] || themeColor?.error?.main}
          status={status}
          sx={{ mt: 1.5 }}
        />
        {versionStatus[status]}
      </Box>
    </ContentRow>
  )
}
