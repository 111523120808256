import React from 'react'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/system/useTheme'
import { checkColorStatus } from './events'

const Dot = ({ status }) => {
  const theme = useTheme()
  return (
    <Typography variant="body2" color={checkColorStatus(status, theme)}>
      {STATUS_TEXT[status]}
    </Typography>
  )
}

export default Dot

export const STATUS_TEXT = {
  DRAFT: 'ฉบับร่าง',
  WAITING: 'รอเปิดรับสมัคร',
  OPENREGISTRATION: 'เปิดรับสมัคร',
  CLOSEREGISTRATION: 'ปิดรับสมัคร',
  INPROGRESS: 'กำลังอบรม',
  SUCCESS: 'จบการอบรม',
  COMPLETED: 'เสร็จสมบูรณ์',
  CANCEL: 'ยกเลิกรอบอบรม',
}
