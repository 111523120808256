import {
  startLoading,
  stopLoading,
} from '../../../../../../redux/slices/eLearning/moduleDetail'
import {
  getBatchDetail,
  getAdminList,
  getTrainerList,
  getTrainerQuestion,
} from '../../../../../../services/eLearning/course/form'

export const fetchCourseBatchDetail = (uuid) => (dispatch) => {
  dispatch(fetchOptions())
  dispatch(fetchELearningCourseBatch(uuid))
}

export const fetchELearningCourseBatch = (uuid) => async (dispatch) => {
  dispatch(startLoading())
  await dispatch(getBatchDetail(uuid))
  dispatch(stopLoading())
}

export const fetchOptions = () => (dispatch) => {
  dispatch(getAdminList())
  dispatch(getTrainerList())
  dispatch(getTrainerQuestion())
}