import React, { useEffect } from 'react'
import { Box, Card, Typography } from '@mui/material'
import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import { CustomDropdown } from '../../../../../../components/CRUD/components/CustomDropdown'
import { validatePermission } from '../../../../../../utils/lib'
import { CancelButton } from '../../../../../../components/CRUD/components/FormFooter/CancelButton'
import { SubmitButton } from '../../../../../../components/CRUD/components/FormFooter/SubmitButton'
import { openStockDrawer } from '../../handler/openStockDrawer'
import { handleSubmitDrawer } from '../../handler/handleSubmitDrawer'
import { drawerFormValidationSchemaLicense } from '../../schema/schema'
import {
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../../../utils/lib'
import { regex } from '../../../../../../components/CRUD/enum/regex'
import { handleInputNumber } from '../../../../../../components/CRUD/handler/handleInputNumber'
import { paymentMethodEnum } from '../../enum/paymentMethod'
import Divider from '@mui/material/Divider'
import { useSelector, shallowEqual } from 'react-redux'
import { handlePayerChange } from '../../handler/handlePayerChange'
import UploadFile from '../../../../../../components/UploadFile'
import { handleUploadFile } from '../../handler/handleUploadFile'
import { InputDateGroup } from '../../components/InputDateGroup'
import { OwnerInfo } from '../../components/OwnerInfo'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../../../constants/stock'
import { store } from '../../../../../../App'
import { setFieldValue } from '../../../../../../redux/slices/crud'
import { getSkuNo } from '../../utils'
import _ from 'lodash'

const Form = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermissionCreate = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN, STOCK_PERMISSION.CREATE],
  })

  const { payer, paymentType, paymentImg, crudConfig, paymentImgError } =
    useSelector(
      (state) => ({
        payer: state.crud.formData.payer,
        paymentType: state.crud.formData.paymentType,
        paymentImg: state.uploadFile.formData.paymentImg,
        crudConfig: state.crud.crudConfig,
        paymentImgError: _.find(state.crud.formErrors, {
          path: 'paymentImg',
        }),
      }),
      shallowEqual,
    )
  useEffect(() => {
    if (!paymentImg?.isUploading && paymentImg) {
      handleUploadFile(paymentImg)
    }
  }, [paymentImg])
  return (
    <>
      <Typography variant="body1b" mt={3}>
        ข้อมูลรหัสทรัพย์สิน
      </Typography>
      <Box display="flex" gap={3} alignItems="start">
        <CustomDropdown
          required
          labelName="ชื่อทรัพย์สินที่เพิ่ม รหัสทรัพย์สิน"
          fieldName="productLicense"
          placeholder="เลือกทรัพย์สิน"
          handleExtraChange={getSkuNo}
        />
        <CustomInput
          required
          disabled={!hasPermissionCreate}
          fieldName="skuNo"
          labelName="รหัสทรัพย์สิน"
          placeholder="ระบุ รหัสทรัพย์สิน"
        />
      </Box>
      <Box display="flex" gap={3} alignItems="start">
        <CustomInput
          required
          disabled={!hasPermissionCreate}
          fieldName="unitPrice"
          labelName="มูลค่าต่อหน่วย (฿)"
          placeholder="ระบุมูลค่าต่อหน่วย (฿)"
          inputProps={{
            'data-testid': 'input-quantity',
            onInput: (e) => {
              handleInputNumber(e, regex.NUMBER)
            },
            onKeyDown: (e) => {
              handleNumberKeyDown(e, ['.'])
            },
            onPaste: handlePasteFormat,
          }}
        />
        <CustomDropdown
          required
          labelName="Cost center"
          fieldName="costCenter"
          placeholder="เลือก Cost center"
        />
      </Box>
      <InputDateGroup />
      <Divider sx={{ mt: 2 }} />
      <Box display="column" alignItems="start" mt={2}>
        <Typography variant="body1b">การชำระเงิน</Typography>
        <CustomDropdown
          required
          labelName="วิธีชำระเงิน"
          fieldName="paymentType"
          placeholder="เลือก วิธีชำระเงิน"
          handleExtraChange={() => {
            store.dispatch(
              setFieldValue({
                key: 'formData.paymentDesc',
                value: '',
              }),
            )
          }}
        />
      </Box>
      {paymentType?.value === paymentMethodEnum.CASH_INVOICE && (
        <Box>
          <Box
            sx={{ width: '855px', maxWidth: '100%' }}
            id={`${crudConfig.moduleSlug}-paymentImg`}
            mt={2}
          >
            <UploadFile
              sx={{ boxShadow: 'none', border: 'none', width: '100%', p: 0 }}
              folder="transferLicense"
              maxFileSize={{ size: 2, sizeType: 'MB' }}
              accept={{
                'image/png': ['.png'],
                'image/jpeg': ['.jpeg'],
                'image/jpg': ['.jpg'],
                'application/pdf': ['.pdf'],
              }}
              acceptLabel=".jpg, .jpeg, .png, .pdf"
              fieldName="paymentImg"
              maxLength={1}
              isForImage
            />
          </Box>
          {!_.isEmpty(paymentImgError) && (
            <Box>
              <Typography color={(theme) => theme.palette.error.main}>
                {_.get(paymentImgError, 'message') ?? ''}
              </Typography>
            </Box>
          )}
        </Box>
      )}

      {(paymentType?.value === paymentMethodEnum.CREDIT_CARD ||
        paymentType?.value === paymentMethodEnum.OTHER) && (
        <Box>
          <CustomInput
            required
            disabled={!hasPermissionCreate}
            fieldName="paymentDesc"
            labelName={`${
              paymentType?.value === paymentMethodEnum.OTHER
                ? 'รายละเอียด'
                : 'ชื่อ (Credit Card Name)'
            }`}
            placeholder={`กรอก${
              paymentType?.value === paymentMethodEnum.OTHER
                ? 'รายละเอียด'
                : 'ชื่อบนบัตร'
            }`}
          />
        </Box>
      )}
      <Divider sx={{ mt: 2 }} />
      <Box display="column" alignItems="start" mt={2}>
        <Typography variant="body1b">ผู้ชำระเงิน</Typography>
        <CustomDropdown
          required
          labelName="ชื่อผู้ชำระเงิน"
          fieldName="payer"
          placeholder="เลือกรายชื่อ"
          handleExtraChange={handlePayerChange}
        />
      </Box>
      {payer?.label && (
        <>
          <Card sx={{ m: 3, p: 2 }}>
            <Box display="flex" justifyContent="start" mb={2}>
              <Typography variant="body2" mr={2} minWidth="120px">
                คำนำหน้าชื่อ
              </Typography>
              <Typography variant="body2" mr={2} minWidth="120px">
                {payer?.prefix}
              </Typography>
              <Typography variant="body2" mr={2} minWidth="120px">
                ชื่อ-สกุล
              </Typography>
              <Typography variant="body2" mr={2} minWidth="120px">
                {payer?.label}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="start">
              <Typography variant="body2" mr={2} minWidth="120px">
                เบอร์โทรศัพท์
              </Typography>
              <Typography variant="body2" mr={2} minWidth="120px">
                {payer?.tel}
              </Typography>
              <Typography variant="body2" mr={2} minWidth="120px">
                Email
              </Typography>
              <Typography variant="body2" mr={2} minWidth="120px">
                {payer?.email}
              </Typography>
            </Box>
          </Card>
        </>
      )}
      <Divider sx={{ mt: 2 }} />
      <OwnerInfo />
      <Box display="flex" my={3} gap={3} justifyContent="end">
        <CancelButton onClick={() => openStockDrawer(false)} />
        <SubmitButton
          disabled={!hasPermissionCreate}
          handleSubmitClick={() =>
            handleSubmitDrawer(drawerFormValidationSchemaLicense)
          }
        />
      </Box>
    </>
  )
}

export default Form
