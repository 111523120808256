import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Close from '@mui/icons-material/Close'
import { Box, Button, Typography, Divider, IconButton } from '@mui/material'
import {
  StyledContentDrawer,
  StyledDrawer,
  StyledFooter,
  StyledHeadLabel,
} from '../Styled'
import { setIsOpenFilterDrawer } from '../../../redux/slices/reportCourseSetting/List'
import AddFilterSection from './components/AddFilterSection'
import FilterListCard from './components/FilterListCard'
import { handleSaveFilters } from './event'
import { handleConfirmCancelDraft } from '../event'

const FilterDrawer = () => {
  const dispatch = useDispatch()
  const { displayFilters, isOpenFilterDrawer, disabledAddBtn } = useSelector(
    (state) => ({
      displayFilters: state.reportCourseSetting.displayFilters,
      isOpenFilterDrawer: state.reportCourseSetting.isOpenFilterDrawer,
      disabledAddBtn: state.reportCourseSetting.disabledAddBtn,
    }),
    shallowEqual,
  )

  return (
    <StyledDrawer
      open={isOpenFilterDrawer}
      onClose={() => dispatch(setIsOpenFilterDrawer(false))}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setIsOpenFilterDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
      </StyledHeadLabel>
      <StyledContentDrawer>
        <AddFilterSection />
        <Divider />
        {displayFilters?.length > 0 && <FilterListCard />}
      </StyledContentDrawer>
      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => dispatch(handleConfirmCancelDraft())}
        >
          ยกเลิก
        </Button>
        <Button
          data-testid="btn-submit"
          variant="contained"
          disabled={!disabledAddBtn}
          onClick={() => {
            dispatch(setIsOpenFilterDrawer(false))
            dispatch(handleSaveFilters())
          }}
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
