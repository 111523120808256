import _ from 'lodash'
import { setToggleFilterDrawer } from '../../../../redux/slices/table'
import { onFilterClick } from '../events'
import { defaultFilter } from './model'

export const handleFiltering = (table, filter) => (dispatch) => {
  dispatch(setToggleFilterDrawer(false))
  dispatch(onFilterClick(table, filter))
}

export const handleChange = ({ key, value, filter, setFilter, listKey }) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  }

  if (key === 'courseTypeChecked' && !value) {
    objState = {
      ...objState,
      courseType: { ...defaultFilter.courseType },
    }
  }

  if (key === 'generalChecked') {
    objState = {
      ...objState,
      questionChecked: defaultFilter.questionChecked,
      question: { ...defaultFilter.question },
    }
  }
  setFilter(objState)
}
