import dayjs from 'dayjs'
import _ from 'lodash'
import { store } from '../../../App'

export const validateMinDate = (value, key) => {
  if (_.isEmpty(value)) return true

  const dayValue = dayjs(value)
  const state = store.getState()
  const oldData = _.get(state, key, '')
  const isSameValue = dayjs(oldData).isSame(dayValue)
  const now = new Date().toJSON().slice(0, 10)
  return isSameValue || dayValue.isAfter(now) || dayValue.isSame(now)
}

export const formatDBDate = (date) => {
  if (_.isEmpty(date)) return ''
  return dayjs(date).format(window.__env__.REACT_APP_DATE_DB)
}
