import { examinationSchedule } from '../../../utils/apiPath'
import {
  setInitialCreateForm,
  setFieldValue,
  setResult,
  setBody,
} from '../../../redux/slices/eExamination/form'
import _ from 'lodash'
import {
  EXAMINER_EVENT_STATUS,
  E_EXAM_TYPE,
} from '../../../constants/eExamination'
import { callAPI } from '../../../utils/lib'
import dayjs from 'dayjs'
import {
  getExaminationScheduleDetail,
  getExaminationScheduleEvent,
  fetchExaminationCompanyList,
} from '../../../services/eExamination/form'
import { openErrorDialog } from '../../../redux/slices/dialog'

export const fetchExaminationData = (props) => async (dispatch) => {
  const { uuid, isEditForm, setIsLoading /*formik,*/ } = props
  setResult(null)
  setIsLoading(true)
  if (_.isEmpty(uuid) || !isEditForm) {
    dispatch(setBody(null))
    await dispatch(setInitialCreateForm())
  } else {
    setIsLoading(true)
    dispatch(setBody(null))
    await dispatch(setInitialCreateForm())
    await dispatch(getExaminationScheduleDetail(uuid))
  }
  setIsLoading(false)
}

export const handleChange = (key, value) => (dispatch) => {
  dispatch(setFieldValue({ key, value }))
}

export const handleChangeEvent = (e) => (dispatch) => {
  const key = _.get(e.target, 'name', '')
  const value = _.get(e.target, 'value', '')
  dispatch(setFieldValue({ key, value }))
}

export const getStateValue = (state, key, defaultValue = '') => {
  return _.get(state.eExamForm.body, key, defaultValue)
}

export const onCancelClickDialog = (setDialog) => {
  setDialog({ open: false })
}

export const onView = (row, history, eExamType) => {
  // history.push(`/e-examination/form/${row.uuid}`)
  history.push(`/e-examination/list?type=${eExamType}`)
}

export const handleNumberInput = (e) => {
  const key = e.nativeEvent.data
  if (_.isNil(key)) return e.target.value
  if (!/^[0-9]+$/.test(key)) e.target.value = e.target.value.slice(0, -1)
  return e.target.value
}

export const submitExamField =
  ({
    eExamType,
    uuid,
    values,
    isEditForm,
    setDialog,
    setIsLoading,
    history,
    openDialog,
    closeDialog,
    formik,
  }) =>
  async (dispatch) => {
    const methodType = isEditForm ? 'put' : 'post'
    if (!dayjs(values.date).isValid()) {
      setDialog({ open: false, isLoading: false })
      const message = 'กรุณากรอกวันที่สอบให้ถูกต้อง'
      formik.setErrors({ date: message })
      dispatch(
        openErrorDialog({
          type: 'fail',
          title: 'ผิดพลาด',
          message: message,
          isCloseDialog: false,
          handleError: () => {
            dispatch(closeDialog())
          },
        }),
      )
      return false
    }
    let body = {
      date: values.date
        ? dayjs(values.date).format(window.__env__.REACT_APP_DATE_SHOW)
        : '',
      startTime: values.startTime,
      endTime: values.endTime,
      startDateRegister: values.startDateRegister
        ? dayjs(values.startDateRegister).format(
            window.__env__.REACT_APP_DATE_SHOW,
          )
        : '',
      startTimeRegister: values.startTimeRegister,
      endDateRegister: values.endDateRegister
        ? dayjs(values.endDateRegister).format(
            window.__env__.REACT_APP_DATE_SHOW,
          )
        : '',
      endTimeRegister: values.endTimeRegister,
      numberOfPeople: values.numberOfPeople
        ? parseInt(values.numberOfPeople)
        : '',
      description: values.description,
      time: values.time,
    }

    if (eExamType === E_EXAM_TYPE.ASSOCIATION) {
      body = {
        ...body,
        association: values.association,
        station: null,
        otherName: null,
      }
    } else if (eExamType === E_EXAM_TYPE.OTHER) {
      body = {
        ...body,
        association: values.association,
        station: null,
        otherName: values.otherName,
      }
    } else if (eExamType === E_EXAM_TYPE.COMPANY) {
      body = {
        ...body,
        association: null,
        station: values.company,
        otherName: null,
      }
    }

    if (isEditForm)
      body = {
        ...body,
        uuid: uuid,
      }

    const bodyArr = []
    bodyArr.push(body)
    setIsLoading(true)
    setDialog({ open: false, isLoading: true })
    callAPI({
      method: methodType,
      url: examinationSchedule,
      body: isEditForm ? body : bodyArr,
      onSuccess: (res) => {
        const uuidLink = isEditForm ? uuid : res
        setDialog({ variant: 'success', open: false, isLoading: false })
        dispatch(
          openDialog({
            type: 'success',
            title: 'สำเร็จ',
            message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
            handleConfirm: () => {
              onView({ uuid: uuidLink }, history, eExamType)
              dispatch(closeDialog())
            },
          }),
        )
      },
      onError: () => {
        setDialog({ open: false, isLoading: false })
      },
      onFinally: () => {
        setIsLoading(false)
        setDialog({ isLoading: false })
      },
    })
  }
export const fetchHistory = (limit, page, uuid) => async (dispatch) => {
  const historyBody = {
    contentId: uuid,
    author: '',
    limit: limit,
    order: 'DESC',
    page: page,
    sort: 'createdAt',
    event: [EXAMINER_EVENT_STATUS.CREATED, EXAMINER_EVENT_STATUS.UPDATED],
  }
  dispatch(getExaminationScheduleEvent(historyBody))
}

export const fetchCompanyList = (props) => async (dispatch) => {
  const { setCompanyLoading } = props
  setCompanyLoading(true)
  await dispatch(fetchExaminationCompanyList())
  setCompanyLoading(false)
}
