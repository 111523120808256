import { store } from '../../../../../App'
import {
  openDialog,
  closeDialog,
  loadingDialog,
} from '../../../../../redux/slices/dialog'
import { requisitionTicketStatus } from '../../../../../utils/apiPath'
import callAxios from '../../../../../utils/baseService'
import { TicketStatus, TicketStatusText } from '../../../../../constants/stock'
import _ from 'lodash'
import { getBodyMutation } from '../mutation/get'
import { crudConfig as initialCrudConfig } from '../../crudConfig'
import { initialConfig } from '../../../../../components/CRUD/handler/initialConfig'
import { fetchDetail } from '../../../../../components/CRUD/handler/fetchDetail'
import { viewTypeEnum } from '../enum/viewTypeEnum'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { setAssets } from './setAssets'
import { validateFormData } from './handleSubmitDrawer'
import { ReturnValidation } from '../schema/schema'

export const handleChangeStatus = async (uuid, status, formData = null) => {
  if (status === TicketStatus.WAITING_RETURN_APPROVE && formData) {
    const isValid = await validateFormData(formData, ReturnValidation)
    if (!isValid) {
      return false
    }
  }
  confirmDialog(uuid, status, formData)
}

function confirmDialog(uuid, status, formData) {
  const dialogText = getDialogText(status)
  store.dispatch(
    openDialog({
      type: dialogText.type,
      title: dialogText.DIALOG_TITLE,
      message: `คุณต้องการ${TicketStatusText?.[status]}รายการนี้ใช่หรือไม่`,
      agreeText: dialogText.agreeText,
      disagreeText: dialogText.disagreeText,
      handleConfirm: () => {
        changeTransferStatus(uuid, status, formData)
      },
      isCloseDialog: false,
    }),
  )
}

const getDialogText = (status) => {
  let DIALOG_TITLE = `ยืนยันลบข้อมูล`
  let agreeText = 'บันทึก'
  let disagreeText = 'ยกเลิก'
  let type = 'delete'
  if (TicketStatus.CANCELED === status) {
    DIALOG_TITLE = `ยืนยันการยกเลิก`
    agreeText = 'ยืนยัน'
    disagreeText = 'ปฏิเสธ'
  } else if (TicketStatus.REJECTED === status) {
    DIALOG_TITLE = `ยืนยันการปฏิเสธ`
    agreeText = 'ยืนยัน'
    disagreeText = 'ยกเลิก'
  } else if (
    [
      TicketStatus.APPROVED,
      TicketStatus.EDITED_APPROVED,
      TicketStatus.WAITING_RETURN,
      TicketStatus.WAITING_RETURN_APPROVE,
      TicketStatus.COMPLETED,
      TicketStatus.COMPLETED_WITH_CONDITION,
    ].includes(status)
  ) {
    DIALOG_TITLE = `ยืนยันอนุมัติรายการ`
    agreeText = 'อนุมัติ'
    disagreeText = 'ยกเลิก'
    type = 'confirm'
  } else if (TicketStatus.DRAFT === status) {
    DIALOG_TITLE = `ยืนยันบันทึกฉบับร่าง`
    agreeText = 'ยืนยัน'
    disagreeText = 'ยกเลิก'
    type = 'confirm'
  }

  return {
    DIALOG_TITLE,
    agreeText,
    disagreeText,
    type,
  }
}

const changeTransferStatus = async (uuid, status, formData) => {
  const body = {
    uuid,
    status,
    products: _.get(formData, 'products', []),
  }
  store.dispatch(loadingDialog())
  await callAxios
    .put(requisitionTicketStatus, body)
    .then(() => {
      store.dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          handleConfirm: () => {
            store.dispatch(closeDialog())
            initialConfig(initialCrudConfig)
            fetchDetail(uuid, getBodyMutation).then((detail) => {
              demoData(detail)
              setAssets()
            })
          },
        }),
      )
    })
    .catch((e) => {
      const value =
        e?.response?.data?.property === 'SkuNoNotAvailable'
          ? e?.response?.data?.constraints?.value ?? 'ระบบขัดข้อง'
          : 'ระบบขัดข้อง'

      store.dispatch(
        openDialog({
          type: 'fail',
          title: 'ผิดพลาด',
          message: value,
        }),
      )
    })
}

const demoData = (detail = {}) => {
  const status =
    viewTypeEnum?.[_.get(detail, 'status', viewTypeEnum.TICKET)] ??
    viewTypeEnum.TICKET
  store.dispatch(
    setFieldValue({
      key: 'viewType',
      value: status,
    }),
  )
}
