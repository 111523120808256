import React from 'react'
import _ from 'lodash'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import CloseIcon from '@mui/icons-material/Close'

import { Image, StickyContainer } from './Styled'
import { useSelector } from 'react-redux'
import { formatIdCard, getFormatDate } from '../../../../../../utils/lib'

const Index = ({ isOpen, url, handleClose, idImage, title, type, footer }) => {
  const { dataList } = useSelector((state) => ({
    dataList: state.manageClassLearnerDocument.dataList,
  }))

  const fullName = `${_.get(dataList, 'firstNameTH', '')} ${
    _.get(dataList, 'lastNameTH', '') || ''
  }`
  const idCardNo = _.get(dataList, 'idCardNo', '')
  const dateOfBirth = _.get(dataList, 'dateOfBirth', '')
  const prefixTH = _.get(dataList, 'prefixTH', '')

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">{title}</Typography>
          <IconButton
            data-testid="close-preview"
            color="primary"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            width: type === 'PDF' ? 900 : 'auto',
            maxWidth: 900,
            height: 'auto',
          }}
        >
          <DialogContentText>
            {fullName !== ' ' && (
              <StickyContainer>
                <Box>
                  <strong>ชื่อ - นามสกุล: </strong>
                  <br />
                  {prefixTH} {fullName}
                </Box>
                <Box>
                  <strong>เลขบัตรประชาชน: </strong>
                  <br />
                  {formatIdCard(idCardNo)}
                </Box>
                <Box>
                  <strong>วัน เดือน ปี เกิด: </strong>
                  <br />
                  {getFormatDate(dateOfBirth)}
                </Box>
              </StickyContainer>
            )}

            {type !== 'PDF' && <Image id={idImage} src={url} />}
            {type === 'PDF' && (
              <Box sx={{ width: '100%' }}>
                <iframe src={url} width="100%" height="700px"></iframe>
              </Box>
            )}
            {_.get(footer, 'status', '') === 'REJECTED' && (
              <Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Typography variant="body1">สถานะ</Typography>
                  <Typography variant="body1">เอกสารไม่ผ่าน</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Typography variant="body1">เหตุผล</Typography>
                  <Typography variant="body1">
                    {_.get(footer, 'remark', '')}
                  </Typography>
                </Box>
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Index
