import React, { useEffect } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import { StyledViewTitle } from '../../../../modules/ETesting/Version/View/Styled'
import { StyledContainer, StyledContent } from '../../Styled'
import { breadcrumbView } from '../model'
import HeaderContent from './HeaderContent'
import Content from './Content'
import { fetchELearning } from './events'

const View = () => {
  const { id: uuid } = useParams()
  const { result, isLoading } = useSelector((state) => state.eLearningModuleDetail)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchELearning(uuid))
  }, [])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <Box>
          <StyledViewTitle sx={{ width: 'calc(100% - 420px)' }} variant="h4">
            รายละเอียด Module : {_.get(result, 'name', '')}
          </StyledViewTitle>
        </Box>
        <Breadcrumbs menuList={breadcrumbView} />
        <HeaderContent />
        {result && <Content detail={result} />}
      </StyledContent>
    </StyledContainer>
  )
}

export default View
