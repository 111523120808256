export const yearBudgetOptions = () => {
  let currentYear = new Date().getFullYear() - 4
  let numberOfYears = 20
  const yearList = []
  
  for (let i = 1; i <= numberOfYears; i++) {
    yearList.push({ label: currentYear.toString(), value: currentYear })
    currentYear++
  }

  return yearList
}
