import { path } from './path'

export const E_CERTIFICATE_SETTING = 'E_CERTIFICATE_SETTING'

export const PERMISSION = {
  VIEW: 'VIEW',
  CREATE: 'CREATE',
  CREATE_BG: 'CREATE_BG',
  DELETE_BG: 'DELETE_BG',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  MENU: 'MENU',
}

export const OWNER = {
  ALL: 'ALL',
  PIC: 'PIC',
}

export const headCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'codeId',
    disablePadding: false,
    label: 'ID',
    width: '80px',
  },
  {
    id: 'name',
    label: 'ชื่อประกาศนียบัตร',
    disablePadding: false,
  },
  {
    id: 'numberOfVersions',
    label: 'จำนวนเวอร์ชั่น',
    disablePadding: false,
  },
  {
    id: 'updatedBy',
    label: 'แก้ไขล่าสุดโดย',
    disablePadding: false,
  },
  {
    id: 'actionE',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideEdit: true,
    hideDelete: true,
    hideSortIcon: true,
    viewPath: path.eCertification,
  },
]

export const backgroundHeadCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'codeId',
    disablePadding: false,
    label: 'ID',
    width: '80px',
  },
  {
    id: 'name',
    label: 'ชื่อภาพพื้นหลัง',
    disablePadding: false,
  },
  {
    id: 'updatedBy',
    label: 'แก้ไขล่าสุดโดย',
    disablePadding: false,
  },
  {
    id: 'actionECertiticate',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideDelete: true,
    hideSortIcon: true,
    viewPath: `${path.eCertification}/background/view`,
    editPath: `${path.eCertification}/background/edit`,
    module: E_CERTIFICATE_SETTING, // 'E_EVALUATION_SETTING'
  },
]

export const backgroundStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}

export const versionHeadCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'version',
    disablePadding: false,
    label: 'เวอร์ชั่น',
    width: '80px',
  },
  {
    id: 'name',
    label: 'ชื่อประกาศนียบัตร',
    disablePadding: false,
  },
  {
    id: 'startDate',
    label: 'วันเริ่มต้นใช้งาน',
    disablePadding: false,
  },
  {
    id: 'updatedBy',
    label: 'แก้ไขล่าสุดโดย',
    disablePadding: false,
  },
  {
    id: 'versionStatus',
    label: 'สถานะ',
    disablePadding: false,
  },
  {
    id: 'actionEVersion',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideDelete: true,
    hideSortIcon: true,
    viewPath: `${path.eCertification}/version/view`,
    editPath: `${path.eCertification}/version/edit`,
    module: E_CERTIFICATE_SETTING, // 'E_EVALUATION_SETTING'
  },
]

export const versionStatus = {
  ACTIVE: 'เปิดใช้งาน',
  INACTIVE: 'ปิดใช้งาน',
  DELETED: 'ถูกลบแล้ว',
}

export const versionStatusColor = {
  ACTIVE: 'primary',
  INACTIVE: '',
  DELETED: '',
}

export const MORE_OPTIONS = [
  {
    text: 'ลบ',
    method: 'delete',
  },
  {
    text: 'คัดลอกเพื่อสร้างเวอร์ชั่นใหม่',
    method: 'clone',
  },
]

export const MORE_OPTIONS_BG = [
  {
    text: 'ลบ',
    method: 'delete',
  },
]

export const LINE_OPTION = {
  MULTILINE: 'multiline',
}

export const defaultValue = {
  defaultTap: {
    certificate: 'certificate',
    setting: 'setting',
  },
}

export const certLanguage = {
  TH: 'th',
  EN: 'en',
}