import React from 'react'
import { StyledRow } from '../../Styled'
import { Box, Button, Typography } from '@mui/material'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import {
  setIsOpenFilterDrawer,
  setIsOpenManageColumnDrawer,
} from '../../../../redux/slices/reportCourseSetting/List'
import { shallowEqual, useDispatch } from 'react-redux'
import { onDownload } from '../event'
import { useSelector } from 'react-redux'

const MultiActionButtons = () => {
  const dispatch = useDispatch()
  const { filters } = useSelector(
    (state) => ({
      filters: state.reportCourseSetting.filters,
    }),
    shallowEqual,
  )

  return (
    <StyledRow sx={{ justifyContent: 'space-between', px: 2, py: 3 }}>
      <Button
        data-testid={'btn-manage-column'}
        sx={{ height: 40, py: 1, px: 2 }}
        variant="text"
        startIcon={<ViewColumnIcon />}
        onClick={() => dispatch(setIsOpenManageColumnDrawer(true))}
      >
        <Typography variant="text">จัดการคอลัมน์</Typography>
      </Button>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          data-testid="btn-download"
          variant="text"
          startIcon={<FileDownloadOutlined />}
          onClick={() => dispatch(onDownload())}
        >
          ดาวน์โหลด
        </Button>
        <Button
          data-testid={'btn-filter'}
          sx={{ height: 40, py: 1, px: 2 }}
          variant="text"
          startIcon={<FilterListOutlinedIcon />}
          onClick={() => dispatch(setIsOpenFilterDrawer(true))}
        >
          <Typography variant="text">
            ตัวกรอง {filters.length > 0 && `(${filters.length})`}
          </Typography>
        </Button>
      </Box>
    </StyledRow>
  )
}
export default MultiActionButtons
