import _ from 'lodash'
import { convertFormatDateTime } from '../../../../../../../../utils/lib'
import {
  resetStaffProfile,
  resetUserAgentList,
  resetUserProfile,
  setStaffProfile,
  setUserAgentList,
  setUserProfile,
  setUserType,
} from '../../../../../../../../redux/slices/manageClassLearner'
import {
  getUserAgent,
  getUserAgentDetail,
  getUserProfile,
} from '../../../../../../../../services/manageClass/form'

export const fetchUserOption = (userType) => (dispatch) => {
  dispatch(resetUserProfile())
  dispatch(resetStaffProfile())
  dispatch(setUserType(userType))
  dispatch(resetUserAgentList())
  if (userType !== 'AGENT') dispatch(getUserProfile(userType))
}

export const handleOptions = (learnerData) => {
  const { userType, userProfileList, userAgentList } = learnerData
  let userList = userProfileList.map((user) => ({
    label: `${user.agentCode} - ${user.firstNameTH} ${user.lastNameTH}`,
    value: user.uuid,
  }))
  if (userType === 'AGENT' || userType === 'LB') {
    userList = userAgentList.map((user) => ({
      label: `${user.agentCode} - ${user.firstName} ${user.lastName}`,
      value: user.agentCode,
    }))
  } else if (userType === 'PROSPECT') {
    userList = userProfileList.map((user) => ({
      label: `${user.userCode} - ${user.firstNameTH} ${user.lastNameTH}`,
      value: user.uuid,
    }))
  }

  return userList
}

export const handleDefaultUser = (userProfile, userType) => {
  const profileUser = _.get(userProfile, '[0]', {})
  if (_.isNil(profileUser)) return { label: '', value: '' }
  const user = profileUser
  let userCode = user?.agentCode
  if (userType === 'AGENT' || userType === 'LB') {
    userCode = user?.userCode
  }
  const label = `${userCode} - ${user?.firstNameTH ?? ''} ${
    user?.lastNameTH ?? ''
  }`
  const value = _.get(user, 'uuid', '')
  return { label, value }
}

export const handleDefaultBirthDay = (userProfile, userType) => {
  const birthDate =
    userType === 'AGENT' || userType === 'LB' || userType === 'PROSPECT'
      ? _.get(userProfile, '[0].dateOfBirth', null)
      : _.get(userProfile, '[0].birthOfDate', null)
  return birthDate
    ? convertFormatDateTime({
        value: birthDate,
        type: 'date',
      })
    : ''
}

export const handleDefaultPhoneNumber = (userProfile, userType) => {
  return userType === 'AGENT' || userType === 'LB' || userType === 'PROSPECT'
    ? _.get(userProfile, '[0].mobileNo', '')
    : _.get(userProfile, '[0].tel', '')
}

export const regex = {
  PROSPECT: /^[\u0E00-\u0E7Fa-zA-Z0-9]+$/,
  STAFF: /^[\u0E00-\u0E7Fa-zA-Z0-9]+$/,
  AGENT: /^[0-9]+$/,
  LB: /^[0-9]+$/,
}

export const handleInputFormat = (e, userType) => {
  const key = e.nativeEvent.data

  if (_.isNil(key)) return e.target.value

  if (!regex[userType].test(key)) e.target.value = e.target.value.slice(0, -1)
  if (!regex[userType].test(e.target.value)) e.target.value = ''
  return e.target.value
}
export const handlePasteFormat = (e, userType) => {
  const key = e.clipboardData.getData('text')
  if (!regex[userType].test(key)) {
    e.preventDefault()
    return false
  }
}

export const handleInputChange = (e, userType) => (dispatch) => {
  if (userType === 'AGENT' || userType === 'LB') {
    const userCode = _.get(e.target, 'value', '').trim()
    if (userCode.length >= 3 && userCode.length <= 6) {
      const body = { userCode, agentType: userType[0] }
      dispatch(getUserAgent(body))
    }
  }
}

export const handleSelectChange = (element, userType) => (dispatch) => {
  const value = _.get(element, 'value', '')
  if (userType === 'AGENT' || userType === 'LB') {
    if (!_.isNull(element)) {
      const body = { userCode: value, agentType: userType[0] }
      dispatch(getUserAgentDetail(body))
    } else {
      dispatch(resetUserAgentList())
      dispatch(resetUserProfile())
      dispatch(resetStaffProfile())
    }
  } else if (userType === 'PROSPECT') {
    dispatch(setUserProfile(value))
  } else if (userType === 'STAFF') {
    dispatch(setStaffProfile(value))
  }
}

export const handleInputBlur = (userProfile) => (dispatch) => {
  const profileUser = _.get(userProfile, '[0]', {})
  if (_.isNil(profileUser)) dispatch(resetUserAgentList())
  else if (profileUser.userType === 'AGENT' || profileUser.userType === 'LB') {
    dispatch(setUserAgentList(_.get(userProfile, '[0].userCode', '')))
  }
}
