import { shallowEqual, useSelector } from 'react-redux'
import AddNewMergeColumn from './AddNewMergeColumn'
import SelectColumnSection from '../../components/SelectColumnSection'
import { handleAddColumn } from '../event'
import { useDispatch } from 'react-redux'

const TabContentAddColumn = () => {
  const dispatch = useDispatch()
  const { tabActiveColCard, columnOptions } = useSelector(
    (state) => ({
      tabActiveColCard: state.reportCourseSetting.tabActiveColCard,
      columnOptions: state.reportCourseSetting.columnOptions,
    }),
    shallowEqual,
  )

  return (
    <>
      {tabActiveColCard === 0 && (
        <SelectColumnSection
          placeholder="เลือกเพื่อเพิ่มในรายการ"
          columnOptions={columnOptions}
          handleAdd={() => dispatch(handleAddColumn({ type: 'default' }))}
        />
      )}
      {tabActiveColCard === 1 && <AddNewMergeColumn />}
    </>
  )
}
export default TabContentAddColumn
