import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { getFormDetailEva } from '../../../services/eEvaluation/form'
import { filterCourseRef } from '../../../modules/ETesting/Form/event'

export const initialState = {
  isLoading: false,
  result: null,
  error: null,
}

const eEvaluationDetail = createSlice({
  name: 'eEvaluationDetail',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFormDetailEva.fulfilled, (state, { payload }) => {
        state.result = {
          ...payload,
          setting: {
            ...payload.setting,
            courseRef: filterCourseRef(
              _.get(payload, 'setting.courseRef', []),
            ),
          },
        }
      })
      .addCase(getFormDetailEva.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const { startLoading, stopLoading } = eEvaluationDetail.actions

export default eEvaluationDetail.reducer
