import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Information from './Information'
import Footer from './Footer'
import MainInformation from './MainInformation'
import WorkExperience from './WorkExperience'

import { BoxSection, StyledDot } from './Styled'
import CourseSection from './Course'
import ExamSection from './Exam'

export const BoxContent = ({ labelLeft, labelRight, status }) => {
  return (
    <BoxSection>
      <Typography variant="body2b" color="text.secondary">
        {labelLeft}
      </Typography>
      <Typography
        variant="body1"
        color="text.primary"
        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
      >
        {status && <StyledDot status={status} />}
        {labelRight}
      </Typography>
    </BoxSection>
  )
}

const Index = ({ type, checkClick }) => {
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <MainInformation checkClick={checkClick}  />
      <Information type={type} />
      <WorkExperience />
      <CourseSection />
      <ExamSection />
      <Footer />
    </Box>
  )
}

export default Index
