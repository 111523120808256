export const headCells = [
  {
    id: 'createdAt',
    label: 'วันเวลา',
    isCustom: true,
    minWidth: '200px',
  },
  {
    id: 'movementType',
    label: 'รายการ',
  },
  {
    id: 'id',
    label: 'ID/รหัสทรัพย์สิน',
  },
  {
    id: 'product.productName',
    label: 'ชื่อทรัพย์สิน',
  },
  {
    id: 'product.category.name',
    label: 'หมวดหมู่',
  },
  {
    id: 'product.subCategory.name',
    label: 'หมวดหมู่ย่อย',
  },
  {
    id: 'quantity',
    label: 'จำนวนทรัพย์สิน',
  },
  {
    id: 'total',
    label: 'คงเหลือ',
  },
  {
    id: 'available',
    label: 'พร้อมใช้งาน',
  },
  {
    id: 'form.warehouseName',
    label: 'จาก',
  },
  {
    id: 'to.warehouseName',
    label: 'ไป',
  },
  {
    id: 'ticket.id',
    label: 'Ticket ID',
  },
  {
    id: 'dropdownAction',
    label: 'คำสั่ง',
    disablePadding: false,
    hideSortIcon: true,
    custom: true,
  },
]
