import React from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'

import { StyledBox } from '../Styled'
import { setOnView } from '../../../../../redux/slices/table'
import useLocalStorage from '@rehooks/local-storage'
import { handleDeleteDialog, handleIsCanDelete } from './events'

const ActionBatchLearner = ({ row, rowIndex, condition }) => {
  const [user] = useLocalStorage('user')
  const dispatch = useDispatch()
  const history = useHistory()
  const viewPath = _.get(condition, 'viewPath', '')
  const viewUrl = `${viewPath}/${row.uuid}`
  const canDelete = handleIsCanDelete(row, user)
  return (
    <StyledBox>
      {row.canView && <IconButton
        data-testid={`btn-view-${rowIndex}`}
        color="primary"
        component="span"
        onClick={() => dispatch(setOnView({ history, viewUrl }))}
      >
        <RemoveRedEyeTwoTone color="action" />
      </IconButton>}

      {row.canDelete && canDelete && (
        <IconButton
          data-testid={`btn-delete-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(handleDeleteDialog(_.get(row, 'uuid', '')))}
        >
          <DeleteTwoTone color="action" />
        </IconButton>
      )}
    </StyledBox>
  )
}

export default ActionBatchLearner
