import { ViewRow } from '../../../../components/CRUD/components/StyledViewBox'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import _ from 'lodash'
import {
  TicketStatusColor,
  TicketStatusText,
} from '../../../../constants/stock'
import Dot from '../../../../components/redux/Dot'
import { Typography } from '@mui/material'
import { PRODUCT_TYPE_VIEW_TEXT } from '../../../../constants/stock'

export const ViewContent = () => {
  const { formData } = useSelector((state) => state.crud)
  const productType = formData?.productType
    ? PRODUCT_TYPE_VIEW_TEXT?.[formData.productType] ?? formData.productType
    : '-'
  return (
    <Box my={2}>
      <Typography variant="h6">ข้อมูลพื้นฐาน</Typography>
      <Box sx={{ mt: 2, display: 'flex', gap: 2, flexDirection: 'column' }}>
        <ViewRow
          title="ต้นทาง"
          variant="variant"
          value={_.get(formData, 'from.warehouseName', '-')}
        />

        <ViewRow
          title="ปลายทาง"
          variant="variant"
          value={_.get(formData, 'to.warehouseName', '-')}
        />

        <ViewRow
          title="ประเภททรัพย์สิน"
          variant="variant"
          value={productType}
        />

        <ViewRow
          boxSx={{ display: 'flex', flexDirection: 'column' }}
          title="หมายเหตุ"
          variant="variant"
          value={_.get(formData, 'remark', '-')}
        />

        <ViewRow
          title="สถานะ"
          variant="variant"
          valueComponent={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {TicketStatusText?.[formData?.status] ? (
                <>
                  <Dot color={TicketStatusColor?.[formData?.status]} />
                  {' ' + TicketStatusText?.[formData?.status]}
                </>
              ) : (
                formData?.status ?? '-'
              )}
            </Box>
          }
        />
      </Box>
    </Box>
  )
}
