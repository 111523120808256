import { store } from '../../../../../App'
import {
  replaceFieldError,
  setFieldError,
} from '../../../../../redux/slices/crud'
import handleScrollToError from '../../../../../components/CRUD/handler/handleScrollToError'
import { openDialog } from '../../../../../redux/slices/dialog'
import { handleProcessSubmitForm } from './handleProcessSubmitForm'

const DIALOG_TITLE = 'ยืนยันบันทึกและเผยแพร่'
const DIALOG_MESSAGE = 'คุณต้องการบันทึกและเผยแพร่ข้อมูลนี้หรือไม่'

const DIALOG_TITLE_DRAFT = 'ยืนยันบันทึกฉบับร่าง'
const DIALOG_MESSAGE_DRAFT = 'คุณต้องการบันทึกฉบับร่างข้อมูลนี้หรือไม่'

export async function validateFormData(formData, validationSchema) {
  store.dispatch(replaceFieldError([]))
  try {
    await validationSchema.validate(formData, { abortEarly: false })
    return true
  } catch (error) {
    store.dispatch(setFieldError(error.inner))
    handleScrollToError(error.inner[0].path)
    return false
  }
}

function confirmDialog(uuid, postBodyMutation, isDraft) {
  store.dispatch(
    openDialog({
      title: isDraft ? DIALOG_TITLE_DRAFT : DIALOG_TITLE,
      message: isDraft ? DIALOG_MESSAGE_DRAFT : DIALOG_MESSAGE,
      handleConfirm: () =>
        handleProcessSubmitForm(uuid, postBodyMutation, isDraft),
      isCloseDialog: false,
    }),
  )
}

export const handleSubmitForm = async (props) => {
  const { uuid, validationSchema, isDraft } = props
  const { formData } = store.getState().crud

  const isValid = await validateFormData(formData, validationSchema)
  if (isValid) {
    confirmDialog(uuid, formData, isDraft)
  }
}
