import React from 'react'
import _ from 'lodash'
import { setTabActiveCol } from '../../../../redux/slices/reportCourseSetting/List'
import { StyledTab } from '../../Styled'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { tabsCardList } from '../model'
import { StyledTabs } from '../Styled'
import TabContentAddColumn from './TabContentAddColumn'

const TabsAddColumn = () => {
  const dispatch = useDispatch()
  const { tabActiveColCard } = useSelector(
    (state) => ({
      tabActiveColCard: state.reportCourseSetting.tabActiveColCard,
    }),
    shallowEqual,
  )

  const handleChangeTab = (e, newValue) => {
    dispatch(setTabActiveCol({ key: 'tabActiveColCard', value: newValue }))
  }

  return (
    <>
      <StyledTabs
        variant="scrollable"
        scrollButtons="auto"
        value={tabActiveColCard}
        onChange={handleChangeTab}
      >
        {_.map(tabsCardList, (tab, idx) => (
          <StyledTab key={idx} label={tab.label} />
        ))}
      </StyledTabs>
      <TabContentAddColumn />
    </>
  )
}

export default TabsAddColumn
