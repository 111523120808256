import { createSlice } from '@reduxjs/toolkit'
import { getDetail } from '../../../../services/eLearning/course/detail'
import { FORM_TAB } from '../../../../modules/ELearning/Course/Form/model'
import { initialState } from './model'

const eLearningCourseDetail = createSlice({
  name: 'eLearningCourseDetail',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setChange: (state, { payload }) => {
      const { key, value } = payload
      state[key] = value
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDetail.fulfilled, (state, { payload }) => {
        state.result = payload.data
        state.tab = FORM_TAB.course
      })
      .addCase(getDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const { startLoading, stopLoading, setChange } =
  eLearningCourseDetail.actions

export default eLearningCourseDetail.reducer
