import ErrorOutline from '@mui/icons-material/ErrorOutline'
import _ from 'lodash'
import React from 'react'
import { postSetExamNotPass } from '../../../../../services/manageClass/check'
import {
  loadingDialog,
  openDialog,
} from '../../../../../redux/slices/dialog'
import SetFailDialog from '../SetFailDialog'

export const handleOnClickMenu =
  (option, setOpen, idProps) => async (dispatch) => {
    if (option == 'history') {
      setOpen(true)
    } else {
      const { id, courseId } = idProps
      const body = {
        trainingPlanUuid: id,
        courseTestAndEvaUuid: courseId,
        isCheck: true,
      }

      const result = await dispatch(postSetExamNotPass(body))
      const data = _.get(result.payload, 'data', undefined)
      if (data) {
        const waitingToCheckExam = _.get(data, 'waitingToCheckExam', 0)
        dispatch(
          openDialog({
            type: 'content',
            title: 'ปรับสถานะผู้เรียนที่เหลือทั้งหมดเป็นไม่ผ่าน',
            icon: <ErrorOutline color="error" />,
            content: <SetFailDialog notChecked={waitingToCheckExam} />,
            disableButton: true,
            handleConfirm: () => dispatch(handleConfirmNotPass(body)),
          })
        )
      }
    }
  }

export const handleConfirmNotPass = (body) => async (dispatch) => {
  dispatch(loadingDialog())
  body.isCheck = false
  const response = await dispatch(postSetExamNotPass(body))
  if (_.isNil(response.error)) window.location.reload()
}
