import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Breadcrumbs from '../../../../../components/Breadcrumbs'
import TextInput from '../../../../../components/Input/TextInput'
import * as dialog from '../../../../../redux/slices/dialog'

import {
  listBreadcrumb,
  onSubmitDocumentApprove,
  onSubmitDocumentReject,
  handleChangeRemark,
  actionSubmitDocument,
  mapTextHeader,
  mapStatusApprove,
} from './events'
import { BoxContent, BoxHeader } from './Styled'

export const DialogContentRejected = ({ type, isConfirm, statusText }) => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const { isLoading, documentBody } = useSelector((state) => ({
    documentBody: state.manageClassLearnerDocument.documentBody,
    isLoading: state.manageClassLearnerDocument.isLoading,
  }))

  return (
    <Box sx={{ mx: 3, my: 2 }}>
      <Typography variant="h6">
        {mapTextHeader(type, isConfirm, statusText)}
      </Typography>
      {!isConfirm && type == 'last' && (
        <Typography variant="body2" sx={{ mt: 1.5 }}>
          คุณต้องการยืนยันอนุมัติเอกสารและ
          <span style={{ color: '#1CC54E' }}>มีสิทธิ์เรียน</span>หรือไม่
        </Typography>
      )}

      {isConfirm && type == 'last' && (
        <>
          <Typography variant="body2" sx={{ mt: 1.5 }}>
            การสมัครหลักสูตรนี้ต้องผ่านขั้นตอน{' '}
            <span style={{ color: '#494DF4' }}> รอคอนเฟิร์มตัวแทน </span> ก่อน
          </Typography>
          <Typography variant="body2">
            คุณต้องการยืนยันการปรับสถานะเพื่อให้มีสิทธิ์เรียนได้หรือไม่
          </Typography>
        </>
      )}

      <Box sx={{ ml: 1, mt: 1 }}>
        <TextInput
          required
          type="basic"
          name="remark"
          rows={2.5}
          labelText="เหตุผล"
          placeholder=""
          multiline={true}
          value={_.get(documentBody, 'remark', '')}
          onChange={(e) =>
            dispatch(handleChangeRemark(_.get(e, 'target.value', '')))
          }
        />
      </Box>

      <Box
        sx={{
          mt: 1.5,
          display: 'flex',
          gap: 3,
          justifyContent: 'flex-end',
        }}
      >
        <Button
          sx={{ width: 100 }}
          disabled={isLoading}
          data-testid="btn-disagree"
          onClick={() => dispatch(dialog.closeDialog())}
          variant="outlined"
          endIcon=""
        >
          ยกเลิก
        </Button>
        <Button
          sx={{ width: 100 }}
          data-testid="btn-agree"
          variant="contained"
          endIcon=""
          isLoading={isLoading}
          disabled={!_.get(documentBody, 'remark', '') || isLoading}
          onClick={() =>
            dispatch(
              actionSubmitDocument({
                status: mapStatusApprove(type, isConfirm, statusText),
                uuid,
                documentBody,
              })
            )
          }
        >
          บันทึก
        </Button>
      </Box>
    </Box>
  )
}

const Index = () => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()

  const { dataList, documentBody, trainingPlanUuid } = useSelector((state) => ({
    dataList: state.manageClassLearnerDocument.dataList,
    documentBody: state.manageClassLearnerDocument.documentBody,
    trainingPlanUuid:
      state.manageClassLearnerDocument.dataList.trainingPlanUuid,
  }))

  const label = `${_.get(dataList, 'firstNameTH', '')} ${
    _.get(dataList, 'lastNameTH', '') || ''
  }`

  const document = _.get(dataList, 'document', [])
  const isConfirm = _.get(dataList, 'isConfirm', false)
  const isMoreReject = _.get(dataList, 'isMoreReject', true)

  const firstDocument = _.minBy(document, 'id')
  const lastDocument = _.maxBy(document, 'id')
  const firstRound = _.get(firstDocument, 'round', 0)
  const status = _.get(lastDocument, 'status', 'รอเอกสาร')

  return (
    <BoxContent>
      <BoxHeader>
        <Typography variant="h4">รายละเอียดเอกสารการสมัคร : {label}</Typography>
        <Breadcrumbs menuList={listBreadcrumb(trainingPlanUuid)} />
      </BoxHeader>
      {isMoreReject &&
        status !== 'ผ่านการตรวจสอบ' &&
        status !== 'เอกสารไม่ผ่าน' && (
          <Box sx={{ gap: 1, display: 'flex' }}>
            <Button
              variant="outlined"
              sx={{ width: status === 'รอคอนเฟิร์มตัวแทน' ? 110 : 100 }}
              disabled={
                status === 'รอคอนเฟิร์มตัวแทน'
                  ? false
                  : !_.get(documentBody, 'learnerDocumentList', []).length
              }
              onClick={() => dispatch(onSubmitDocumentReject(status))}
            >
              <Typography variant="buttonl">
                {status === 'รอคอนเฟิร์มตัวแทน'
                  ? 'ไม่มีสิทธิ์เรียน'
                  : 'ไม่อนุมัติ'}
              </Typography>
            </Button>
            <Button
              variant="contained"
              disabled={documentBody.learnerDocumentList.length}
              sx={{ width: status === 'รอคอนเฟิร์มตัวแทน' ? 110 : 100 }}
              onClick={() =>
                dispatch(
                  onSubmitDocumentApprove({
                    status: 'approve',
                    document: document.length,
                    statusText: status,
                    firstRound,
                    isConfirm,
                    uuid,
                  })
                )
              }
            >
              <Typography variant="buttonl">
                {status === 'รอคอนเฟิร์มตัวแทน' ? 'มีสิทธิ์เรียน' : 'อนุมัติ'}
              </Typography>
            </Button>
          </Box>
        )}
    </BoxContent>
  )
}

export default Index
