import React from 'react'
import { Typography, Box } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import Button from '@mui/material/Button'
import { BoxButton, BoxHeader, StyledInputImport } from '../../Styled'
import { openStockDrawer } from '../../handler/openStockDrawer'
import { handleImportAssetFile } from '../../handler/handleImportAssetFile'
import { onDownloadTemplate } from '../../handler/onDownloadTemplate'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'

const Header = () => {
  const dispatch = useDispatch()
  const { from, to } = useSelector(
    (state) => ({
      from: state.crud.formData.from,
      to: state.crud.formData.to,
    }),
    shallowEqual,
  )

  const checkForm = from ? false : true
  const checkTo = to ? false : true

  return (
    <>
      <BoxHeader>
        <Box>
          <Typography variant="h6">รายการทรัพย์สิน</Typography>
        </Box>
        <BoxButton>
          <Button
            type="submit"
            data-testid="btn-draft"
            variant="outlined"
            size="m"
            onClick={() => openStockDrawer(true, true)}
            startIcon={<AddIcon />}
            disabled={checkForm || checkTo}
          >
            กำหนดเอง
          </Button>
          <Typography variant="body2b">หรือ</Typography>
          <label
            style={{ display: 'flex', gap: 16, alignItems: 'center' }}
            htmlFor="import-asset"
          >
            <Button
              data-testid="btn-draft"
              variant="outlined"
              size="m"
              startIcon={<UploadFileRoundedIcon />}
              component="span"
              disabled={checkForm || checkTo}
            >
              อัปโหลดไฟล์
            </Button>
            <StyledInputImport
              data-testid="import-asset"
              id="import-asset"
              accept=".xlsx, .xls, .csv"
              type="file"
              onChange={(e) => dispatch(handleImportAssetFile(e))}
              disabled={checkForm || checkTo}
            />
          </label>
          <Button
            data-testid="btn-download-0"
            variant="text"
            onClick={onDownloadTemplate}
            size="m"
            startIcon={<FileDownloadOutlinedIcon />}
            disabled={checkForm}
          >
            ดาวน์โหลด TEMPLATE
          </Button>
        </BoxButton>
      </BoxHeader>
    </>
  )
}

export default Header
