import React, { useEffect } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import { useSelector, useDispatch } from 'react-redux'

import Select from '../../../../../components/Input/Select'
import Table from '../../../../../components/redux/Table/LearnerDetail'
import * as events from './events'

export const ActionIcon = ({ item }) => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const status = _.get(item, 'status', 'WAITING')
  const bodyEvent = {
    learnerUuid: uuid,
    date: _.get(item, 'date', ''),
    round: _.get(item, 'round', ''),
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', ml: -1 }}>
      <IconButton
        disabled={status === 'STUDY'}
        onClick={() =>
          dispatch(
            events.handleDialogAction({
              ...bodyEvent,
              type: 'STUDY',
            })
          )
        }
      >
        <Check color={status !== 'STUDY' ? 'success' : 'action.active'} />
      </IconButton>
      <IconButton
        disabled={status === 'MISSING'}
        onClick={() =>
          dispatch(
            events.handleDialogAction({
              ...bodyEvent,
              type: 'MISSING',
            })
          )
        }
      >
        <Close color={status !== 'MISSING' ? 'error' : 'action.active'} />
      </IconButton>
    </Box>
  )
}

const Index = () => {
  const dispatch = useDispatch()
  const { attendance, selectDate } = useSelector((state) => ({
    selectDate: state.manageClassLearnerDetail.selectDate,
    attendance: state.manageClassLearnerDetail.initialState.attendance,
  }))

  useEffect(() => {
    dispatch(events.filterData({ attendance, selectDate }))
  }, [selectDate, attendance])

  return (
    <>
      <Card sx={{ mt: 3 }}>
        <Box sx={{ m: 3 }}>
          <Typography variant="h6">การเข้าเรียน</Typography>
          <Select
            dataTestid="select-date"
            required
            disabled={!events.filterListDate(attendance).length}
            id="type"
            name="type"
            labelText="วันที่เข้าเรียน"
            type="basic"
            options={events.filterListDate(attendance)}
            handleChange={(e) =>
              dispatch(
                events.handleChange({
                  key: 'selectDate',
                  value: _.get(e, 'target.value', selectDate),
                })
              )
            }
            value={selectDate}
          />
          <Table type="details" />
        </Box>
      </Card>
    </>
  )
}
export default Index
