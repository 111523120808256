import { Box, styled } from '@mui/system'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import ViewComponent from './ViewComponent'
import ViewString from './ViewString'
import ViewOther from './ViewOther'

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const ViewRow = ({
  title,
  titleSx = {},
  boxSx = {},
  value,
  valueComponent,
  variant = 'body1b',
}) => {
  const isValueComponent = () => {
    if (!_.isUndefined(valueComponent) && _.isUndefined(value)) {
      return true
    }
    return false
  }
  const isValueString = () => {
    if (!_.isUndefined(value) && typeof value === 'string') {
      return true
    }
    return false
  }

  return (
    <StyledBox sx={{ ...boxSx }}>
      <Typography sx={{ minWidth: 330, ...titleSx }} variant={variant}>
        {title}
      </Typography>
      {isValueComponent() ? (
        <ViewComponent value={valueComponent} />
      ) : isValueString() ? (
        <ViewString value={value} />
      ) : (
        <ViewOther value={value} />
      )}
    </StyledBox>
  )
}
