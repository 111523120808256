import React from 'react'
import { useSelector } from 'react-redux'
import { StyledNewCard } from '../../../components/View/Styled'
import TabMenuContent from './TabMenuContent'
import Course from './Course'
import Setting from './Setting'
import { FORM_TAB } from '../../Form/model'
import Learner from './Learner'
import BatchTab from '../../Form/BatchTab'

const Content = () => {
  const tab = useSelector((state) => state.eLearningCourseDetail.tab)
  return (
    <StyledNewCard id="view">
      <TabMenuContent />
      {tab === FORM_TAB.course && <Course />}
      {tab === FORM_TAB.setting && <Setting />}
      {tab === FORM_TAB.batch && <BatchTab sx={{ boxShadow: 'unset' }} />}
      {tab === FORM_TAB.learner && <Learner />}
    </StyledNewCard>
  )
}

export default Content
