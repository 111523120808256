import { PRODUCT_TYPE } from '../../../../../constants/stock'
import * as yup from 'yup'

const assetSchema = yup
  .object()
  .shape({
    value: yup.string().nullable().required('กรุณเลือกทรัพย์สิน'),
    productType: yup.string().nullable(),
    qty: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
    availableQty: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
    assetCode: yup.mixed(),
  })
  .test('asset-object', 'Custom validation error', function (value) {
    const {
      value: valueField,
      productType,
      qty,
      assetCode,
      availableQty,
    } = value
    const index = this.options.index
    if (valueField) {
      if (productType === PRODUCT_TYPE.STOCK) {
        if (!qty) {
          return this.createError({
            path: `assets[${index}].qty`,
            message: 'กรุณากรอกจำนวน',
          })
        } else if (qty > availableQty) {
          return this.createError({
            path: `assets[${index}].qty`,
            message: 'จำนวนไม่เพียงพอ',
          })
        }
      }
      if (
        productType !== PRODUCT_TYPE.STOCK &&
        (!assetCode || !assetCode.value)
      ) {
        return this.createError({
          path: `assets[${index}].assetCode`,
          message: 'กรุณาเลือกรหัสทรัพย์สิน',
        })
      }
    }
    return true
  })

export const validationSchema = yup.object({
  trainingDate: yup.string().nullable().required('กรุณาเลือกวันที่อบรม'),
  courseSelected: yup.object().nullable().required('กรุณาเลือกคอร์ส'),
  responsibleUser: yup.object().nullable().required('กรุณาเลือกผู้รับผิดชอบ'),
  warehouse: yup.object().nullable().required('กรุณาเลือกคลังทรัพย์สิน'),
  annotation: yup.string().nullable().max(3000, 'กรุณาระบุภายใน 3000 ตัวอักษร'),
  assets: yup
    .array()
    .of(assetSchema)
    .min(1, 'ต้องมีรายการทรัพย์สินอย่างน้อย 1 รายการ'),
})

export const validationSchemaOther = yup.object({
  warehouse: yup.object().nullable().required('กรุณาเลือกคลังทรัพย์สิน'),
  annotation: yup.string().nullable().max(3000, 'กรุณาระบุภายใน 3000 ตัวอักษร'),
  assets: yup
    .array()
    .of(assetSchema)
    .min(1, 'ต้องมีรายการทรัพย์สินอย่างน้อย 1 รายการ'),
})
