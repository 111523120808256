import { fetchMasterDataDropdown } from '../../../../../components/CRUD/handler/fetchDetail'
import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { PRODUCT_TYPE } from '../../../../../constants/stock'

export const handleSelectWarehouse = (value) => {
  if (value?.value) {
    fetchMasterDataDropdown([
      {
        key: 'assets',
        moduleId: 'INVENTORY_PRODUCT_ALL',
        body: {
          isRequisition: true,
          productType: [PRODUCT_TYPE.STOCK, PRODUCT_TYPE.STOCK_ASSET],
          warehouseUuid: value.value,
        },
      },
    ])
  }
  store.dispatch(
    setFieldValue({
      key: 'formData.assets',
      value: [
        {
          label: '',
          value: '',
          assetCode: '',
          assetCodeOption: [],
          qty: '',
          availableQty: '',
          totalQty: '',
          status: '',
        },
      ],
    }),
  )
}
