import _ from 'lodash'
import { store } from '../../../../../App'

export const checkSkuNo = async () => {
  let isDuplicate = false
  const { skuNoList, assetData, formData } = store.getState().crud
  const checkAssetData = _.defaultTo(assetData, [])
  for (const data of checkAssetData) {
    if (
      data?.skuNo === formData?.skuNo &&
      data?.rowIndex !== formData?.rowIndex
    ) {
      isDuplicate = true
      break
    }
  }
  for (const ticket of _.defaultTo(skuNoList, [])) {
    if (formData?.uuid && ticket.uuid === formData?.uuid) continue
    if (ticket?.skuNo === formData?.skuNo) {
      isDuplicate = true
      break
    }
  }
  return isDuplicate
}

export const checkSkuNoForm = async () => {
  let isDuplicate = false
  const { skuNoList, assetData, formData } = store.getState().crud
  for (const ticket of _.defaultTo(skuNoList, [])) {
    if (formData?.uuid && ticket.uuid === formData?.uuid) continue
    const findAsset = _.defaultTo(assetData, []).find(
      (item) => item?.skuNo === ticket?.skuNo,
    )
    if (findAsset) {
      isDuplicate = true
      break
    }
  }

  return isDuplicate
}
