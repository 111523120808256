import React from 'react'
import _ from 'lodash'
import Autocomplete from '../../../components/Input/Autocomplete'
import { TextField } from '@mui/material'
import {
  COLUMN_NAME,
  COLUMN_NAME_TH,
} from '../../../constants/reportCourseSetting/reportCourseSetting'

const ColumnSelect = ({
  columnSelected = null,
  placeholder = 'เลือกคอลัมน์',
  labelText = 'คอลัมน์',
  hasTwoBoxes = false,
  options = [],
  handleChange = () => {},
}) => {
  const columnName = _.findKey(COLUMN_NAME, (col) => col === columnSelected)
  const displayValue = !_.isNull(columnSelected) ? columnSelected : ''
  const displayLabel = !_.isNull(columnSelected)
    ? COLUMN_NAME_TH[columnName]
    : ''

  return (
    <Autocomplete
      boxSx={{
        width: hasTwoBoxes ? '50%' : '100%',
      }}
      id="column-selected"
      name="column-selected"
      labelText={labelText}
      placeholder={placeholder}
      options={options}
      defaultValue={{
        value: displayValue,
        label: displayLabel,
      }}
      isHideSort={true}
      renderInput={(params) => (
        <TextField placeholder="กรุณาเลือก" {...params} />
      )}
      handleChange={handleChange}
    />
  )
}

export default ColumnSelect
