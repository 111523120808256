import React, { useEffect } from 'react'
import Header from './Header'
import List from './List'
import Calendar from './Calendar'

import { BoxContainer } from './Styled'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { fetchDataStation } from './events'

const Index = () => {
  const dispatch = useDispatch()
  const defaultMenu = useSelector(
    (state) => state.manageClass.defaultMenu,
    shallowEqual
  )

  useEffect(() => {
    dispatch(fetchDataStation())
  }, [])

  return (
    <BoxContainer>
      <Header />
      {defaultMenu === 'list' && <List event="manageClass" />}
      {defaultMenu === 'calendar' && <Calendar />}
    </BoxContainer>
  )
}
export default Index
