import React from 'react'
import _ from 'lodash'
import { LINE_OPTION, certLanguage } from '../../../../constants/eCertification'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { StyledLayoutBox } from './Styled'
import {
  callAPI,
  getDateThai,
  replaceAll,
  replaceNewLine,
  thaiNumber,
} from '../../../../utils/lib'
import * as reducer from '../../../../redux/slices/eCertificateForm'
import { eCertificationCourseSettingDownload } from '../../../../utils/apiPath'
import useLocalStorage from '@rehooks/local-storage'
import dayjs from 'dayjs'

export const LayoutPreview = ({
  version,
  width,
  englishCertificateName,
  certificateLanguage,
}) => {
  version = versionPreview(version, englishCertificateName, certificateLanguage)
  let num = 0
  return (
    <StyledLayoutBox
      url={`${window.__env__.REACT_APP_API_URL}/file${version.eCertificationBackground?.imageKey}`}
      width={width}
    >
      {_.map(version.layout.layout, (l, key) => {
        if (l.type === LINE_OPTION.MULTILINE) {
          return (
            <div key={`layout_box_${key}`} style={{ display: 'flex' }}>
              {_.map(l.layout, (li, n) => {
                if (l.courseName) {
                  const inline =
                    num <= 1
                      ? { ...l.style, marginTop: '120px' }
                      : { ...l.style, marginTop: '40px' }
                  return (
                    <Typography
                      key={`layout_box_n_${n}`}
                      sx={{ ...inline, ...li.style }}
                    >
                      {li.text}
                    </Typography>
                  )
                }
                return (
                  <Typography
                    key={`layout_box_n_${n}`}
                    sx={{ ...l.style, ...li.style }}
                  >
                    {!_.isEmpty(li.text) ? li.text : ' '}
                  </Typography>
                )
              })}
            </div>
          )
        }

        let loopFor = 0
        if (!_.isEmpty(l.text)) {
          for (const replace of replaceNewLine(l.text).split('\\n')) {
            if (!_.isEmpty(replace)) loopFor++
          }
        }
        num = loopFor

        return (
          <Box key={`layout_list_${key}-${version.id}`} sx={l.style}>
            {l.text}
          </Box>
        )
      })}
    </StyledLayoutBox>
  )
}

export const eCertificateMapObj = (
  englishCertificateName,
  user,
  thai = false,
  language
) => {
  const getCurrentDateTime = dayjs()
  const date = getDateThai(getCurrentDateTime)
  const isTH = language === certLanguage.TH
  const prefix = _.get(user, isTH ? 'prefixTH' : 'prefix', '')
  const prefixDot = isTH ? ' ' : '. '
  const firstName = `${_.isEmpty(prefix) ? '' : prefix + prefixDot}${_.get(
    user,
    isTH ? 'firstNameTH' : 'firstName',
    ''
  )}`
  return {
    '{FirstName}': firstName,
    '{LastName}': _.get(user, isTH ? 'lastNameTH' : 'lastName', '') || '',
    '{CourseName}': englishCertificateName ?? '',
    '{Date}': thai ? thaiNumber(date) : date,
  }
}

export const versionPreview = (version, englishCertificateName, language) => {
  const [user] = useLocalStorage('user')
  const isThai =
    _.get(version, 'layout.uuid', '') === '1a1dad0e-da84-408d-a11c-f623999b2c4f'
  const mapObj = eCertificateMapObj(
    englishCertificateName,
    user,
    isThai,
    language
  )
  const versionJSON = JSON.stringify(version)
  return JSON.parse(replaceAll(versionJSON, mapObj))
}

export const onDownload = (uuid, mapObj) => (dispatch) => {
  dispatch(reducer.startLoading())
  callAPI({
    method: 'post',
    url: eCertificationCourseSettingDownload + '/' + uuid,
    responseType: 'blob',
    body: {
      mapObj: mapObj,
    },
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `e-certification.pdf`)
      document.body.appendChild(link)
      link.click()
    },
    onFinally: () => {
      dispatch(reducer.stopLoading())
    },
  })
}
