import React from 'react'
import { CustomDropdown } from '../../../../../../components/CRUD/components/CustomDropdown'
import { CustomDateInput } from '../../../../../../components/CRUD/components/CustomDateInput'
import { Box } from '@mui/material'
import { useSelector, shallowEqual } from 'react-redux'
import { handleSelectWarehouse } from '../../handler/handleSelectWarehouse'

export const MonthlyForm = () => {
  const { courseSelected, trainingDate } = useSelector(
    (state) => ({
      courseSelected: state.crud.formData.courseSelected,
      trainingDate: state.crud.formData.trainingDate,
    }),
    shallowEqual,
  )

  return (
    <>
      <Box display="flex" gap={3} mb={2}>
        <CustomDateInput
          // disabled
          fieldName="trainingDate"
          labelName="วันที่อบรม (Training date)"
          placeholder={'เลือกวันที่'}
          required={true}
          hideDisableDateRange={false}
          disabledStartDate={
            courseSelected?.startDate ? courseSelected?.startDate : null
          }
          disabledEndDate={
            courseSelected?.finishDate ? courseSelected?.finishDate : null
          }
          isShouldDisableDate={true}
        />
        <CustomDropdown
          required={true}
          fieldName="courseSelected"
          masterDataName="filteredCourses"
          labelName="ชื่อคอร์ส (Station)"
          placeholder="เลือก ชื่อคอร์ส"
          disabled={!(trainingDate && trainingDate !== 'Invalid Date')}
        />
      </Box>
      <Box display="flex" gap={3} mb={2}>
        <CustomDropdown
          required={true}
          fieldName="responsibleUser"
          labelName="ผู้รับผิดชอบ"
          placeholder="เลือกรายชื่อ"
          disabled={courseSelected ? false : true}
        />
        <CustomDropdown
          required={true}
          fieldName="warehouse"
          labelName="คลังทรัพย์สิน"
          placeholder="เลือก คลังทรัพย์สิน"
          // disabled={false}
          handleExtraChange={(event, value) => {
            handleSelectWarehouse(value)
          }}
        />
      </Box>
    </>
  )
}
