import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'
import _ from 'lodash'

const reportComp = createSlice({
  name: 'reportComp',
  initialState,
  reducers: {
    setReduxValue: (state, { payload }) => {
      const { key, value } = payload
      state[key] = value
    },
    setInitialReport: (state, { payload }) => {
      const {
        columns,
        filterColumnOptions,
        moduleId,
        onDownload,
        defaultColumns,
        columnName,
        columnNameTH,
      } = payload
      state.columns = columns
      state.filterColumnOptions = filterColumnOptions
      state.moduleId = moduleId
      state.onDownload = onDownload
      state.defaultColumns = defaultColumns
      state.columnName = columnName
      state.columnNameTH = columnNameTH
    },
    setReportConfig: (state, { payload }) => {
      _.set(state, 'reportConfig', payload.reportConfig)
    },
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startLoadingDrawer: (state) => {
      state.isLoadingDrawer = true
    },
    stopLoadingDrawer: (state) => {
      state.isLoadingDrawer = false
    },
    setIsOpenFilterDrawer: (state, { payload }) => {
      state.isOpenFilterDrawer = payload
    },
    setIsOpenManageColumnDrawer: (state, { payload }) => {
      state.isOpenManageColumnDrawer = payload
    },
    setColumns: (state, { payload }) => {
      state.columns = payload
    },
    setColumnSelected: (state, { payload }) => {
      state.columnSelected = payload
    },
    setIsFilter: (state, { payload }) => {
      state.isFilter = payload
    },
    setFiltersData: (state, { payload }) => {
      state.filters = payload
    },
    setValue: (state, { payload }) => {
      state.value = payload
    },
  },
})

export const {
  setReduxValue,
  setInitialReport,
  setReportConfig,
  startLoading,
  stopLoading,
  startLoadingDrawer,
  stopLoadingDrawer,
  setIsOpenFilterDrawer,
  setIsOpenManageColumnDrawer,
  setColumns,
  setColumnSelected,
  setIsFilter,
  setFiltersData,
  setValue,
} = reportComp.actions

export default reportComp.reducer
