import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { Column } from '../../../../../../components/Preview/Layout/Styled'
import { E_LEARNING_LEARNER_LOG_RESULT } from '../../../../../../constants/eLearning'
import { StyledDivider } from '../../../../components/View/Styled'
import FaceCard from './FaceCard'
import { StyledButton, StyledEnrollContainer } from './Styled'

const FaceHistory = () => {
  const [isExpand, setExpand] = useState(false)
  const { learnerRekognition, enrollData } = useSelector(
    (state) => state.eLearningCourseBatchLearnerDetail,
  )
  const eLearningIdCardEnroll = _.get(enrollData, 'eLearningIdCardEnroll', {})
  const idCardImageKey = _.get(eLearningIdCardEnroll, 'idCardImageKey', '')
  const faceImageKey = _.get(enrollData, 'faceImageKey', '')
  const attends = useMemo(() => {
    const attends = _.values(_.get(learnerRekognition, 'attend', []))
    const list = [[]]
    let i = 0
    _.forEach(attends[0], (item) => {
      list[i].push(item)
      if (item.status === E_LEARNING_LEARNER_LOG_RESULT.SUCCESS) {
        i++
        list.push([])
      }
    })
    return list.filter((data) => !_.isEmpty(data))
  }, [learnerRekognition])
  const learns = _.get(learnerRekognition, 'learn', {})

  return (
    <Column sx={{ gap: 2 }}>
      <Typography variant="h6">
        การเก็บใบหน้า (สุ่มตรวจเฉพาะหลักสูตร OIC)
      </Typography>

      {!_.isEmpty(idCardImageKey) && (
        <>
          <StyledEnrollContainer>
            <Column>
              <ImageDisplay imageKey={idCardImageKey} />
              <Typography variant="body2b">รูปจากบัตรประชาชน</Typography>
            </Column>
            <StyledDivider orientation="vertical" flexItem />
            <Column>
              <ImageDisplay imageKey={faceImageKey} />
              <Typography variant="body2b">รูปต้นฉบับ</Typography>
            </Column>
          </StyledEnrollContainer>
          {!isExpand ? (
            <StyledButton
              data-testid="btn-more"
              onClick={() => setExpand(true)}
            >
              ดูเพิ่มเติม
            </StyledButton>
          ) : (
            <>
              <StyledDivider />
              {_.map(_.values(attends), (items, key) => (
                <FaceCard key={key} items={items} />
              ))}
              {_.map(_.values(learns), (items, key) => (
                <FaceCard key={key} items={items} />
              ))}

              <StyledButton onClick={() => setExpand(false)}>
                ดูน้อยลง
              </StyledButton>
            </>
          )}
        </>
      )}
    </Column>
  )
}
export default FaceHistory

export const ImageDisplay = ({ imageKey }) => {
  return (
    <img
      src={`${window.__env__.REACT_APP_API_URL}/face-rekognition${imageKey}`}
    />
  )
}
