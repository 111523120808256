import {
  COLUMN_NAME,
  COLUMN_NAME_TH,
} from '../../../../../constants/reportCourseSetting/reportCourseSetting'
import _ from 'lodash'

export const handleLabelCustomColumn = (column) => {
  const columns = _.get(column, 'column', [])
  return _.reduce(
    columns,
    (acc, column, idx) => {
      const keyColumn = _.findKey(COLUMN_NAME, (col) => col === column)
      acc += COLUMN_NAME_TH[keyColumn]
      if (idx < columns.length - 1) acc += ' , '

      return acc
    },
    '',
  )
}
