import _ from 'lodash'
// import { enumStatus } from '../../../../../components/CRUD/enum/status'
import { tabTypeEnum } from '../enum/requisitionTypeEnum'

export const getBodyMutation = (data) => {
  const monthlyPlan = _.get(data, 'monthlyPlan', '')
  const trainingPlan = _.get(data, 'trainingPlan', '')
  const responsibility = _.get(data, 'responsibility', '')
  const station = trainingPlan
    ? { label: trainingPlan?.label, value: trainingPlan?.value }
    : ''
  const responsibleUser = responsibility
    ? {
        label: `${responsibility?.firstNameTH} ${responsibility?.lastNameTH}`,
        value: responsibility?.uuid,
      }
    : ''
  const courseSelected = trainingPlan
    ? {
        value: trainingPlan?.value,
        label: trainingPlan?.label,
        startDate: trainingPlan?.startDate,
        finishDate: trainingPlan?.finishDate,
        monthlyPlanUuid: monthlyPlan.uuid,
      }
    : ''
  let requisitionType = _.get(data, 'requisitionType', tabTypeEnum.OTHER)
  if (data?.uuid) {
    requisitionType = monthlyPlan ? tabTypeEnum.MONTHLY : tabTypeEnum.OTHER
  }
  return {
    id: _.get(data, 'id', ''),
    uuid: _.get(data, 'uuid', undefined),
    requisitionType: requisitionType,
    trainingDate: trainingPlan?.startDate ?? '',
    station: station,
    responsibleUser: responsibleUser,
    warehouse: _.get(data, 'warehouse', ''),
    annotation: _.get(data, 'remark', ''),
    status: _.get(data, 'status', ''),
    assets: _.get(data, 'assets', [
      {
        label: '',
        value: '',
        assetCode: '',
        assetCodeOption: [],
        qty: '',
        availableQty: '',
        totalQty: '',
        status: '',
      },
    ]),
    trainingPlan: _.get(data, 'trainingPlan', ''),
    monthlyPlan: monthlyPlan,
    responsibility: _.get(data, 'responsibility', ''),
    courseSelected: courseSelected,
  }
}
