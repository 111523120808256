export const initialValuePointForm = {
  event: '',
  point: '',
  remark: '',
}

export const initialState = {
  isLoading: false,
  isLoading2: false,
  isLoading3: false,
  isLoadingDrawer: false,
  search: {
    courseSearch: '',
    orderCourseBy: 'DESC',
    sortCourseBy: 'updatedAt',
    examSearch: '',
    handleCourseSearch: '',
    handleExamSearch: '',
    orderExamBy: 'DESC',
    sortExamBy: 'registerDateTime',
  },
  division: '',
  initialState: {
    contact: {
      email: '',
      mobileNo: '',
    },
    course: {
      result: [],
      totalCount: 0,
    },
    educational: [],
    exam: { result: [], totalCount: 0 },
    info: {
      agentCode: '',
      fullAgentCode: '',
      fullNameTH: '',
      image: '',
      uuid: '',
    },
    info2: { dateOfBirth: '', idCardNo: '' },
    license: [],
    work: {
      RAMCode: '',
      agentType: '',
      agentUnitName: '',
      level: '',
      status: '',
      unitNumber: '',
      workDistance: '',
      workStart: '',
      broker: '',
      brokerNumber: '',
    },
    updated: '',
    isSwitch: false,
    point: 0,
    badge: [],
  },
  pointDrawerOpen: false,
  pointDialog: {
    open: false,
    tab: '',
    pointForm: initialValuePointForm,
    cart: [],
    loading: false,
    cost: 0,
    remaining: 0,
    keyword: '',
    isConfirmation: false,
  },
  uploadDialog: {
    agentType: '',
    agentList: [],
    fileName: 'No File Chosen',
    file: null,
    isSuccess: false,
    processAgent: false,
    success: 0,
    total: 0,
    isLoading: false,
    uploadResult: null,
    downloading: false,
  },
  pagination: {
    courselimit: 100,
    coursePage: 1,
    examLimit: 100,
    examPage: 1,
  },
  agentPageType: '',
}
