import { memo } from 'react'
import { useInputField } from '../hooks/useInputField'
import { setFieldValue } from '../../../redux/slices/crud'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Checkbox, FormControlLabel } from '@mui/material'
import _ from 'lodash'

const CustomCheckbox = memo(
  ({ disabled, fieldName, handleExtraChange, labelText = '' }) => {
    const dispatch = useDispatch()
    const { value } = useInputField(fieldName)

    const { crudConfig } = useSelector(
      (state) => ({ crudConfig: state.crud.crudConfig }),
      shallowEqual,
    )

    function handleValueChange(event, value) {
      dispatch(setFieldValue({ key: `formData.${fieldName}`, value: value }))
      if (handleExtraChange) {
        handleExtraChange(event, value)
      }
    }

    return (
      <FormControlLabel
        control={
          <Checkbox
            id={`${crudConfig.moduleSlug}-${fieldName}`}
            checked={_.isBoolean(value) ? value : false}
            name={fieldName}
            disabled={disabled}
            onChange={handleValueChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label={labelText}
      />
    )
  },
)

CustomCheckbox.displayName = 'CustomCheckboxComponent'

export { CustomCheckbox }
