import * as yup from 'yup'
import { path } from './path'

export const E_EXAM_TYPE = {
  ASSOCIATION: 'ASSOCIATION',
  COMPANY: 'COMPANY',
  OTHER: 'OTHER',
}

export const E_EXAM_UPLOAD_STATUS = {
  SUCCESS: 'สำเร็จ',
  ERROR: 'ไม่สำเร็จ',
}

export const E_EXAM_UPLOAD_TEST_STATUS = {
  ACTIVE: 'สำเร็จ',
  ERROR: 'ไม่สำเร็จ',
}

export const SCHEDULE_STATUS = {
  OPEN_REGISTER: 'OPEN_REGISTER',
  IN_PROGRESS: 'IN_PROGRESS',
  CANCEL: 'CANCEL',
  CLOSED_REGISTER: 'CLOSED_REGISTER',
}

export const SCHEDULE_STATUS_TEXT = {
  OPEN_REGISTER: 'Open Register',
  IN_PROGRESS: 'In progress',
  CANCEL: 'Cancel',
  CLOSED_REGISTER: 'Closed Register',
}

export const STUDENT_STATUS = {
  WAITING_CHECK: 'WAITING_CHECK',
  WAITING_ANNOUNCEMENT: 'WAITING_ANNOUNCEMENT',
  ANNOUNCEMENT: 'ANNOUNCEMENT',
  PERMISSION_DENIED: 'PERMISSION_DENIED',
  CANCEL: 'CANCEL',
}

export const STUDENT_STATUS_TEXT = {
  WAITING_CHECK: 'รอตรวจสอบ',
  WAITING_ANNOUNCEMENT: 'รอประกาศสิทธิ์',
  ANNOUNCEMENT: 'ประกาศสิทธิ์แล้ว',
  PERMISSION_DENIED: 'ไม่มีสิทธิ์สอบ',
  CANCEL: 'ยกเลิกการสมัคร',
  CANCEL_BY_SELF: 'ยกเลิกโดยผู้สมัคร',
  CANCEL_BY_ADMIN: 'ยกเลิกโดยแอดมิน',
  CANCEL_EXAMINATION: 'ยกเลิกตารางสอบ',
  CANCEL_BY_MANAGER: 'ยกเลิกโดยผู้จัดการ',
}

export const TEST_RESULT_STATUS = {
  PASS: 'PASS',
  NOT_PASS: 'NOT_PASS',
  ABSENCE: 'ABSENCE',
}

export const TEST_RESULT_STATUS_TEXT = {
  PASS: 'ผ่าน',
  NOT_PASS: 'ไม่ผ่าน',
  ABSENCE: 'ขาดสอบ',
}

export const STUDENT_STATUS_DOT_COLOR = {
  รอตรวจสอบ: 'WAITING',
  รอประกาศสิทธิ์: 'INPROGRESS',
  ประกาศสิทธิ์แล้ว: 'ACTIVE',
  ไม่มีสิทธิ์สอบ: 'ERROR',
  ยกเลิกการสมัคร: 'DELETED',
  ยกเลิกโดยผู้สมัคร: 'DELETED',
  ยกเลิกโดยแอดมิน: 'DELETED',
  ยกเลิกตารางสอบ: 'DELETED',
  ยกเลิกโดยผู้จัดการ: 'DELETED',
}

export const STUDENT_STATUS_DOT_COLOR_TEXT = {
  WAITING_CHECK: 'WAITING',
  WAITING_ANNOUNCEMENT: 'INPROGRESS',
  ANNOUNCEMENT: 'ACTIVE',
  PERMISSION_DENIED: 'ERROR',
  CANCEL: 'DELETED',
  CANCEL_BY_SELF: 'DELETED',
  CANCEL_BY_ADMIN: 'DELETED',
  CANCEL_EXAMINATION: 'DELETED',
  CANCEL_BY_MANAGER: 'DELETED',
}

export const IMPORT_EXAMINER_TYPE = {
  ANNOUNCEMENT: 'ANNOUNCEMENT',
  TEST_RESULT: 'TEST_RESULT',
}

export const EXAMINATION_EVENT_STATUS = {
  CREATED: 'สร้าง',
  UPDATED: 'แก้ไข',
  DELETED: 'ลบ',
}

export const EXAMINER_EVENT_STATUS = {
  CREATED: 'CREATED',
  UPDATED: 'UPDATED',
  DELETED: 'DELETED',
  UPLOAD_PERMISSION: 'UPLOAD_PERMISSION',
  ANNOUNCE: 'ANNOUNCE',
  UPLOAD_RESULT: 'UPLOAD_RESULT',
  CREATED_STUDENT: 'CREATED_STUDENT',
  UPDATED_STUDENT: 'UPDATED_STUDENT',
  DELETED_STUDENT: 'DELETED_STUDENT',
  CANCEL_STUDENT: 'CANCEL_STUDENT',
  CANCEL_BY_SELF: 'CANCEL_BY_SELF',
  CANCEL_BY_ADMIN: 'CANCEL_BY_ADMIN',
  CANCEL_EXAMINATION: 'CANCEL_EXAMINATION',
  CANCEL_BY_MANAGER: 'CANCEL_BY_MANAGER',
}

export const EXAMINER_EVENT_STATUS_TH = {
  CREATED: 'สร้างตารางสอบ',
  UPDATED: 'แก้ไขตารางสอบ',
  DELETED: 'ลบตารางสอบ',
  UPLOAD_PERMISSION: 'อัปโหลดชื่อผู้มีสิทธิ์สอบ',
  ANNOUNCE: 'ประกาศรายชื่อผู้สอบ',
  UPLOAD_RESULT: 'อัปโหลดผลการสอบ',
  CREATED_STUDENT: 'เพิ่มรายชื่อผู้สอบ',
  UPDATED_STUDENT: 'แก้ไขรายชื่อผู้สอบ',
  DELETED_STUDENT: 'ลบรายชื่อผู้สอบ',
  CANCEL_STUDENT: 'ไม่มีสิทธิ์สอบ',
  CANCEL_BY_SELF: 'ยกเลิกโดยผู้สมัคร',
  CANCEL_BY_ADMIN: 'ยกเลิกโดยแอดมิน',
  CANCEL_EXAMINATION: 'ยกเลิกตารางสอบ',
  CANCEL_BY_MANAGER: 'ยกเลิกโดยผู้จัดการ',
}

export const EXAMINER_EVENT_HISTORY_STATUS_TH = {
  CREATED: 'สมัครสอบ',
  UPDATED: 'แก้ไขตารางสอบ',
  DELETED: 'ลบตารางสอบ',
  UPLOAD_PERMISSION: 'อัปโหลดชื่อผู้มีสิทธิ์สอบ',
  ANNOUNCE: 'ประกาศรายชื่อผู้สอบ',
  UPLOAD_RESULT: 'อัปโหลดผลการสอบ',
  CREATED_STUDENT: 'เพิ่มรายชื่อผู้สอบ',
  UPDATED_STUDENT: 'แก้ไขรายชื่อผู้สอบ',
  DELETED_STUDENT: 'ลบรายชื่อผู้สอบ',
  CANCEL_STUDENT: 'ไม่มีสิทธิ์สอบ',
  CANCEL_BY_SELF: 'ยกเลิกโดยผู้สมัคร',
  CANCEL_BY_ADMIN: 'ยกเลิกโดยแอดมิน',
  CANCEL_EXAMINATION: 'ยกเลิกตารางสอบ',
  CANCEL_BY_MANAGER: 'ยกเลิกโดยผู้จัดการ',
}

export const EXAMINER_TEST_RESULT_TEXT = {
  P: 'pass',
  F: 'fail',
  M: 'miss',
}

export const validationSchema = {
  date: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณาเลือกข้อมูลในช่องนี้')
    .nullable(),
  time: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณาเลือกข้อมูลในช่องนี้'),
  startTime: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณาเลือกข้อมูลในช่องนี้'),
  endTime: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  startDateRegister: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .nullable(),
  startTimeRegister: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  endDateRegister: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณาเลือกข้อมูลในช่องนี้')
    .nullable(),
  endTimeRegister: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  numberOfPeople: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .min(1, 'กรุณากรอกจำนวนมากว่า 0')
    .max(4, 'กรอกได้ไม่เกิน 9,999'),
  description: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .max(3000, 'ระบุได้ไม่เกิน 3000 ตัวอักษร')
    .nullable(),
}

export const validationExamAssociationSchema = yup.object({
  ...validationSchema,
  association: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณาเลือกข้อมูลในช่องนี้')
    .nullable(),
})
export const validationExamCompanySchema = yup.object({
  ...validationSchema,
  company: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณาเลือกข้อมูลในช่องนี้')
    .nullable(),
})
export const validationExamOtherSchema = yup.object({
  ...validationSchema,
  association: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณาเลือกข้อมูลในช่องนี้')
    .nullable(),
  otherName: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณาเลือกข้อมูลในช่องนี้')
    .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร')
    .nullable(),
})
export const headCells = [
  {
    id: 'examStatus',
    label: 'สถานะ',
    hideSortIcon: false,
  },
  {
    id: 'regionName',
    disablePadding: false,
    label: 'ภาค',
  },
  {
    id: 'examStation',
    disablePadding: false,
    label: 'สนามสอบ',
  },
  {
    id: 'examDate',
    label: 'วันและเวลาที่สอบ',
    disablePadding: false,
  },
  {
    id: 'numberOfPeople',
    label: 'จำนวน',
    disablePadding: false,
    width: '60px',
  },
  {
    id: 'examStartRegisterDate',
    label: 'วันเปิดรับสมัครและเวลา',
    disablePadding: false,
  },
  {
    id: 'examEndRegisterDate',
    label: 'วันที่ปิดรับสมัครและเวลา',
    disablePadding: false,
  },
  {
    id: 'actionExam',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    viewPath: `${path.eExamination}/examiner/list`,
    editPath: `${path.eExamination}/form`,
  },
]

export const headCellsCompany = [
  {
    id: 'examStatus',
    label: 'สถานะ',
    hideSortIcon: false,
  },
  {
    id: 'examStation',
    disablePadding: false,
    label: 'สนามสอบ',
  },
  {
    id: 'examDate',
    label: 'วันและเวลาที่สอบ',
    disablePadding: false,
  },
  {
    id: 'numberOfPeople',
    label: 'จำนวน',
    disablePadding: false,
    width: '60px',
  },
  {
    id: 'examStartRegisterDate',
    label: 'วันเปิดรับสมัครและเวลา',
    disablePadding: false,
  },
  {
    id: 'examEndRegisterDate',
    label: 'วันที่ปิดรับสมัครและเวลา',
    disablePadding: false,
  },
  {
    id: 'actionExam',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    viewPath: `${path.eExamination}/examiner/list`,
    editPath: `${path.eExamination}/form`,
  },
]

export const headCellsOther = [
  {
    id: 'examStatus',
    label: 'สถานะ',
    hideSortIcon: false,
  },
  {
    id: 'regionName',
    disablePadding: false,
    label: 'ภาค',
  },
  {
    id: 'proviceName',
    disablePadding: false,
    label: 'จังหวัด',
  },
  {
    id: 'examStation',
    disablePadding: false,
    label: 'สนามสอบ',
  },
  {
    id: 'examDate',
    label: 'วันและเวลาที่สอบ',
    disablePadding: false,
  },
  {
    id: 'numberOfPeople',
    label: 'จำนวน',
    disablePadding: false,
    width: '60px',
  },
  {
    id: 'examStartRegisterDate',
    label: 'วันเปิดรับสมัครและเวลา',
    disablePadding: false,
  },
  {
    id: 'examEndRegisterDate',
    label: 'วันที่ปิดรับสมัครและเวลา',
    disablePadding: false,
  },
  {
    id: 'actionExam',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    viewPath: `${path.eExamination}/examiner/list`,
    editPath: `${path.eExamination}/form`,
  },
]

export const headCellsExaminer = [
  {
    id: 'order',
    label: 'ลำดับ',
    hideSortIcon: false,
  },
  {
    id: 'examinerStatus',
    disablePadding: false,
    label: 'สถานะเข้าสอบ',
    minWidth: '150px',
  },
  {
    id: 'userCode',
    label: 'Agent Code',
    disablePadding: false,
  },
  {
    id: 'announcement',
    label: 'รอประกาศสิทธิ์',
    disablePadding: false,
  },
  {
    id: 'firstNameTH',
    label: 'ชื่อ',
    disablePadding: false,
  },
  {
    id: 'lastNameTH',
    label: 'นามสกุล',
    disablePadding: false,
  },
  {
    id: 'unitNumber',
    label: 'รหัสหน่วย',
    disablePadding: false,
  },
  {
    id: 'mobileNo',
    label: 'โทรศัพท์มือถือ',
    disablePadding: false,
  },
  {
    id: 'examResult',
    label: 'ผลสอบ',
    disablePadding: false,
  },
  {
    id: 'actionExam',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    viewPath: `${path.eExamination}/examiner/detail`,
    editPath: `${path.eExamination}/form`,
    isExaminer: true,
  },
]

export const EXAMINATION_UPLOAD_OTHER_DOC_CONFIG_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}

export const monthNames = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
]

export const anounceExaminerValidationSchema = yup.object({
  scheduleName: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .max(3000, 'ระบุได้ไม่เกิน 3000 ตัวอักษร')
    .nullable(),
  annotation: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .max(3000, 'ระบุได้ไม่เกิน 3000 ตัวอักษร')
    .nullable(),
})
