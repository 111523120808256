import React, { useEffect, useState, useContext } from 'react'
import Typography from '@mui/material/Typography'
import { useHistory } from 'react-router-dom'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import AddIcon from '@mui/icons-material/Add'
import _ from 'lodash'
import useTheme from '@mui/system/useTheme'
import { Link } from 'react-router-dom'
import { useLocalStorage } from '@rehooks/local-storage'
import { DialogContext } from '../../context/dialog-context'
import Table from '../../components/Table'
import Breadcrumbs from '../../components/Breadcrumbs'
import FilterDrawer from './FilterDrawerVersion'
import { downloadCourse, filterCourse } from '../../utils/apiPath'
import { callAPI, handleDownload, validatePermission } from '../../utils/lib'
import AlertDialog from '../../components/AlertDialog'
import { handleFilterTotal } from '../../redux/slices/table/events'

const breadcrumbList = [{ title: 'จัดการหลักสูตร', link: '/', pointer: false }]

const CourseSettingList = () => {
  const theme = useTheme()
  const history = useHistory()
  const [user] = useLocalStorage('user')
  const { context, setContext: setDialog } = useContext(DialogContext)
  const dialog = context.dialog
  const [isLoading, setIsLoading] = useState(false)
  const [limit, setLimit] = useState('100')
  const [order, setOrder] = useState('DESC')
  const [page, setPage] = useState(-1)
  const [sort, setSort] = useState('')
  const [dataList, setDataList] = useState([])
  const [allCount, setAllCount] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [textFilter, setTextFilter] = useState({
    courseCode: '',
    englishName: '',
    updatedBy: '',
    numberOfVersions: -1,
  })
  const [text, setText] = useState('')
  const [status, setStatus] = useState([
    'ACTIVE',
    'INACTIVE',
    'WAITING_FOR_ACTIVE',
    'DRAFT',
  ])
  const [isFilterDrawer, setIsFilterDrawer] = useState(false)
  const [tablePage, setTablePage] = useState(0)
  const [filterTotal, setFilterTotal] = useState(0)

  const getPermissionView = validatePermission({
    user: user,
    moduleType: 'COURSE_SETTING',
    permission: ['VIEW'],
  })

  useEffect(() => {
    fetchDataList({
      method: 'fetch',
      statusList: status,
      limit,
      order,
      page,
      sort,
      search: searchText,
      filter: textFilter,
    })
  }, [limit, order, sort, page])

  const fetchDataList = async ({
    method,
    statusList,
    limit,
    order,
    page,
    sort,
    search,
    filter,
  }) => {
    const sortBy = sort === '' ? 'updatedAt' : sort
    const realPage = page <= 0 ? 1 : +page
    const numberOfVersions =
      _.isNaN(filter.numberOfVersions) ||
      filter.numberOfVersions === 0 ||
      filter.numberOfVersions < -1
        ? 0
        : filter.numberOfVersions

    setIsLoading(true)
    const body = {
      courseCode: filter.courseCode || '',
      englishName: method !== 'fetch' ? search : searchText,
      numberOfVersions: numberOfVersions,
      nameForLearner: filter.nameForLearner || '',
      updatedBy: filter.updatedBy || '',
      limit: limit,
      page: realPage,
      order: order.toString().toUpperCase(),
      sort: sortBy,
      status: statusList,
      createdBy: filter.createdBy || '',
    }
    await callAPI({
      url: filterCourse,
      method: 'POST',
      body: body,
      onSuccess: (res) => {
        if (res.result.length > 0) {
          res.result.map((item) => {
            item.statusCourse = item.status
            item.nameForLearner = item.nameForLearner || '-'
          })
        }
        setDataList(res.result)
        setAllCount(res.totalCount)
      },
      onFinally: () => {
        setIsLoading(false)
      },
    })
  }

  return (
    <Box sx={{ pb: 2 }}>
      <Box sx={isLoading && { opacity: 0.4, pointerEvents: 'none' }}>
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">จัดการหลักสูตร (Course Setting)</Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />
        {validatePermission({
          user: user,
          moduleType: 'COURSE_SETTING',
          permission: ['CREATE'],
        }) && (
          <Link data-testid={`btn-course-setting`} to="/course-setting/form">
            <Button
              sx={{
                ml: 2,
                [theme.breakpoints.up('md')]: {
                  position: 'absolute',
                  mt: -10,
                  mr: 3,
                },
                right: 0,
              }}
              startIcon={<AddIcon />}
              variant="contained"
            >
              สร้างหลักสูตร
            </Button>
          </Link>
        )}
        <Card sx={{ minWidth: 275, mx: 3, mt: 3 }}>
          <Table
            data={dataList}
            headCells={headCells(getPermissionView)}
            onView={(row) => onView(row, history)}
            placeholder="ค้นหาชื่อหลักสูตร EN"
            searchKey={'title'}
            setLimit={setLimit}
            order={order}
            setOrder={setOrder}
            page={page}
            setPage={setPage}
            sort={sort}
            setSort={setSort}
            allCount={allCount}
            handleSearch={(text) =>
              handleQuickSearch(
                text,
                limit,
                order,
                sort,
                setTablePage,
                fetchDataList,
                setTextFilter
              )
            }
            onDownload={(selected) =>
              onDownload(selected, order, sort, setIsLoading)
            }
            setIsFilterDrawer={setIsFilterDrawer}
            searchText={searchText}
            setSearchText={setSearchText}
            tablePage={tablePage}
            setTablePage={setTablePage}
            text={text}
            setText={setText}
            filterTotal={filterTotal}
          />
        </Card>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <FilterDrawer
        open={isFilterDrawer}
        onClose={() => setIsFilterDrawer(false)}
        onFilterClick={(filter) =>
          onFilterClick(
            filter,
            limit,
            order,
            sort,
            setSearchText,
            setText,
            setStatus,
            setTablePage,
            fetchDataList,
            setTextFilter,
            setFilterTotal
          )
        }
        drawerType={'course'}
      />
      <AlertDialog
        open={dialog.open}
        setOpen={setDialog}
        handleClose={() => handleClose(setDialog)}
        title={dialog.title}
        content={dialog.content}
        variant={dialog.variant}
        onConfirmClick={dialog.onConfirmClick}
        onCancelClick={dialog.onCancelClick}
        isLoading={isLoading}
      />
    </Box>
  )
}
export default CourseSettingList

export const onView = (row, history) => {
  history.push('/course-setting/version/' + row.courseCode)
}

export const handleClose = (setDialog) => {
  setDialog({ open: false })
}

export const headCells = (getPermissionView) => {
  return [
    {
      id: 'statusCourse',
      disablePadding: false,
    },
    {
      id: 'courseCode',
      disablePadding: false,
      label: 'รหัสหลักสูตร',
      width: '220px',
    },
    {
      id: 'englishName',
      label: 'ชื่อหลักสูตร EN',
    },
    {
      id: 'nameForLearner',
      label: 'ชื่อหลักสูตร (สำหรับ Learner)',
    },
    {
      id: 'numberOfVersions',
      disablePadding: false,
      label: 'จำนวนเวอร์ชั่น',
      width: '150px',
    },
    {
      id: 'updatedBy',
      disablePadding: false,
      label: 'แก้ไขล่าสุดโดย',
      width: '170px',
    },
    {
      id: 'action',
      disablePadding: false,
      label: 'คำสั่ง',
      width: '90px',
      hideSortIcon: true,
      hideView: getPermissionView ? false : true,
      hideEdit: true,
      hideDelete: true,
    },
  ]
}

export const handleQuickSearch = async (
  text,
  limit,
  order,
  sort,
  setTablePage,
  fetchDataList,
  setTextFilter
) => {
  setTablePage(0)
  const filterText = {
    courseCode: '',
    englishName: '',
    updatedBy: '',
    numberOfVersions: -1,
  }
  setTextFilter(filterText)

  const statusList = [
    'ACTIVE',
    'INACTIVE',
    'WAITING_FOR_ACTIVE',
    'DELETED',
    'DRAFT',
  ]
  await fetchDataList({
    method: 'search',
    statusList: statusList,
    limit: limit,
    order: order,
    page: 1,
    sort: sort,
    search: text,
    filter: filterText,
  })
}

export const onFilterClick = async (
  filter,
  limit,
  order,
  sort,
  setSearchText,
  setText,
  setStatus,
  setTablePage,
  fetchDataList,
  setTextFilter,
  setFilterTotal
) => {
  setSearchText('')
  setText('')
  const statusList = []
  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      statusList.push('ACTIVE')
    }
    if (filter.status.inActiveChecked) {
      statusList.push('INACTIVE')
    }
    if (filter.status.pendingChecked) {
      statusList.push('WAITING_FOR_ACTIVE')
    }
    if (filter.status.deletedChecked) {
      statusList.push('DELETED')
    }
    if (filter.status.draftChecked) {
      statusList.push('DRAFT')
    }
  } else {
    statusList.push('ACTIVE', 'INACTIVE', 'WAITING_FOR_ACTIVE', 'DRAFT')
  }
  const numberOfVersions =
    filter.numberOfVersionsChecked && filter.numberOfVersions !== ''
      ? filter.numberOfVersions
      : 0
  setStatus(statusList)
  const filterText = {
    courseCode: filter.courseCodeChecked ? filter.courseCode : '',
    englishName: filter.englishNameChecked ? filter.englishName : '',
    nameForLearner: filter.nameForLearnerChecked ? filter.nameForLearner : '',
    updatedBy: filter.updatedByChecked ? filter.updatedBy : '',
    numberOfVersions: numberOfVersions === 0 ? -1 : numberOfVersions,
    createdBy: filter.createdByChecked ? filter.createdBy : '',
  }
  setSearchText(filterText.englishName)
  setTextFilter(filterText)
  setTablePage(0)
  fetchDataList({
    method: 'filter',
    statusList: statusList,
    limit: limit,
    order: order,
    page: 1,
    sort: sort,
    search: filterText.englishName,
    filter: filterText,
  })
  setFilterTotal(handleFilterTotal(filter))
}

export const onDownload = async (selected, order, sort, setIsLoading) => {
  setIsLoading(true)
  const body = {
    courseCode: '',
    englishName: '',
    nameForLearner: '',
    numberOfVersions: -1,
    updatedBy: '',
    order: order.toUpperCase(),
    sort: sort === '' ? 'updatedAt' : sort,
    status: [],
    type: 'CUSTOM',
    list: selected,
  }
  await handleDownload({
    url: downloadCourse,
    body: body,
    fileName: `รายการจัดการหลักสูตร.xlsx`,
  })
  setIsLoading(false)
}
