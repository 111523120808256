import { EditTwoTone, RemoveRedEyeTwoTone } from '@mui/icons-material'
import { StyledColumn, StyledRow } from '../../Styled'
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import useTheme from '@mui/system/useTheme'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import {
  filteredRoomList,
  handleLabelDateTime,
  handleRoomLabel,
  handleTime,
} from '../event'
import dayjs from 'dayjs'
import { enumRequestType } from '../../View/Content/CalendarCard/constanst'
import {
  BOOKING_PERIOD_TYPE,
  STATUS_TYPE,
  statusList,
} from '../../../../constants/roomManagement'
import { RowLabels } from './RowLabels'
import {
  openEditFormDrawerBooking,
  openViewDrawerBooking,
} from '../../../../redux/slices/roomManagement/Drawer'
import { useLocalStorage } from '@rehooks/local-storage'

export const RoomRequestItem = (props) => {
  const theme = useTheme()
  const [user] = useLocalStorage('user')
  const { stationUuid } = useSelector((state) => ({
    stationUuid: state.roomManagement.stationUuid,
  }))
  const dispatch = useDispatch()
  const {
    uuid,
    requestId,
    requestType,
    bookingTopic,
    roomManagementMeetingRoom,
    status,
    bookFor,
    bookForOther,
    bookingPeriod,
    createdAtTime,
  } = props

  const bookForName = !_.isEmpty(bookFor)
    ? bookFor?.firstNameTH + ' ' + bookFor?.lastNameTH
    : !_.isEmpty(bookForOther)
    ? bookForOther
    : '-'

  const sortedRoom = _.orderBy(roomManagementMeetingRoom, 'id', 'asc')
  const hasRoomFiltered = _.filter(
    sortedRoom,
    (room) =>
      !_.isNull(_.get(room, 'meetingRoom')) ||
      !_.isNull(_.get(room, 'meetingRoomOther')) ||
      _.get(room, 'isOnline'),
  )

  let roomName = '-'
  const meetingRoom = _.get(hasRoomFiltered, '[0].meetingRoom', '')
  const meetingRoomOther = _.get(hasRoomFiltered, '[0].meetingRoomOther', null)
  const isOnline = _.get(hasRoomFiltered, '[0].isOnline', false)
  if (!_.isEmpty(meetingRoom)) {
    roomName = _.get(meetingRoom, 'roomName', '')
  } else if (!_.isNull(meetingRoomOther)) {
    roomName = _.get(hasRoomFiltered, '[0].meetingRoomOther', '')
  } else if (isOnline) {
    roomName = 'Online'
  }
  const stationName = _.get(
    sortedRoom,
    '[0].meetingRoom.station.station',
    _.get(sortedRoom, '[0].station.station', ''),
  )

  const filteredRoom = filteredRoomList(hasRoomFiltered)
  const roomNameList = _.map(filteredRoom, (room) => {
    return handleRoomLabel(room)
  })

  const dateLabel = handleLabelDateTime({ ...props })
  const dateBookingList = _.map(sortedRoom, (room) => {
    const bookingDate = _.get(room, 'bookingDate', '')
    const period = _.get(room, 'period', '')
    const startTimeTraining = _.get(room, 'startTimeTraining', '')
    const endTimeTraining = _.get(room, 'endTimeTraining', '')
    const startTimeRoom = _.get(room, 'startTimeRoom', '')
    const endTimeRoom = _.get(room, 'endTimeRoom', '')
    let timeLabel = ''
    if (
      startTimeRoom === startTimeTraining &&
      endTimeRoom === endTimeTraining
    ) {
      timeLabel = handleTime(period, startTimeRoom, endTimeRoom)
    } else {
      timeLabel = ` ${startTimeRoom} - ${endTimeRoom}`
    }
    const roomLabel = handleRoomLabel(room)

    return `${roomLabel} วันที่ ${dayjs(bookingDate).format(
      'DD/MM/YY',
    )} เวลา ${timeLabel}`
  })
  const threeDaysPass = dayjs(new Date()).subtract(3, 'day')
  const isExpired =
    requestType === enumRequestType.MONTHLY_PLAN &&
    status !== STATUS_TYPE.COMPLETED &&
    status !== STATUS_TYPE.CANCEL
      ? dayjs(createdAtTime).isSameOrBefore(threeDaysPass, 'day')
      : false

  const primaryColor =
    requestType === enumRequestType.E_BOOKING
      ? '#C9DDFF'
      : isExpired
      ? '#C91432'
      : '#FFE0C7'
  const secondaryColor =
    requestType === enumRequestType.E_BOOKING
      ? '#EFF5FF'
      : isExpired
      ? '#FFDFE5'
      : '#FFF5EE'

  const statusTicket = _.chain(statusList).find({ status: status }).value()

  const label = _.get(statusTicket, 'label', '')
  const labelColor = _.get(statusTicket, 'labelColor', '')
  const bgColor = _.get(statusTicket, 'bgColor', '')

  const permissionList = _.get(user, 'permission', [])
  const hasPermissionALL = _.find(permissionList, (item) => {
    return (
      item.owner === 'ALL' &&
      item.permission === 'EDIT' &&
      item.module === 'ROOMMANAGEMENT_SETTING'
    )
  })
  const hasPermissionPIC = _.find(permissionList, (item) => {
    return (
      item.owner === 'PIC' &&
      item.permission === 'EDIT' &&
      item.module === 'ROOMMANAGEMENT_SETTING'
    )
  })
  const isUserStation = !_.isEmpty(
    _.find(_.get(user, 'area', []), (item) => {
      return item.station.uuid === stationUuid
    }),
  )

  return (
    <Tooltip
      title={
        isExpired ? (
          <Typography variant="tooltip">ขอให้ดำเนินการเร่งด่วน</Typography>
        ) : null
      }
      followCursor
      componentsProps={{
        tooltip: {
          sx: {
            background: theme?.palette?.blue?.azure,
            color: theme?.palette?.common?.white,
            boxShadow: theme?.palette?.boxShadow?.main,
          },
        },
      }}
    >
      <StyledRow sx={{ height: { sm: 88, xs: 199 }, position: 'relative' }}>
        <StyledColumn
          sx={{
            position: 'absolute',
            left: { sm: 98, xs: 74 },
            top: 0,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              width: 18,
              height: 9,
              borderBottomLeftRadius: 18,
              borderBottomRightRadius: 18,
            }}
          />
          <div
            style={{
              borderRight: `1px solid ${theme.palette.text.gray}`,
              height: 'calc(100% - 18px)',
              borderRightStyle: 'dashed',
            }}
          />
          <div
            style={{
              backgroundColor: 'white',
              width: 18,
              height: 9,
              borderTopLeftRadius: 18,
              borderTopRightRadius: 18,
            }}
          />
        </StyledColumn>
        <StyledColumn
          sx={{
            background: primaryColor,
            p: { sm: 2.5, xs: 1 },
            alignItems: 'center',
            width: { sm: 110, xs: 'unset' },
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="body3b"
            color={isExpired ? `text.white` : `text.secondary`}
          >
            {requestType === enumRequestType.E_BOOKING
              ? 'E-Booking'
              : 'Monthly Plan'}
          </Typography>
          <Typography
            variant="body2b"
            color={isExpired ? `text.white` : `text.primary`}
          >
            {requestId}
          </Typography>
        </StyledColumn>
        <StyledRow
          sx={{
            backgroundColor: secondaryColor,
            p: 2,
            height: '100%',
            width: '100%',
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            gap: { sm: 2, xs: 1 },
          }}
        >
          <Grid
            sx={{ pl: { sm: 2, xs: 1 } }}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 3 }}
          >
            <Grid item sm={4} xs={12}>
              <RowLabels title={'หัวข้อ'} label={bookingTopic} />
            </Grid>
            <Grid item sm={4} xs={12}>
              <RowLabels title={'Station'} label={stationName} />
            </Grid>
            <Grid item sm={4} xs={12}>
              <RowLabels
                title={'ห้อง'}
                label={roomName}
                moreThanOne={filteredRoom?.length > 1}
                listAll={roomNameList}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <RowLabels
                title={'วันเวลาใช้ห้อง'}
                label={dateLabel}
                moreThanOne={
                  (hasRoomFiltered?.length > 1 ||
                    (requestType === 'MONTHLY_PLAN' &&
                      dateBookingList.length > 1)) &&
                  bookingPeriod === BOOKING_PERIOD_TYPE.CUSTOM
                }
                listAll={dateBookingList}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <RowLabels title={'ผู้ขอ'} label={bookForName} />
            </Grid>
          </Grid>
          <StyledColumn sx={{ alignItems: 'center', minWidth: 74 }}>
            {!_.isNull(status) && status !== STATUS_TYPE.CONFIRM && (
              <Box
                sx={{
                  backgroundColor: bgColor ? bgColor : theme.palette.white.main,
                  boxShadow: `0px 0px 0px 1px ${theme.palette.grey.grey300}`,
                  padding: `${theme.spacing(0.25)} ${theme.spacing(0.75)}`,
                  borderRadius: 15,
                  height: 20,
                  width: 74,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="body3b" color={labelColor}>
                  {label}
                </Typography>
              </Box>
            )}
            <IconButton
              onClick={() => {
                status === STATUS_TYPE.IN_PROGRESS &&
                (hasPermissionALL || (hasPermissionPIC && isUserStation))
                  ? dispatch(openEditFormDrawerBooking({ bookingUuid: uuid }))
                  : dispatch(openViewDrawerBooking({ bookingUuid: uuid }))
              }}
            >
              {status === STATUS_TYPE.CANCEL ||
              requestType === enumRequestType.E_BOOKING ? (
                <RemoveRedEyeTwoTone color="primary" />
              ) : (
                <EditTwoTone color="primary" />
              )}
            </IconButton>
          </StyledColumn>
        </StyledRow>
      </StyledRow>
    </Tooltip>
  )
}
