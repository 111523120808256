import React from 'react'
import { Box, Typography } from '@mui/material'
import QRCode from 'qrcode.react'
import { useTheme } from '@mui/system'

export const QRCodeTemplate = ({
  link,
  size,
  boxSx = {},
  qrStyle = {},
  titleSx = { mt: 4 },
  title = '',
  id = '',
  variantTitle = 'h6',
  isHidden = false,
}) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        ...boxSx,
        backgroundColor: `${theme?.palette?.common?.white}`,
        zIndex: isHidden ? -999 : 1,
        position: isHidden ? 'absolute' : 'static',
        mx: isHidden ? 'auto' : 0,
      }}
      id={id}
      display={isHidden ? 'none' : 'flex'}
      flexDirection="column"
    >
      <Typography
        sx={{ ...titleSx, textAlign: 'center', mt: isHidden ? titleSx.mt : 0 }}
        variant={isHidden ? 'h6' : variantTitle}
      >
        {title}
      </Typography>
      <QRCode
        id="qr-code"
        style={{ ...qrStyle, margin: 'auto' }}
        size={isHidden ? 480 : size}
        value={link}
        includeMargin={true}
        marginSize={4}
      />
    </Box>
  )
}
