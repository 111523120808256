import React from 'react'
import _ from 'lodash'
import { setTabActiveCol } from '../../../../redux/slices/reportCourseSetting/List'
import { StyledTab } from '../../Styled'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import TabContentMain from './TabContentMain'
import { tabsDrawerList } from '../model'
import { StyledTabs } from '../Styled'

const MainTabs = () => {
  const dispatch = useDispatch()
  const { tabActiveColDrawer } = useSelector(
    (state) => ({
      tabActiveColDrawer: state.reportCourseSetting.tabActiveColDrawer,
    }),
    shallowEqual,
  )

  const handleChangeTab = (e, newValue) => {
    dispatch(setTabActiveCol({ key: 'tabActiveColDrawer', value: newValue }))
  }

  return (
    <>
      <StyledTabs
        hasBorderBottom={true}
        variant="scrollable"
        scrollButtons="auto"
        value={tabActiveColDrawer}
        onChange={handleChangeTab}
      >
        {_.map(tabsDrawerList, (tab, idx) => (
          <StyledTab key={idx} label={tab.label} />
        ))}
      </StyledTabs>
      <TabContentMain />
    </>
  )
}

export default MainTabs
