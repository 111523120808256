import React from 'react'
import _ from 'lodash'
import {
  loadingDialog,
  openDialog,
  closeDialog,
  stopLoadingDialog,
} from '../../../../../../../redux/slices/dialog'
import { resetForm } from '../../../../../../../redux/slices/manageClassLearner'
import AddLearnerDialog from '../AddLearnerDialog'
import { announceCourseBatchLearner } from '../../../../../../../services/eLearning/course/form'

export const handleAddLearner = () => (dispatch) => {
  dispatch(resetForm())
  dispatch(
    openDialog({
      type: 'mini',
      maxWidth: 'form',
      disableButton: true,
      content: <AddLearnerDialog />,
    }),
  )
}

export const handleAnnounceLearner = (uuid, selected, rows) => (dispatch) => {
  const mapSelectedList = selected.map((item) => {
    return { uuid: item }
  })
  const intersecSelected = _.intersectionBy(rows, mapSelectedList, 'uuid')
  const filterWaitingAnnounceStatus = intersecSelected.filter(
    (item) => item.statusLearner === 'WAITING_ANNOUNCE',
  )
  const uuidWaitingAnnounceList = filterWaitingAnnounceStatus.map((item) => {
    return item.uuid
  })
  const body = {
    courseBatchUuid: uuid,
    courseLearner: uuidWaitingAnnounceList,
  }
  dispatch(
    openDialog({
      title: 'ประกาศผู้มีสิทธิ์เข้าเรียน',
      message:
        'ระบบจะอัปเดตสถานะพร้อมส่งอีเมลยืนยันแจ้งกับผู้เรียนที่มีสิทธิ์ทราบต้องการดำเนินการเลยหรือไม่',
      handleConfirm: async () => {
        dispatch(loadingDialog())
        await dispatch(announceCourseBatchLearner(body))
        dispatch(stopLoadingDialog())
        dispatch(closeDialog())
        window.location.reload()
      },
    }),
  )
}
