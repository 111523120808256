import axios from 'axios'
import _ from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { reportFilter, reportGetOptions } from '../../utils/apiPath'

export const filterReport = createAsyncThunk(
  'report/filterReport',
  async ({ modulePath, body }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${reportFilter}${modulePath}`, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getOptionsReportByKey = createAsyncThunk(
  'reportDashboard/getOptionsReportByKey',
  async ({ modulePath, body }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${reportGetOptions}${modulePath}`,
        body,
      )
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
