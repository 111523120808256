import Box from '@mui/material/Box'
import { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import Breadcrumbs from '../../../../../components/Breadcrumbs'
import { breadcrumbStationForm } from '../../Module/Lib/constanst'
import {
  examinationCompany,
} from '../../../../../utils/apiPath'
import Form from '../Form'
import { validationSchemaStation } from '../../Module/Lib/constanst'
import { DialogContext } from '../../../../../context/dialog-context'
import { callAPI } from '../../../../../utils/lib'
import { onView } from '../../Module/Lib/constanst'
import _ from 'lodash'


const Index = ({isEditForm}) => {
  const { id: uuid } = useParams()
  const titleText = isEditForm ? 'แก้ไขสเตชั่น' : 'สร้างสเตชั่น'
  const history = useHistory()

  useEffect(() => {
    if (isEditForm) {
      setIsPageLoading(true)
      fetchStationById({
        uuid,
        setInitialValues,
        setDisplayTitle,
        formik,
        setDialog,
        setIsPageLoading,
      })
    }
  }, [isEditForm])

  const [isPageLoading, setIsPageLoading] = useState(false)
  const { context, setContext: setDialog } = useContext(DialogContext)
  const dialog = context.dialog
  const [isLoading, setIsLoading] = useState(false)
  const [displayTitle, setDisplayTitle] = useState('')
  const [initialValues, setInitialValues] = useState({
    station: '',
    initial: '',
    stationName: '',
    stationNameEN: '',
    locationCode: '',
    description: '',
    annotation: '',
    status: false,
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaStation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      values.status = initialValues.status
      setDialog({
        ...dialog,
        title: 'ยืนยันการบันทึกข้อมูล',
        content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        variant: 'save',
        open: true,
        isLoading: false,
        onConfirmClick: () =>
          submitStationField({
            uuid,
            values,
            isEditForm,
            setDialog,
            setIsLoading,
            history,
            formik,
          }),
        onCancelClick: () =>
          setDialog({
            variant: 'save',
            content: '',
            open: false,
          }),
      })
    },
  })

  return (
    <>
      <Box sx={{ pb: 2 }}>
        <Box
          sx={{
            opacity: isPageLoading ? 0.4 : 'unset',
            pointerEvents: isPageLoading ? 'none' : 'unset',
          }}
        >
          <Box
            sx={{
              mx: 3,
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            <Typography variant="h4">
              {titleText}
              {isEditForm && ` : ${displayTitle}`}
            </Typography>
          </Box>
          <Breadcrumbs
            sx={{ mx: 3, mb: 3 }}
            menuList={breadcrumbStationForm({titleText: titleText})}
          />
          <Form
            isEditForm={isEditForm}
            dialog={dialog}
            setDialog={setDialog}
            handleClose={() => onCancelClickDialog(setDialog)}
            formik={formik}
            isLoading={isLoading}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
          />
        </Box>
        <CircularProgress
          disableShrink
          sx={{
            position: 'absolute',
            display: isPageLoading ? 'unset' : 'none',
            top: '50%',
            left: '50%',
            color: 'primary.main',
          }}
        />
      </Box>
    </>
  )
}

export const onCancelClickDialog = (setDialog) => {
  setDialog({ open: false })
}

export const submitStationField = async ({
  uuid,
  values,
  isEditForm,
  setDialog,
  setIsLoading,
  history,
  formik,
}) => {
  const methodType = isEditForm ? 'put' : 'post'
  let body = {
    station: values.station,
    initial: values.initial,
    academyStationName: values.stationName,
    academyStationNameEN: values.stationNameEN,
    locationCode: values.locationCode,
    description: values.description,
    annotation: values.annotation,
    status: values.status ? 'ACTIVE' : 'INACTIVE',
  }
  if (isEditForm)
    body = {
      ...body,
      id: values.id,
      uuid: uuid,
    }

  setDialog({ open: true, isLoading: true })
  formik.setErrors([])
  callAPI({
    method: methodType,
    url: examinationCompany,
    body: body,
    onSuccess: (res) => {
      const uuidLink = isEditForm ? uuid : res
      setDialog({
        isLoading: false,
        title: 'สำเร็จ',
        content: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        open: true,
        variant: 'success',
        onConfirmClick: () => {
          setDialog({ variant: 'success', open: false })
          onView({ uuid: uuidLink }, history, true)
        },
      })
    },
    onError: (er) => {
      let errors = {...formik.errors}
      const key = _.get(er.response.data, 'message[0].constraints.key', '').split(',')
      key.map((item) => {
        if(item === 'locationCode'){
          errors = { ...errors, [item]: 'รหัสนี้ถูกใช้งานแล้ว'}
        }else{
          errors = { ...errors, [item]: 'ชื่อนี้ถูกใช้งานแล้ว'}
        }
      })
      formik.setErrors(errors)
      setDialog({ open: false, isLoading: false })
    },
    onFinally: () => {
      setIsLoading(false)
    },
  })
}

export const fetchStationById = async ({
  uuid,
  setInitialValues,
  setDisplayTitle,
  formik,
  setDialog,
  setIsPageLoading,
}) => {
  callAPI({
    method: 'get',
    url: examinationCompany + `/${uuid}`,
    onSuccess: (res) => {
      if(res?.result.length > 0){
        const { 
          station, initial,
          annotation, description, id, locationCode, 
          academyStationName, academyStationNameEN, updatedBy, updatedAt,
          createdAt, createdBy
        } = res.result[0]
        const status = _.get(res.result[0], 'status', true)
        setInitialValues((prevInitialValues)=>{
          return {
            ...prevInitialValues,
            status: status === 'ACTIVE' ? true : false,
            updatedBy: updatedBy,
            updatedAt: updatedAt, 
            createdAt: createdAt,
            createdBy: createdBy,
          }
        })
        setDisplayTitle(academyStationName ?? '')
        setValue({key: 'id', value: id, formik, setInitialValues})
        setValue({key: 'station', value: station, formik, setInitialValues})
        setValue({key: 'initial', value: initial, formik, setInitialValues})
        setValue({key: 'stationName', value: academyStationName, formik, setInitialValues})
        setValue({key: 'stationNameEN', value: academyStationNameEN, formik, setInitialValues})
        setValue({key: 'locationCode', value: locationCode, formik, setInitialValues})
        setValue({key: 'description', value: description, formik, setInitialValues})
        setValue({key: 'annotation', value: annotation, formik, setInitialValues})
      }
    },
    onError: (er) => {
      formik.setErrors({ jobTitle: er.response.data.message[0] })
      setDialog({
        open: true,
        variant: 'fail',
        content: er.response.data.message[0],
        onConfirmClick: () =>
          setDialog({
            variant: 'fail',
            content: er.response.data.message[0],
            open: false,
          }),
      })
    },
    onFinally: () => {
      setIsPageLoading(false)
    },
  })
}

export const setValue = (props) => {
  const { key, value, formik, setInitialValues } = props
  setInitialValues((prevInitialValues)=>{
    return {
      ...prevInitialValues,
      [key]: value ?? '',
    }
  })
  formik.setFieldValue(key, value ?? '')
}

export default Index
