import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { handleNumberKeyDown, handlePasteFormat } from '../../../utils/lib'
import TextInput from '../../../components/Input/TextInput'
import _ from 'lodash'

export const handleInputNumber = (e, format) => {
  const key = e.nativeEvent.data
  if (_.isNil(key)) return e.target.value

  if (!format.test(key)) e.target.value = e.target.value.replace(/[^\d]/g, '')

  return e.target.value
}

export const regex = {
  NUMBER: /^\d+$/,
  FLOAT: /^(\d+(\.\d*)?|\.\d*)?$/,
}

const Contact = (props) => {
  const { formik, checkPermission } = props

  return (
    <>
      <Card
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          minWidth: 275,
          pt: 2,
          px: 3,
        }}
      >
        <Typography variant="h6">ข้อมูลติดต่อ</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, mt: 2 }}>
          <TextInput
            id="tel"
            name="tel"
            labelText="เบอร์โทรศัพท์"
            type="basic"
            disabled={!checkPermission ? true : false}
            textError={formik?.errors?.tel}
            required
            value={formik.values.tel}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            boxSx={{ width: '50%' }}
            inputProps={{
              'data-testid': `test-tel`,
              onInput: (e) => {
                handleInputNumber(e, regex.NUMBER)
              },
              onKeyDown: (e) => {
                handleNumberKeyDown(e, ['.', ' '])
              },
              onPaste: handlePasteFormat,
            }}
            maxlength={10}
          />
          <TextInput
            id="email"
            name="email"
            labelText="อีเมล"
            type="basic"
            disabled={!checkPermission ? true : false}
            textError={formik?.errors?.email}
            required
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            boxSx={{ width: '50%' }}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
          <TextInput
            id="deskNumber"
            name="deskNumber"
            labelText="เบอร์โต๊ะทำงาน"
            type="basic"
            disabled={!checkPermission ? true : false}
            textError={formik?.errors?.deskNumber}
            required
            value={formik.values.deskNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            boxSx={{ width: '50%' }}
          />
          <Box sx={{ width: '50%' }}></Box>
        </Box>
      </Card>
    </>
  )
}
export default Contact
