import { ListItemText, IconButton, Typography } from '@mui/material'
import React from 'react'
import { StyledListItem, StyledRow } from '../../Styled'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import {
  COLUMN_NAME,
  COLUMN_NAME_TH,
} from '../../../../constants/reportCourseSetting/reportCourseSetting'
import { handleDeleteFilter } from '../event'

const FilterListItem = ({ filter }) => {
  const dispatch = useDispatch()
  const id = _.get(filter, 'id', 1)
  const key = _.findKey(COLUMN_NAME, (col) => col === _.get(filter, 'key', ''))
  const label = _.get(filter, 'label', '')

  return (
    <StyledListItem
      secondaryAction={
        <IconButton
          color="primary"
          onClick={() => dispatch(handleDeleteFilter(id))}
        >
          <CloseIcon />
        </IconButton>
      }
    >
      <ListItemText
        primary={
          <StyledRow
            sx={{
              flexWrap: 'wrap',
              width: '100%',
            }}
          >
            <Typography variant="body1" component="span">
              {COLUMN_NAME_TH[key]} :{' '}
            </Typography>
            <Typography
              sx={{ wordBreak: 'break-word' }}
              variant="body2b"
              component="span"
            >
              {label}
            </Typography>
          </StyledRow>
        }
      />
    </StyledListItem>
  )
}

export default FilterListItem
