export const initialState = {
  isLoading: false,
  isLoading2: false,
  isLoading3: false,
  isLoadingDrawer: false,
  search: {
    courseSearch: '',
    examSearch: '',
    handleCourseSearch: '',
    handleExamSearch: '',
    orderExamBy: 'DESC',
    sortExamBy: 'registerDateTime',
    orderCourseBy: 'DESC',
    sortCourseBy: 'updatedAt',
  },
  division: '',
  initialState: {
    contact: {
      email: '',
      mobileNo: '',
    },
    course: {
      result: [],
      totalCount: 0,
    },
    educational: [],
    exam: { result: [], totalCount: 0 },
    info: {
      prefixTH: '',
      tempCode: '',
      firstNameTH: '',
      lastNameTH: '',
      image: '',
      uuid: '',
    },
    info2: { dateOfBirth: '', idCardNo: '' },
    work: {
      access: '',
      statusProspect: '',
      unitName: '',
      unitNumber: '',
    },
    default: {
      firstNameTH: '',
      lastNameTH: '',
    },
    updated: '',
  },
  errors: {},
  pagination: {
    courselimit: 100,
    coursePage: 1,
    examLimit: 100,
    examPage: 1,
  },
}
