import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'
import { getStaffInArea, getHistory } from '../../../services/monthlyPlan'

const monthlyPlan = createSlice({
  name: 'monthlyPlan',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStaffInArea.fulfilled, (state, { payload }) => {
        state.staffList = _.get(payload, 'result', [])
      })
      .addCase(getStaffInArea.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getHistory.fulfilled, (state, { payload }) => {
        state.historyList = _.get(payload, 'data', [])
      })
      .addCase(getHistory.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const { startLoading, stopLoading } = monthlyPlan.actions

export default monthlyPlan.reducer
