import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import DateRangeInput from '../../../../../../../components/DateRangeInput'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
} from '../../../../../../../components/FilterDrawer/Styled'
// import { store } from '../../../../../../../App'
// import _ from 'lodash'
// import { PRODUCT_TYPE } from '../../../../../../../constants/stock'

const defaultFilter = {
  dateChecked: false,
  startDate: '',
  endDate: '',
  displayDate: '',
  eventChecked: false,
  event: {
    transferInChecked: false, //โอนเข้า
    transferOutChecked: false, // โอนออก
    editChecked: false, // แก้ไข
    changeHolderChecked: false, // เปลี่ยนผู้ถือครอง
  },
  startChecked: false, // - จาก
  start: '',
  destinationChecked: false, // - ไป
  destination: '',
}
const FilterDrawer = ({ open, onClose, onFilterClick }) => {
  const [filter, setFilter] = useState(defaultFilter)
  const [filterMemo, setFilterMemo] = useState()

  const handleOnLevelTextChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    })
  }

  const handleChangeEventList = (event) => {
    setFilter({
      ...filter,
      event: {
        ...filter.event,
        [event.target.name]: event.target.checked,
      },
    })
  }

  const onClearClick = () => {
    setFilter(defaultFilter)
  }
  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter(defaultFilter)
  }, [open])

  const handleChangeChecked = ({ checked, key }) => {
    setFilter({
      ...filter,
      [key]: checked,
    })
  }

  // const { formData } = store.getState().crud
  // const productType = _.get(formData, 'productType', PRODUCT_TYPE.STOCK)

  return (
    <StyledDrawer open={open} onClose={onClose}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid={`btn-close-drawer`}
            color="primary"
            component="span"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-date"
                name="dateChecked"
                defaultChecked
                checked={filter.dateChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'dateChecked',
                  })
                }}
              />
            }
            label="วันเวลาที่เคลื่อนไหวล่าสุด"
            sx={!filter.dateChecked ? { marginBottom: -1 } : {}}
          />
          {filter.dateChecked && (
            <DateRangeInput
              placeholder={'เลือกวันที่'}
              dateState={{
                startDate: !filter.startDate ? new Date() : filter.startDate,
                endDate: !filter.endDate ? new Date() : filter.endDate,
                display: filter.displayDate,
                key: 'selection',
              }}
              onChange={(selectedDates) => {
                handleSelectDate(selectedDates, filter, setFilter)
              }}
            />
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          label="รายการ"
          control={
            <Checkbox
              data-testid="checkbox-level-status"
              name="eventChecked"
              checked={filter.eventChecked}
              onChange={(e) => {
                handleChangeChecked({
                  checked: e.target.checked,
                  key: 'eventChecked',
                })
              }}
            />
          }
        />
        {filter.eventChecked && (
          <Box sx={{ display: 'flex', flexDirection: 'column', mx: 3 }}>
            <FormControlLabel
              label="โอนเข้า"
              control={
                <Checkbox
                  data-testid="checkbox-status-active"
                  name="transferInChecked"
                  checked={filter.event.transferInChecked}
                  onChange={handleChangeEventList}
                />
              }
            />
            <FormControlLabel
              label="โอนออก"
              control={
                <Checkbox
                  data-testid="checkbox-status-active"
                  name="transferOutChecked"
                  checked={filter.event.transferOutChecked}
                  onChange={handleChangeEventList}
                />
              }
            />
            <FormControlLabel
              label="แก้ไข"
              control={
                <Checkbox
                  data-testid="checkbox-status-active"
                  name="editOutChecked"
                  checked={filter.event.editOutChecked}
                  onChange={handleChangeEventList}
                />
              }
            />
            <FormControlLabel
              label="เปลี่ยนผู้ถือครอง"
              control={
                <Checkbox
                  data-testid="checkbox-status-active"
                  name="changeHolderChecked"
                  checked={filter.event.changeHolderChecked}
                  onChange={handleChangeEventList}
                />
              }
            />
          </Box>
        )}
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-qty"
                name="startChecked"
                defaultChecked
                checked={filter.startChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'startChecked',
                  })
                }}
              />
            }
            label="จาก"
            sx={!filter.startChecked ? { marginBottom: -1 } : {}}
          />
          {filter.startChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              type="basic"
              disabled={!filter.startChecked}
              placeholder="จาก"
              value={filter.start}
              onChange={(e) => handleOnLevelTextChange('start', e.target.value)}
            />
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-qty"
                name="destinationChecked"
                defaultChecked
                checked={filter.destinationChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'destinationChecked',
                  })
                }}
              />
            }
            label="ไป"
            sx={!filter.destinationChecked ? { marginBottom: -1 } : {}}
          />
          {filter.destinationChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              type="basic"
              disabled={!filter.destinationChecked}
              placeholder="ไป"
              value={filter.destination}
              onChange={(e) =>
                handleOnLevelTextChange('destination', e.target.value)
              }
            />
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledFooter>
        <Button sx={{ width: '45%' }} variant="outlined" onClick={onClearClick}>
          ล้าง
        </Button>
        <Button
          data-testid={`btn-confirm-filter`}
          sx={{ width: '45%' }}
          onClick={() => {
            onClose()
            onFilterClick(filter)
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer

export const handleSelectDate = (selectedDates, filter, setFilter) => {
  let objState = {
    ...filter,
    startDate: selectedDates.startDate,
    endDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }
  setFilter(objState)
}
