import { store } from '../../../../../App'
import {
  setInitialTable,
  setSearchText,
} from '../../../../../redux/slices/table'
import {
  headCells,
  headCellsSKU,
  headCellsAsset,
  headCellsLicense,
  headCellsFormNotImportModule,
  headCellsFormNotImportModuleSKU,
} from '../../../../../constants/inventoryTransfer'
import _ from 'lodash'
import { assetTypeEnum, fromTypeEnum } from '../enum/assetTypEnum'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { removeAssetData } from './removeAssetData'
import {
  convertNumberWithComma,
  convertFloatWithComma,
} from '../../../../../utils/lib'
import { checkAndConvertDateShow } from './checkAndConvertDate'
import { paymentMethodEnum, paymentMethodType } from '../enum/paymentMethod'

export const fetchAssetData = async (props) => {
  const { search } = props
  const { assetData } = store.getState().crud
  const { type, from } = store.getState().crud.formData
  const totalCount = assetData.length

  store.dispatch(setSearchText(search))

  let results = []
  results = assetData.map((item, index) => {
    const quantity = item?.quantity
      ? convertNumberWithComma(String(item?.quantity).replace(/,/g, ''))
      : null
    const unitPrice = item?.unitPrice
      ? convertFloatWithComma(String(item?.unitPrice).replace(/,/g, ''))
      : null
    const stockValue = item?.stockValue
      ? convertFloatWithComma(String(item?.stockValue).replace(/,/g, ''))
      : null

    const contractStartDate = checkAndConvertDateShow(
      _.get(item, 'contractStartDate', null),
    )
    const contractEndDate = checkAndConvertDateShow(
      _.get(item, 'contractEndDate', null),
    )
    const renewContractDate = checkAndConvertDateShow(
      _.get(item, 'renewContractDate', null),
    )
    return {
      ...item,
      no: index + 1,
      uuid: _.get(item, 'uuid', undefined),
      product: _.get(item, 'product', {}),
      productName: _.get(item, 'product.label', ''),
      quantity: quantity,
      unitPrice: unitPrice,
      stockValue: stockValue,
      skuNo: _.get(item, 'skuNo', ''),
      costCenter: _.get(item, 'costCenter', {}),
      costCenterName: _.get(
        item,
        'costCenter.label',
        _.get(item, 'costCenter.descriptionName', ''),
      ),
      assetNo: _.get(item, 'assetNo', ''),
      contractStartDate: contractStartDate,
      contractEndDate: contractEndDate,
      renewContractDate: renewContractDate,
      holder: _.get(item, 'holder', ''),
      ownerLName: _.get(item, 'ownerLName', ''),
      ownerEmail: _.get(item, 'ownerEmail', ''),
      payer: _.get(item, 'payer', ''),
      payerLName: _.get(item, 'payerLName', ''),
      payerEmail: _.get(item, 'payerEmail', ''),
      paymentType: _.get(item, 'paymentType.label', ''),
      paymentDesc: _.get(item, 'paymentDesc', ''),
      paymentTypeValue: _.get(item, 'paymentType', ''),
      skuNoOption: _.get(item, 'skuNoOption', ''),
    }
  })

  if (search && search != '') {
    results = results.filter((item) => {
      return item.productName.toUpperCase().includes(search.toUpperCase())
    })
  }

  let headCellsData = headCells
  if (from?.value === fromTypeEnum.IMPORT_PRODUCT) {
    if (type === assetTypeEnum.SKU) {
      headCellsData = headCellsSKU
    } else if (type === assetTypeEnum.ASSET) {
      headCellsData = headCellsAsset
    } else if (type === assetTypeEnum.LICENSE) {
      headCellsData = headCellsLicense
    }
  } else {
    if ([assetTypeEnum.STOCK].includes(type)) {
      headCellsData = headCellsFormNotImportModule
    } else {
      headCellsData = headCellsFormNotImportModuleSKU
    }
  }

  store.dispatch(
    setInitialTable({
      rows: results,
      allCount: totalCount,
      headCells: headCellsData,
      placeholder: 'ค้นหาด้วยคีย์เวิร์ด',
      searchKey: 'product',
      //   status: statusList,
      handleSearch: (text) => fetchAssetData({ search: text }),
      isCheckBox: false,
      onDelete: (row) => removeAssetData(row),
    }),
  )
}

export const handleFetchEditAssetData = () => {
  const { formData } = store.getState().crud
  const formEditData = _.map(
    _.get(formData, 'ticketDetail', []),
    (item, index) => {
      const quantity = item?.quantity
        ? convertNumberWithComma(item?.quantity)
        : null
      const unitPrice = item?.unitPrice
        ? convertFloatWithComma(item?.unitPrice)
        : null
      const stockValue = item?.stockValue
        ? convertFloatWithComma(item?.stockValue)
        : null
      const costCenter = item?.costCenter
        ? {
            ...item.costCenter,
            label: item?.costCenter.descriptionName,
            value: item?.costCenter.uuid,
          }
        : ''
      const holder = item?.holder
        ? {
            ...item.holder,
            prefix: _.get(item, 'holder.prefixTH'),
            label: `${_.get(item, 'holder.firstNameTH', '')} ${_.get(
              item,
              'holder.lastNameTH',
              '',
            )}`,
            value: _.get(item, 'holder.uuid', ''),
            name: _.get(item, 'holder.firstNameTH', ''),
            lastname: _.get(item, 'holder.lastNameTH', ''),
            email: _.get(item, 'holder.email', ''),
            tel: _.get(item, 'holder.tel', ''),
          }
        : ''
      const payer = item?.payer
        ? {
            ...item.payer,
            prefix: _.get(item, 'payer.prefixTH'),
            label: `${_.get(item, 'payer.firstNameTH', '')} ${_.get(
              item,
              'payer.lastNameTH',
              '',
            )}`,
            value: _.get(item, 'payer.uuid', ''),
            name: _.get(item, 'payer.firstNameTH', ''),
            lastname: _.get(item, 'payer.lastNameTH', ''),
            email: _.get(item, 'payer.email', ''),
            tel: _.get(item, 'payer.tel', ''),
          }
        : ''

      const paymentType = paymentMethodType.find(
        (item2) => item2.value === _.get(item, 'paymentType', ''),
      )

      return {
        rowIndex: index,
        uuid: _.get(item, 'uuid', undefined),
        product: {
          ..._.get(item, 'product'),
          label: _.get(item, 'product.productName'),
          value: _.get(item, 'product.uuid'),
        },
        quantity: quantity,
        unitPrice: unitPrice,
        stockValue: stockValue,

        //to
        skuNo: _.get(item, 'skuNo', ''),

        costCenter: costCenter,
        costCenterName: _.get(item, 'costCenter.descriptionName', ''),
        //to
        assetNo: _.get(item, 'assetNo', ''),

        contractStartDate: _.get(item, 'contractStartDate', null),
        contractEndDate: _.get(item, 'contractEndDate', null),
        renewContractDate: _.get(item, 'renewContractDate', null),

        holder: holder,
        ownerFName: _.get(item, 'holder.firstNameTH', ''),
        ownerLName: _.get(item, 'holder.lastNameTH', ''),
        ownerEmail: _.get(item, 'holder.email', ''),
        payer: payer,
        payerFName: _.get(item, 'payer.lastNameTH', ''),
        payerLName: _.get(item, 'payer.firstNameTH', ''),
        payerEmail: _.get(item, 'payer.email', ''),

        paymentType: paymentType,
        paymentFile: _.get(item, 'paymentFile', ''),
        paymentDesc:
          paymentType?.value === paymentMethodEnum.CREDIT_CARD
            ? _.get(item, 'creditCardName', '')
            : paymentType?.value === paymentMethodEnum.OTHER
            ? _.get(item, 'creditCardDescription', '')
            : '',
      }
    },
  )

  store.dispatch(
    setFieldValue({
      key: 'assetData',
      value: formEditData,
    }),
  )
}
