import { openStockDrawer } from './openStockDrawer'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { store } from '../../../../../App'
import _ from 'lodash'
import { checkAndConvertDate } from './checkAndConvertDate'
import { setFieldUploadValue } from '../../../../../redux/slices/uploadFile'

export const fetchEditAsset = async (props) => {
  const { assetEdit } = props
  if (assetEdit) {
    openStockDrawer(true, true).then(() => {
      fetchEditData(assetEdit)
    })
  }
}

export const fetchEditData = async (assetEdit) => {
  const product = _.get(assetEdit, 'product', '')
  const quantity = assetEdit?.quantity ?? null
  const unitPrice = assetEdit?.unitPrice ?? null
  const stockValue = assetEdit?.stockValue ?? null
  const rowIndex = _.get(assetEdit, 'rowIndex', '')

  const value = {
    uuid: _.get(assetEdit, 'uuid', undefined),
    rowIndex: _.get(assetEdit, 'rowIndex', ''),
    product: _.get(assetEdit, 'product', ''),
    quantity: quantity ? quantity.replace(/,/g, '') : null,
    unitPrice: unitPrice ? unitPrice.replace(/,/g, '') : null,
    stockValue: stockValue ? stockValue.replace(/,/g, '') : null,
    skuNo: _.get(assetEdit, 'skuNo', ''),
    costCenter: _.get(assetEdit, 'costCenter', ''),
    assetNo: _.get(assetEdit, 'assetNo', ''),
    contractStartDate: _.get(assetEdit, 'contractStartDate', null),
    contractEndDate: _.get(assetEdit, 'contractEndDate', null),
    renewContractDate: _.get(assetEdit, 'renewContractDate', null),
    holder: _.get(assetEdit, 'holder', ''),
    ownerFName: _.get(assetEdit, 'ownerFName', ''),
    ownerLName: _.get(assetEdit, 'ownerLName', ''),
    ownerEmail: _.get(assetEdit, 'ownerEmail', ''),
    payer: _.get(assetEdit, 'payer', ''),
    payerFName: _.get(assetEdit, 'payerFName', ''),
    payerLName: _.get(assetEdit, 'payerLName', ''),
    payerEmail: _.get(assetEdit, 'payerEmail', ''),
    paymentType: _.get(assetEdit, 'paymentTypeValue', ''),
    paymentFile: _.get(assetEdit, 'paymentFile', ''),
    paymentDesc: _.get(assetEdit, 'paymentDesc', ''),
    skuNoOption: _.get(assetEdit, 'skuNoOption', ''),
  }

  store.dispatch(setFieldValue({ key: 'formData.uuid', value: value.uuid }))
  store.dispatch(setFieldValue({ key: 'formData.rowIndex', value: rowIndex }))
  store.dispatch(setFieldValue({ key: 'formData.skuNo', value: value.skuNo }))
  store.dispatch(setFieldValue({ key: 'formData.product', value: product }))
  store.dispatch(setFieldValue({ key: 'formData.productStockAsset', value: product }))
  store.dispatch(setFieldValue({ key: 'formData.productAsset', value: product }))
  store.dispatch(setFieldValue({ key: 'formData.productLicense', value: product }))
  store.dispatch(
    setFieldValue({ key: 'formData.quantity', value: value.quantity }),
  )
  store.dispatch(
    setFieldValue({ key: 'formData.unitPrice', value: value.unitPrice }),
  )
  store.dispatch(
    setFieldValue({ key: 'formData.stockValue', value: value.stockValue }),
  )
  store.dispatch(
    setFieldValue({ key: 'formData.costCenter', value: value.costCenter }),
  )
  store.dispatch(
    setFieldValue({ key: 'formData.assetNo', value: value.assetNo }),
  )
  store.dispatch(
    setFieldValue({
      key: 'formData.contractStartDate',
      value: checkAndConvertDate(value.contractStartDate),
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'formData.contractEndDate',
      value: checkAndConvertDate(value.contractEndDate),
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'formData.renewContractDate',
      value: checkAndConvertDate(value.renewContractDate),
    }),
  )
  store.dispatch(setFieldValue({ key: 'formData.holder', value: value.holder }))
  store.dispatch(
    setFieldValue({ key: 'formData.ownerLName', value: value.ownerLName }),
  )
  store.dispatch(
    setFieldValue({ key: 'formData.ownerFName', value: value.ownerFName }),
  )
  store.dispatch(
    setFieldValue({ key: 'formData.ownerEmail', value: value.ownerEmail }),
  )
  store.dispatch(setFieldValue({ key: 'formData.payer', value: value.payer }))
  store.dispatch(
    setFieldValue({ key: 'formData.payerFName', value: value.payerFName }),
  )
  store.dispatch(
    setFieldValue({ key: 'formData.payerLName', value: value.payerLName }),
  )
  store.dispatch(
    setFieldValue({ key: 'formData.payerEmail', value: value.payerEmail }),
  )
  store.dispatch(
    setFieldValue({
      key: 'formData.paymentType',
      value: value.paymentType,
    }),
  )
  store.dispatch(
    setFieldValue({ key: 'formData.paymentDesc', value: value.paymentDesc }),
  )
  store.dispatch(
    setFieldValue({ key: 'formData.skuNoOption', value: value.skuNoOption }),
  )

  if (value.paymentFile && !_.isEmpty(value.paymentFile)) {
    store.dispatch(
      setFieldUploadValue({
        key: `formData.paymentImg`,
        value: {
          files: [
            {
              id: 1,
              key: value.paymentFile?.key,
              displayName: value.paymentFile?.fileName,
              fileSize: value.paymentFile?.fileSize,
              fileType: value.paymentFile?.fileType,
              percent: 100,
            },
          ],
          isUploading: false,
        },
      }),
    )
  }
}
