import React, { useState } from 'react'
import MoreVert from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { openHistoryDrawer } from '../../handler/openHistoryDrawer'
import { useHistory } from 'react-router-dom'
import callAxios from '../../../../../../utils/baseService'
import { store } from '../../../../../../App'
import { openDialog, closeDialog } from '../../../../../../redux/slices/dialog'
import { productSkuStatus } from '../../../../../../utils/apiPath'
import { validatePermission } from '../../../../../../utils/lib'
import {
  INVENTORY_SETTING,
  STOCK_PERMISSION,
} from '../../../../../../constants/stock'
import { setFieldValue } from '../../../../../../redux/slices/crud'
import _ from 'lodash'

const DropdownButton = (props) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const hasPermission = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN, STOCK_PERMISSION.CREATE],
  })
  const {
    isHistory,
    ticketId,
    assetId,
    isAsset,
    requisitionId,
    item,
    productType,
    warehouseUuid,
  } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const history = useHistory()
  let menuOptions = createOptions
  if (isHistory) {
    menuOptions = historyOptions
    if (!ticketId)
      menuOptions = menuOptions.filter(
        (option) => !option.value === 'view_ticket',
      )
  }
  if (isAsset) {
    if (item?.status === 'ACTIVE') {
      menuOptions = [{ text: 'ปิดใช้งาน', value: 'disabled' }]
    } else {
      menuOptions = [{ text: 'เปิดใช้งาน', value: 'enabled' }]
    }
    if (!['ASSET', 'LICENSE'].includes(productType)) {
      menuOptions = [
        ...menuOptions,
        { text: 'ดูรายการเบิกปัจจุบัน', value: 'transfer' },
      ]
    }
  }
  return (
    <>
      <IconButton
        aria-label="more"
        data-testid="btn-more"
        id="more"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVert />
      </IconButton>
      <Menu
        MenuListProps={{ 'aria-labelledby': 'more' }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {menuOptions.map((option, index) => {
          let disabled = false
          if (option.value === 'asset_detail' && !hasPermission) {
            disabled = true
            history.push(`${productPath?.[productType]}/${assetId}`)
            openHistoryDrawer(false)
          } else if (option.value === 'transfer' && _.isEmpty(requisitionId)) {
            disabled = true
          }
          return (
            <MenuItem
              key={index}
              data-testid={`menu-${index}`}
              onClick={() => {
                if (option.value === 'history') {
                  openHistoryDrawer(true)
                } else if (option.value === 'asset_detail') {
                  history.push(`${productPath?.[productType]}/${assetId}`)
                  openHistoryDrawer(false)
                } else if (option.value === 'view_ticket') {
                  if (item?.movementType === 'REQUISITION') {
                    history.push(`${productPath.REQUISITION_VIEW}/${ticketId}`)
                  } else {
                    history.push(`${productPath.TICKET_VIEW}/${ticketId}`)
                  }
                  openHistoryDrawer(false)
                } else if (option.value === 'copy_ticket') {
                  history.push(
                    `${productPath.TICKET}/?warehouse=IMPORT_PRODUCT&to=${warehouseUuid}`,
                  )
                } else if (option.value === 'transfer') {
                  history.push(
                    `${productPath.REQUISITION_VIEW}/${requisitionId}`,
                  )
                } else if (['enabled', 'disabled'].includes(option.value)) {
                  enabledAsset(option.value, item)
                }
                setAnchorEl(null)
              }}
              disabled={disabled}
            >
              {option.text}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default DropdownButton

export const productPath = {
  STOCK: '/inventory/product/stock/view',
  STOCK_ASSET: '/inventory/product/stock/view',
  ASSET: '/inventory/product/asset/view',
  LICENSE: '/inventory/product/license/view',
  TICKET: '/inventory/transfer/form',
  TICKET_VIEW: '/inventory/transfer/view',
  REQUISITION_VIEW: '/inventory/requisition/view',
}

export const createOptions = [
  { text: 'นำเข้าทรัพย์สิน', value: 'copy_ticket' },
  { text: 'ประวัติการเคลื่อนไหว', value: 'history' },
]

export const historyOptions = [
  { text: 'รายละเอียด Ticket', value: 'view_ticket' },
  { text: 'รายละเอียดทรัพย์สิน', value: 'asset_detail' },
]

export const enabledAsset = async (value, item) => {
  const status = value === 'enabled' ? 'ACTIVE' : 'INACTIVE'
  const body = {
    status,
    skuWarehouseUuid: item.uuid,
  }
  await callAxios
    .put(productSkuStatus, body)
    .then(() => {
      store.dispatch(
        openDialog({
          type: 'success',
          title: 'สำเร็จ',
          message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          handleConfirm: () => {
            store.dispatch(closeDialog())
            store.dispatch(
              setFieldValue({
                key: 'isRefreshTable',
                value: true,
              }),
            )
          },
        }),
      )
    })
    .catch(() => {
      store.dispatch(
        openDialog({
          type: 'fail',
          title: 'ผิดพลาด',
          message: 'ระบบขัดข้อง',
        }),
      )
    })
}
