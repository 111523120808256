import _ from 'lodash'
import { handleCategoryChange } from '../handler/handleCategoryChange'
import { validatePermission } from '../../../../../utils/lib'

export const getBodyMutation = (data, user) => {
  const hasPermissionEContent = validatePermission({
    user: user,
    moduleType: 'E_CONTENT_AND_KB',
    permission: ['CREATE', 'EDIT', 'CREATE_ECONTENT', 'EDIT_ECONTENT'],
  })
  const category = _.get(data, 'category', '')
  const subCategory = _.get(data, 'subCategory', '')
  if (!_.isEmpty(category)) {
    handleCategoryChange(null, { value: data.category.uuid })
  }
  if (
    _.get(data, 'status', 'DRAFT') !== 'DRAFT' &&
    _.get(data, 'status', 'DRAFT') !== 'DELETED'
  ) {
    if (_.get(data, 'isActive', false) === true) {
      _.set(data, 'status', 'READY')
    } else {
      _.set(data, 'status', 'READYINACTIVE')
    }
  }
  return {
    id: _.get(data, 'id', ''),
    uuid: _.get(data, 'uuid', undefined),
    name: _.get(data, 'name', ''),
    contentType: _.get(
      data,
      'contentType',
      hasPermissionEContent ? 'E_CONTENT' : 'KB',
    ),
    coverImage: data?.coverImage || {},
    isDownload: _.get(data, 'isDownload', '') === true ? 'ACTIVE' : 'INACTIVE',
    isActive: _.get(data, 'isActive', '') === true ? 'ACTIVE' : 'INACTIVE',
    contents: _.get(data, 'contents', [])
      .map((item) => ({
        ...item,
        id: crypto.randomUUID(),
      }))
      .sort((a, b) => a.sequence - b.sequence),
    category: _.isEmpty(category)
      ? undefined
      : {
          label: category.name,
          value: category.uuid,
        },
    subCategory: _.isEmpty(subCategory)
      ? undefined
      : {
          label: subCategory.name,
          value: subCategory.uuid,
        },
    tagInput: _.get(data, 'tagInput', ''),
    eContentTag: _.get(data, 'eContentTag', []).map((d) => ({
      value: d.name,
      label: d.name,
    })),
    distributionCh: data?.isAllDistributionChannel
      ? [
          {
            label: 'ทั้งหมด',
            value: 'all',
          },
        ]
      : _.get(data, 'distributionChannel', [
          { distribution: 'ทั้งหมด', uuid: 'all' },
        ]).map((d) => ({
          value: d.uuid,
          label: d.distribution,
        })),
    assignees: _.get(data, 'assignees', []).map((d) => ({
      value: d.uuid,
      label: `${d.firstNameTH} ${d.lastNameTH}`,
    })),
    levelOfLearner: data?.isAllLevelOfLearner
      ? [
          {
            label: 'ทั้งหมด',
            value: 'all',
          },
        ]
      : _.get(data, 'levelOfLearner', [
          {
            title: 'ทั้งหมด',
            uuid: 'all',
          },
        ]).map((d) => ({
          value: d.uuid,
          label: d.title,
        })),
    startPublishDate: _.get(data, 'startPublishDate', ''),
    oldStartPublishDate: _.get(data, 'startPublishDate', ''),
    endPublishDate: _.get(data, 'endPublishDate', null),
    remark: _.get(data, 'remark', ''),
    isEndPublishDate:
      _.get(data, 'isEndPublishDate', '') === true ? 'ACTIVE' : 'INACTIVE',
    status: _.get(data, 'status', 'DRAFT'),
    canDownloadContent: _.get(data, 'canDownloadContent', 'INACTIVE'),
    updatedBy: _.get(data, 'updatedBy', ''),
    updatedAt: _.get(data, 'updatedAt', ''),
    createdBy: _.get(data, 'createdBy', ''),
    createdAt: _.get(data, 'createdAt', ''),
    isPinned: _.get(data, 'isPinned', false),
    totalPinned: _.get(data, 'totalPinned', 0),
  }
}
