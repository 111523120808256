import { array, object, string } from 'yup'
import _ from 'lodash'
import { TYPE_OF_VALUE } from '../../../constants/reportCourseSetting/reportCourseSetting'
import dayjs from 'dayjs'

export const validateValueTypeString = string()
  .test('check-input', 'กรุณากรอกค่า', (value, context) => {
    if (
      context.parent.typeOfValue !== TYPE_OF_VALUE.INPUT_NUMBER &&
      context.parent.typeOfValue !== TYPE_OF_VALUE.INPUT_TEXT
    ) {
      return true
    }
    return value.length > 0
  })
  .test(
    'check-input-number',
    'กรุณากรอกเป็นตัวเลขเท่านั้น',
    (value, context) => {
      if (context.parent.typeOfValue !== TYPE_OF_VALUE.INPUT_NUMBER) {
        return true
      }
      return !_.isNaN(Number(value))
    },
  )

export const validateValueTypeArray = array().min(1, 'กรุณาเลือก')

export const validateValueTypeObj = object().test(
  'check-date-picker',
  'กรุณาเลือกวันที่',
  (value, context) => {
    if (context.parent.typeOfValue !== TYPE_OF_VALUE.DATE_RANGE) return true
    const { startDate, endDate } = value
    return dayjs(startDate).isValid() && dayjs(endDate).isValid()
  },
)
