import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import TableCell from '@mui/material/TableCell'
import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { useSelector, useDispatch } from 'react-redux'
import InboxTwoTone from '@mui/icons-material/InboxTwoTone'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import QrCodeOutlined from '@mui/icons-material/QrCodeOutlined'
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined'
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined'
import InfoOutlined from '@mui/icons-material/InfoOutlined'

import {
  StyledFullWidth,
  StyledTable,
  StyledTableBodyCell,
  StyledTableCell,
} from '../Styled'
import { setOnView } from '../../../../redux/slices/tableAttendance'

import { BoxContentQRCode, BoxFooterQRCode, BoxTooltip } from './Styled'
import { checkExpiredQRCode, countdownTime, startTimerQRCode } from './events'
import { QRCodeTemplate } from '../../../../components/QRCodeTemplate'
import dayjs from 'dayjs'
import { downloadQRCode } from '../../../../utils/lib'

export const ActionView = ({
  headCells,
  row,
  indexRound,
  rowIndex,
  cellIndex,
  date,
}) => {
  const dispatch = useDispatch()
  const columnKey = _.get(headCells, `[${cellIndex}].id`, '')

  if (columnKey === 'action') {
    const trainingPlanUuid = _.get(row, 'qrCodeBody.trainingPlanUuid', '')
    const url = `/manage-class/attendance/list/${trainingPlanUuid}?date=${date}&round=${row.round}`

    return (
      <TableCell key={cellIndex} sx={{ textAlign: 'center' }}>
        <IconButton
          data-testid={`btn-view-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(setOnView(url))}
        >
          <RemoveRedEyeTwoTone color="action" />
        </IconButton>
      </TableCell>
    )
  } else if (columnKey === 'qrCodeBody') {
    const isQRCode = _.get(row, 'expiredDate', '-') === '-' ? false : true
    return (
      <TableCell key={cellIndex} sx={{ textAlign: 'left' }}>
        <Button
          sx={{
            width: 120,
          }}
          data-testid="btn-qr-code"
          variant="outlined"
          size="m"
          startIcon={<QrCodeOutlined />}
          onClick={() =>
            dispatch(
              checkExpiredQRCode({
                body: row[columnKey],
                isQRCode,
                indexRound,
                rowIndex,
              }),
            )
          }
        >
          <Typography variant="buttonm"> QR Code</Typography>
        </Button>
      </TableCell>
    )
  }

  return (
    <TableCell key={columnKey}>
      <Box sx={{ ml: 0.5 }}>
        <Typography variant="body2">
          {_.get(row, `${columnKey}`, '-')}
        </Typography>
      </Box>
    </TableCell>
  )
}

export const EnhancedTableHead = () => {
  const headCells = useSelector((state) => state.tableAttendance.headCells)
  return (
    <TableHead>
      <TableRow key="head">
        {headCells &&
          headCells.map((headCell) => {
            const valueId = headCell.id
            return (
              <StyledTableCell
                key={valueId}
                align={'left'}
                padding={'normal'}
                sortDirection={false}
                headcellwidth={headCell.width}
                sx={{ textAlign: headCell.id !== 'action' ? 'left' : 'center' }}
              >
                <Typography
                  variant="tableHeader"
                  data-testid={`sort-cell-${valueId}`}
                  active={false}
                >
                  {headCell.label}
                </Typography>
              </StyledTableCell>
            )
          })}
      </TableRow>
    </TableHead>
  )
}

export const ContentQRCode = ({ props }) => {
  const dispatch = useDispatch()
  const [copy, setCopy] = useState({
    isOpen: false,
    isCopy: false,
  })
  const [[hrs, mins, secs], setTime] = useState([0, 0, 0])

  const { objQrCode, qrCodeBody } = useSelector((state) => ({
    objQrCode: state.tableAttendance.objQrCode,
    qrCodeBody: state.tableAttendance.qrCodeBody,
  }))

  useEffect(() => {
    dispatch(startTimerQRCode({ body: qrCodeBody, ...props }))
  }, [])

  const linkQRCode = _.get(objQrCode, 'link', '')
  const valueSeconds = _.get(objQrCode, 'secondToExpire', 0)
  const date = _.get(qrCodeBody, 'date', '')
  const round = _.get(qrCodeBody, 'round', 1)

  useEffect(() => {
    const hours = Math.floor(valueSeconds / (60 * 60))
    const divisorMinutes = valueSeconds % (60 * 60)
    const minutes = Math.floor(divisorMinutes / 60)
    const divisorSeconds = divisorMinutes % 60
    const seconds = Math.ceil(divisorSeconds)
    setTime([hours, minutes, seconds])
  }, [valueSeconds])

  const time = { hrs, mins, secs }
  let timer = null

  useEffect(() => {
    timer = setInterval(() => countdownTime(time, timer, setTime), 1000)
    return () => clearInterval(timer)
  })

  const hrStr = hrs.toString().padStart(2, '0')
  const minStr = mins.toString().padStart(2, '0')
  const secStr = secs.toString().padStart(2, '0')

  return (
    <BoxContentQRCode>
      <QRCodeTemplate
        id="attendance-qr-code"
        isHidden={true}
        link={linkQRCode}
        title={`QR Code เช็คชื่อ ครั้งที่ ${round} - ${dayjs(date).format(
          'DD/MM/YYYY',
        )}`}
        titleSx={{ mt: 0 }}
      />
      <Typography variant="h5">Scan QR Code</Typography>
      <Typography variant="h4"> {`${hrStr}:${minStr}:${secStr}`}</Typography>
      <QRCodeTemplate
        titleSx={{ mx: 3, mt: 1 }}
        size={480}
        id="show-qr-code"
        link={linkQRCode}
        includeMargin={true}
        title={`QR Code เช็คชื่อ ครั้งที่ ${round} - ${dayjs(date).format(
          'DD/MM/YYYY',
        )}`}
      />
      <BoxFooterQRCode
        onMouseLeave={() => {
          setCopy({
            ...copy,
            isOpen: false,
          })
        }}
      >
        <ClickAwayListener
          onClickAway={() => {
            setCopy({
              ...copy,
              isOpen: false,
            })
          }}
        >
          <Button
            data-testid="btn-copy-qr-code"
            variant="outlined"
            size="m"
            startIcon={<ContentCopyOutlined />}
            onClick={() => {
              navigator.clipboard.writeText(linkQRCode)
              setCopy({
                isOpen: true,
                isCopy: true,
              })
            }}
          >
            <Box sx={{ gap: 1, display: 'flex' }}>
              <Typography variant="button" sx={{ mt: 0.5 }}>
                คัดลอกลิงก์
              </Typography>
              {copy.isCopy && (
                <BoxTooltip
                  open={copy.isOpen}
                  title={
                    <Typography variant="tooltip">
                      อายุของลิงก์เริ่มนับเวลาแล้ว
                    </Typography>
                  }
                  arrow
                  placement="top"
                >
                  <InfoOutlined
                    sx={{ width: 18, height: 18, mt: 1.3 }}
                    onMouseOver={() =>
                      setCopy({
                        ...copy,
                        isOpen: true,
                      })
                    }
                  />
                </BoxTooltip>
              )}
            </Box>
          </Button>
        </ClickAwayListener>
        <Button
          data-testid="btn-download-qr-code"
          variant="contained"
          size="m"
          startIcon={<FileDownloadOutlined />}
          onClick={() => downloadQRCode('attendance-qr-code')}
        >
          <Typography variant="button">ดาวน์โหลด</Typography>
        </Button>
      </BoxFooterQRCode>
    </BoxContentQRCode>
  )
}

export const TableEmpty = () => {
  const { headCells } = useSelector((state) => state.tableAttendance)
  return (
    <Card sx={{ mb: 3 }}>
      <Box sx={{ ml: 2 }}>
        <StyledFullWidth>
          <TableContainer>
            <StyledTable>
              <EnhancedTableHead />
              <TableBody>
                <TableRow>
                  <StyledTableBodyCell colSpan={headCells.length}>
                    <Box>
                      <InboxTwoTone />
                      <Typography variant="body2" color="text.lightGray">
                        ไม่พบข้อมูล
                      </Typography>
                    </Box>
                  </StyledTableBodyCell>
                </TableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>
        </StyledFullWidth>
      </Box>
    </Card>
  )
}

const EnhancedTable = () => {
  const { listTable, headCells } = useSelector((state) => state.tableAttendance)
  if (!listTable.length) return <TableEmpty />
  return (
    <Box key={'enhanced-table'}>
      {listTable.map((item, indexRound) => {
        const valueDate = _.get(item, 'date', '-')
        return (
          <>
            <Card key={indexRound} sx={{ mb: 3 }}>
              <Box sx={{ m: 2, ml: 4 }}>
                <Typography variant="body1b">{valueDate}</Typography>
              </Box>
              <Box sx={{ ml: 2 }}>
                <StyledFullWidth>
                  <TableContainer>
                    <StyledTable>
                      <EnhancedTableHead indexRound={indexRound} />
                      <TableBody>
                        {item.rounds &&
                          item.rounds.map((row, rowIndex) => {
                            return (
                              <TableRow
                                key={rowIndex}
                                role="checkbox"
                                tabIndex={-1}
                                color="primary"
                                type="hover"
                              >
                                {headCells &&
                                  headCells.map((i, cellIndex) => {
                                    return (
                                      <ActionView
                                        key={cellIndex}
                                        headCells={headCells}
                                        row={row}
                                        indexRound={indexRound}
                                        rowIndex={rowIndex}
                                        cellIndex={cellIndex}
                                        date={valueDate}
                                      />
                                    )
                                  })}
                              </TableRow>
                            )
                          })}
                      </TableBody>
                    </StyledTable>
                  </TableContainer>
                </StyledFullWidth>
              </Box>
            </Card>
          </>
        )
      })}
    </Box>
  )
}

export default EnhancedTable
