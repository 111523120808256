import dayjs from 'dayjs'
import { store } from '../../../../App'
import { BGM_STATUS } from '../../../../constants/budgetManagement/budgetManagement'

export const mutateFilterSateToProp = () => {
  const { filterState } = store.getState().table
  let status = []
  if (filterState.statusChecked) {
    if (filterState.status.activeChecked) {
      status.push(BGM_STATUS.ACTIVE)
    }
    if (filterState.status.inActiveChecked) {
      status.push(BGM_STATUS.INACTIVE)
    }
    if (filterState.status.deletedChecked) {
      status.push(BGM_STATUS.DELETED)
    }
  }
  const createdAtRange = {
    startDate: dayjs(filterState.createdAtStartDate).format('YYYY-MM-DD'),
    endDate: dayjs(filterState.createdAtEndDate).format('YYYY-MM-DD'),
  }
  const updatedAtRange = {
    startDate: dayjs(filterState.updatedAtStartDate).format('YYYY-MM-DD'),
    endDate: dayjs(filterState.updatedAtEndDate).format('YYYY-MM-DD'),
  }

  return {
    yearBudget: filterState.yearBudgetChecked
      ? Number(filterState.yearBudget)
      : 0,
    costCenter: filterState.costCenterChecked ? filterState.costCenter : '',
    costCenterName: filterState.costCenterNameChecked
      ? filterState.costCenterName
      : '',
    oldAccountCode: filterState.oldAccountCodeChecked
      ? filterState.oldAccountCode
      : '',
    newAccountCode: filterState.newAccountCodeChecked
      ? filterState.newAccountCode
      : '',
    categoryAccountCode: filterState.categoryChecked
      ? filterState.category
      : '',
    createdAt: filterState.createdAtChecked ? createdAtRange : {},
    updatedAt: filterState.updatedAtChecked ? updatedAtRange : {},
    updatedBy: filterState.updatedByChecked ? filterState.updatedBy : '',
    status: status,
  }
}
