import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { initialState } from './model'
import { getTicketFromMonthlyPlan } from '../../../services/inventory'
import dayjs from 'dayjs'

const manageClassInventory = createSlice({
  name: 'manageClassInventory',
  initialState,
  reducers: {
    setReduxValue: (state, { payload }) => {
      const { key, value } = payload
      _.set(state, `${key}`, value)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTicketFromMonthlyPlan.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data', [])
        let needAction = false
        state.tickets = data.map((ticket) => {
          let remainDate = null
          if (ticket.status === 'WAITING_RETURN') {
            const endDate = dayjs(ticket.monthlyPlan.finishDate).add(7, 'day')
            remainDate = endDate.diff(dayjs(), 'day')
          }
          if (
            ticket.status === 'WAITING_REQUISITION_COMFIRM' ||
            remainDate < 0
          ) {
            needAction = true
          }
          return { ...ticket, remainDate }
        })
        state.needAction = needAction
      })
      .addCase(getTicketFromMonthlyPlan.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const { setReduxValue } = manageClassInventory.actions

export default manageClassInventory.reducer
