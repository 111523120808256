// import { validatePermission } from '../../../../../utils/lib'
// import { crudConfig } from '../crudConfig'
import { store } from '../../../../../App'
import { PRODUCT_TYPE } from '../../../../../constants/stock'
import _ from 'lodash'

export const headCellsStock = [
  {
    id: 'status',
    label: 'สถานะ',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'warehouseName',
    label: 'คลังทรัพย์สินปัจจุบัน',
    disablePadding: false,
  },
  {
    id: 'warehouseId',
    label: 'ID คลังทรัพย์สิน',
    disablePadding: false,
  },
  {
    id: 'stationName',
    label: 'Station',
    disablePadding: false,
  },
  {
    id: 'total',
    label: 'คงเหลือ',
    disablePadding: false,
  },
  {
    id: 'available',
    label: 'พร้อมใช้งาน',
    disablePadding: false,
  },
  {
    id: 'stockValue',
    label: 'มูลค่ารวม (฿)',
    disablePadding: false,
  },
]

export const headCellsStockAsset = [
  {
    id: 'status',
    label: 'สถานะ',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'warehouseName',
    label: 'คลังทรัพย์สินปัจจุบัน',
    disablePadding: false,
  },
  {
    id: 'warehouseId',
    label: 'ID คลังทรัพย์สิน',
    disablePadding: false,
  },
  {
    id: 'stationName',
    label: 'Station',
    disablePadding: false,
  },
  {
    id: 'total',
    label: 'คงเหลือ',
    disablePadding: false,
  },
  {
    id: 'available',
    label: 'พร้อมใช้งาน',
    disablePadding: false,
  },
  {
    id: 'stockValue',
    label: 'มูลค่ารวม (฿)',
    disablePadding: false,
  },
]

export const headCellsAsset = [
  {
    id: 'status',
    label: 'สถานะ',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'warehouseName',
    label: 'คลังทรัพย์สินปัจจุบัน',
    disablePadding: false,
  },
  {
    id: 'warehouseId',
    label: 'ID คลังทรัพย์สิน',
    disablePadding: false,
  },
  {
    id: 'stationName',
    label: 'Station',
    disablePadding: false,
  },
  {
    id: 'total',
    label: 'คงเหลือ',
    disablePadding: false,
  },
  {
    id: 'available',
    label: 'พร้อมใช้งาน',
    disablePadding: false,
  },
  {
    id: 'stockValue',
    label: 'มูลค่ารวม (฿)',
    disablePadding: false,
  },
]

export const headCellsLicense = [
  {
    id: 'status',
    label: 'สถานะ',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'warehouseName',
    label: 'คลังทรัพย์สินปัจจุบัน',
    disablePadding: false,
  },
  {
    id: 'warehouseId',
    label: 'ID คลังทรัพย์สิน',
    disablePadding: false,
  },
  {
    id: 'stationName',
    label: 'Station',
    disablePadding: false,
  },
  {
    id: 'total',
    label: 'คงเหลือ',
    disablePadding: false,
  },
  {
    id: 'available',
    label: 'พร้อมใช้งาน',
    disablePadding: false,
  },
  {
    id: 'stockValue',
    label: 'มูลค่ารวม (฿)',
    disablePadding: false,
  },
]

export const headerCells = () => {
  // const user = JSON.parse(localStorage.getItem('user'))
  // const hasPermissionDelete = validatePermission({
  //   user: user,
  //   moduleType: 'INVENTORY_PRODUCT',
  //   permission: ['DELETE'],
  // })
  const { formData } = store.getState().crud
  const productType = _.get(formData, 'productType', PRODUCT_TYPE.STOCK)

  let headCells = []
  if (productType === PRODUCT_TYPE.STOCK) {
    headCells = headCellsStock
  } else if (productType === PRODUCT_TYPE.STOCK_ASSET) {
    headCells = headCellsStockAsset
  } else if (productType === PRODUCT_TYPE.ASSET) {
    headCells = headCellsAsset
  } else if (productType === PRODUCT_TYPE.LICENSE) {
    headCells = headCellsLicense
  }
  return [
    ...headCells,
    // {
    //   id: 'action',
    //   disablePadding: false,
    //   label: 'คำสั่ง',
    //   width: '90px',
    //   hideSortIcon: true,
    //   viewPath: `${crudConfig.modulePath}/stock/view`,
    //   editPath: `${crudConfig.modulePath}/stock/form`,
    //   hideDelete: hasPermissionDelete ? false : true,
    // },
    {
      id: 'ActionStockDropown',
      label: 'คำสั่ง',
      disablePadding: false,
      hideSortIcon: true,
    },
  ]
}
