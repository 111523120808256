import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const handleUploadFile = async (files) => {
  if (files?.files?.length === 0) return
  const { key, displayName, fileType, fileSize } = files.files[0]
  store.dispatch(
    setFieldValue({
      key: 'formData.paymentFileForm',
      value: {
        key: key,
        fileName: displayName,
        fileType: fileType,
        fileSize: fileSize,
      },
    }),
  )
}
