import _ from 'lodash'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setStatusFilter,
  startLoading,
  stopLoading,
  setFilterTotal,
  resetPage,
} from '../../../redux/slices/table'
import {
  closeDialog,
  loadingDialog,
  openDialog,
  stopLoadingDialog,
} from '../../../redux/slices/dialog'
import { downloadFile } from '../../../services/util'
import { initialState } from '../../../redux/slices/table/model'
import callAxios from '../../../utils/baseService'
import {
  COURSE_STATUS,
  MODULE_STATUS,
  headCellsLearningPath,
} from '../../../constants/eLearning'
import {
  eLearningLearningPathDownload,
  eLearningLearningPathFilter,
} from '../../../utils/apiPath'
import { deleteLearningPath } from '../../../services/eLearning/learningPath/form'
import { convertFormatDateTime } from '../../../utils/lib'

import { DEFUALT_PATH_STATUS } from './model'

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    order: order.toUpperCase(),
    sort: sort,
    type: 'CUSTOM',
    list: selected,
  }

  await dispatch(
    downloadFile({
      url: eLearningLearningPathDownload,
      body: body,
      fileName: `รายการLearning-path.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const handleDeleteModule = (row, table) => async (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันลบ​ Learning path',
      message: 'คุณต้องการลบ​ Learning path ใช่หรือไม่',
      colorDisagreeText: 'error',
      colorAgreeText: 'error',
      handleConfirm: () => dispatch(handleConfirmDelete(row.uuid, table)),
    }),
  )
}

export const handleConfirmDelete = (uuid, table) => async (dispatch) => {
  dispatch(loadingDialog())
  await dispatch(deleteLearningPath(uuid))
  dispatch(stopLoadingDialog())
  dispatch(closeDialog())

  dispatch(startLoading())
  await dispatch(
    fetchDataList({
      method: 'initial',
      table,
      page: 1,
      status: [],
      keyword: '',
    }),
  )
  dispatch(stopLoading())
}

export const fetchDataList = (props) => async (dispatch) => {
  const { method, table, page, status, filterProp, keyword, setInitial } = props
  const isInitial = method == 'initial'
  const realPage = page <= 0 ? 1 : +page
  const tableProps = isInitial ? initialState.table : table
  const filter = isInitial ? null : filterProp
  const title = isInitial ? '' : _.get(filter, 'name', keyword)
  const body = {
    limit: _.get(tableProps, 'limit', '20'),
    page: realPage,
    order: tableProps.order?.toString().toUpperCase(),
    sort: _.get(tableProps, 'sort', 'updatedAt'),
    status: isInitial ? DEFUALT_PATH_STATUS : status,
    id: _.get(filter, 'id', ''),
    name: method === 'filter' ? keyword : title,
    nameCourse: _.get(filter, 'nameCourse', ''),
    periodStart: _.get(filter, 'periodStart', ''),
    periodEnd: _.get(filter, 'periodEnd', ''),
  }

  dispatch(startLoading())
  const isCancel = await callAxios
    .post(eLearningLearningPathFilter, body)
    .then((res) => {
      const row = _.get(res, 'data.result', []).map((item) => ({
        ...item,
        description: item.description || '-',
        createdAt: convertFormatDateTime({
          value: item.createdAt,
          type: 'date',
        }),
      }))
      dispatch(
        setInitialTable({
          rows: row,
          allCount: _.get(res, 'data.totalCount', 0),
          headCells: headCellsLearningPath,
          placeholder: 'ค้นหา',
          searchKey: 'name',
          status: status,
          handleSearch: (text) => dispatch(handleQuickSearch(tableProps, text)),
          onDownload: (selected, sort, order) =>
            dispatch(onDownload(selected, sort, order)),
          onDelete: (row) => dispatch(handleDeleteModule(row, tableProps)),
        }),
      )
    })
    .catch((e) => {
      dispatch(
        setInitialTable({
          rows: [],
          allCount: 0,
          headCells: headCellsLearningPath,
        }),
      )
      return e.message?.includes('method')
    })

  setInitial && setInitial(false)
  if (!isCancel) dispatch(stopLoading())
}

export const onFilterClick = (table, filter) => (dispatch) => {
  dispatch(setSearchText(''))

  let status = []
  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      status.push(MODULE_STATUS.ACTIVE)
    }
    if (filter.status.inActiveChecked) {
      status.push(MODULE_STATUS.INACTIVE)
    }
    if (filter.status.deletedChecked) {
      status.push(MODULE_STATUS.DELETED)
    }
    if (filter.status.draftChecked) {
      status.push(COURSE_STATUS.DRAFT)
    }
  } else {
    status = DEFUALT_PATH_STATUS
  }
  dispatch(setStatusFilter(status))

  const startDate = filter.startDate
    ? convertFormatDateTime({
        value: _.get(filter, 'startDate', ''),
        type: 'dateDb',
      })
    : ''
  const endDate = filter.endDate
    ? convertFormatDateTime({
        value: _.get(filter, 'endDate', ''),
        type: 'dateDb',
      })
    : ''

  const filterProp = {
    id: filter.idChecked ? filter.id : '',
    name: filter.nameChecked ? filter.name : '',
    nameCourse: filter.nameCourseChecked ? filter.nameCourse : '',
    periodStart: filter.dateChecked ? startDate : '',
    periodEnd: filter.dateChecked ? endDate : '',
    status: status,
  }

  dispatch(setFilterProp(filterProp))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(
    fetchDataList({
      method: 'filter',
      table,
      page: 1,
      status,
      filterProp,
      keyword: filterProp.name,
    }),
  )
}

export const handleQuickSearch = (table, keyword) => (dispatch) => {
  dispatch(setSearchText(keyword))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  if (table.page === 1) {
    dispatch(
      fetchDataList({
        method: 'search',
        table,
        page: 1,
        status: DEFUALT_PATH_STATUS,
        keyword,
      }),
    )
  } else {
    dispatch(resetPage())
    dispatch(setStatusFilter(DEFUALT_PATH_STATUS))
  }
}
