import { store } from '../../../App'
import {
  setColumnSelected,
  setFiltersData,
  setIsFilter,
  setReduxValue,
  setValue,
} from '../../../redux/slices/reportCourseSetting/List'
import _ from 'lodash'
import { TYPE_OF_VALUE } from '../../../constants/reportCourseSetting/reportCourseSetting'
import { object } from 'yup'
import dayjs from 'dayjs'
import { fetchReportCourseSetting } from '../List/event'
import {
  validateValueTypeArray,
  validateValueTypeObj,
  validateValueTypeString,
} from './model'

export const handleChangeValue = (value) => (dispatch) => {
  dispatch(setValue(value))
}

export const handleNumberInput = (e) => {
  const target = _.get(e, 'target', {})
  const regex = /^[0-9]*$/

  if (!target?.value?.match(regex)) {
    target.value = e.target.value.slice(0, -1)
  }
  return target.value.replaceAll(',', '')
}

export const handleAddFilter = () => async (dispatch) => {
  const {
    displayFilters,
    columnSelected,
    value,
    typeOfValue,
    options,
    masterDataOptions,
  } = store.getState().reportCourseSetting

  let formattedValue = value
  let optionSelected = []
  let label = ''
  if (typeOfValue === TYPE_OF_VALUE.DATE_RANGE) {
    const { startDate, endDate } = value
    label = `${dayjs(startDate).format('DD/MM/YYYY')} -  ${dayjs(
      endDate,
    ).format('DD/MM/YYYY')}`
  } else if (
    typeOfValue === TYPE_OF_VALUE.INPUT_NUMBER ||
    typeOfValue === TYPE_OF_VALUE.INPUT_TEXT
  ) {
    label = value
  } else if (typeOfValue === TYPE_OF_VALUE.DROPDOWN_SINGLE) {
    optionSelected = _.filter(options, (opt) => opt.value === value)
    label = _.get(optionSelected, '[0].label', '')
  } else if (typeOfValue === TYPE_OF_VALUE.DROPDOWN_MULTI) {
    formattedValue = _.map(value, (val) => val.value)
    label = _.reduce(
      formattedValue,
      (acc, val, idx) => {
        let label = acc
        _.map(masterDataOptions, (opt) => {
          if (val === opt.value) {
            label += opt.label
            if (idx !== formattedValue.length - 1) label += ','
          }
        })
        return label
      },
      '',
    )
  }

  const newFilter = {
    id: displayFilters.length + 1,
    key: columnSelected,
    value: formattedValue,
    typeOfValue: typeOfValue,
    label: label,
  }
  const validationResults = await validationFilter(newFilter, typeOfValue)

  if (_.isEmpty(validationResults.inner)) {
    const newFilters = [...displayFilters, newFilter]
    await dispatch(setReduxValue({ key: 'displayFilters', value: newFilters }))
    await dispatch(setColumnSelected(null))
    await dispatch(setReduxValue({ key: 'options', value: [] }))
  } else {
    dispatch(setReduxValue({ key: 'error', value: validationResults.message }))
  }
}

export const handleDeleteFilter = (id) => (dispatch) => {
  const { displayFilters } = store.getState().reportCourseSetting
  const newFilters = _.filter(displayFilters, (filter) => filter.id !== id)
  dispatch(setReduxValue({ key: 'displayFilters', value: newFilters }))
}

export const validationFilter = (filter, typeOfValue) => {
  let validateSchema = object().shape({
    value:
      typeOfValue !== TYPE_OF_VALUE.DROPDOWN_MULTI
        ? typeOfValue !== TYPE_OF_VALUE.DATE_RANGE
          ? validateValueTypeString
          : validateValueTypeObj
        : validateValueTypeArray,
  })

  return validateSchema.validate(filter, { abortEarly: false }).catch((e) => e)
}

export const handleSaveFilters = () => async (dispatch) => {
  const { displayFilters, rowsPerPage, page } =
    store.getState().reportCourseSetting
  await dispatch(setIsFilter(true))
  await dispatch(setFiltersData(displayFilters))
  await dispatch(fetchReportCourseSetting())
  await dispatch(setReduxValue({ key: 'page', value: page }))
  await dispatch(setReduxValue({ key: 'rowsPerPage', value: rowsPerPage }))
}
