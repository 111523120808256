import { createSelector, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  getAcquiredSkillList,
  getECertificationList,
} from '../../../../services/eLearning/course/form'
import { getDetail } from '../../../../services/eLearning/learningPath/detail'
import {
  getAllDistributionChannel,
  getAllProductType,
} from '../../../../services/manageContent'
import { getLearningPathCourses } from '../../../../services/eLearning/learningPath/form'
import { initialState } from './model'
import { FORM_TAB } from '../../../../modules/ELearning/LearningPath/Form/model'

const eLearningPathForm = createSlice({
  name: 'eLearningPathForm',
  initialState,
  reducers: {
    setInitialCreateForm: (state) => {
      state.isLoading = false
      state.isOptionLoading = false
      state.isECertLoading = false
      state.isUploadLoading = false
      state.isUploadSuccess = false
      state.uploadError = null
      state.body = { ...initialState.body }
    },
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startOptionLoading: (state) => {
      state.isOptionLoading = true
    },
    stopOptionLoading: (state) => {
      state.isOptionLoading = false
    },
    startECertLoading: (state) => {
      state.isECertLoading = true
    },
    stopECertLoading: (state) => {
      state.isECertLoading = false
    },
    startUploadLoading: (state) => {
      state.isUploadLoading = true
    },
    stopUploadLoading: (state) => {
      state.isUploadLoading = false
    },
    setUploadSuccess: (state) => {
      state.isUploadSuccess = true
    },
    setUploadError: (state, { payload }) => {
      state.uploadError = payload
    },
    setBody: (state, { payload }) => {
      state.body = payload
    },
    setChange: (state, { payload }) => {
      const { key, value } = payload
      state[key] = value
    },
    setFieldValue: (state, { payload }) => {
      const { key, value } = payload
      _.set(state.body, key, value)

      if (key === 'setting.distribution') {
        const distribution = _.get(state.body, 'setting.distribution', []).map(
          (item) =>
            state.distributionOption.find(
              (option) => option.value === item.value,
            ),
        )
        let option = []
        _.forEach(distribution, (dis) => (option = _.concat(option, dis.level)))
        state.levelOfLearnerOption = _.uniqBy(option, 'value')
        state.body.levelOfLearner = []
      }
    },
    setCropperData: (state, { payload }) => {
      const { key, value } = payload
      state.cropper[key] = value
    },
    setPathCreateCourse: (state, { payload }) => {
      state.pathCreateCourse = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLearningPathCourses.fulfilled, (state, { payload }) => {
        state.coursesOption = _.get(payload, 'data', []).map((course) => {
          const courseCode = _.get(course, 'courseCode', course.id)
          const name = _.get(course, 'englishName', course.name)
          return {
            ...course,
            label: `${courseCode} ${name}`,
            value: _.get(course, 'uuid', ''),
          }
        })
      })
      .addCase(getLearningPathCourses.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getAllDistributionChannel.fulfilled, (state, { payload }) => {
        state.distributionOption = _.get(payload.data, 'result', []).map(
          (distribution) => ({
            label: _.get(distribution, 'distribution', ''),
            value: _.get(distribution, 'uuid', ''),
            level: _.get(distribution, 'level', []).map((levelOfLearner) => ({
              label: _.get(levelOfLearner, 'title', ''),
              value: _.get(levelOfLearner, 'uuid', ''),
            })),
          }),
        )
      })
      .addCase(getAllDistributionChannel.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getECertificationList.fulfilled, (state, { payload }) => {
        state.eCertificateList = _.get(payload.data, 'result', [])
      })
      .addCase(getECertificationList.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getAcquiredSkillList.fulfilled, (state, { payload }) => {
        state.acquireSkillList = _.get(payload.data, 'result', []).map(
          (item) => ({
            label: `${item?.skillType?.skillType || ''}: ${
              item?.acquiredSkill
            }`,
            value: _.get(item, 'uuid', ''),
          }),
        )
      })
      .addCase(getAcquiredSkillList.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getAllProductType.fulfilled, (state, { payload }) => {
        state.productTypeList = _.get(payload.data, 'result', []).map(
          (item) => ({
            label: item.productType,
            value: _.get(item, 'uuid', ''),
          }),
        )
      })
      .addCase(getAllProductType.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getDetail.fulfilled, (state, { payload }) => {
        state.body = payload.data
        state.body['advanceNotificationCheck'] = !!_.get(
          payload.data,
          'advanceNotification',
          null,
        )
        state.body['continuousNotificationsCheck'] = !!_.get(
          payload.data,
          'continuousNotifications',
          null,
        )
        state.body['eLearningLearningPathCourses'] = _.get(
          payload.data,
          'eLearningLearningPathCourses',
          [],
        ).map((course) => ({
          ...course,
          course: course.eLearningCourse || course.courseVersion,
        }))
        state.oldPeriodStart = payload.data.periodStart
        state.oldDueDate = payload.data.dueDate
        state.tab = FORM_TAB.learningPath

        const distribution = _.get(
          payload.data,
          'setting.distribution',
          [],
        ).map((item) =>
          state.distributionOption.find(
            (option) => option.value === item.value,
          ),
        )
        let option = []
        _.forEach(
          distribution,
          (dis) => (option = _.concat(option, _.get(dis, 'level', []))),
        )
        state.levelOfLearnerOption = _.uniqBy(option, 'value')
      })
      .addCase(getDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const fieldSelector = (keyField) =>
  createSelector(
    (state) => state.eLearningPathForm,
    (state) => _.get(state.body, keyField, []).map((item) => item.id),
  )

export const {
  setInitialCreateForm,
  startLoading,
  stopLoading,
  startOptionLoading,
  stopOptionLoading,
  startECertLoading,
  stopECertLoading,
  startUploadLoading,
  stopUploadLoading,
  setUploadSuccess,
  setUploadError,
  setBody,
  setChange,
  setFieldValue,
  setCropperData,
  setPathCreateCourse,
} = eLearningPathForm.actions

export default eLearningPathForm.reducer
