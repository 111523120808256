import _ from 'lodash'
import { postAddLearner } from '../../../../../services/manageClass/form'
import {
  closeDialog,
  loadingDialog,
  setDisableButton,
  setEnableButton,
} from '../../../../../redux/slices/dialog'
import { resetForm } from '../../../../../redux/slices/manageClassLearner'

export const handleCloseDialog = () => (dispatch) => {
  dispatch(resetForm())
  dispatch(closeDialog())
}

export const handleEnableButton = (e) => (dispatch) => {
  if (e.target.checked) dispatch(setEnableButton())
  else dispatch(setDisableButton())
}

export const handleNumber = (index) => {
  if (index > 0 && index < 10) return `0${index}`
  return index
}

export const handleSubmitAdd = (uploadResult) => (dispatch) => {
  dispatch(loadingDialog())
  const result = _.get(uploadResult, 'result', [])
  const userType = _.get(uploadResult, 'userType', '')
  const agentType = _.get(uploadResult, 'agentType', '')
  const userProfile = _.chain(result)
    .filter((item) => item.status === 'SUCCESS')
    .map((item) => item.uuid)
    .value()
  let staffProfile = []
  if (userType === 'STAFF') {
    staffProfile = userProfile
  }
  const body = {
    trainingPlan: _.get(uploadResult, 'trainingPlan', ''),
    userType: userType,
    agentType: agentType,
    remark: _.get(uploadResult, 'remark', ''),
    userProfile,
    staffProfile,
  }
  dispatch(postAddLearner(body))
}
