import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import {
  setBookingRoomWeekly,
  setCurrentDate,
  setFilter,
  setFilterMemo,
  setIsOpenFilterDrawer,
  setListArrayFilter,
  setMondayDate,
  setMonth,
  setReduxValue,
  setRequestType,
  setRowsPerPage,
  setSelectedDate,
  setStationList,
  setStationUuid,
  setTabActive,
  setTypeFilter,
  setYear,
  startLoading,
  stopLoading,
} from '.'
import {
  fetchBookingRoom,
  fetchRoomRequestFilter,
  handleReloadBookingRoom,
} from '../../../../modules/RoomManagement/BookingRoomSection/event'
import { startOfWeek } from 'date-fns'
import _ from 'lodash'
import {
  fetchBookingRoomWeekly,
  handleBookingRoomList,
  handleDayList,
} from '../../../../modules/RoomManagement/DashboardSection/WeeklyBookingsCard/event'
import { isValidateDate } from '../../../../utils/lib'
import dayjs from 'dayjs'
import {
  fetchDataRoomManagement,
  handleFetchStation,
  handleStationName,
} from '../../../../modules/RoomManagement/DashboardSection/event'
import { setFilterProp } from '../../../../redux/slices/table'
import { ROOM_AVAILABLE } from '../../../../constants/roomManagement'
import {
  arrayCheckboxMeetingRoom,
  arrayCheckboxRoomRequest,
  defaultFilterMeetingRoom,
  defaultFilterRoomRequest,
} from '../../../../modules/RoomManagement/FilterDrawer/model'

export const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  matcher: isAnyOf(setRequestType, setRowsPerPage),
  effect: async (action, listenerApi) => {
    const { tabActive, page, rowsPerPage, requestType } =
      listenerApi.getState().roomManagement
    const { tabActive: tabActiveOriginal, requestType: requestTypeOriginal } =
      listenerApi.getOriginalState().roomManagement
    if (
      (tabActive === tabActiveOriginal &&
        requestType === requestTypeOriginal) ||
      tabActive !== 2
    )
      return

    if (rowsPerPage.value !== 100 || page !== -1) {
      listenerApi.dispatch(
        setReduxValue({
          key: 'rowsPerPage',
          value: { label: '100', value: 100 },
        }),
      )
      listenerApi.dispatch(setReduxValue({ key: 'page', value: -1 }))
      listenerApi.dispatch(setReduxValue({ key: 'tablePage', value: 0 }))
    } else {
      listenerApi.dispatch(fetchRoomRequestFilter())
    }
  },
})

listenerMiddleware.startListening({
  actionCreator: setCurrentDate,
  effect: async (action, listenerApi) => {
    const { currentDate, selectedDate } = listenerApi.getState().roomManagement
    const mondayDate = startOfWeek(currentDate, { weekStartsOn: 1 })

    listenerApi.dispatch(setMondayDate(mondayDate))
    if (!_.isNull(selectedDate)) {
      listenerApi.dispatch(setSelectedDate(mondayDate))
    }
  },
})

listenerMiddleware.startListening({
  actionCreator: setSelectedDate,
  effect: async (action, listenerApi) => {
    const { mondayDate } = listenerApi.getState().roomManagement

    if (isValidateDate(dayjs(mondayDate).format('YYYY-MM-DD'))) {
      listenerApi.dispatch(handleDayList(mondayDate))
    }
    listenerApi.dispatch(handleBookingRoomList())
  },
})

listenerMiddleware.startListening({
  matcher: isAnyOf(setMondayDate, setStationUuid),
  effect: async (action, listenerApi) => {
    const { mondayDate, stationUuid, requestType } =
      listenerApi.getState().roomManagement
    const body = {
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      roomName: '',
      roomFunction: [],
      roomType: [],
      roomStatus: [
        ROOM_AVAILABLE.ALL_AVAILABLE,
        ROOM_AVAILABLE.SOME_TIME_AVAILABLE,
        ROOM_AVAILABLE.NOT_AVAILABLE,
      ],
      operator: '',
      status: [],
      limit: -1,
      page: 1,
      requestType: requestType,
      stationUuid: stationUuid,
    }
    const { type } = action
    if (_.isEmpty(stationUuid)) return
    listenerApi.dispatch(startLoading())

    if (isValidateDate(dayjs(mondayDate).format('YYYY-MM-DD')))
      await listenerApi.dispatch(fetchBookingRoomWeekly())
    if (type === 'roomManagement/setStationUuid') {
      listenerApi.dispatch(setFilter(defaultFilterMeetingRoom))
      listenerApi.dispatch(setFilterMemo(defaultFilterMeetingRoom))
      await listenerApi.dispatch(
        setFilterProp({
          ...body,
        }),
      )
      listenerApi.dispatch(setRequestType(requestType))
      await listenerApi.dispatch(fetchDataRoomManagement())
      listenerApi.dispatch(handleReloadBookingRoom())
      await listenerApi.dispatch(handleFetchStation())
    }

    listenerApi.dispatch(stopLoading())
  },
})

listenerMiddleware.startListening({
  actionCreator: setBookingRoomWeekly,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(handleBookingRoomList())
  },
})

listenerMiddleware.startListening({
  actionCreator: setStationList,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(handleStationName())
  },
})

listenerMiddleware.startListening({
  actionCreator: setTabActive,
  effect: async (action, listenerApi) => {
    const { stationUuid, rowsPerPage, page, tabActive, requestType } =
      listenerApi.getState().roomManagement
    listenerApi.dispatch(
      setReduxValue({
        key: 'rowsPerPage',
        value: { label: '100', value: 100 },
      }),
    )
    if (tabActive === 2) {
      listenerApi.dispatch(
        setReduxValue({
          key: 'page',
          value: -1,
        }),
      )
    }

    const body = {
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      roomName: '',
      roomFunction: [],
      roomType: [],
      roomStatus: [
        ROOM_AVAILABLE.ALL_AVAILABLE,
        ROOM_AVAILABLE.SOME_TIME_AVAILABLE,
        ROOM_AVAILABLE.NOT_AVAILABLE,
      ],
      operator: '',
      status: [],
      limit: rowsPerPage.value,
      page: page,
      requestType: requestType,
      stationUuid: stationUuid,
    }
    await listenerApi.dispatch(
      setFilterProp({
        ...body,
      }),
    )
    let newRequestType = 'ALL'
    if (action.payload?.actionFromMonthlyPlanCard) {
      // listenerApi.dispatch(setRequestType(requestType))
      newRequestType = requestType
    }

    listenerApi.dispatch(setRequestType(newRequestType))

    listenerApi.dispatch(fetchBookingRoom())
  },
})

listenerMiddleware.startListening({
  matcher: isAnyOf(setMonth, setYear),
  effect: async (action, listenerApi) => {
    const { stationUuid } = listenerApi.getState().roomManagement
    if (_.isEmpty(stationUuid)) return
    listenerApi.dispatch(fetchDataRoomManagement())
  },
})

listenerMiddleware.startListening({
  matcher: isAnyOf(setIsOpenFilterDrawer, setTypeFilter),
  effect: async (action, listenerApi) => {
    const {
      typeFilter,
      typeMemo,
      filterMemo,
      stationUuidMemo,
      stationUuid,
      page,
      rowsPerPage,
      requestType,
    } = listenerApi.getState().roomManagement

    if (typeFilter === 'meetingRoom') {
      if (
        filterMemo &&
        _.isEqual(typeFilter, typeMemo) &&
        _.isEqual(stationUuid, stationUuidMemo)
      ) {
        listenerApi.dispatch(setFilter(filterMemo))
      } else {
        listenerApi.dispatch(setFilter(defaultFilterMeetingRoom))
        listenerApi.dispatch(setFilterMemo(defaultFilterMeetingRoom))
        const body = {
          stationUuid: [stationUuid],
          roomStatus: ['ALL_AVAILABLE', 'SOME_TIME_AVAILABLE', 'NOT_AVAILABLE'],
          roomName: '',
          startDate: '',
          endDate: '',
          startTime: '',
          endTime: '',
          roomFunction: [],
          roomType: [],
          limit: -1,
          page: 1,
        }
        listenerApi.dispatch(
          setFilterProp({
            ...body,
          }),
        )
        listenerApi.dispatch(setRequestType(requestType))
      }
      listenerApi.dispatch(setListArrayFilter(arrayCheckboxMeetingRoom))
    } else if (typeFilter === 'roomRequest') {
      if (
        filterMemo &&
        _.isEqual(typeFilter, typeMemo) &&
        _.isEqual(stationUuid, stationUuidMemo)
      ) {
        listenerApi.dispatch(setFilter(filterMemo))
      } else {
        listenerApi.dispatch(setFilter(defaultFilterRoomRequest))
        listenerApi.dispatch(setFilterMemo(defaultFilterRoomRequest))
        const body = {
          startDate: '',
          endDate: '',
          startTime: '',
          endTime: '',
          roomName: '',
          operator: '',
          status: [],
          limit: rowsPerPage.value,
          page: page,
          requestType: requestType,
          stationUuid: stationUuid,
        }
        listenerApi.dispatch(
          setFilterProp({
            ...body,
          }),
        )
        listenerApi.dispatch(setRequestType(requestType))
      }

      listenerApi.dispatch(setListArrayFilter(arrayCheckboxRoomRequest))
    }
  },
})

listenerMiddleware.startListening({
  actionCreator: setReduxValue,
  effect: async (action, listenerApi) => {
    const {
      payload: { key },
    } = action
    if (key === 'tablePage') {
      listenerApi.dispatch(fetchBookingRoom())
    }
  },
})
