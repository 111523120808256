import {
  resetTablePage,
  setFilterProp,
  setFilterTotal,
  setSearchText,
  setToggleFilterDrawer,
} from '../../../redux/slices/table'
import { store } from '../../../App'
import { fetchDataList } from './fetchDataList'

export const handleFiltering =
  (mutateFilterSateToProp, mutateFilterPropToBody, downloadName) =>
  (dispatch) => {
    const { filterState } = store.getState().table
    dispatch(setSearchText(''))
    dispatch(setToggleFilterDrawer(false))
    const filterProp = mutateFilterSateToProp()
    dispatch(setFilterProp(filterProp))
    dispatch(setFilterTotal(filterState))
    dispatch(resetTablePage())
    fetchDataList(mutateFilterPropToBody, {}, downloadName).then()
  }
