import { CContainer } from '../../../../components/CRUD/components/Container'
import { crudConfig as initialCrudConfig } from './crudConfig'
import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { fetchDataList } from '../../../../components/CRUD/handler/fetchDataList'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { mutateFilterPropToBody } from './mutation/filterPropToBody'
import { mutateFilterSateToProp } from './mutation/filterStateToProp'
import CustomEnhancedTableToolbar from './components/CustomEnhanceTableToolbar'
import CFilterDrawer from '../../../../components/CRUD/components/FilterDrawer'
import { filterItems } from './model/filterItems'
import { defaultFilter } from './model/defaultFilter'
import { handleChange } from '../../../../components/CRUD/handler/handleChange'
import { handleFiltering } from './handler/handleFiltering'
import { setTableProps } from './model/setTableProps'
import HistoryDrawer from './Drawer/History'
import AssetListDrawer from './Drawer/AssetList'
import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import { handleSetDataRows } from './handler/handleSetDataRows'

export const ProductList = ({ warehouseUuid = null }) => {
  const {
    page,
    sort,
    order,
    limit,
    isOpenHistoryDrawer,
    isOpenAssetDrawer,
    isRefreshTable,
  } = useSelector(
    (state) => ({
      page: state.table.table.page,
      sort: state.table.table.sort,
      order: state.table.table.order,
      limit: state.table.table.limit,
      isOpenHistoryDrawer: state.crud.isOpenHistoryDrawer,
      isOpenAssetDrawer: state.crud.isOpenAssetDrawer,
      isRefreshTable: state.crud.isRefreshTable,
    }),
    shallowEqual,
  )
  const { crudConfig } = useSelector(
    (state) => ({
      crudConfig: state.crud.crudConfig,
    }),
    shallowEqual,
  )

  const { isFetchProductDetail } = useSelector((state) => state.crud)

  useEffect(() => {
    if (isFetchProductDetail) {
      initialConfig({ ...initialCrudConfig, warehouseUuid }, false)
    }
  }, [isFetchProductDetail])

  useEffect(() => {
    if (
      !_.isUndefined(crudConfig) &&
      crudConfig?.moduleId === 'INVENTORY_PRODUCT_WAREHOUSE' &&
      isFetchProductDetail
    ) {
      fetchDataList(mutateFilterPropToBody, setTableProps).then(() => {
        handleSetDataRows()
      })
    }
  }, [limit, order, page, sort, crudConfig, isFetchProductDetail])

  useEffect(() => {
    if (isRefreshTable) {
      fetchDataList(mutateFilterPropToBody, setTableProps).then(() => {
        handleSetDataRows()
      })
      store.dispatch(
        setFieldValue({
          key: 'isRefreshTable',
          value: false,
        }),
      )
    }
  }, [isRefreshTable])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  return (
    <>
      <CContainer
        drawer={
          <CFilterDrawer
            handleChange={handleChange}
            handleFiltering={() =>
              handleFiltering(
                mutateFilterSateToProp,
                mutateFilterPropToBody,
                setTableProps,
              )
            }
            filterItems={filterItems}
            defaultFilter={defaultFilter}
          />
        }
        sx={{ mx: 3 }}
        content={<CustomEnhancedTableToolbar />}
      />
      {isOpenHistoryDrawer && <HistoryDrawer open={isOpenHistoryDrawer} />}

      {isOpenAssetDrawer && <AssetListDrawer open={isOpenAssetDrawer} />}
    </>
  )
}
