import { requisitionSendMail } from '../../../../../utils/apiPath'
import callAxios from '../../../../../utils/baseService'
import { setFieldValue } from '../../../../../redux/slices/crud'
import { store } from '../../../../../App'

export const handleSendMailReturnProduct = async () => {
  const { formData } = store.getState().crud
  const body = {
    uuid: formData?.uuid,
  }
  store.dispatch(setFieldValue({ key: 'isLoading', value: true }))
  await callAxios
    .post(requisitionSendMail, body)
    .then(() => {
      store.dispatch(
        setFieldValue({
          key: 'isDisabledEmailButton',
          value: true,
        }),
      )
      store.dispatch(
        setFieldValue({
          key: 'hasSentMailToday',
          value: true,
        }),
      )
    })
    .catch((e) => {
      return e.message?.includes('method')
    })
  store.dispatch(setFieldValue({ key: 'isLoading', value: false }))
}
