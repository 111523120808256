import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import { setIsOpenExaminerFilterDrawer } from '../../../redux/slices/eExamination/list'
import { StyledHeadLabel } from '../../../components/FilterDrawer/Styled'
import { examinerTypes } from './model'
import * as events from './events'
import { getOptionsKey } from '../../../utils/lib'
import Drawer from '@mui/material/Drawer'

import { useFormik } from 'formik'
import { validationSchema } from '../../../constants/eExaminer'
import { initialValues } from '../../../redux/slices/eExaminer/form/model'
import { setUserDetail } from '../../..//redux/slices/eExaminer/form'
import { setSelectedExaminer } from '../../../redux/slices/eExamination/list'
import {
  getExaminationGender,
  getExaminationQualification,
  getExaminationAddress,
  getExaminationUserProfile,
  sendExaminationIdCard,
} from '../../../services/eExamination/form'
import { DialogContext } from '../../../context/dialog-context'
import Form from './form'
import { LoadingExaminer } from '../Styled'
import { validateEPermission } from '../../../utils/lib'
import { EXAMINATION_SETTING, PERMISSION } from '../../../constants/examination'

const ExaminerFilterDrawer = ({ open }) => {
  const dispatch = useDispatch()
  const [examinerTypeOption, setExaminerTypeOption] = useState([])
  const [genderOption, setGenderOption] = useState([])
  const [qualificationOption, setQualificationOption] = useState([])
  const [provinceOption, setProvinceOption] = useState([])
  const [amphureOption, setAmphureOption] = useState([])
  const [tambonOption, setTambonOption] = useState([])
  const [isDisabled, setIsDisabled] = useState(0)
  const { context, setContext: setDialog } = useContext(DialogContext)
  const dialog = context.dialog
  const [isLoading, setIsLoading] = useState(false)
  const { id: scheduleUuid } = useParams()
  const {
    eExaminerFormBody,
    genderList,
    qualificationList,
    addressList,
    userDetail,
    eExaminerDetail,
    selectedExaminer,
    isLoadingDrawer,
    loading,
    loadingAddr,
    otherDocConfig,
    otherDocs,
    examinationIdCard,
  } = useSelector(
    (state) => ({
      eExaminerFormBody: state.eExaminerForm.body,
      genderList: state.eExaminerForm.genderList,
      qualificationList: state.eExaminerForm.qualificationList,
      addressList: state.eExaminerForm.addressList,
      userDetail: state.eExaminerForm.userDetail,
      eExaminerDetail: state.eExaminerForm.eExaminerDetail,
      selectedExaminer: state.eExamList.selectedExaminer,
      isLoadingDrawer: state.eExaminerForm.isLoadingDrawer,
      loading: state.eExaminerForm.loading,
      loadingAddr: state.eExaminerForm.loadingAddr,
      otherDocConfig: state.eExamList.otherDocConfig,
      otherDocs: state.eExaminerForm.body.otherDocs,
      examinationIdCard: state.uploadFile.formData.examinationIdCard,
    }),
    shallowEqual,
  )

  const defaultDisabled = {
    prefix: true,
    firstName: true,
    lastName: true,
    gender: true,
    dateOfBirth: true,
    qualification: true,
    mobilePhone: true,
    branch: true,
    between: true,
    institution: true,
    gpa: true,
    idCardNo: true,
  }
  const isEditForm = selectedExaminer ? true : false
  const [disableInputList, setDisableInputList] = useState(defaultDisabled)

  const defaultOptions = { label: '', value: '' }
  const [userProfileOption, setUserProfileOption] = useState([])
  const [defaultUser, setDefaultUser] = useState(null)
  const [zipCodeOption, setZipCodeOption] = useState([])
  const [defaultZipCode, setDefaultZipCode] = useState(null)
  const hasDcPermission = validateEPermission({
    module: EXAMINATION_SETTING,
    permission: PERMISSION.DC,
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setDialog({
        ...dialog,
        title: 'ยืนยันบันทึกข้อมูล',
        content: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่',
        variant: 'save',
        open: true,
        isLoading: false,
        onConfirmClick: () => {
          events.submitExaminerField({
            scheduleUuid,
            values,
            isEditForm,
            setDialog,
            setIsLoading,
            selectedExaminer,
            handleCloseExaminerFilterDrawyer,
            dispatch,
            formik,
          })
        },
        onCancelClick: () =>
          setDialog({
            variant: 'save',
            content: '',
            open: false,
          }),
      })
    },
  })

  useEffect(() => {
    if (open) {
      setIsDisabled(0)
      getDefaultOptions()
      formik.setErrors([])
      dispatch(
        events.resetAddressList({
          data: [],
          setTambonOption,
          setAmphureOption,
          setProvinceOption,
          formik,
        }),
      )
      dispatch(events.resetFormUserNotFound(formik))
      dispatch(
        events.fetchExaminerData({
          selectedExaminer,
          genderList,
          qualificationList,
        }),
      )
    }
  }, [open])

  useEffect(() => {
    if (eExaminerDetail) {
      dispatch(
        events.setExaminerForm({
          eExaminerDetail,
          formik,
          handleSelectedAddress,
          setDefaultUser,
          otherDocConfig,
        }),
      )
      events.setInputDisabled({
        eExaminerFormBody,
        setDisableInputList,
        disableInputList,
        profile: null,
        isEditForm,
        hasDcPermission,
        eExaminerDetail,
      })
    }
  }, [eExaminerDetail])

  useEffect(() => {
    setIsDisabled(0)
    if (formik.values.examinerType) {
      setIsDisabled(1)
    }

    if ((userDetail?.result && userDetail?.result.length > 0) || isEditForm) {
      setIsDisabled(2)
    }

    if (events.checkValues({ formik, isEditForm, otherDocConfig })) {
      setIsDisabled(3)
    }
  }, [formik])

  useEffect(() => {
    formik.setFieldValue('otherDocs', otherDocs)
  }, [otherDocs])

  useEffect(() => {
    const getGenderList =
      genderList &&
      getOptionsKey({
        data: genderList,
        label: 'genderName',
        key: 'uuid',
      })
    setGenderOption(getGenderList)
    const getQualificationList =
      qualificationList &&
      getOptionsKey({
        data: qualificationList,
        label: 'qualificationName',
        key: 'uuid',
      })
    setQualificationOption(getQualificationList)
  }, [genderList, qualificationList, addressList])

  useEffect(() => {
    if (['นาย'].includes(eExaminerFormBody.prefix)) {
      const findGender = genderOption.find((item) => item.label === 'ช')
      dispatch(
        events.handleChangeKeyValue(formik, 'gender', findGender?.value ?? ''),
      )
    } else if (['นาง', 'นางสาว'].includes(eExaminerFormBody.prefix)) {
      const findGender = genderOption.find((item) => item.label === 'ญ')
      dispatch(
        events.handleChangeKeyValue(formik, 'gender', findGender?.value ?? ''),
      )
    }
  }, [eExaminerFormBody.prefix])

  useEffect(() => {
    setDisableInputList(defaultDisabled)
    if (userDetail?.result && userDetail?.result.length > 0) {
      const profile = userDetail.userProfile
        ? userDetail.userProfile
        : userDetail?.result[0]
      const mobileNo = profile?.mobileNo
        ? profile?.mobileNo.replace(/-/g, '')
        : ''
      dispatch(
        events.handleChangeKeyValue(
          formik,
          'dateOfBirth',
          profile.dateOfBirth ?? '',
        ),
      )
      dispatch(
        events.handleChangeKeyValue(formik, 'email', profile.email ?? ''),
      )
      dispatch(
        events.handleChangeKeyValue(formik, 'idCardNo', profile.idCardNo ?? ''),
      )
      dispatch(
        events.handleChangeKeyValue(
          formik,
          'unitNumber',
          profile.unitNumber ?? '',
        ),
      )
      dispatch(
        events.handleChangeKeyValue(formik, 'prefix', profile.prefixTH ?? ''),
      )
      dispatch(
        events.handleChangeKeyValue(
          formik,
          'firstName',
          profile.firstNameTH ?? '',
        ),
      )
      dispatch(
        events.handleChangeKeyValue(
          formik,
          'lastName',
          profile.lastNameTH ?? '',
        ),
      )
      dispatch(
        events.handleChangeKeyValue(formik, 'mobilePhone', mobileNo ?? ''),
      )
      dispatch(
        events.handleChangeKeyValue(formik, 'userProfile', profile.uuid ?? ''),
      )

      dispatch(
        events.handleChangeKeyValue(formik, 'branch', profile.branch ?? ''),
      )
      dispatch(
        events.handleChangeKeyValue(formik, 'between', profile.between ?? ''),
      )
      dispatch(
        events.handleChangeKeyValue(
          formik,
          'institution',
          profile.institution ?? '',
        ),
      )
      dispatch(events.handleChangeKeyValue(formik, 'gpa', profile.gpa ?? ''))
      dispatch(
        events.handleChangeKeyValue(
          formik,
          'qualification',
          profile?.qualification?.uuid ?? '',
        ),
      )

      if (eExaminerFormBody.examinerType === 'STAFF') {
        dispatch(
          events.handleChangeKeyValue(
            formik,
            'idCardNo',
            profile?.user?.idCardNo ?? '',
          ),
        )
        dispatch(
          events.handleChangeKeyValue(
            formik,
            'dateOfBirth',
            profile?.user?.dateOfBirth ?? '',
          ),
        )
        dispatch(
          events.handleChangeKeyValue(
            formik,
            'branch',
            profile?.user?.branch ?? '',
          ),
        )
        dispatch(
          events.handleChangeKeyValue(
            formik,
            'between',
            profile?.user?.between ?? '',
          ),
        )
        dispatch(
          events.handleChangeKeyValue(
            formik,
            'institution',
            profile?.user?.institution ?? '',
          ),
        )
        dispatch(
          events.handleChangeKeyValue(formik, 'gpa', profile?.user?.gpa ?? ''),
        )
        dispatch(
          events.handleChangeKeyValue(
            formik,
            'userProfile',
            profile?.user?.uuid ?? '',
          ),
        )
        dispatch(
          events.handleChangeKeyValue(
            formik,
            'unitNumber',
            profile?.user?.unitNumber ?? '',
          ),
        )
        dispatch(
          events.handleChangeKeyValue(
            formik,
            'unitName',
            profile?.user?.unitName ?? '',
          ),
        )
        const qualification = profile?.user?.qualification?.uuid ?? ''
        const staffMobileNo = profile?.tel ? profile?.tel.replace(/-/g, '') : '' // profile?.user?.mobileNo
        dispatch(
          events.handleChangeKeyValue(formik, 'qualification', qualification),
        )
        dispatch(
          events.handleChangeKeyValue(
            formik,
            'mobilePhone',
            staffMobileNo ?? '',
          ),
        )
      }

      const address = profile?.user?.address
        ? profile?.user?.address ?? ''
        : profile.address
      dispatch(events.handleChangeKeyValue(formik, 'address', address))
      if (profile?.province || profile?.user?.province) {
        let province = profile?.user?.province
          ? profile?.user?.province ?? ''
          : profile.province
        let amphure = profile?.user?.amphure
          ? profile?.user?.amphure ?? ''
          : profile.amphure
        let tambon = profile?.user?.tambon
          ? profile?.user?.tambon ?? ''
          : profile.tambon
        let zipCode = tambon ? tambon.zipCode : ''
        dispatch(events.handleChangeKeyValue(formik, 'zipCode', zipCode))
        amphure = {
          ...amphure,
          provinces: province,
        }
        tambon = {
          ...tambon,
          amphure,
        }
        handleSelectedAddress('', tambon)
      }

      if (
        eExaminerFormBody.examinerType === 'L' ||
        eExaminerFormBody.examinerType === 'A'
      ) {
        // const user = userDetail.user ?? ''
        const unitName = userDetail?.result[0].agentProfile?.agentUnitName
        // dispatch(events.handleChangeKeyValue(formik, 'userProfile', user ?? ''))
        dispatch(
          events.handleChangeKeyValue(formik, 'unitName', unitName ?? ''),
        )
      } else {
        dispatch(
          events.handleChangeKeyValue(
            formik,
            'unitName',
            profile.unitName ?? '',
          ),
        )
      }
      events.setInputDisabled({
        eExaminerFormBody,
        setDisableInputList,
        disableInputList,
        profile,
        isEditForm,
        hasDcPermission,
      })
    } else {
      dispatch(events.resetFormUserNotFound(formik))
    }
  }, [userDetail])

  useEffect(() => {
    if (!isEditForm) {
      if (['A', 'L'].includes(eExaminerFormBody.examinerType)) {
        fetchUserCode(false)
      }
    }
  }, [eExaminerFormBody.code])

  useEffect(() => {
    if (!isEditForm && open) {
      fetchUserCode()
      dispatch(setUserDetail(null))
    }
  }, [eExaminerFormBody.examinerType])

  const fetchUserCode = (isDefault = true) => {
    if (isDefault && !isEditForm) {
      handleSelectUser(formik, null)
      setUserProfileOption([])
    }
    dispatch(
      events.fetchUserOptions({
        userType: eExaminerFormBody.examinerType,
        userCode: eExaminerFormBody.code,
        defaultOptions,
        setUserProfileOption,
        setDefaultUser,
        defaultUser,
        isEditForm,
      }),
    )
  }

  const getDefaultOptions = async () => {
    const examinerTypesArr =
      examinerTypes &&
      getOptionsKey({
        data: examinerTypes,
        label: 'label',
        key: 'name',
      })
    setExaminerTypeOption(examinerTypesArr)
    setUserProfileOption([])
    setDefaultUser(null)
    setZipCodeOption([])
    setDefaultZipCode(null)
    dispatch(getExaminationGender())
    dispatch(getExaminationQualification())
  }

  const handleSelectedAddress = (event, item) => {
    const tambonUuid = item.uuid ?? ''
    const tambonName = item.tambonName ?? ''
    const amphureUuid = item?.amphure.uuid ?? ''
    const amphureName = item?.amphure.amphureName ?? ''
    const provinceUuid = item?.amphure?.provinces.uuid ?? ''
    const provinceName = item?.amphure?.provinces.provinceName ?? ''
    setTambonOption([{ label: tambonName, value: tambonUuid }])
    setAmphureOption([{ label: amphureName, value: amphureUuid }])
    setProvinceOption([{ label: provinceName, value: provinceUuid }])
    dispatch(events.handleChangeKeyValue(formik, 'tambon', tambonUuid))
    dispatch(events.handleChangeKeyValue(formik, 'amphure', amphureUuid))
    dispatch(events.handleChangeKeyValue(formik, 'province', provinceUuid))
  }

  useEffect(() => {
    if (eExaminerFormBody.idCardPhoto) {
      dispatch(sendExaminationIdCard(eExaminerFormBody.idCardPhoto))
    }
  }, [eExaminerFormBody.idCardPhoto])

  useEffect(() => {
    if (examinationIdCard && !examinationIdCard.isUploading) {
      if (examinationIdCard && examinationIdCard?.files?.length > 0) {
        const idCardImg = examinationIdCard.files[0]
        const key = idCardImg.key
        const displayName = idCardImg.displayName
        const fileSize = idCardImg.fileSize
        const fileType = idCardImg.fileType
        dispatch(
          events.handleChangeKeyValue(formik, 'idCardImageKey', {
            key,
            fileName: displayName,
            fileSize,
            fileType,
          }),
        )
      } else {
        dispatch(events.handleChangeKeyValue(formik, 'idCardImageKey', {}))
      }
    }
  }, [examinationIdCard])

  const onCancelClickDialog = (setDialog) => {
    setDialog({ open: false })
  }

  const handleCloseExaminerFilterDrawyer = () => {
    dispatch(setIsOpenExaminerFilterDrawer(false))
    dispatch(setSelectedExaminer(null))
    dispatch(events.resetFormAll(formik))
    setDisableInputList(defaultDisabled)
  }

  useEffect(() => {
    if (eExaminerFormBody?.zipCode?.length === 5) {
      dispatch(getExaminationAddress(eExaminerFormBody.zipCode))
    } else {
      dispatch(
        events.resetAddressList({
          data: [],
          setTambonOption,
          setAmphureOption,
          setProvinceOption,
          formik,
          resetAddr: false,
        }),
      )
    }
  }, [eExaminerFormBody.zipCode])

  useEffect(() => {
    const addressListArr = addressList.map((item) => {
      const tambonName = item.tambonName ?? ''
      const zipCode = item.zipCode ?? ''
      const amphureName = item?.amphure.amphureName ?? ''
      const provinceName = item?.amphure?.provinces.provinceName ?? ''
      const listItemText = `ตำบล${tambonName} อำเภอ${amphureName} จังหวัด${provinceName} ${zipCode}`
      return {
        label: listItemText,
        value: item.uuid,
        item: item,
      }
    })
    setZipCodeOption(addressListArr)
  }, [addressList])

  const handleSelectUser = (formik, element) => {
    if (!isEditForm) {
      let codeVal = ''
      if (element) {
        dispatch(events.handleChangeKeyValue(formik, 'code', element))
        setDefaultUser(element)
        codeVal = element.value
      } else {
        dispatch(events.handleChangeKeyValue(formik, 'code', null))
        setDefaultUser(null)
        codeVal = null
      }
      fetchUserDetail(codeVal)
    }
  }

  const fetchUserDetail = (codeVal) => {
    if (codeVal) {
      dispatch(events.resetFormUserNotFound(formik))
      let body = { userCode: codeVal }
      if (['A', 'L'].includes(eExaminerFormBody.examinerType)) {
        body = {
          ...body,
          agentType: eExaminerFormBody.examinerType,
        }
      } else {
        const userType = eExaminerFormBody.examinerType.toUpperCase()
        const status =
          eExaminerFormBody.examinerType === 'STAFF' ? ['ACTIVE'] : []
        body = {
          ...body,
          userType: userType,
          username: '',
          email: '',
          prefix: '',
          firstName: '',
          lastName: '',
          mobileNo: '',
          unitNumber: '',
          createdBy: '',
          updatedBy: '',
          limit: -1,
          order: 'ASC',
          page: 1,
          sort: 'id',
          status: status,
        }
      }
      dispatch(
        getExaminationUserProfile({
          body: body,
          type: eExaminerFormBody.examinerType,
        }),
      )
    } else {
      dispatch(events.resetFormUserNotFound(formik))
      dispatch(
        events.resetAddressList({
          data: [],
          setTambonOption,
          setAmphureOption,
          setProvinceOption,
          formik,
        }),
      )
      setDisableInputList(defaultDisabled)
      dispatch(setUserDetail(null))
    }
  }

  return (
    <Drawer
      sx={{ height: '100vh' }}
      anchor="right"
      open={open}
      onClose={handleCloseExaminerFilterDrawyer}
    >
      <Box
        sx={{
          width: 765,
          position: 'relative',
        }}
      >
        <StyledHeadLabel>
          <Box>
            <Typography variant="h5">
              {isEditForm ? 'แก้ไขข้อมูลผู้เข้าสอบ' : 'เพิ่มผู้เข้าสอบ'}
            </Typography>
            <IconButton
              data-testid="btn-close-drawer"
              color="primary"
              component="span"
              onClick={handleCloseExaminerFilterDrawyer}
            >
              <Close />
            </IconButton>
          </Box>
          <Divider />
        </StyledHeadLabel>
        <Form
          formik={formik}
          isEditForm={isEditForm}
          dispatch={dispatch}
          events={events}
          eExaminerFormBody={eExaminerFormBody}
          examinerTypeOption={examinerTypeOption}
          isDisabled={isDisabled}
          qualificationOption={qualificationOption}
          provinceOption={provinceOption}
          amphureOption={amphureOption}
          tambonOption={tambonOption}
          dialog={dialog}
          setDialog={setDialog}
          genderOption={genderOption}
          handleSelectedAddress={handleSelectedAddress}
          onCancelClickDialog={onCancelClickDialog}
          isLoading={isLoading}
          disableInputList={disableInputList}
          userProfileOption={userProfileOption}
          defaultUser={defaultUser}
          setDefaultUser={setDefaultUser}
          zipCodeOption={zipCodeOption}
          defaultZipCode={defaultZipCode}
          handleSelectUser={handleSelectUser}
          loading={loading}
          loadingAddr={loadingAddr}
          otherDocConfig={otherDocConfig}
          otherDocs={otherDocs}
        />
        <LoadingExaminer isLoading={isLoadingDrawer} />
      </Box>
    </Drawer>
  )
}

export default ExaminerFilterDrawer
