export const getLearnerList = (learners, tab, search) => {
  const learnerList = getLearnerTab(learners, tab)
  
  if (search === '') return learnerList

  return learnerList.filter(
    (user) =>
      user.firstNameTH.search(search) !== -1 ||
      user.lastNameTH.search(search) !== -1
  )
}

export const getLearnerTab = (learners, tab) => {
  const { learnerAll, learnerWait, learnerPass, learnerFail, learnerNotSent } =
    learners
  switch (tab) {
    case 'ALL':
      return learnerAll
    case 'WAITING':
      return learnerWait
    case 'PASS':
      return learnerPass
    case 'FAIL':
      return learnerFail
    case 'NOT_SENT':
      return learnerNotSent
    default:
      return learnerAll
  }
}
