import { store } from '../../../../../App'
import {
  convertFormatDateTime,
  convertNumberWithComma,
  convertFloatWithComma,
} from '../../../../../utils/lib'
import _ from 'lodash'
import { setRows } from '../../../../../redux/slices/table'
import {
  paymentMethodEnum,
  paymentMethodTxt,
} from '../../Form/enum/paymentMethod'

export const handleSetDataRows = () => {
  const { rows } = store.getState().table.table
  const modifiedRows = _.defaultTo(rows, []).map((item) => {
    const contractEndDate = item?.contractEndDate
      ? convertFormatDateTime({
          value: item?.contractEndDate,
          type: 'date',
        })
      : null
    const contractStartDate = item?.contractStartDate
      ? convertFormatDateTime({
          value: item?.contractStartDate,
          type: 'date',
        })
      : null
    const renewContractDate = item?.renewContractDate
      ? convertFormatDateTime({
          value: item?.renewContractDate,
          type: 'date',
        })
      : null

    const quantity = _.get(item, 'quantity', null)
      ? convertNumberWithComma(_.get(item, 'quantity', null))
      : null
    const unitPrice = _.get(item, 'unitPrice', null)
      ? convertFloatWithComma(_.get(item, 'unitPrice', null))
      : null
    const stockValue = _.get(item, 'stockValue', null)
      ? convertFloatWithComma(_.get(item, 'stockValue', null))
      : null
    const acceptReturnQuantity = _.get(item, 'acceptReturnQuantity', null)
      ? convertFloatWithComma(_.get(item, 'acceptReturnQuantity', null))
      : null
    return {
      ...item,
      contractEndDate,
      contractStartDate,
      renewContractDate,
      quantity,
      unitPrice,
      stockValue,
      acceptReturnQuantity,
      paymentType: paymentMethodTxt?.[_.get(item, 'paymentType', null)],
      paymentDesc:
        _.get(item, 'paymentType') === paymentMethodEnum.CREDIT_CARD
          ? _.get(item, 'creditCardName')
          : _.get(item, 'paymentType') === paymentMethodEnum.OTHER
          ? _.get(item, 'creditCardDescription')
          : '',
    }
  })
  store.dispatch(setRows(modifiedRows))
}
