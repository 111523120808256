import React from 'react'
import _ from 'lodash'
import dayjs from 'dayjs'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Launch from '@mui/icons-material/Launch'
import { useHistory, useParams } from 'react-router-dom'
import { convertFormatDateTime } from '../../../../../../../utils/lib'
import { StyledCard } from '../../../../../Styled'
import { StyledButton } from './Styled'
import {
  StyledCardContent,
  StyledContentRow,
  StyledDivider,
  StyledFooterText,
} from './Styled'
import { QUESTION_STATUS } from './model'

const Content = ({ detail }) => {
  const periodStart = _.get(detail, 'periodStart', '')
  const periodEnd = _.get(detail, 'periodEnd', '')
  const startDate = convertFormatDateTime({
    value: periodStart,
    type: 'date',
  })
  const endDate = convertFormatDateTime({
    value: periodEnd,
    type: 'date',
  })
  const lengthDate = dayjs(periodEnd).diff(dayjs(periodStart), 'day') + 1
  return (
    <StyledCard id="view">
      <StyledCardContent>
        <ContentRow
          title="สถานะของรอบ"
          content={_.get(detail, 'statusTH', '-')}
        />
        <ContentRowQuestion detail={detail} />
        <ContentRow
          title="ช่วงเวลาอบรม"
          content={`${startDate} - ${endDate} (${lengthDate} วัน)`}
        />
        <ContentRowStaff
          title="วิทยากรบรรยาย"
          content={_.get(detail, 'trainerList', '-')}
          count={_.get(detail, 'trainerCount', '0')}
          isStaff={false}
          uuid={_.get(detail, 'uuid', '')}
        />
        <ContentRowStaff
          title="วิทยากรตอบคำถาม"
          content={_.get(detail, 'adminQuestionList', '-')}
          count={_.get(detail, 'adminQuestionCount', '0')}
          isStaff={false}
          uuid={_.get(detail, 'uuid', '')}
        />
        <ContentRowStaff
          title="Admin"
          content={_.get(detail, 'adminList', '-')}
          count={_.get(detail, 'adminCount', '0')}
          isStaff={false}
          uuid={_.get(detail, 'uuid', '')}
        />

        <ContentRowStaff
          title="ผู้เรียน"
          content={_.get(detail, 'staffList', [])}
          count={
            _.get(detail, 'eLearningCourseBatchLearner', []).length > 0
              ? _.get(detail, 'eLearningCourseBatchLearner', []).length
              : 0
          }
          isStaff={true}
          uuid={_.get(detail, 'uuid', '')}
        />

        <StyledDivider />
        <FooterContent title="สร้าง" field="created" detail={detail} />
        <FooterContent title="แก้ไขล่าสุด" field="updated" detail={detail} />
      </StyledCardContent>
    </StyledCard>
  )
}

export default Content

export const ContentRow = ({ title, content }) => {
  return (
    <StyledContentRow>
      <Typography id="label" variant="body1b">
        {title}
      </Typography>
      <Typography sx={{ wordBreak: 'break-all' }} variant="body1">
        {content}
      </Typography>
    </StyledContentRow>
  )
}

export const ContentRowQuestion = ({ detail }) => {
  const { id: uuid } = useParams()
  const history = useHistory()
  const status = _.get(detail, 'questionStatus', '')
  const isNoQuestion = _.isEmpty(status)
  return (
    <StyledContentRow>
      <Typography id="label" variant="body1b">
        คำถาม
      </Typography>
      <Box sx={{ display: 'flex', gap: 3 }}>
        {isNoQuestion && <Typography>-</Typography>}
        {status === QUESTION_STATUS.WAITING && (
          <Typography color="error.main" variant="body2">
            รอการตอบ
          </Typography>
        )}
        {!isNoQuestion && (
          <Button
            variant="contained"
            size="s"
            startIcon={<Launch />}
            onClick={() => history.push(`/e-learning/question?batch=${uuid}`)}
          >
            ดูคำถาม
          </Button>
        )}
      </Box>
    </StyledContentRow>
  )
}

export const ContentRowStaff = ({ title, content, count, isStaff, uuid }) => {
  const history = useHistory()
  const learnerUrl = `/e-learning/course/batch/view/learner/${uuid}`
  return (
    <StyledContentRow>
      <Typography id="label" variant="body1b">
        {title} ({count})
      </Typography>
      <Typography sx={{ wordBreak: 'break-all' }} variant="body1">
        {content &&
          content.length > 0 &&
          _.map(
            content,
            (name) => {
              return (
                <li>
                  <Typography variant="body">{name}</Typography>
                </li>
              )
            },
            '',
          )}
      </Typography>
      {isStaff && (
        <>
          <StyledButton
            data-testid="btn-history"
            onClick={() => history.push(`${learnerUrl}`)}
          >
            <Launch />
            จัดการผู้เรียน
          </StyledButton>
        </>
      )}
    </StyledContentRow>
  )
}

export const FooterContent = ({ title, detail, field }) => {
  const name = _.get(detail, `${field}By.name`, '')
  const dateTime = convertFormatDateTime({
    value: _.get(detail, `${field}At`, ''),
    type: 'dateTime',
  })

  return (
    <StyledFooterText variant="caption" color="text.lightGray">
      {title}โดย {name} {dateTime}
    </StyledFooterText>
  )
}
