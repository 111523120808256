import { shallowEqual, useSelector } from 'react-redux'
import DragDropColumnCard from './DragDropColumnCard'
import ManageColumnCard from './ManageColumnCard'
import ContentsDrawer from './ContentMain'

const TabContentMain = () => {
  const { tabActiveColDrawer } = useSelector(
    (state) => ({
      tabActiveColDrawer: state.reportCourseSetting.tabActiveColDrawer,
    }),
    shallowEqual,
  )

  return (
    <>
      {tabActiveColDrawer === 0 && (
        <ContentsDrawer>
          <DragDropColumnCard />
        </ContentsDrawer>
      )}
      {tabActiveColDrawer === 1 && (
        <ContentsDrawer>
          <ManageColumnCard />
          <DragDropColumnCard showTitle={true} />
        </ContentsDrawer>
      )}
    </>
  )
}
export default TabContentMain
