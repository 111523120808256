import { arrayStatus } from "../../model"

export const arrayCheckbox = [
  {
    label: ' ID',
    nameCheckBox: 'idChecked',
    nameInput: 'id',
    placeholder: 'ค้นหา ID',
    type: 'text',
  },
  {
    label: 'ชื่อ Learning path',
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    placeholder: 'ค้นหาชื่อ Learning path',
    type: 'text',
  },
  {
    label: 'ชื่อหลักสูตรใน Learning path',
    nameCheckBox: 'nameCourseChecked',
    nameInput: 'nameCourse',
    placeholder: 'ค้นหาชื่อหลักสูตรใน Learning path',
    type: 'text',
  },
  {
    label: 'ช่วงเวลา เปิด-ปิด รับสมัคร',
    nameCheckBox: 'dateChecked',
    nameInput: 'date',
    placeholder: 'วัน',
    type: 'date',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: arrayStatus,
  },
]

export const defaultFilter = {
  idChecked: false,
  id: '',
  nameChecked: false,
  name: '',
  nameCourseChecked: false,
  nameCourse: '',
  dateChecked: false,
  startDate: '',
  endDate: '',
  displayDate: false,
  statusChecked: false,
  status: {
    draftChecked: false,
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
}
