import { CContainer } from '../../../../components/CRUD/components/Container'
import { CHeader } from '../../../../components/CRUD/components/Header'
import { crudConfig as initialCrudConfig } from './crudConfig'
import { useEffect, useState } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { breadcrumb } from './model/breadcrumb'
import { fetchDataList } from '../../../../components/CRUD/handler/fetchDataList'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { mutateFilterPropToBody } from './mutation/filterPropToBody'
import { handleOpenCreateDialog } from './handler/handleOpenCreateDialog'
import { Add } from '@mui/icons-material'
import { Button } from '@mui/material'
import { setFieldValue } from '../../../../redux/slices/crud'
import { productTypeList } from './model/productTypeList'
import { useHistory } from 'react-router-dom'
import { TabsSelectProductType } from './components/TabsSelectProductType'
import CFilterDrawer from '../../../../components/CRUD/components/FilterDrawer'
import { filterItems, filterItemsAsset } from './model/filterItems'
import { defaultFilter } from './model/defaultFilter'
import { handleChange } from '../../../../components/CRUD/handler/handleChange'
// import { handleFiltering } from '../../../../components/CRUD/handler/handleFiltering'
import { handleFiltering } from './handler/handleFiltering'
import { mutateFilterSateToProp } from './mutation/filterStateToProp'
import { validatePermission } from '../../../../utils/lib'
import useLocalStorage from '@rehooks/local-storage'
import { INVENTORY_SETTING, STOCK_PERMISSION } from '../../../../constants/stock'
import { handleSetDataRows } from './handler/handleSetDataRows'
import { handleChangeHeadCells } from './handler/handleChangeHeadCells'
import { store } from '../../../../App'
import callAxios from '../../../../utils/baseService'
import { stationFilter, CRUDListFilterPath } from '../../../../utils/apiPath'
import { enumStatus } from '../../../../components/CRUD/enum/status'

export const InventoryProductList = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [user] = useLocalStorage('user')
  const hasPermissionCreate = validatePermission({
    user,
    moduleType: INVENTORY_SETTING,
    permission: [STOCK_PERMISSION.ADMIN, STOCK_PERMISSION.CREATE],
  })

  const [isInitial, setIsInitial] = useState(true)
  const { page, sort, order, limit, crudConfig, tabActive } =
    useSelector(
      (state) => ({
        page: state.table.table.page,
        sort: state.table.table.sort,
        order: state.table.table.order,
        limit: state.table.table.limit,
        crudConfig: state.crud.crudConfig,
        tabActive: state.crud.tabActive,
      }),
      shallowEqual,
    )

  const initialize = async () => {
    dispatch(setFieldValue({ key: 'tabActive', value: 0 }))
    initialConfig(initialCrudConfig)
    dispatch(
      setFieldValue({
        key: 'masterData',
        value: { productType: { isLoading: false, options: productTypeList } },
      }),
    )
    dispatch(
      setFieldValue({
        key: 'formData.productType',
        value: productTypeList[0]?.value,
      }),
    )
    handleChangeHeadCells(0)
    fetchFilterData()
    setIsInitial(false)
  }

  const tableProps = {
    setTableProps: {
      placeholder: 'ค้นหาด้วยคีย์เวิร์ด',
    },
  }

  useEffect(() => {
    if (isInitial) {
      initialize()
    } else {
      if (
        !_.isUndefined(crudConfig) &&
        crudConfig.moduleId === initialCrudConfig.moduleId
      ) {
        fetchDataList(mutateFilterPropToBody, tableProps).then(() => {
          handleSetDataRows()
        })
      }
    }
  }, [limit, order, page, sort])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }

  let filterItems_ = filterItems
  if (tabActive > 0) {
    filterItems_ = filterItemsAsset
    if (tabActive === 1) {
      filterItems_ = filterItemsAsset.filter(
        (item) => item.nameInput !== 'vendor',
      )
    }
  }
  return (
    <CContainer
      drawer={
        <CFilterDrawer
          handleChange={handleChange}
          handleFiltering={() =>
            handleFiltering(
              mutateFilterSateToProp,
              mutateFilterPropToBody,
              tableProps,
            )
          }
          filterItems={filterItems_}
          defaultFilter={defaultFilter}
        />
      }
      header={
        <CHeader
          title={crudConfig.moduleTitleFull}
          breadcrumbs={breadcrumb}
          action={
            <Button
              disabled={!hasPermissionCreate}
              startIcon={<Add />}
              variant="contained"
              onClick={() => handleOpenCreateDialog(history)}
            >
              สร้างทรัพย์สิน
            </Button>
          }
        />
      }
      content={<TabsSelectProductType />}
    />
  )
}

export const fetchFilterData = async () => {
  const stationList = await fetchStationList()
  const costCenterList = await fetchCostCenterList()
  store.dispatch(
    setFieldValue({
      key: 'filterList',
      value: {
        station: stationList,
        costCenter: costCenterList,
      },
    }),
  )
}

export const fetchStationList = async () => {
  const status = ['ACTIVE']
  const body = {
    station: '',
    limit: -1,
    order: 'DESC',
    page: 1,
    sort: 'updatedAt',
    status: status,
    createdBy: '',
    updatedBy: '',
  }

  let result = []
  await callAxios
    .post(stationFilter, body)
    .then(({ data }) => {
      const response = _.get(data, 'result', [])
      response.forEach((item) => {
        const uuid = _.get(item, 'uuid', '')
        const station = _.get(item, 'station', '')
        result.push({ label: station, name: uuid })
      })
    })
    .catch((err) => {
      console.log(err)
    })
  return result
}

export const fetchCostCenterList = async () => {
  const bodyCostCenter = {
    limit: -1,
    page: 1,
    order: 'ASC',
    sort: 'updatedAt',
    quickSearch: '',
    name: '',
    costCenter: '',
    groupCode: '',
    updateStartDate: '',
    updateFinishDate: '',
    createStartDate: '',
    createFinishDate: '',
    updatedBy: '',
    status: [enumStatus.ACTIVE],
    createdBy: '',
  }

  let result = []
  await callAxios
    .post(CRUDListFilterPath, bodyCostCenter, {
      'x-type': 'COST_CENTER',
      key: 'costCenter',
    })
    .then(({ data }) => {
      const response = _.get(data, 'result', [])
      response.forEach((item) => {
        const uuid = _.get(item, 'uuid', '')
        const descriptionName = _.get(item, 'descriptionName', '')
        result.push({ label: descriptionName, value: uuid })
      })
    })
    .catch((err) => {
      console.log(err)
    })
  return result
}
