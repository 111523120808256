import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import {
  setColumnSelected,
  setIsOpenFilterDrawer,
  setIsOpenManageColumnDrawer,
  setReduxValue,
  setIsFilter,
  setTabActiveCol,
  setTypeOfValue,
  setValue,
} from '.'
import {
  COLUMN_NAME,
  TYPE_OF_VALUE,
} from '../../../../constants/reportCourseSetting/reportCourseSetting'
import {
  fetchOptions,
  fetchOptionsReportCourseSettingByKey,
  handleColumnOptions,
  handleDisabledColumnOptions,
  setMasterDataOptions,
  setOptions,
} from './event'
import _ from 'lodash'

export const listenerMiddlewareReportCourseSetting = createListenerMiddleware()

listenerMiddlewareReportCourseSetting.startListening({
  actionCreator: setColumnSelected,
  effect: async (action, listenerApi) => {
    const { columnSelected } = listenerApi.getState().reportCourseSetting
    let typeOfValue = TYPE_OF_VALUE.INPUT_NUMBER
    switch (columnSelected) {
      case COLUMN_NAME.START_DATE:
      case COLUMN_NAME.CREATED_AT:
      case COLUMN_NAME.UPDATED_AT:
        typeOfValue = TYPE_OF_VALUE.DATE_RANGE
        break
      case COLUMN_NAME.COURSE_VERSION:
      case COLUMN_NAME.BREAK_MEAL_BUDGET:
      case COLUMN_NAME.ATTENDANCE_COUNT:
      case COLUMN_NAME.ATTENDANCE_CRITERIA:
      case COLUMN_NAME.QR_CODE_TIME:
        typeOfValue = TYPE_OF_VALUE.INPUT_NUMBER
        break
      case COLUMN_NAME.COURSE_CODE:
      case COLUMN_NAME.COURSE_NAME_EN:
      case COLUMN_NAME.COURSE_NAME_TH:
      case COLUMN_NAME.COURSE_NAME_FOR_LEARNER:
      case COLUMN_NAME.COUNT_HRS:
      case COLUMN_NAME.CERTIFICATE_NAME_EN:
      case COLUMN_NAME.CERTIFICATE_NAME_TH:
      case COLUMN_NAME.DOCUMENT_LIST:
      case COLUMN_NAME.PRE_TEST_NAME:
      case COLUMN_NAME.POST_TEST_NAME:
      case COLUMN_NAME.ASSESSMENT_NAME:
      case COLUMN_NAME.OTHER_TEST_NAME:
      case COLUMN_NAME.EVALUATION_NAME:
      case COLUMN_NAME.STAFF_NAME_LIST:
      case COLUMN_NAME.CREATED_BY:
      case COLUMN_NAME.UPDATED_BY:
        listenerApi.dispatch(fetchOptionsReportCourseSettingByKey())
        typeOfValue = TYPE_OF_VALUE.INPUT_TEXT
        break
      case COLUMN_NAME.DISTRIBUTION:
      case COLUMN_NAME.ABBREVIATION_DISTRIBUTION:
      case COLUMN_NAME.ROADMAP:
      case COLUMN_NAME.ABBREVIATION_ROADMAP:
      case COLUMN_NAME.LEVEL_OF_LEARNER:
      case COLUMN_NAME.OTHER_LEVEL_OF_LEARNER:
      case COLUMN_NAME.TRAINING_PLATFORM:
      case COLUMN_NAME.ACQUIRED_SKILL:
      case COLUMN_NAME.PRODUCT_TYPE:
      case COLUMN_NAME.COURSE_LEVEL_CONTROL:
        listenerApi.dispatch(setMasterDataOptions(columnSelected))
        typeOfValue = TYPE_OF_VALUE.DROPDOWN_MULTI
        break
      case COLUMN_NAME.IS_APPROVED:
      case COLUMN_NAME.HAS_PREREQUISITE:
      case COLUMN_NAME.HAS_BREAK_MEAL:
      case COLUMN_NAME.REQUIRE_OIC_EXAM:
      case COLUMN_NAME.AGENT_CONFIRMATION:
      case COLUMN_NAME.REQUIRE_DOCUMENT:
      case COLUMN_NAME.IS_ATTENDANCE:
      case COLUMN_NAME.HAS_DOCUMENT:
      case COLUMN_NAME.STATUS:
        listenerApi.dispatch(setOptions(columnSelected))
        typeOfValue = TYPE_OF_VALUE.DROPDOWN_SINGLE
        break
      default:
        break
    }
    listenerApi.dispatch(setTypeOfValue(typeOfValue))
    listenerApi.dispatch(setValue(null))
    listenerApi.dispatch(setReduxValue({ key: 'error', value: null }))
  },
})

listenerMiddlewareReportCourseSetting.startListening({
  matcher: isAnyOf(setIsOpenFilterDrawer, setIsOpenManageColumnDrawer),
  effect: async (action, listenerApi) => {
    const { isOpenFilterDrawer, filters, isOpenManageColumnDrawer, columns } =
      listenerApi.getState().reportCourseSetting

    listenerApi.dispatch(handleColumnOptions())
    if (isOpenFilterDrawer) {
      listenerApi.dispatch(
        setReduxValue({ key: 'displayFilters', value: filters }),
      )
      listenerApi.dispatch(fetchOptions())
    }
    if (isOpenManageColumnDrawer) {
      listenerApi.dispatch(
        setReduxValue({ key: 'displayColumns', value: columns }),
      )
      listenerApi.dispatch(handleDisabledColumnOptions())
      listenerApi.dispatch(
        setTabActiveCol({ key: 'tabActiveColDrawer', value: 0 }),
      )
    }
    listenerApi.dispatch(setColumnSelected(null))
  },
})

listenerMiddlewareReportCourseSetting.startListening({
  matcher: isAnyOf(setValue, setColumnSelected),
  effect: async (action, listenerApi) => {
    const {
      value,
      columnSelected,
      isOpenFilterDrawer,
      isOpenManageColumnDrawer,
      tabActiveColCard,
      firstColumnSelected,
      secondaryColumnSelected,
      newColumnName,
    } = listenerApi.getState().reportCourseSetting

    let disabledAddBtn = true
    if (isOpenFilterDrawer) {
      disabledAddBtn =
        _.isNull(value) || value.length === 0 || _.isNull(columnSelected)
    } else if (isOpenManageColumnDrawer) {
      disabledAddBtn =
        tabActiveColCard === 0
          ? _.isNull(columnSelected)
          : _.isNull(firstColumnSelected) ||
            _.isNull(secondaryColumnSelected) ||
            _.isNull(newColumnName)
    }

    listenerApi.dispatch(
      setReduxValue({ key: 'disabledAddBtn', value: disabledAddBtn }),
    )
  },
})

listenerMiddlewareReportCourseSetting.startListening({
  actionCreator: setIsFilter,
  effect: async (action, listenerApi) => {
    const { isFilter } = listenerApi.getState().reportCourseSetting
    if (isFilter) {
      listenerApi.dispatch(setReduxValue({ key: 'tablePage', value: 0 }))
      listenerApi.dispatch(setReduxValue({ key: 'page', value: -1 }))
      listenerApi.dispatch(
        setReduxValue({
          key: 'rowsPerPage',
          value: { label: '100', value: 100 },
        }),
      )
      listenerApi.dispatch(setIsFilter(false))
    }
  },
})

listenerMiddlewareReportCourseSetting.startListening({
  actionCreator: setReduxValue,
  effect: async (action, listenerApi) => {
    const {
      payload: { key },
    } = action

    if (
      key !== 'newColumnName' &&
      key !== 'firstColumnSelected' &&
      key !== 'secondaryColumnSelected'
    )
      return
    const { firstColumnSelected, secondaryColumnSelected, newColumnName } =
      listenerApi.getState().reportCourseSetting

    listenerApi.dispatch(
      setReduxValue({
        key: 'disabledAddBtn',
        value:
          _.isNull(firstColumnSelected) ||
          _.isNull(secondaryColumnSelected) ||
          _.isNull(newColumnName),
      }),
    )
  },
})

listenerMiddlewareReportCourseSetting.startListening({
  actionCreator: setTabActiveCol,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(setColumnSelected(null))
    listenerApi.dispatch(
      setReduxValue({
        key: 'newColumnName',
        value: null,
      }),
    )
    listenerApi.dispatch(
      setReduxValue({
        key: 'firstColumnSelected',
        value: null,
      }),
    )
    listenerApi.dispatch(
      setReduxValue({
        key: 'secondaryColumnSelected',
        value: null,
      }),
    )
  },
})
