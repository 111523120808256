import _ from 'lodash'
import { BGM_STATUS } from '../../../../constants/budgetManagement/budgetManagement'
import { defaultBudgetDetails } from '../model/defaultBudgetDetails'
import { defaultBudgetSummary } from '../model/defaultBudgetSummary'

export const postBodyMutation = (data) => {
  return {
    yearBudget: _.get(data, 'yearBudget.value', 2024),
    costCenterUuid: _.get(data, 'costCenter.uuid', ''),
    costCenter: {
      costCenter: _.get(data, 'costCenter.costCenter', ''),
      descriptionName: _.get(data, 'costCenter.descriptionName', ''),
    },
    accountCode: {
      oldAccountCode: _.get(data, 'accountCode.oldAccountCode', ''),
      newAccountCode: _.get(data, 'accountCode.newAccountCode', ''),
    },
    accountCodeUuid: _.get(data, 'accountCode.uuid', ''),
    categoryAccountCode: _.get(data, 'accountCode.category.uuid', ''),
    status: _.get(data, 'status', BGM_STATUS.INACTIVE),
    budgetDetails: _.get(data, 'budgetDetails', defaultBudgetDetails),
    budgetSummary: _.get(data, 'budgetSummary', defaultBudgetSummary),
    remark: _.get(data, 'remark', null),
  }
}
