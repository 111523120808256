import { StyledRow } from '../../Styled'
import { Typography } from '@mui/material'
import { TooltipBox } from './TooltipBox'

export const RowLabels = ({
  title,
  label,
  titleSx,
  moreThanOne = false,
  listAll = [],
}) => {
  return (
    <StyledRow sx={{ gap: 1 }}>
      <Typography sx={{ ...titleSx }} variant="body2" color="text.gray">
        {title}
      </Typography>
      <Typography
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 300,
        }}
        variant="body2b"
      >
        {label} {moreThanOne && <TooltipBox tooltipTitle={listAll} />}
      </Typography>
    </StyledRow>
  )
}
