import {
  closeDialog,
  loadingDialog,
  openDialog,
  setDisableButton,
  setEnableButton,
} from '../../../../../../redux/slices/dialog'
import _ from 'lodash'
import { setFieldValue } from '../../../../../../redux/slices/crud'
import { store } from '../../../../../../App'
import { IMPORT_STATUS } from '../../enum/assetImportColumn'
import { checkAndConvertDate } from '../../handler/checkAndConvertDate'
import { paymentMethodType } from '../../enum/paymentMethod'

export const handleCloseDialog = () => (dispatch) => {
  dispatch(closeDialog())
}

export const handleEnableButton = (e) => (dispatch) => {
  if (e.target.checked) dispatch(setEnableButton())
  else dispatch(setDisableButton())
}

export const handleNumber = (index) => {
  if (index > 0 && index < 10) return `0${index}`
  return index
}

export const resetFilter = () => (dispatch) => {
  dispatch(closeDialog())
}

export const handleSubmitAdd = async () => {
  store.dispatch(loadingDialog())
  const { importData, assetData } = store.getState().crud
  let productDuplicate = null
  const data = _.get(importData, 'data', [])
    .filter((item) => item.status === IMPORT_STATUS.SUCCESS)
    .map((item, index) => {
      const product = {
        label: _.get(item, 'productName', '-'),
        value: _.get(item, 'productUuid'),
      }

      productDuplicate = _.find(assetData, (item) => {
        return item.productName === product.label
      })

      if (!_.isEmpty(productDuplicate)) {
        store.dispatch(
          openDialog({
            type: 'fail',
            title: 'ผิดพลาด',
            message: `ทรัพย์สิน ${product.label} ซ้ำ`,
            handleConfirm: () => {
              store.dispatch(handleCloseDialog())
            },
          }),
        )
      }

      const rowIndex = _.size(assetData) + index + 1
      const paymentType = item?.paymentType
        ? paymentMethodType.find(
            (data) => data.label === item.paymentType.trim(),
          )
        : ''
      return {
        rowIndex,
        uuid: _.get(item, 'uuid', undefined),
        productName: _.get(item, 'productName', ''),
        product,
        productStockAsset: product,
        productAsset: product,
        productLicense: product,
        quantity: _.get(item, 'quantity', null),
        unitPrice: _.get(item, 'unitPrice', null),
        stockValue: _.get(
          item,
          'stockValue',
          _.get(item, 'quantity', 0) * _.get(item, 'unitPrice', 0),
        ),
        skuNo: _.get(item, 'skuNo', ''),
        costCenter: _.get(item, 'costCenter', ''),
        assetNo: _.get(item, 'assetNo', ''),
        contractStartDate: checkAndConvertDate(
          _.get(item, 'contractStartDate', null),
        ),
        contractEndDate: checkAndConvertDate(
          _.get(item, 'contractEndDate', null),
        ),
        renewContractDate: checkAndConvertDate(
          _.get(item, 'renewContractDate', null),
        ),
        holder: _.get(item, 'holder', ''),
        ownerFName: _.get(item, 'firstNameHolder', ''),
        ownerLName: _.get(item, 'lastNameHolder', ''),
        ownerEmail: _.get(item, 'emailHolder', ''),
        payer: _.get(item, 'payer', ''),
        payerFName: _.get(item, 'firstNamePayer', ''),
        payerLName: _.get(item, 'lastNamePayer', ''),
        payerEmail: _.get(item, 'emailPayer', ''),
        paymentType: paymentType,
        paymentDesc: _.get(item, 'creditCardName', ''),
      }
    })
  if (!productDuplicate) {
    store.dispatch(
      setFieldValue({
        key: 'assetData',
        value: [...assetData, ...data],
      }),
    )

    store.dispatch(handleCloseDialog())
  }
}
