import { store } from '../../../App'
import {
  setColumnSelected,
  setColumns,
  setIsFilter,
  setReduxValue,
} from '../../../redux/slices/reportCourseSetting/List'
import _ from 'lodash'
import { object, string } from 'yup'
import { fetchReportCourseSetting } from '../List/event'
import {
  COLUMN_NAME,
  COLUMN_NAME_TH,
} from '../../../constants/reportCourseSetting/reportCourseSetting'
import { handleDisabledColumnOptions } from '../../../redux/slices/reportCourseSetting/List/event'

export const onDragEnd = (event) => (dispatch) => {
  if (!event.destination) return
  if (event.destination.index === event.source.index) return
  const results = reorder({
    startIndex: event.source.index,
    endIndex: event.destination.index,
  })

  dispatch(setReduxValue({ key: 'displayColumns', value: results }))
}

export const reorder = (payload) => {
  const { startIndex, endIndex } = payload
  const { displayColumns } = store.getState().reportCourseSetting
  const listColumns = Array.from(displayColumns)
  const [removed] = listColumns.splice(startIndex, 1)
  listColumns.splice(endIndex, 0, removed)

  return _.filter(listColumns, (item, index) => {
    return {
      ...item,
      id: `id-${index + 1}`,
      sequence: index + 1,
    }
  })
}

export const handleDeleteColumn = (id) => async (dispatch) => {
  const { displayColumns } = store.getState().reportCourseSetting

  const newColumns = _.filter(displayColumns, (column) => column.id !== id)
  await dispatch(setReduxValue({ key: 'displayColumns', value: newColumns }))
  await dispatch(handleDisabledColumnOptions())
}

export const handleSaveColumns = () => async (dispatch) => {
  const { displayColumns } = store.getState().reportCourseSetting
  localStorage.setItem('columns', JSON.stringify(displayColumns))
  await dispatch(setReduxValue({ key: 'hasSortedColumns', value: true }))
  await dispatch(setIsFilter(true))
  await dispatch(setColumns(displayColumns))
  await dispatch(fetchReportCourseSetting())
}

export const handleChange =
  ({ key, value }) =>
  (dispatch) => {
    dispatch(setReduxValue({ key, value }))
  }

export const handleAddColumn =
  ({ type = 'default' }) =>
  async (dispatch) => {
    const {
      displayColumns,
      columnSelected,
      newColumnName,
      firstColumnSelected,
      secondaryColumnSelected,
    } = store.getState().reportCourseSetting

    const maxIdColumn = _.maxBy(displayColumns, 'id')
    let newColumn = {
      id: maxIdColumn ? maxIdColumn.id + 1 : 1,
      type: type,
      minWidth: columnSelected === COLUMN_NAME.NO ? 60 : 160,
    }
    const key = _.findKey(COLUMN_NAME, (col) => col === columnSelected)
    if (type === 'default') {
      newColumn = {
        ...newColumn,
        name: columnSelected,
        label: COLUMN_NAME_TH[key],
      }
    } else if (type === 'custom') {
      newColumn = {
        ...newColumn,
        name: newColumnName,
        label: newColumnName,
        column: [firstColumnSelected, secondaryColumnSelected],
      }
    }

    const validationResults = await validationColumn(newColumn)

    if (
      (_.isEmpty(validationResults.inner) && type === 'custom') ||
      type === 'default'
    ) {
      const newColumns = [...displayColumns, newColumn]
      await dispatch(
        setReduxValue({ key: 'displayColumns', value: newColumns }),
      )
      await dispatch(handleDisabledColumnOptions())
      await dispatch(setReduxValue({ key: 'newColumnName', value: null }))
      await dispatch(setReduxValue({ key: 'firstColumnSelected', value: null }))
      await dispatch(
        setReduxValue({ key: 'secondaryColumnSelected', value: null }),
      )
      await dispatch(setColumnSelected(null))
    } else {
      dispatch(
        setReduxValue({ key: 'error', value: validationResults.message }),
      )
    }
  }

export const validationColumn = (column) => {
  let validateSchema = object().shape({
    name: string()
      .required('กรุณากรอกชื่อ')
      .max(100, 'ระบุได้ไม่เกิน 100 ตัวอักษร')
      .nullable(),
  })

  return validateSchema.validate(column, { abortEarly: false }).catch((e) => e)
}
