import { CustomTextArea } from '../../../../components/CRUD/components/CustomTextArea'
import { Box, Typography } from '@mui/material'
import { ViewRow } from '../../../../components/CRUD/components/StyledViewBox'
import { useSelector, shallowEqual } from 'react-redux'
import { tabTypeEnum, tabTypeTxtEnum } from './enum/requisitionTypeEnum'
import { MonthlyForm } from './components/MonthlyForm'
import { CustomDropdown } from '../../../../components/CRUD/components/CustomDropdown'
import { handleSelectWarehouse } from './handler/handleSelectWarehouse'

export const Content = () => {
  const { requisitionType } = useSelector(
    (state) => ({
      requisitionType: state.crud.formData.requisitionType,
    }),
    shallowEqual,
  )

  return (
    <>
      <Typography variant="h6">ข้อมูลพื้นฐาน</Typography>
      <Box>
        <ViewRow
          titleSx={{ minWidth: 150 }}
          title="ประเภทรายการเบิก"
          value={tabTypeTxtEnum?.[requisitionType]}
          boxSx={{ mb: 2 }}
        />
        {requisitionType === tabTypeEnum.MONTHLY && <MonthlyForm />}

        {requisitionType === tabTypeEnum.OTHER && (
          <Box mb={2}>
            <CustomDropdown
              required={true}
              fieldName="warehouse"
              labelName="คลังทรัพย์สิน"
              placeholder="เลือก คลังทรัพย์สิน"
              // disabled={false}
              handleExtraChange={(event, value) => {
                handleSelectWarehouse(value)
              }}
            />
          </Box>
        )}
        <CustomTextArea
          fieldName="annotation"
          placeholder="กรอกหมายเหตุ"
          labelName="หมายเหตุ"
        />
      </Box>
    </>
  )
}
