import React from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone'
import Button from '@mui/material/Button'

import Breadcrumbs from '../../../components/Breadcrumbs'
import { breadcrumbList } from '../model'
import {
    StyledHeaderContainer,
    StyledHeaderButtons,
} from './Styled'


const Header = ({
    formik,
    setIsHistoryDrawer,
}) => {
    // const hasCreatePermission = validateEPermission({
    //     module: E_CERTIFICATE_SETTING,
    //     permission: PERMISSION.CREATE,
    // })
    // const history = useHistory()
    // const hasViewBackgroundPermission = validateEPermission({
    //     module: E_CERTIFICATE_SETTING,
    //     permission: PERMISSION.VIEW,
    // })
  return (
    <StyledHeaderContainer>
        <Box>
            <Typography variant='h4'>
                ตั้งค่าระบบ
            </Typography>
            <Breadcrumbs menuList={breadcrumbList} />
        </Box>

        <StyledHeaderButtons>
            {/* {hasViewBackgroundPermission && ( */}
                <Button
                    startIcon={<RestoreTwoToneIcon />}
                    data-testid="btn-history"
                    variant="outlined"
                    onClick={() => setIsHistoryDrawer(true)}
                    size="m"
                >
                    ประวัติการแก้ไข
                </Button>
            {/* )} */}

            {/* {hasCreatePermission && ( */}
                <Button
                    data-testid="btn-save"
                    variant="contained"
                    onClick={() => formik.handleSubmit()}
                    size="m"
                >
                    บันทึก
                </Button>
            {/* )} */}
        </StyledHeaderButtons>
    </StyledHeaderContainer>
  )
}

export default Header
