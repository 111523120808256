import {
  defaultColumns,
  filterColumnOptions,
  COLUMN_NAME,
  COLUMN_NAME_TH,
} from './courseSetting'

export const REPORTS_CONST = {
  COURSE_SETTING: {
    defaultColumns,
    filterColumnOptions: filterColumnOptions,
    COLUMN_NAME,
    COLUMN_NAME_TH,
  },
}
